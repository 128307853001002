import React, { useState, useEffect } from "react";
import axios from "axios";
import { Navbar } from "../../../../../../nav-fotter/navbar";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";
import {
  Box,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Checkbox,
  FormControlLabel,
  TextField,
  Container,
} from "@mui/material";

export const PrevisualizacionEncuestaCLiente = ({ id }) => {
  const { UserDataMarca, Empresa, Token } = useUserEmpresa();
  const [EncuestaId, setEncuestaId] = useState({});
  const [loading, setLoading] = useState(true);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const fetchEncuestas = async () => {
    if (!id || !Token) return; // Verificar si el ID y el Token están presentes
    setLoading(true); // Inicia el estado de carga
    try {
      const response = await axios.get(
        `${backendURL}/api/get/encuestas-especifica/${id}`, // Ruta que coincide con tu backend
        {
          headers: {
            Authorization: `Bearer ${Token}`, // Token para autenticar
          },
        }
      );
     
      setEncuestaId(response.data.data); // Asegúrate de que sea el atributo correcto
    } catch (error) {
      console.error("Error al obtener encuestas:", error);
    } finally {
      setLoading(false); // Finaliza el estado de carga
    }
  };

  useEffect(() => {
    fetchEncuestas(); // Llamar a la función al montar el componente
  }, [id, Token, backendURL]);

  // Crear una lista de las preguntas que no están vacías
  const preguntas = [
    "pregunta_1", "pregunta_2", "pregunta_3", "pregunta_4", "pregunta_5", 
    "pregunta_6", "pregunta_7", "pregunta_8", "pregunta_9", "pregunta_10"
  ];

  const validQuestions = preguntas.filter(pregunta => EncuestaId[pregunta]);

  const [answers, setAnswers] = useState(Array(validQuestions.length).fill(null));
  const [activeStep, setActiveStep] = useState(0);

  const handleAnswer = (value) => {
    const updatedAnswers = [...answers];
    updatedAnswers[activeStep] = value;
    setAnswers(updatedAnswers);

    if (activeStep < validQuestions.length - 1) {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const questions = Object.keys(EncuestaId) // Filtra las preguntas
  .filter((key) => key.startsWith("pregunta_") && EncuestaId[key] !== null && EncuestaId[key] !== "")
  .map((key) => ({
    key,
    value: EncuestaId[key],
  }));

  return (
    <Container maxWidth="md">
      <Box sx={{ display: "flex", flexDirection: "column",backgroundColor: UserDataMarca?.color_1 || "#ffff", justifyContent: "center", alignItems: "center", backgroundColor: UserDataMarca?.color_1 || "#E1DBE6", minHeight: "100vh", padding: 0 }}>
         
        
        {/* Información de la encuesta */}
        <Box sx={{ textAlign: "center", mb: 4 }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "30px",
              marginBottom: "30px",
            }}
          >
            <div style={{ width: "70px" }}>
              {UserDataMarca?.logo ? (
                <img
                  src={`${backendURL}/${UserDataMarca.logo}`}
                  className="marcoimglogoempresademo"
                  alt="Smart Memorial"
                  title="SmartMemorial"
                />
              ) : (
                <></>
              )}
            </div>
            <Typography
              variant="h4"
              sx={{
                color:
                  UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                    ? UserDataMarca.color_4
                    : "#694B81",
                fontFamily: "Poppins",
                textTransform: "capitalize",
                fontWeight: "600",
                fontSize: "1.3rem",
              }}
            >
              {Empresa?.nombre_empresa || "Nombre Empresa"}
            </Typography>
          </Box>

          <Typography
            variant="h5"
            sx={{
              color:
                UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                  ? UserDataMarca.color_4
                  : "#694B81",
              fontFamily: "Poppins",
              textTransform: "capitalize",
              fontWeight: "500",
              fontSize: "1.1rem",
            }}
          >
            {EncuestaId.nombre_encuesta}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color:
                UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                  ? UserDataMarca.color_4
                  : "#694B81",
              fontFamily: "Poppins",
              textTransform: "capitalize",
              fontWeight: "500",
            }}
          >
            {EncuestaId.descripcion}
          </Typography>
        </Box>

        {/* Stepper vertical */}
        <Box
          sx={{
            width: "100%",
            maxWidth: '80%',
            border:'2px solid background.paper',
            padding: 3,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
        {/* Lógica para mostrar preguntas */}
        <Stepper activeStep={activeStep} orientation="vertical">
          {questions.map((question, index) => {
            const isCompleted = answers[index] !== null;
            const isCurrent = index === activeStep;
            const isFuture = index > activeStep;
  
            return (
              <Step key={question.key}>
                <StepLabel
                  sx={{
                    "&  .Mui-active":{
                        color: UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                        ? UserDataMarca.color_4 : "#694B81"
                    },
                   "& .MuiStepLabel-label": {
      color: isCurrent
        ? UserDataMarca?.color_4 || "#694B81" // Color para el paso actual.
        : isCompleted || isFuture
        ? UserDataMarca?.color_3|| "text.secondary" // Color para pasos completados o futuros.
        : "text.secondary", // Color predeterminado.
      fontWeight: isCurrent ? "bold" : "normal", // Negrita para el paso actual.
    },
                    "& .MuiStepIcon-root":{
                        
                        color: UserDataMarca?.color_3 && UserDataMarca.color_3 !== "undefined"
                        ? UserDataMarca.color_3
                        : "#694B81"
                    },
                    "& .MuiStepIcon-text":{
                        fill:UserDataMarca?.color_4 || "#694B81"
                    },
                    
                    
                  }}
                >
                  
                  {question.value}
                </StepLabel>
                <Box
                  sx={{
                    mt: 2,
                    mb: 2,
                    opacity: isCurrent ? 1 : 0.5, 
                    transform: isCurrent ? "translateY(0)" : "translateY(20px)",
                    transition: "opacity 500ms ease-in-out, transform 500ms ease-in-out",
                    visibility: isCompleted || isFuture || isCurrent ? "visible" : "hidden",
                  }}
                >
                  {question.key === "pregunta_10" ? (
                    // Si es la pregunta_10, mostramos un input de texto
                    <>
                   
                      <TextField
                        fullWidth
                        sx={{
                            marginTop:'-10px',
                            color:UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                            ? UserDataMarca.color_4
                            : "#694B81",
                            '& .MuiOutlinedInput-input':{
                                color:UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                            ? UserDataMarca.color_4
                            : "#694B81",
                            },
                            '& .MuiOutlinedInput-root':{
                                color:UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                            ? UserDataMarca.color_4
                            : "#694B81",
                            border:'1px solid',
                            borderColor:UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                            ? UserDataMarca.color_4
                            : "#694B81",
                            
                            },
                        }}
                        variant="outlined"
                        value={answers[index] || ""}
                        onChange={(event) => {
                          const updatedAnswers = [...answers];
                          updatedAnswers[index] = event.target.value;
                          setAnswers(updatedAnswers);
                        }}
                        placeholder="Escribe tu respuesta aquí..."
                        disabled={!isCurrent}
                      />
                    </>
                  ) : (
                    // Para las preguntas de 1 a 9, usamos checkboxes
                    <div style={{
                         marginTop:'-10px',
                         marginBottom:'0px'
                    }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            
                            borderColor: UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                              ? UserDataMarca.color_4
                              : "#694B81",
                            color: UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                              ? UserDataMarca.color_4
                              : "#694B81",
                              "& .MuiSvgIcon-root":{
                                fill:"#68A354"
                              }
                          }}
                          checked={answers[index] === "Sí"}
                          onChange={() => handleAnswer("Sí")}
                          disabled={!isCurrent}
                        />
                      }
                      label="Sí"
                      sx={{
                        color: UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                          ? UserDataMarca.color_4
                          : "#694B81",
                          '& .MuiTypography-root':{
                            color: UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                          ? UserDataMarca.color_4
                          : "#694B81",
                          },
                          "&.Mui-disabled .MuiTypography-root": {
                            color: UserDataMarca?.color_3 || "#694B81",
                          },
                      }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          sx={{
                            borderColor: UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                              ? UserDataMarca.color_4
                              : "#694B81",
                            color: UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                              ? UserDataMarca.color_4
                              : "#694B81",
                              "& .MuiSvgIcon-root":{
                                fill:"#68A354"
                              }
                          }}
                          checked={answers[index] === "No"}
                          onChange={() => handleAnswer("No")}
                          disabled={!isCurrent}
                        />
                      }
                      label="No"
                      sx={{
                        color: UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                          ? UserDataMarca.color_4
                          : "#694B81",
                          '& .MuiTypography-root':{
                            color: UserDataMarca?.color_4 && UserDataMarca.color_4 !== "undefined"
                          ? UserDataMarca.color_4
                          : "#694B81",
                          },
                          "&.Mui-disabled .MuiTypography-root": {
                            color: UserDataMarca?.color_3 || "#694B81",
                          },
                      }}
                    />
                  </div>
                  )}
                </Box>
              </Step>
            );
          })}
        </Stepper>
        <Box
  sx={{
    mt: 4,
    display: "flex",
    justifyContent: "space-between",
  }}
>
  {/* Botón Atrás */}
  <Button
    variant="contained"
    sx={{
      backgroundColor: UserDataMarca?.color_2 || "#694B81",
      color: "#fff",
      '&:disabled': {
        backgroundColor: "#ccc",
      },
       textTransform:'capitalize'
    }}
    onClick={handleBack}
    disabled={activeStep === 0}
  >
    Atrás
  </Button>

  {/* Botón Siguiente */}
  <Button
    variant="contained"
    sx={{
      backgroundColor: UserDataMarca?.color_2 || "#68A354",
      color: "#fff",
      '&:disabled': {
        backgroundColor: "#ccc",
      },
       textTransform:'capitalize'
    }}
    onClick={() => setActiveStep((prevStep) => prevStep + 1)}
    disabled={
      answers[activeStep] === null || activeStep === validQuestions.length - 1
    }
  >
    Siguiente
  </Button>
</Box>

        </Box>
      </Box>
    </Container>
  );
};
      


