import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import EmpresaLayout from "../../../../../../../EmpresaLayout";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";
import {
    Container,
    Grid,
    Box,
    CircularProgress,
    Modal,
    Typography,
    Button,
    IconButton,
    Alert,
    Card,
    CardContent,
  } from "@mui/material";
  import {  Info,  } from '@mui/icons-material';
  import Diversity2Icon from '@mui/icons-material/Diversity2';
  import MailOutlineIcon from '@mui/icons-material/MailOutline';
  import { CgCardHearts } from "react-icons/cg";
  import { GrInfo } from "react-icons/gr";




export const HomeCorreo  = ({ setShowNavbarFooter }) => {
        const { Token, UserDataMarca, Empresa } = useUserEmpresa();
        const { id } = useParams();
        const backendURL = process.env.REACT_APP_BACKEND_URL;

        
        useEffect(() => {
                setShowNavbarFooter(false);
                return () => {
                    setShowNavbarFooter(true);
                };
            }, [setShowNavbarFooter]);

    return(
        <EmpresaLayout>
            <Container maxWidth="lg">
            
                 <Box sx={formStyle}>
                            <div>
                              <Typography
                                variant="h4"
                                align="center"
                                gutterBottom
                                sx={tituloStyle}
                              >
                               Correos Especiales "Recordatorios y mensajes personalizados de fidelización"
                              </Typography>
                            </div>
                            <Grid container spacing={3} justifyContent="center">
                                {/* Card 1: Envío de Correos de Recordatorio */}
                                <Grid item xs={12} sm={4}>
                                    <Card sx={{borderRadius:'10px', background:'#ffff', height:'300px'}}>
                                    <CardContent sx={{display:'flex', alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                        <Diversity2Icon sx={{ fontSize: 50, color: UserDataMarca?.color_1 && UserDataMarca?.color_1 !== "undefined"
                                            ? UserDataMarca?.color_1
                                            : "#585857", marginBottom: 2 }} />
                                        <Typography variant="h6" gutterBottom sx={subtituloStyle}>
                                        Envío de Correos de Recordatorio
                                        </Typography>
                                        <Typography variant="body2" sx={subtituloCuerpoStyle}>
                                        Envía correos de recordatorio a los seres queridos para mantener viva la memoria 
                                        y conexión con aquellos que siempre estarán en nuestros corazones.
                                        Personaliza los mensajes y ajusta los detalles según tus necesidades.
                                        </Typography>
                                    </CardContent>
                                    </Card>
                                </Grid>

                                {/* Card 2: Envío de Correos Informativos */}
                                <Grid item xs={12} sm={4}>
                                <Card sx={{borderRadius:'10px', background:'#ffff', height:'300px'}}>
                                <CardContent sx={{display:'flex', alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                        <Info  sx={{ fontSize: 50, color: UserDataMarca?.color_1 && UserDataMarca?.color_1 !== "undefined"
                                            ? UserDataMarca?.color_1
                                            : "#585857", marginBottom: 2 }} />
                                        <Typography variant="h6" gutterBottom sx={subtituloStyle}>
                                        Envío de Correos Informativos
                                        </Typography>
                                        <Typography variant="body2" sx={subtituloCuerpoStyle}>
                                        Envía mensajes informativos y personalizados a tus clientes registrados,
                                        manteniéndolos actualizados con ofertas exclusivas, novedades y todo lo relacionado con tu empresa.
                                        Fortalece la relación con tus clientes y asegúrate de que siempre estén al tanto de lo que tu negocio tiene para ofrecer.
                                        </Typography>
                                    </CardContent>
                                    </Card>
                                </Grid>

                                {/* Card 3: Fidelización Inteligente */}
                                <Grid item xs={12} sm={4}>
                                <Card sx={{borderRadius:'10px', background:'#ffff', height:'300px'}}>
                                <CardContent sx={{display:'flex', alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                        <MailOutlineIcon sx={{ fontSize: 50, color: UserDataMarca?.color_1 && UserDataMarca?.color_1 !== "undefined"
                                            ? UserDataMarca?.color_1
                                            : "#585857", marginBottom: 2 }} />
                                        <Typography variant="h6" gutterBottom sx={subtituloStyle}>
                                        Fidelización Inteligente
                                        </Typography>
                                        <Typography variant="body2"  sx={subtituloCuerpoStyle}>
                                        Con la fidelización inteligente,
                                        crea correos electrónicos personalizados para usuarios o usuarios específicos, 
                                        permitiendo una comunicación más cercana y significativa. Fomenta un vínculo emocional único,
                                        y facilita la creación de mensajes personalizados 
                                        </Typography>
                                    </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>

                          </Box>
                 <Box sx={formStyle}>
                            <div>
                                <Typography
                                    variant="h4"
                                    align="center"
                                    gutterBottom
                                    sx={tituloStyle}
                                >
                                Sección de envió de correo electrónico 
                                </Typography>
                            </div>
                            <Grid container spacing={3} justifyContent="center">
                                {/* Card 1: Envío de Correos de Recordatorio */}
                                <Grid item xs={12} sm={4}>
                                    <Link to={`/correo/memorial/${id}`}>
                                        <Card  sx={{
                                                borderRadius: '10px',
                                                padding: '20px',
                                                cursor:'pointer',
                                                transition: 'transform 0.3s ease-in-out',
                                                '&:hover': {
                                                    transform: 'scale(1.05)', // Escalar el card al hacer hover
                                                },
                                                }}>
                                        <CardContent sx={{display:'flex', alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                            <CgCardHearts style={{ fontSize: 60, color:'#ffff', }} />
                                            <Typography variant="h6" gutterBottom sx={subtituloDosStyle}>
                                            Correos Memoriales
                                            </Typography>
                                            
                                        </CardContent>
                                        </Card>
                                    </Link>
                                </Grid>

                                {/* Card 2: Envío de Correos Informativos */}
                                <Grid item xs={12} sm={4}>
                                <Link to={`/correo/informacion/${id}`}>
                                <Card  sx={{
                                        borderRadius: '10px',
                                        padding: '20px',
                                        transition: 'transform 0.3s ease-in-out',
                                        cursor:'pointer',
                                        '&:hover': {
                                            transform: 'scale(1.05)', // Escalar el card al hacer hover
                                        },
                                 }}>
                                <CardContent sx={{display:'flex', alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                        <GrInfo   style={{ fontSize: 60, color:'#ffff', }} />
                                        <Typography variant="h6" gutterBottom sx={subtituloDosStyle}>
                                        Envío de Correos Informativos
                                        </Typography>
                                        
                                    </CardContent>
                                    </Card>
                                    </Link>
                                </Grid>

                                {/* Card 3: Fidelización Inteligente */}
                                <Grid item xs={12} sm={4}>
                                <Link to={`/correo/personalizado/${id}`}>
                                <Card sx={{
                                    borderRadius: '10px',
                                    padding: '20px',
                                    cursor:'pointer',
                                    transition: 'transform 0.3s ease-in-out',
                                    '&:hover': {
                                        transform: 'scale(1.05)', // Escalar el card al hacer hover
                                    },
                                    }}>
                                <CardContent sx={{display:'flex', alignItems:'center', justifyContent:'center',flexDirection:'column'}}>
                                        <MailOutlineIcon sx={{ fontSize: 60, color: '#ffff',  }} />
                                        <Typography variant="h6" gutterBottom sx={subtituloDosStyle}>
                                        Fidelización Inteligente
                                        </Typography>
                                        
                                    </CardContent>
                                    </Card>
                                    </Link>
                                </Grid>
                            </Grid>                    
                 </Box>
                  
            </Container>
        </EmpresaLayout>
    )
}


const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop:'50px',
    padding:'10px'
};

const tituloStyle = {
    fontFamily:'Poppins',
    color:'#585857',
    fontSize:'1.3rem',
    fontWeight:'600',
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'center'
} 

const subtituloStyle = {
    fontFamily:'Poppins',
    color:'#585857',
    fontSize:'1rem',
    fontWeight:'500',
    marginBottom:'10px',
    textAlign:'center',
    
}

const subtituloCuerpoStyle = {
    fontFamily:'Poppins',
    color:'#585857',
    fontSize:'0.9rem',
    fontWeight:'500',
    marginBottom:'10px',
    textAlign:'center',
    
}

const subtituloDosStyle = {
    fontFamily:'Poppins',
    color:'#ffff',
    fontSize:'1rem',
    fontWeight:'600',
    marginTop:'10px',
    marginBottom:'0px',
    textAlign:'center',
    
}