import React, { useEffect, useState } from "react";
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";
import { useNavigate, useParams } from "react-router-dom";
import EmpresaLayout from "../../../../../EmpresaLayout";

/* componenete estrcutura */
import { EncabezadoAdmiEmpresa } from "../ComponenteEstructura/EncabezadoEmpresaAdmi";
import { CodigosQrInnicioEmpresaAdmi } from "../ComponenteEstructura/InfoCodigosQR";
import { CalendarioMemorial } from "../ComponenteEstructura/CalendarioMemorial";
import { InformacionSucursales } from "../ComponenteEstructura/IndexComponenetesSucursales";
/* libreria UI */
import { Container, Grid, Box, CircularProgress} from '@mui/material';

// Configuración de axios-rate-limit
const https = rateLimit(axios.create(), {
    maxRequests: 2, // Número máximo de solicitudes permitidas
    perMilliseconds: 1000, // Tiempo en milisegundos
});

export const IndexEmpresaAdmin = ({ setShowNavbarFooter }) => {
    const { Token, setAdminUserEmpresaToken, Empresa, Usuario } = useUserEmpresa();
    const navigate = useNavigate(); // Inicializa navigate
    const { id } = useParams();
    const [usuarioData, setUsuarioData] = useState([]);
    const [empresaData, setEmpresaData] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [loading, setLoading] = useState(false);

   

    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

    useEffect(() => {
        const storedToken = sessionStorage.getItem('Token');  
        if (storedToken) {
            setAdminUserEmpresaToken(storedToken);
        } else {    
            navigate("/Inicio-Sesion-Empresas");
        }
        
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [navigate, setAdminUserEmpresaToken, Token, setShowNavbarFooter]); 
    

    useEffect(() => {
        if (!Token) return; 

        const fetchEmpresaData = async () => {
            setLoading(true);
            try {
                const response =  await https.get(`${backendURL}/api/usuarioEmpresa/${id}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${Token}` // Agregar el token en los headers
                    }
                });

                if (response.status === 200) {
                    setUsuarioData(response.data.user); 
                    setEmpresaData(response.data.empresa);// Guarda los datos de la empresa
                } else {
                    console.error("Error al obtener datos de la empresa");
                }
            } catch (error) {
                console.error("Error en la solicitud:", error);
            } finally {
                setLoading(false);
            }
        };

        if (Token && id) {
            fetchEmpresaData(); // Llamar a la función si el token y el id están presentes
        }
    }, [Token, id, backendURL]);

   


    return (
        <EmpresaLayout >
        <Container maxWidth="xl" style={{
            fontFamily:'Poppins'
        }}>
        
           <Grid container sx={styleGrid}>
            <Grid item xs={7}> 
                <div style={{
                    marginTop:'50px'
                }}>
               <CodigosQrInnicioEmpresaAdmi/>
                </div>
                <div>
                    <InformacionSucursales/>
                </div>
            </Grid>
            <Grid item xs={5}>
                
                <CalendarioMemorial user={usuarioData} empresa={empresaData} Token={Token}/>
            </Grid>
          </Grid> 
        </Container>
        </EmpresaLayout>
    );
};

/* style material UI */
const styleGrid = {
    maxWidth:'100%',
    display:'flex',
    alignItems:'start',
    justifyContent:'center',

}