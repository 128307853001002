import React, {useEffect, useState}from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { AppProvider } from '@toolpad/core/AppProvider';
import { DashboardLayout } from '@toolpad/core/DashboardLayout';
import { useUserEmpresa } from './Components/context/UsuarioEmpresa';
import { Avatar, CircularProgress, Card, CardContent} from '@mui/material';
import QrCodeIcon from '@mui/icons-material/QrCode';
import PersonIcon from '@mui/icons-material/Person';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import HomeIcon from '@mui/icons-material/Home';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import LogoutIcon from '@mui/icons-material/Logout';
import Skeleton from '@mui/material/Skeleton';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import BarChartIcon from '@mui/icons-material/BarChart';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import { useTheme } from '@mui/material/styles';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import EmailIcon from '@mui/icons-material/Email';

  const http = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 1000 });

function AppProviderThemeSucursal({ children}) {
    const { Usuario, Token, Empresa, UserDataMarca, Sucursal, setAdminUserEmpresaToken } = useUserEmpresa();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [tareas, setTareas] = useState([]);
    const [loading, setLoading] = useState(true); // Estado para manejar la carga
    const [error, setError] = useState(null);

    const navigate = useNavigate();
    


    useEffect(() => {
        const fetchTareas = async () => {
            if (!Empresa?.id || !Token) return; // Verifica que empresa.id y Token estén presentes
  
            try {   
                const response = await http.get(
                    `${backendURL}/api/tareasuscripcionSucursal/${Empresa.id}`, // Usamos la variable de entorno aquí
                    {
                        headers: {
                            'Authorization': `Bearer ${Token}` // Pasa el token de autenticación
                        }
                    }
                );
                setTareas(response.data.empresas); // Almacena los datos obtenidos en el estado
                setLoading(false); // Cambia el estado de carga
            } catch (error) {
                setError('Error al cargar las tareas'); // Manejo de error
                setLoading(false); // Cambia el estado de carga
            }
        };
  
        fetchTareas(); // Llamada a la función para obtener los datos
    }, [Empresa, Token]); // Se ejecutará cuando `empresa.id` o `Token` cambien
    


    if (loading) {
      return (
          <Box

              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="10vh"
              sx={{ flexDirection: 'column', position: 'relative', height: '200px', width: '100%' }}
          >
              {/* Circular Progress grande */}
              <CircularProgress 
                  size={100} 
                  thickness={3} 
                  sx={{ color: 'primary.main' }} 
              />
              
              {/* Avatar centrado */}
              {Empresa?.logo && (
                  <Avatar
                      src={`${backendURL}/${Empresa.logo}`}
                      alt="Logo Empresa"
                      sx={{
                          width: 40, // Ajusta el tamaño del avatar
                          height: 40,
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)', // Para centrar
                      }}
                  />
              )}
          </Box>
      );
  }

   const demoTheme = createTheme({
    palette: {
      mode: 'dark', // Cambiar a 'light' si el modo predeterminado es claro
      background: {
        default:'#ffff', // Fondo oscuro
        paper:  (UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
        ? UserDataMarca.color_1 
        : '#694B81', // Fondo para componentes secundarios
      },
      text: {
        primary: '#ffffff', // Texto principal en blanco
        secondary: '#B0B0B0', // Texto secundario en gris claro
        fontSize:'0.9rem'
      },
    },
    typography: {
      fontFamily: 'Poppins',
      body1: {
        color: '#ffffff', 
        fontSize:'0.8rem'// Color predeterminado para párrafos (body1)
      },
      body2: {
        color: '#ffffff', // Color predeterminado para textos secundarios (body2)
      },
    },
    components: {
      Icons:{
        color:'#ffff'
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: {
            color: '#ffffff', // Hace que todos los íconos sean blancos por defecto
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: '#ffffff', // Hace que la mayoría de los textos sean blancos
          },
        },
      },
    },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 600,
      lg: 1200,
      xl: 1536,
    },
  },
 // Modificar el AppBar para quitar los iconos y el texto del encabezado
 components: {
    MuiAppBar: {

        styleOverrides: {
            
          root: {
        
         '.css-yzjoij':{
            display:'none'
         },
         '.MuiTypography-root':{
            display:'none'
         }
          },
        },
      },
    },
  });
  
const handleLogout = () => {
  sessionStorage.removeItem('Token'); // Elimina el token de sessionStorage
  setAdminUserEmpresaToken(null); // Elimina el token del contexto
  navigate("/Inicio-Sesion-Empresas"); // Redirige al usuario
};

  

const NAVIGATION = [
    
  {
    segment: `Inicio-Sucursal/${Usuario.id}`,
    title: 'Inicio',
    icon: <HomeIcon />,
    
  },
  {
    segment: `sucursal/perfil/${Usuario.id}`,
    title: `Perfil ${Empresa?.nombre_social || ''}`,
    icon: <PersonIcon />,
   
  },
  /* {
    segment: 'page-2',
    title: 'Servicio tecnico',
    icon: <ManageAccountsIcon/>,
  }, */
 
  {
    segment: `sucursal/crearqr/${Usuario.id}`,
    title: 'Crear QR',
    icon: <GroupAddIcon />,
  },
 
  {
    segment: `sucursal/${Usuario.id}/qr`,
    title: `Clientes QR ${Sucursal.nombre_sucursal || ''}`,
    icon: <PersonSearchIcon />,
  },
  /* {
    segment: 'page-7',
    title: `Memoriales ${Empresa?.nombre_social || ''}`,
    icon: <Diversity1Icon />,
  }, */
  // Aquí mapeamos las tareas con iconos específicos
  ...tareas
  .filter(
    (tarea) =>
      tarea.nombre_tarea !== 'Encuesta' &&
      tarea.nombre_tarea !== 'Marca'
  )
    .map((tarea) => {
      let tareaIcon = <QrCodeIcon />; // Valor por defecto
      let segment = 'pages'; 

      if (tarea.nombre_tarea === 'Reporte') {
        segment = `sucursal/${Usuario?.id || 'default'}/bd/tarea`;
        tareaIcon = <BarChartIcon />; // Icono para 'Reporte'
      }
      if (tarea.nombre_tarea === 'Fidelización') {
        segment = `sucursal/correo/fidelizacion/${Usuario?.id || 'default'}`;
        tareaIcon = <EmailIcon />; // Icono para 'Reporte'
      }

    return {
      segment,
      title: tarea.nombre_tarea,
      icon: tareaIcon
    };
  }),
  {
    segment: 'Inicio-Sesion-Empresas',
    title: `Cerrar sesión`,
    icon: <LogoutIcon/>,
    onClick: handleLogout,
  },
];





  return (
    <AppProvider navigation={NAVIGATION}   theme={demoTheme} >
        
      <DashboardLayout  defaultSidebarCollapsed>
        <SidebarHeader/>
        {children}
        
        </DashboardLayout>
    </AppProvider>
  );
}

AppProviderThemeSucursal.propTypes = {
  children: PropTypes.node.isRequired,
  window: PropTypes.func,  // Agregado para que el prop `window` sea opcional
};

export default  AppProviderThemeSucursal;

export const SidebarHeader = () => {
    const { Usuario, Token, Empresa, Sucursal } = useUserEmpresa();
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const theme = useTheme();
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', padding: '16px', width:'100%' }}>
        <Card sx={{ display: 'flex', alignItems: 'center', justifyContent:"flex-start", width:'100%' }} >
          <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent:"flex-start", width:'100%' }}  >
        
        {Empresa.logo && (
         <Avatar    
          src={`${backendURL}/${Empresa.logo}`}     
          alt="Logo Empresa"
          sx={{ width: 50, height: 50, marginRight: 2 }}
        /> )}
           <Box sx={{ display: 'flex', flexDirection:'column' }}>
     <Typography variant="h5" className="TituloBienvenidoEmpresaAdmi" sx={{
                      color: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',fontFamily:'Poppins', fontWeight:'600'
                }}>Bienvenido {Empresa.nombre_empresa} Sucursal {Sucursal.nombre_sucursal}</Typography>
                <Typography variant="h6" className="NombreBienvenidoEmpresaAdmi" sx={{
                      color: theme.palette.mode === 'light' ? '#ffff' : '#FFFFFF',fontFamily:'Poppins', fontWeight:'500'
                }}>{Usuario.name} {Usuario.apellido}</Typography>
     
       </Box>
          </CardContent>
        </Card>
   

      </Box> 
    );
  } 
  