import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Box, Typography, Button, Container, Grid, Card, CardContent, TextField,CircularProgress, Alert} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import { keyframes } from "styled-components";
import MuiAccordionSummary, {
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { fontFamily, margin, styled } from "@mui/system";
import { Fab } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import '../../Style/inicio/smartmemorial.css';
import BackgroundImage from "../../Image/inicio/incio.jpg";
import Lapiz from"../.././Image/inicio/lapiz.png";
import qr from"../.././Image/inicio/qr.png";
import person from"../.././Image/inicio/person.png"
import portada from"../.././Image/inicio/portada.jpg"
import fondo from "../../Image/inicio/fondo.jpg";
/* imagenss galeria */
import uno from "../../Image/inicio/1.jpg"
import dos from "../../Image/inicio/2.jpg"
import tres from "../../Image/inicio/3.jpg"
import cuatro from "../../Image/inicio/4.jpg"
import cinco from "../../Image/inicio/5.jpg"
import seis from "../../Image/inicio/6.jpg"




const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  background:'#ffff',
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
    {
      transform: 'rotate(90deg)',
    },
  [`& .${accordionSummaryClasses.content}`]: {
    marginLeft: theme.spacing(1),
  },
  ...theme.applyStyles('dark', {
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const images = [uno, dos, tres, cuatro, cinco, seis];



export const HomeSmart = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [expanded, setExpanded] = React.useState('panel1');
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    contact_type:"General"
  });
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleNavigation = () => {
    // Navegar a la ruta deseada
    navigate("/personas-smart-memorial");

    // Usar setTimeout para garantizar que el scroll ocurra después de la navegación
    setTimeout(() => {
      window.scrollTo({
        top: 0, // Posición deseada
        behavior: "smooth", // Desplazamiento suave
      });
    }, 100); // Ajusta el tiempo si es necesario
  };

  
  const handleNavigations = () => {
    // Navegar a la ruta deseada
    navigate("/");

    // Usar setTimeout para garantizar que el scroll ocurra después de la navegación
    setTimeout(() => {
      window.scrollTo({
        top: 0, // Posición deseada
        behavior: "smooth", // Desplazamiento suave
      });
    }, 100); // Ajusta el tiempo si es necesario
  };

  // Manejar la visibilidad del botón de scroll
  const handleScrolls = () => {
    if (window.scrollY > 200) {
      setShowScrollButton(true); // Mostrar botón si se supera 200px de desplazamiento
    } else {
      setShowScrollButton(false); // Ocultar botón al volver arriba
    }
  };

  // Desplazar al inicio de la página
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Scroll suave
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrolls);
    return () => {
      window.removeEventListener("scroll", handleScrolls);
    };
  }, []);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const handleChanges = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError("");
    setSuccess(false);

    try {
        const response = await axios.post(
            `${backendURL}/api/contacto/formulario`, // Ruta del backend
            formData, // Datos enviados en el cuerpo de la solicitud
            {
                headers: {
                    "Content-Type": "application/json", // Asegúrate de usar el tipo de contenido correcto
                },
            }
        );

        // Si la respuesta es exitosa
        if (response.status === 200 || response.status === 201) {
            setSuccess(true);
            setFormData({ name: "", email: "", message: "", contact_type: "General" });
        }
    } catch (err) {
        // Captura errores del backend
        if (err.response && err.response.data.errors) {
            const backendErrors = Object.values(err.response.data.errors)
                .flat()
                .join(", ");
            setError(backendErrors);
        } else {
            setError(err.message || "Error desconocido al enviar el formulario");
        }
    } finally {
        setIsSubmitting(false);
    }
};
  const handleScroll = () => {
    // Detectar cuándo mostrar el contenido con transición
    if (window.scrollY > 300) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.3 }
    );

    const section = document.getElementById("sec-e2ef");
    if (section) observer.observe(section);

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsVisible(entry.isIntersecting),
      { threshold: 0.3 }
    );

    const section = document.getElementById("masinformacion");
    if (section) observer.observe(section);

    return () => observer.disconnect();
  }, []);

  
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  useEffect(() => {
              const handleResize = () => {
                if (window.innerWidth > 850) {
                  setIsVisible(false); // Resetea el estado si el ancho es mayor a 850px
                }
              };
          
              // Agregar el listener para resize
              window.addEventListener("resize", handleResize);
          
              // Comportamiento inicial
              if (window.innerWidth <= 850) {
                const observer = new IntersectionObserver(
                  ([entry]) => setIsVisible(entry.isIntersecting),
                  { threshold: 0.3 }
                );
          
                const section = document.getElementById("masinformacion");
                if (section) observer.observe(section);
          
                // Cleanup para IntersectionObserver
                return () => {
                  observer.disconnect();
                  window.removeEventListener("resize", handleResize);
                };
              }
          
              // Cleanup para el listener
              return () => {
                window.removeEventListener("resize", handleResize);
              };
            }, []);
            useEffect(() => {
                        const handleResize = () => {
                          if (window.innerWidth > 850) {
                            setIsVisible(false); // Resetea el estado si el ancho es mayor a 850px
                          }
                        };
                    
                        // Agregar el listener para resize
                        window.addEventListener("resize", handleResize);
                    
                        // Comportamiento inicial
                        if (window.innerWidth <= 850) {
                          const observer = new IntersectionObserver(
                            ([entry]) => setIsVisible(entry.isIntersecting),
                            { threshold: 0.3 }
                          );
                    
                          const section = document.getElementById("sec-e2ef");
                          if (section) observer.observe(section);
                    
                          // Cleanup para IntersectionObserver
                          return () => {
                            observer.disconnect();
                            window.removeEventListener("resize", handleResize);
                          };
                        }
                    
                        // Cleanup para el listener
                        return () => {
                          window.removeEventListener("resize", handleResize);
                        };
                      }, []);

  return (
    <>
    {showScrollButton && (
        <Fab
        onClick={scrollToTop}
          color="primary"
          aria-label="scroll-to-bottom"
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            zIndex: 1000,
          }}
        >
          <ArrowUpwardIcon />
        </Fab>
      )}
      {/* Sección Parallax */}
      <ParallaxSection>
        <ParallaxImage alt="Parallax Background" />
        <FixedContent>
          <Typography variant="h2" className="title">
            Un memorial
          </Typography>
          <Typography variant="h2" className="title">
            para sus seres fallecidos
          </Typography>
          <Typography variant="body1" className="description">
            Plataforma online para compartir y conectar, desempeña un papel significativo en el
            proceso de duelo y en mantener viva la memoria de alguien especial.
          </Typography>
          <Typography variant="body1" className="description">
            Brinda una forma innovadora y conveniente de preservar y honrar la memoria de un ser
            querido.
          </Typography>
          <Button className="cta-button" onClick={() => {
    const contactSection = document.getElementById("contact");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  }}>Solicítelo aquí</Button>
        </FixedContent>
      </ParallaxSection>

      {/* Contenido con Transiciones Suaves */}
      <Box sx={{ py: 4, marginTop: '80px', marginBottom: '80px' }}>
  <Container
    maxWidth="lg"
    style={{
      opacity: visible ? 1 : 0,
      transform: visible ? "translateY(0)" : "translateY(50px)",
      transition: "opacity 0.8s ease, transform 0.8s ease",
    }}
  >
    <StyledTitle variant="h4" gutterBottom>
      Principales Atribuciones
    </StyledTitle>
    <StyledGrid container spacing={3}>
      <Grid item xs={12} sm={4}>
        <Card sx={{p:2, height:'250px', display:'flex', alignItems:'start', justifyContent:'center', background:'#F0EDF3','@media (max-width: 1000px)': {
    height:'90%'
  },}}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
              <img src={qr} alt="QR" style={{ width: '80px', height: '80px' }} />
            </Box>
            <StyleTypographycard variant="subtitle1" gutterBottom>
              Placa ultraresistente
            </StyleTypographycard>
            <StyleTypographycarddos variant="caption">
              Placa metálica con impresión de alta resistencia a situaciones climáticas extremas con un adhesivo ultra adherente.
            </StyleTypographycarddos>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
      <Card sx={{p:2, height:'250px', display:'flex', alignItems:'start', justifyContent:'center',background:'#F0EDF3','@media (max-width: 1000px)': {
    height:'90%',
  },}}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
              <img src={person} alt="Person" style={{ width: '80px', height: '80px' }} />
            </Box>
            <StyleTypographycard variant="subtitle1" gutterBottom>
              Creación de grupo familiar
            </StyleTypographycard>
            <StyleTypographycarddos variant="caption">
              Se puede crear un grupo familiar donde podrá agrupar hasta 5 personas por código QR. Esto entendiendo que los espacios en los cementerios podrían tener más de una persona.
            </StyleTypographycarddos>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
      <Card sx={{p:2, height:'250px', display:'flex', alignItems:'start', justifyContent:'center',background:'#F0EDF3','@media (max-width: 1000px)': {
    height:'90%',
  },}}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: 2 }}>
              <img src={Lapiz} alt="Lapiz" style={{ width: '80px', height: '80px' }} />
            </Box>
            <StyleTypographycard variant="subtitle1" gutterBottom>
              Edite su memorial cuando quiera
            </StyleTypographycard>
            <StyleTypographycarddos variant="caption">
              Tanto fotos, videos, nombres o información podrá ser editada en todo momento por su administrador.
            </StyleTypographycarddos>
          </CardContent>
        </Card>
      </Grid>
    </StyledGrid>
  </Container>
</Box>

<section
 className={`sectioninfoportadasmart ${isVisible ? "visible" : ""}`}
  id="sec-e2ef"
>
  {/* Columna izquierda: Texto */}
  <Container
    maxWidth="md"
    sx={{
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: { xs: "center", md: "flex-start" },
      textAlign: { xs: "center", md: "left" },
      padding: "0px",
    }}
  >
    <StyledTitleportada
      variant="h4"
      sx={{
        marginBottom: "20px",
      }}
    >
      Por todo lo que nos entregaron...
    </StyledTitleportada>
    <StyledTitleTextportada
      variant="body1"
      sx={{
        marginBottom: "20px",
      }}
    >
      Preserve la historia de sus seres queridos con Smart Memorial.
      Comparta sus recuerdos, fotografías, vídeos e historias.
      Simplemente ponga su código QR en su lápida y escanéelo para recordar a
      sus seres queridos. Con Smart Memorial su legado se mantendrá por siempre.
    </StyledTitleTextportada>
    <StyledButton
     onClick={() => {
      const contactSection = document.getElementById("contact");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    }}
    >
      Solicítelo aquí
    </StyledButton>
  </Container>

  {/* Columna derecha: Imagen */}
  <Box
    sx={{
      flex: 1,
      position: "relative",
      height: "100%", // La altura de la sección
      backgroundImage: `url(${portada})`, // Reemplaza con tu imagen
      backgroundSize: "cover", // Escala la imagen para cubrir el espacio
      backgroundPosition: "center", // Centra la imagen
     
    }}
  />
</section>
<ParallaxSectiondos>
<ParallaxImagedos alt="Parallax Background" />
<FixedContent>
          <Typography variant="h2" className="title">
          Revise aquí nuestros planes
          </Typography>
         
          <Typography variant="body1" className="description">
          En Smart Memorial mantenemos vivo el legado de aquellos que han partido, es por este motivo que tenemos los mejores planes para usted.
          </Typography>
          
          <Box sx={{
            width:'100%',
            display:'flex',
            alignItems:"center",
            justifyContent:'center',
            '@media (max-width: 750px)': {
              
              flexDirection:'column'
            },
}}>
            <Button onClick={handleNavigation} className="cta-button">Plan Personas</Button>
            <Button className="cta-button" onClick={handleNavigations}>Plan Empresas</Button>
          </Box>
        </FixedContent>
</ParallaxSectiondos>
<section
      className={`sectioninfoportadasmartdos ${isVisible ? "visible" : ""}`}
      id="masinformacion"
    >
      <Container maxWidth="lg">
        <StyledTitle variant="h4" gutterBottom>
          Más atributos de Smart Memorial
        </StyledTitle>

        {/* Acordeón */}
        <div>
          <Accordion
            expanded={expanded === "panel1"} // Compara si el panel1 es el actual expandido
            onChange={handleChange("panel1")}
            sx={{
              boxShadow: "none",
              border: "0px solid ",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
            sx={{
              background:'#ffff',
            }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1-header"
            >
              <StyleTypographyAcordion variant="h6" sx={{
                color: expanded === "panel1" ? "#694B81" : "#585857", // Cambia el color si es el panel expandido
              }}>
                Más económico
              </StyleTypographyAcordion>
            </AccordionSummary>
            <AccordionDetails>
              <StyleTypographycarddosacordeon>
                En la actualidad existen alternativas que incluso tienen menos atributos,
                sobre ellas Smart Memorial es la más completa y más económica.
              </StyleTypographycarddosacordeon>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"} // Compara si el panel2 es el actual expandido
            onChange={handleChange("panel2")}
            sx={{
              boxShadow: "none",
              border: "0px solid ",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
             sx={{
              background:'#ffff',
            }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2a-content"
              id="panel2-header"
            >
              <StyleTypographyAcordion variant="h6" sx={{
                color: expanded === "panel2" ? "#694B81" : "#585857", // Cambia el color si es el panel expandido
              }}>
                Imágenes y videos
              </StyleTypographyAcordion>
            </AccordionSummary>
            <AccordionDetails>
              <StyleTypographycarddosacordeon>
                Esta plataforma cuenta con la posibilidad de agregar imágenes y videos tanto el administrador como visitantes.
              </StyleTypographycarddosacordeon>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"} // Compara si el panel3 es el actual expandido
            onChange={handleChange("panel3")}
            sx={{
              boxShadow: "none",
              border: "0px solid ",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
             sx={{
              background:'#ffff',
            }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3-header"
            >
              <StyleTypographyAcordion variant="h6" sx={{
                color: expanded === "panel3" ? "#694B81" : "#585857", // Cambia el color si es el panel expandido
              }}>
                Podrá dejar mensajes
              </StyleTypographyAcordion>
            </AccordionSummary>
            <AccordionDetails>
              <StyleTypographycarddosacordeon>
                Cada visitante a la plataforma podrá dejar sus mensajes de condolencia.
              </StyleTypographycarddosacordeon>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel4"} // Compara si el panel4 es el actual expandido
            onChange={handleChange("panel4")}
            sx={{
              boxShadow: "none",
              border: "0px solid ",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
             sx={{
              background:'#ffff',
            }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel4a-content"
              id="panel4-header"
            >
              <StyleTypographyAcordion variant="h6" sx={{
                color: expanded === "panel4" ? "#694B81" : "#585857", // Cambia el color si es el panel expandido
              }}>
                Creación de eventos
              </StyleTypographyAcordion>
            </AccordionSummary>
            <AccordionDetails>
              <StyleTypographycarddosacordeon>
                El administrador podrá crear eventos como misas o reuniones que podrán ser agendados dentro de la plataforma.
              </StyleTypographycarddosacordeon>
            </AccordionDetails>
          </Accordion>
        </div>
      </Container>
    </section>
    <section >

      <div className="galeria-container">
        {images.map((image, index) => (
          <div className="image-wrapper" key={index}>
            <img className="image" src={image} alt={`Imagen ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
    <ParallaxSectiontres  id="contact">
    <ParallaxImagedos alt="Parallax Background" />
    <Container maxWidth='sm'>
    <Box
    
      component="form"
      onSubmit={handleSubmit}
      sx={{
        position:'relative',
        marginTop:'40px',
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 3,
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h4" align="center" gutterBottom sx={{fontFamily:'Poppins', fontWeight:'600',fontSize:'1.2rem',color:'#585857'}}>
        Contáctenos
      </Typography>

      {success && (
        <Alert severity="success">¡Mensaje enviado correctamente!</Alert>
      )}
      {error && <Alert severity="error">{error}</Alert>}

      <TextField
      sx={{background:"#ffff", fontFamily:'Poppins', color:'#585857'}}
        label="Nombre"
        name="name"
        value={formData.name}
        onChange={handleChanges}
        fullWidth
        required
      />

      <TextField
      sx={{background:"#ffff", fontFamily:'Poppins', color:'#585857'}}
        label="Email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChanges}
        fullWidth
        required
      />

      <TextField
      sx={{background:"#ffff", fontFamily:'Poppins', color:'#585857'}}
        label="Mensaje"
        name="message"
        value={formData.message}
        onChange={handleChanges}
        multiline
        rows={4}
        fullWidth
        required
      />

      <Box display="flex" justifyContent="center" alignItems="center">
        {isSubmitting ? (
          <CircularProgress />
        ) : (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ px: 4, fontFamily:'Poppins', textTransform:'capitalize' }}
          >
            Enviar
          </Button>
        )}
      </Box>
    </Box>
    </Container>
    </ParallaxSectiontres>
    </>
  );
};

// Styled Components
const ParallaxSection = styled(Box)({
  position: "relative",
  height: "100vh",
  overflow: "hidden",
  '@media (max-width: 850px)': {
    overflow:'auto' // Para asegurar que la posición cambie
  },
});

const ParallaxImage = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url(${BackgroundImage})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed", // Imagen fija
  '@media (max-width: 850px)': {
    backgroundAttachment: "scroll", // Imagen estática
   // Para asegurar que la posición cambie
  },
});

const ParallaxSectiondos = styled(Box)({
  position: "relative",
  height: "80vh",
  overflow: "hidden",
  '@media (max-width: 850px)': {
    overflow:'auto'// Para asegurar que la posición cambie
  },
});
const ParallaxSectiontres = styled(Box)({
  position: "relative",
  height: "80vh",
  overflow: "hidden",
  '@media (max-width: 850px)': {
    overflow:'auto'// Para asegurar que la posición cambie
  },
});
const ParallaxImagedos = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundImage: `url(${fondo})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  backgroundAttachment: "fixed", // Imagen fija
  '@media (max-width: 850px)': {
    backgroundAttachment: "scroll", // Imagen estática
    // Para asegurar que la posición cambie
  },
});

const FixedContent = styled(Container)({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  textAlign: "center",
  color: "#fff",
  zIndex: 2,
  "& .title": {
    fontFamily: "Poppins",
    fontWeight: 600,
    fontSize: "2.5rem",
    marginBottom: "0",
    '@media (max-width: 750px)': {
      fontSize: "1.5rem",
    },
  },
  "& .description": {
    fontFamily: "Poppins",
    fontWeight: 400,
    fontSize: "1.2rem",
    marginTop:'0.7rem',
    '@media (max-width: 750px)': {
      fontSize: "0.8rem",
      marginTop:'9px'
    },
  },
  "& .cta-button": {
    backgroundColor: "#fff",
    color: "#694B81",
    fontFamily:'Poppins',
    fontWeight: "500",
    textTransform: "capitalize",
    borderRadius: "50px",
    padding: "10px 20px",
    transition: "all 0.3s ease",
    margin:'20px',
    "&:hover": {
      backgroundColor: "#694B81",
      color: "#fff",
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    },
    '@media (max-width: 750px)': {
      fontSize: "0.8rem",
      margin:'10px'
     
    },
  },
});

const StyledTitle = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 600,
  color: "#585857",
  textAlign: "center",
  marginBottom:'40px',
  '@media (max-width: 750px)': {
      fontSize: "1.3rem",
     
    },
});

const StyledGrid = styled(Grid)({
  "& .MuiCard-root": {
    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s ease",
    "&:hover": {
      transform: "translateY(-10px)",
      boxShadow: "0px 8px 20px rgba(0, 0, 0, 0.2)",
    },
  },
});


/* card */

const StyleTypographycard = styled(Typography)({
   textAlign:'center',
   display:'flex',
   alignItems:'center',
   justifyContent:'center',
   fontFamily:'Poppins',
   fontWeight:'600',
   color: "#585857",
   
  });
  const StyleTypographycarddos = styled(Typography)({
    textAlign:'center',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontFamily:'Poppins',
    fontWeight:'500',
    color: "#585857",
    
   });


  
  /* portada */

  const StyledTitleportada = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 600,
  color: "#585857",
  textAlign: "center",
  marginBottom:'40px',
  '@media (max-width: 750px)': {
      fontSize: "1.2rem",
    },
});

const StyledTitleTextportada = styled(Typography)({
  fontFamily: "Poppins",
  fontWeight: 500,
  color: "#585857",
  textAlign: "justify",
  marginBottom:'40px',
  '@media (max-width: 750px)': {
    fontSize: "0.8rem",
    padding:'0px 10px'
  },
});
const StyledButton = styled(Button)({
  fontFamily:'Poppins',
backgroundColor: "#fff",
color: "#694B81",
fontWeight: "500",
textTransform: "capitalize",
borderRadius: "50px",
padding: "10px 20px",
transition: "all 0.3s ease",
"&:hover": {
  backgroundColor: "#694B81",
  color: "#fff",
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
},
})

/* acordeon */
const StyleTypographyAcordion = styled(Typography)({
  textAlign:'center',
  display:'flex',
  alignItems:'center',
  justifyContent:'center',
  fontFamily:'Poppins',
  fontWeight:'600',
  color: "#585857",
  '@media (max-width: 750px)': {
    fontSize: "1rem",
  },
  
 });

 const StyleTypographycarddosacordeon = styled(Typography)({
  textAlign:'start',
  display:'flex',
  alignItems:'center',
  justifyContent:'flex-start',
  fontFamily:'Poppins',
  fontWeight:'500',
  color: "#585857",
  '@media (max-width: 750px)': {
    fontSize: "0.8rem",
  },
  
 });

 const fadeIn = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Section = styled("section")`
  padding: 20px;
  text-align: center;
  h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color: #333;
  }
`;

const GaleriaContainer = styled("div")`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 100%;
  margin: 0 auto;
  animation: ${fadeIn} 1s ease-out;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const ImageWrapper = styled("div")`
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  
  &:hover {
    transform: scale(1.05);
  }
`;

const Image = styled("img")`
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;