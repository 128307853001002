import React, { useEffect, useState } from "react";
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import { useNavigate, Link } from "react-router-dom";
import '../../../../../../Style/AdministradorSuperEmpresa/InicioEmpresaAdmi.css'

/* libreria UI */
import { Box, Container, Grid} from "@mui/material";
import Skeleton from '@mui/material/Skeleton';

/* react icon */
import { FaRegUser } from "react-icons/fa";
import { IoSettings } from "react-icons/io5";
import { IoMdLogOut } from "react-icons/io";
import { BiHomeHeart } from "react-icons/bi";

const http = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 3000 });


export const EncabezadoAdmiSucursal = ({sucursalData, user, id}) => {
    const { Token, setAdminUserEmpresaToken } = useUserEmpresa();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate(); 
    const [empresaData, setEmpresaData] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;

    
    useEffect(() => {
        const storedToken = sessionStorage.getItem('Token');  
        if (storedToken) {
          setAdminUserEmpresaToken(storedToken); // Sincroniza el contexto con sessionStorage
        } else {    
          navigate("/Inicio-Sesion-Empresas"); // Redirige si no hay token
        }

      }, [navigate, setAdminUserEmpresaToken]);; // No necesitas 'Token' en las dependencias

      useEffect(() => {
        if (!sucursalData.id_empresa || !Token) return;
        const fetchEmpresaData = async () => {
            setLoading(true);
            try {
                const response = await http.get(`${backendURL}/api/usuarioEmpresa/sucursal/${sucursalData.id_empresa}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${Token}` // Agregar el token en los headers
                    }
                });

                if (response.status === 200) {
                   
                    setEmpresaData(response.data.empresa); // Guarda los datos de la empresa
                } else {
                    console.error("Error al obtener datos de la empresa");
                }
            } catch (error) {
                console.error("Error en la solicitud:", error);
            } finally {
                setLoading(false);
            }
        };

        if (Token && id) {
            fetchEmpresaData(); // Llamar a la función si el token y el id están presentes
        }
    }, [Token, id, backendURL, sucursalData.id_empresa]); 
    
    const handleLogout = () => {
        sessionStorage.removeItem('Token'); // Elimina el token de sessionStorage
        setAdminUserEmpresaToken(null); // Elimina el token del contexto
        navigate("/Inicio-Sesion-Empresas"); // Redirige al usuario
      };

      if (loading) {
        return(
          <Box display="flex" justifyContent="center" alignItems="center"  >
            <Skeleton variant="circular" width={60} height={60} sx={{marginRight:'10px'}}  />
          <Skeleton variant="text" width="80%" height="150px" />
          
      </Box>)
      }

    return(
        <section style={StyleContainer}>
        <Box sx={StyleBoximagen}>
               <Link to={`/Inicio-Sucursal/${user.id}`}>
               {empresaData.logo && (
                    <img src={`${backendURL}/${empresaData.logo}`} alt="smart memorial" title="smart memorial" width={'100px'} />
                    )}
                </Link>
        </Box>
        <Box sx={StyleBox}>
            <Grid container>

                <Grid item xs={7} sx={StyleGrid}>
                    <h3 className="TituloBienvenidoEmpresaAdmi">Bienvenido sucursal {sucursalData?.nombre_sucursal}</h3>
                    <h5 className="NombreBienvenidoEmpresaAdmi">{user.name} {user.apellido}</h5>
                </Grid>
                
            </Grid>
      
        </Box>
    </section>
    )
}


/* style material Ui*/

const StyleBox ={
    width:'100%',
    border:'1px solid #F0EDF3',
    marginTop:'30px',
    borderRadius:'10px',
    p:2,
    background:'#F0EDF3'
}

const StyleBoximagen ={
    width:"20%",
   display:'flex',
   alignItems:'center',
   justifyContent:'center',
   marginTop:'30px'
}


const StyleGrid ={
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'center',
    flexDirection:'column'
}

const StyleContainer = {
    display:'flex',
    paddingLeft: '0px',
    paddingRight:'0px',
    flexDirection:'row',
    maxWidth:"100%",
    alignItems:'flex-center',
}

 const StyleGridContieneIcono = {
   maxWidth:'50%'
 }

 const StyleGridsocial = {
    display:'flex',
    alignItems:'flex-end',
    justifyContent:'flex-end',
    
}