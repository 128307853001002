import React from "react";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import { Container, Box, CircularProgress, Grid } from "@mui/material";
import foto from "../../../../../../Image/generales/ejemplo.jpg"
import { Height } from "@mui/icons-material";

export const DiseñoRecuerdo = () => {
    const { Token, UserDataMarca } = useUserEmpresa();

    const styles = {
       
        titleStyle: {
          color: UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined' ? UserDataMarca.color_2 :'#694B81',
          fontSize:'1rem',
          fontWeight:'600',
          marginTop:'5px'
         
        },
        titleFecha: {
            color: UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined' ? UserDataMarca.color_1 :'#694B81',
            fontSize:'0.8rem',
            fontWeight:'600',
            marginTop:'2px'
          },
        fondomensajeStyle: {
          background: UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined' ? UserDataMarca.color_2 :'#F0EDF3',
        },
        fondosStyle: {
          background: UserDataMarca?.color_3 && UserDataMarca.color_3 !== 'undefined' ? UserDataMarca.color_3 :'#F0EDF3',
        },
        fondosTotal: {
            background: UserDataMarca?.color_4 && UserDataMarca.color_4 !== 'undefined' ? UserDataMarca.color_4 :'#F0EDF3',
            display:'flex',
            alignItems:'center', 
            justifyContent:'center',
            flexDirection:'column'
            
          },
        titulooscuroStyle: {
          color: UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined' ? UserDataMarca.color_2 :'#D26999',
        },
        texto: {
          color: "#fff"
        },
        selectedIconStyle: {
      
          cursor: "pointer",
          width: '40px',
          height: '40px',
          color: '#fff',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined' ? UserDataMarca.color_2 :'#694B81',
        },
        iconStyle: {
          cursor: "pointer",
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          background: 'transparent',  // Aquí está el error corregido
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined' ? UserDataMarca.color_2 :'#694B81',
        },
        
      };
      
     

    return(
        <section style={styles.fondosTotal}>
          <Box
  sx={{
    width: "100%",
    height: '400px',
    position: "relative",
    borderRadius: "8px",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
    overflow: "hidden",
  }}
>
  <img
    src={foto}
    alt="Recuerdo"
    style={{
      width: "100%",
      height: "100%",
      objectFit: "cover",
    }}
  />
  <Box
    sx={{
      position: "absolute",
      bottom: 0,
      left: 0,
      width: "100%",
      height: "150px",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Fondo negro con transparencia
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff", // Letras blancas
      padding: "20px",
      textAlign: "center",
    }}
  >
    <hr style={styleHrinicio}/>
    <h3 style={{color:'#ffff', fontSize:'0.9rem', fontStyle:'italic', fontWeight:'600'}} > Narciza Riquelme</h3>
    <div className="fechaobipersona" style={{color:'#ffff', paddingTop:'5px', fontSize:'0.8rem', fontStyle:'italic',marginBottom:'10px'}}> 10/12/2000 • 30/11/2024 </div>
    <hr style={styleHrinicio}/>
  </Box>
</Box>
          <Box
          sx={{
            width:'100%',
            borderRadius:'10px',
           
            marginTop:'20px',
            display:'flex',
             alignItems:'center',
             justifyContent:'center',
             flexDirection:'column'
          }}>
            <hr style={styleHr}/>
              <div>
                <h4 style={{
                    fontWeight:'500',
                    fontStyle:'italic',
                    fontSize:'0.8rem',
                    margin:'5px'   
                }}>
                Con profundo pesar, comunicamos el fallecimiento
                </h4>
                <h3 style={styles.titleStyle} > Narciza Riquelme</h3>
                <div className="fechaobipersona" style={styles.titleFecha}> 10/12/2000 • 30/11/2024 </div>
                <h4
                 style={{
                    fontWeight:'500',
                    fontStyle:'italic',
                    fontSize:'0.8rem',
                    margin:'5px'   
                }}> cuya memoria vivirá por siempre en nuestros corazones. </h4>
              </div>
              
              <hr style={styleHr}/>
           
          <div className="parentescoovit"><span> Fue abuela, madre, prima y hija quien despediremos en compañía de su familia y amigos.</span></div>
          <div style={{
            marginTop:'20px',
            background: UserDataMarca?.color_3 && UserDataMarca.color_3 !== 'undefined' ? UserDataMarca.color_3 :'#F0EDF3',
            width:'100%'

          }}>
            <h6 style={{
                fontSize:'0.75rem',
                fontWeight:'500',
                padding:'0px 30px'
            }}>El velatorio se llevará a cabo el día [fecha] desde las [hora inicio] hasta las [hora fin] en [dirección del velatorio]. Posteriormente, el funeral se realizará el día [fecha] a las [hora] en [dirección del funeral].</h6>
          </div>
          </Box>
         
        
        
        </section>
    )
}

const styleHrinicio = {
    width:'98%',
    border:'1px solid rgb(255 255 255 / 16%)',
    background:'rgb(255 255 255 / 16%)',
    height:'1px',
    borderRadius:'60px',
    margin:'0px'

}

const styleHr = {
    width:'98%',
    border:'1px solid #ffff',
    background:'#ffff',
    height:'3px',
    borderRadius:'60px',
    marginBottom:'10px'

}