import React, { useState, useEffect } from "react";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";
import axios from "axios";
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Paper,
  
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { BarChart } from "@mui/x-charts";



export const InformaciónEncuesta = ({id}) => {
  const { Token, UserDataMarca, Empresa } = useUserEmpresa();
  const [dataEncuesta, setDataEncuesta] = useState([]);
  const [dataRepsuesta, setDataRepsuesta] = useState([]);
  const [dataUsuario, setDataUsuario] = useState([]);
  const [Pregunta10, setPregunta10] = useState([])
  const [loading, setLoading] = useState(true);
  const [alreadyResponded, setAlreadyResponded] = useState(false); 
  const backendURL = process.env.REACT_APP_BACKEND_URL;


  const fetchEncuestaRespuestas = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${backendURL}/api/encuestas/respuestas/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );

      const encuesta = response.data.data;
    

     
      setDataEncuesta(encuesta);  // Establecer las preguntas
     
    } catch (error) {
      console.error("Error al obtener las respuestas de la encuesta:", error);
      setAlreadyResponded(true); 
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchEncuestaRespuestas();
  }, [id]);

  /* usuarios repsuesta */
  const fetchObtenerEncuestaRespuestasUsuarios = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${backendURL}/api/Obtener-encuestas-usuario-respuestas/${id}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
          },
        }
      );

      const encuesta = response.data.data;
    
      const formattedData = encuesta.map((item, index) => ({
        id: index + 1, // El DataGrid necesita un campo único llamado 'id'
        nombre: item.nombre,
        apellido: item.apellido,
        email: item.email || "Sin email",
        telefono: item.telefono || "Sin teléfono",
        fecha: item.fecha_respuesta
    ? new Intl.DateTimeFormat('es-ES', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      }).format(new Date(item.fecha_respuesta))
    : "Fecha no disponible",
        respuesta: Array.isArray(item.respuestas)
        ? item.respuestas
            .map((resp) =>
              resp.respuesta == 1
                ? "Sí"
                : resp.respuesta == 0
                ? "No"
                : resp.respuesta ?? "Sin respuesta"
            )
            .join(", ")
        : "Sin respuesta",
      }));
     

      setDataUsuario(formattedData);  // Establecer las preguntas
     
    } catch (error) {
      console.error("Error al obtener las respuestas de la encuesta:", error);
   
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchObtenerEncuestaRespuestasUsuarios();
  }, [id]);

  

    // Definición de columnas base
    const columns = [
      { field: "id", headerName: "ID", width: 70 },
      { field: "nombre", headerName: "Nombre", width: 200 },
      { field: "apellido", headerName: "Apellido", width: 200 },
      { field: "email", headerName: "Email", width: 250 },
      { field: "telefono", headerName: "Teléfono", width: 150 },
      { field: "fecha", headerName: "Fecha de Respuesta", width: 200 },
      { field: "respuesta", headerName: "Respuestas", width: 300, sortable: false },
    ];

// Combinar columnas base con la columna de respuestas




    /* usuarios repsuesta  10*/
    const fetchObtenerEncuestaRespuestas10 = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${backendURL}/api/respuesta-pregunta-10-usuario/${id}`,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          }
        );
  
        const encuesta = response.data.data;
        const NombreEncuesta = response.data.data[0].pregunta;
       
       

       setPregunta10(NombreEncuesta);
       
        const formattedData = encuesta.map((item, index) => ({
          id: index + 1, // El DataGrid necesita un campo único llamado 'id'
          usuario: `${item.usuario.nombre} ${item.usuario.apellido}` || "Usuario desconocido",
          respuesta: item.respuesta || "Sin respuesta",
        }));
  
        setDataRepsuesta(formattedData);
       
      } catch (error) {
        console.error("Error al obtener las respuestas de la encuesta:", error);
      } finally {
        setLoading(false);
      }
    };
  
    useEffect(() => {
      fetchObtenerEncuestaRespuestas10();
    }, [id]);
  // Preparamos los datos para el gráfico
  // Estructura para el BarChart
  const categories = dataEncuesta.map((item) => item.pregunta); // Preguntas como etiquetas
  const conteoSi = dataEncuesta.map((item) => item.conteo_si); // Conteo de "Sí"
  const conteoNo = dataEncuesta.map((item) => item.conteo_no); // Conteo de "No"


  const textColumns = [
    { field: "usuario", headerName: "Usuario", flex: 1 },
    { field: "respuesta", headerName: "Respuesta", flex: 2 },
  ];

  if (alreadyResponded) {
    return (
     
          
<Box
              sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  padding: 3,
                  backgroundColor: "#fff",
                  borderRadius: 2,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  width: "80%",
                  margin:'100px'
              }}
          >
             
              <Typography
                  variant="h5"
                  sx={{
                      fontWeight: "600",
                      color: UserDataMarca?.color_1 || "#333",
                      marginBottom: 1,
                      fontFamily:'Poppins'
                  }}
              >
                  No hay respuestas todavia de los usuarios.
              </Typography>
             
              
             
          </Box>
     
    );
}

  return (
    <Box sx={{ p: 3 }}>
      <Typography sx={tituloStyle}>
        Reporte de Encuesta
      </Typography>
      {loading ? (
        <Grid container justifyContent="center" sx={{ mt: 4 }}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {/* Gráficos */}
          <Grid container spacing={3} sx={{
            marginTop:'50px'
          }}>
            <Grid item xs={12} >
            <Paper
      elevation={3}
      sx={{
        p: 2,
        background: UserDataMarca?.color_1 || "#3f51b5",
        color: "#fff",
      }}
    >
      <Typography variant="h6" sx={{ mb: 2, fontFamily: "Poppins", fontSize: "1rem", color:"#ffff", }}>
        Respuestas por preguntas 
      </Typography>

      {loading ? (
        <Typography>Cargando respuestas...</Typography>
      ) : (
        <> <BarChart
        xAxis={[
          { 
            data: categories, // Etiquetas del eje X
            scaleType: 'band' // Configurar como escala de tipo "band"
          }
        ]}
       
        series={[
          { data: conteoSi, label: "Sí",  },
          { data: conteoNo, label: "No", },
        ]}
        height={400} // Altura del gráfico
        width={800} // Ancho del gráfico
         barLabel="value"
         slotProps={{ legend: { hidden: true } }}
         sx={{
          fontFamily:'Poppins'
         }}
      />
      </>
       
      )}
    </Paper>
            </Grid>
            {dataRepsuesta && dataRepsuesta.length > 0 ? (
            <Grid item xs={12} >
            
              <Paper elevation={3} sx={{ p: 2 , background: UserDataMarca?.color_4 && UserDataMarca?.color_4 !== "undefined"
                      ? UserDataMarca?.color_4
                      : "#3f51b5"}}>
                <Typography variant="h6" sx={{ mb: 2, color: UserDataMarca?.color_2 && UserDataMarca?.color_2 !== "undefined"
                      ? UserDataMarca?.color_2
                      : "#585857",
                      fontSize:'1rem', }}>   
                  Distribución Respuestas {Pregunta10}
                </Typography>
                <DataGrid
          rows={dataRepsuesta}
          columns={textColumns}
          pageSize={5}
          rowsPerPageOptions={[5, 10, 20]}
          disableSelectionOnClick
          sx={{
            background:'#ffff',
            marginTop: '50px',
            fontFamily: 'Poppins',
            boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra del borde
            borderRadius: '8px', // Borde redondeado (si lo deseas)
            '& .MuiDataGrid-columnHeaders': {
              color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de texto de la cabecera
              fontWeight: '600', // Estilo de fuente de la cabecera
              boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)', // Sombra para la cabecera
              borderBottom: '2px solid rgba(0, 0, 0, 0.2)', // Borde inferior de la cabecera
            },
            '& .MuiDataGrid-cell': {
              color: (UserDataMarca?.color_2 && UserDataMarca?.color_2 !== 'undefined') ? UserDataMarca?.color_2 : '#3f51b5',
              borderBottom: '1px solid rgba(224, 224, 224, 1)', // Borde entre celdas
            },
            '& .MuiCheckbox-root': {
              color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de los checkboxes (opcional)
            },
            '& .MuiTablePagination-selectLabel':{
                color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                fontFamily:'Poppins'
            },
            '& .MuiTablePagination-displayedRows' : {
               color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                fontFamily:'Poppins'
            },
            '& .MuiInputBase-input':{
               color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                fontFamily:'Poppins'
            },
            '& .Mui-disabled':{
                color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                fontFamily:'Poppins'
            },
            '& .MuiSelect-icon':{
               color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
                fontFamily:'Poppins'
            }
          }}
        />
               
              </Paper>
             
            </Grid>
          ) : (
            <Grid item xs={12}>
              <Typography variant="h6" sx={{ color: UserDataMarca?.color_2 || "#585857", fontSize: '1rem' }}>
               
              </Typography>
            </Grid>
          )}
          </Grid>

          {/* Tabla de estadísticas generales */}
          <Box sx={{ height: 300, width: "100%", mt: 4 }}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Repsuestas de usuario por fecha
            </Typography>
            <DataGrid
         rows={dataUsuario}
         columns={columns}
        pageSize={5} // Tamaño de página
        rowsPerPageOptions={[5, 10, 20]} 
        autoHeight
        disableSelectionOnClick
        sx={{
          background:'#ffff',
          marginTop: '50px',
          fontFamily: 'Poppins',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Sombra del borde
          borderRadius: '8px', // Borde redondeado (si lo deseas)
          '& .MuiDataGrid-columnHeaders': {
            color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de texto de la cabecera
            fontWeight: '600', // Estilo de fuente de la cabecera
            boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)', // Sombra para la cabecera
            borderBottom: '2px solid rgba(0, 0, 0, 0.2)', // Borde inferior de la cabecera
          },
          '& .MuiDataGrid-cell': {
            color: (UserDataMarca?.color_2 && UserDataMarca?.color_2 !== 'undefined') ? UserDataMarca?.color_2 : '#3f51b5',
            borderBottom: '1px solid rgba(224, 224, 224, 1)', // Borde entre celdas
          },
          '& .MuiCheckbox-root': {
            color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5', // Color de los checkboxes (opcional)
          },
          '& .MuiTablePagination-selectLabel':{
              color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
              fontFamily:'Poppins'
          },
          '& .MuiTablePagination-displayedRows' : {
             color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
              fontFamily:'Poppins'
          },
          '& .MuiInputBase-input':{
             color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
              fontFamily:'Poppins'
          },
          '& .Mui-disabled':{
              color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
              fontFamily:'Poppins'
          },
          '& .MuiSelect-icon':{
             color: (UserDataMarca?.color_1 && UserDataMarca?.color_1 !== 'undefined') ? UserDataMarca?.color_1 : '#3f51b5',
              fontFamily:'Poppins'
          }
        }}
      />
          </Box>

        </>
      )}
    </Box>
  );
};

const tituloStyle = {
    fontFamily:'Poppins',
    color:'#585857',
    fontSize:'1.3rem',
    fontWeight:'600',
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'center',
    marginBotton:'30px'
}