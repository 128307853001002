import React, { useEffect, useState, useRef } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Helmet } from 'react-helmet';
import "../../../../../Style/paginavista/empredemo.css"
import { Navbar } from '../../../../nav-fotter/navbar';
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import ReactPlayer from 'react-player';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
/*  libreria UI */
import { Container, Grid, Box, CircularProgress, Modal, Snackbar} from '@mui/material';
import GridViewIcon from '@mui/icons-material/GridView';
import MuiAlert from '@mui/material/Alert';

/* REACT ICONS */
import { BiErrorCircle } from "react-icons/bi";
import { PiScrollFill } from "react-icons/pi";
import { FaPhotoVideo } from "react-icons/fa";
import { IoList } from "react-icons/io5";
import { BsEnvelopePaperHeartFill } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";
import { IoPlayCircleOutline } from 'react-icons/io5';
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { FaRegCircleCheck } from "react-icons/fa6";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

/* imagenes */
import fondo from "../../../../../Image/generales/fondo-memorial.jpg";
import tombmorado from "../../../../../Image/generales/icono-tumba-morado.png"
import tombverde from "../../../../../Image/generales/icono-tumba-verde.png"
import tombnaranjo from "../../../../../Image/generales/icono-tumba-naranjo.png"
import fotovideo from "../../../../../Image/video/foto.jpg"
import verdeluto from "../../../../../Image/generales/verdeluto.png"
import naranjoluto from "../../../../../Image/generales/naranjoluto.png"
import moradoluto from "../../../../../Image/generales/moradoluto.png"
import herramientas from '../../../../../Image/generales/memorial-en-proceso.png' 


export const PaginaEmpresaDemo = ({ setShowNavbarFooter }) => {
    const { nombre_empresa, id, id_qr } = useParams();
    const navigate = useNavigate();
    const [marcas, setMarcas] = useState(null);
    const [empresaData, setEmpresaData] = useState(null);
    const [usuarioData, setusuarioData] = useState(null);
    const [memorialData, setMemorialData] = useState(null);
    const [memorialunicoData, setMemorialDataunico] = useState(null);
    const [grupoFamiliar, setGrupoFamiliar] = useState(null);
    const [loading, setLoading] = useState(true);
    const [loadings, setLoadings] = useState(true);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [error, setError] = useState(false);
    const messagesSectionRef = useRef(null);

   
    /* galeria */
  const [selectedIcon, setSelectedIcon] = useState('grid');
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
 
  /* video */
  const videoRef = useRef(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [isPreviewImageLoaded, setIsPreviewImageLoaded] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  /* comentario */
  const [showMoreCount, setShowMoreCount] = useState(3);
    const [openModal, setOpenModal] = useState(false);
    const [openVideoModal, setOpenVideoModal] = useState(false);

    const handleShowMore = () => {
        const newCount = showMoreCount + 3;
        setShowMoreCount(newCount);
    };
    
    /* BOTONES */ 
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const buttonRef = useRef(null);
    const [comentarios, setComentarios] = useState([]);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [openTerminosModal, setOpenTerminosModal] = useState(false);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [backgroundImage, setBackgroundImage] = useState(''); 
        const [formData, setFormData] = useState({
          nombre: '',
          rut:'',
          email:'',
          titulo: '',
          descripcion: '',
          imagen: null,
          visible:'1',
          aceptaTerminos: false,
      });
    useEffect(() => {
      setShowNavbarFooter(false);
      return () => {
          setShowNavbarFooter(true);
      };
  }, [setShowNavbarFooter]);

  useEffect(() => {
    // Detectar espacios en nombre_empresa
    if (nombre_empresa.includes(" ")) {
        // Transformar espacios en %20
        const fixedNombreEmpresa = encodeURIComponent(nombre_empresa);
        // Redirigir a la URL corregida
        navigate(`/empresa/${fixedNombreEmpresa}/${id}/${id_qr}`, { replace: true });
    }
}, [nombre_empresa, id, id_qr, navigate]);;
  
useEffect(() => {
  // Si hay una imagen de fondo, se carga la versión de baja calidad
  if (marcas?.fondo_memorial) {
    const lowQualityImage = `${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}`;
    setBackgroundImage(lowQualityImage); // Mostrar imagen de baja calidad primero

    // Crear una nueva instancia de imagen para cargar la versión completa
    const img = new Image();
    img.src = `${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}`;
    img.onload = () => {
      setBackgroundImage(img.src); // Cambia a la imagen completa cuando se haya cargado
    };
  }
}, [marcas]);

  useEffect(() => {
    const fetchEmpresaData = async () => {
      try {
        const empresaResponse = await axios.get(`${backendURL}/api/empresa/especifica/${id}`);
        setEmpresaData(empresaResponse.data.empresa);
        setusuarioData(empresaResponse.data.empresa.usuario);
      } catch (error) {
        console.error("Error al obtener datos de la empresa:", error);
      } finally {
        setLoading(false);
      }
    };
  
    fetchEmpresaData();
  }, [id, backendURL]);
  
  useEffect(() => {
    const fetchMarcasData = async () => {
      try {
        const marcasResponse = await axios.get(`${backendURL}/api/marcaempresa/especifica/${id}`);
        const marcaData = marcasResponse.data.data[0];
  
        if (marcaData) {
          setMarcas(marcaData); // Guardar la marca si existe
        }
      } catch (error) {
        console.error("Error al obtener datos de marcas:", error);
      }
    };
  
    fetchMarcasData();
  }, [id, backendURL]);
  
  useEffect(() => { 
    setLoadings(true);
    const fetchGrupoFamiliar = async () => {
      if (id_qr) { // Verifica que id_qr esté presente
        try {
          const grupoFamiliarResponse = await axios.get(`${backendURL}/api/empresa/demoqr/${id_qr}`);
          const grupoFamiliarData = grupoFamiliarResponse.data.grupo_familiar;
          
          // Verifica si hay un grupo familiar y si tiene memoriales
          if (grupoFamiliarData && grupoFamiliarData[0]?.memorial?.length > 0) {
            // Extrae el primer memorial
            const primerMemorial = grupoFamiliarData[0].memorial[0];
            setGrupoFamiliar(grupoFamiliarData); // Guarda todo el grupo familiar
            setMemorialDataunico(primerMemorial); // Guarda solo el primer memorial
          }
          
          setLoadings(false);
        } catch (error) {
          console.error("Error al obtener el grupo familiar:", error);
          setLoadings(false);
        }
      } else {
          console.warn("id_qr no está definido");
          setLoadings(false); // Detiene la carga si id_qr no está presente
      }
    };
  
    fetchGrupoFamiliar();
  }, [id_qr, backendURL]); 
  
  // Maneja la carga de los datos
  useEffect(() => {
    if (grupoFamiliar && grupoFamiliar[0]?.memoriales?.length > 0) {
      setLoadings(false); // Deja de cargar cuando ya tienes los datos
    }
  }, [grupoFamiliar]);

  const fetchDatamemorial = async () => {
    setLoadings(true);
    try {
      // Verificar si el memorial existe
      const memorialResponse = await axios.get(`${backendURL}/api/verificarmemorial/${memorialunicoData.id}`);
      if (!memorialResponse.data.exists) {
        setLoadings(false);
        // Redirigir si el memorial no existe
        navigate('/Registro');
        return;
      }

      // Obtener los datos del memorial
      const memorialDataResponse = await axios.get(`${backendURL}/api/getMemorialesPorGrupoFamiliarpublic/${id_qr}`);
     
      setMemorialData(memorialDataResponse.data[0]); 
      setError(false);


    } catch (error) {
      console.error('Error fetching memorial data:', error);
      setError(true);
    } finally {
      setLoadings(false);
    }
  };

  useEffect(() => {
    if (memorialunicoData && memorialunicoData.id) {
      fetchDatamemorial();
    }
  }, [memorialunicoData]);
  
   /* botones */
   const handleIconClick = (icon) => setSelectedIcon(icon);

   const handleOpenModals = (index) => {
     setSelectedImageIndex(index);
     setModalOpen(true);
   };
 
     const handleCloseModals = () => {
       setModalOpen(false);
       setSelectedImageIndex(null);
     };
 
     const handlePrevImage = () => {
       setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? memorialData.galeria.length - 1 : prevIndex - 1));
     };
 
     const handleNextImage = () => {
       setSelectedImageIndex((prevIndex) => (prevIndex === memorialData.galeria.length - 1 ? 0 : prevIndex + 1));
     };
 
     const handleVideoModalOpen = (e) => {
       e.preventDefault();
       setOpenVideoModal(true);
     };
 
     const handleOpenModal = () => setOpenModal(true);
     const handleCloseModal = () => {
       setOpenModal(false);
       setenviandoAlert(false);
     };
 
     const handleChange = (e) => {
     const { name, value } = e.target;
     setFormData(prevFormData => ({
         ...prevFormData,
         [name]: value
     }));
     };
 
 
 
     const handleImageChange = (e) => {
     setFormData(prevFormData => ({
         ...prevFormData,
         imagen: e.target.files[0]
     }));
     };
 
 
     const handleTerminosChange = () => {
       setFormData({ ...formData, aceptaTerminos: !formData.aceptaTerminos });
     };
 
     const handleOpenTerminosModal = () => {
       setOpenTerminosModal(true);
   };
 
   const handleCloseTerminosModal = () => {
       setOpenTerminosModal(false);
   };
 
   const Alert = React.forwardRef(function Alert(props, ref) {
     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
 });
 const handleSubmit = async (e) => {
  e.preventDefault();

  // Validaciones previas
  if (!formData.aceptaTerminos) {
      setSnackbarMessage("Debes aceptar los términos y condiciones antes de enviar.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
  }
  if (!formData.email) {
      setSnackbarMessage("Debes ingresar correo electrónico.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
  }
  if (!formData.telefono) {
      setSnackbarMessage("Debes ingresar telefono.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
  }
  if (!formData.nombre) {
      setSnackbarMessage("Debes ingresar nombre.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
  }
  if (!formData.titulo) {
      setSnackbarMessage("Debes ingresar título.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
  }
  if (!formData.descripcion) {
      setSnackbarMessage("Debes ingresar descripción.");
      setSnackbarSeverity('error');
      setOpenSnackbar(true);
      return;
  }

  // Desestructurar datos del formulario
  const { nombre, titulo, telefono, email, descripcion, imagen } = formData;

  // Crear un nuevo FormData para enviar la información
  const formDataToSend = new FormData();
  formDataToSend.append('titulo', titulo);
  formDataToSend.append('creador', nombre);
  formDataToSend.append('telefono', telefono);
  formDataToSend.append('email', email);
  formDataToSend.append('mensaje', descripcion);
  if (imagen) {
      formDataToSend.append('imagen', imagen); // Solo agregar imagen si existe
  }
  formDataToSend.append('id_memorial', memorialData.id);

  try {
      setenviandoAlert(true); // Activar el estado de "enviando"

      const response = await axios.post(`${backendURL}/api/comentarioStoresdemo`, formDataToSend, {
          headers: {
              'Content-Type': 'multipart/form-data', // Asegurarte de enviar datos como FormData
          },
      });

     
      setAlertMessage('Su mensaje será verificado por el administrador del memorial.');
      setSuccessAlertOpen(true);
      await fetchDatamemorial(); // Actualizar datos del memorial

      // Reiniciar el formulario después de un tiempo
      setTimeout(() => {
          setSuccessAlertOpen(false);
          setFormData({
              nombre: '',
              titulo: '',
              descripcion: '',
              telefono: '',
              email: '',
              imagen: null,
          });
      }, 5000);
  } catch (error) {
      console.error('Error al enviar el comentario:', error);
      setAlertMessage('Error al enviar el mensaje');
      setErrorAlertOpen(true);
      setTimeout(() => setErrorAlertOpen(false), 5000);
  } finally {
      setOpenModal(false); // Cerrar el modal
      setenviandoAlert(false); // Desactivar el estado de "enviando"
  }
};
     
  const capitalizeFirstLetter = (string) => {
    if (!string) return ''; // Manejar el caso cuando la cadena es null o undefined
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  /* fechas */
  function formatFecha(fecha) {
    // Verifica si la fecha es nula o indefinida
    if (!fecha) {
      return '';
    }
    
    // Convierte la fecha a un objeto Date
    const date = new Date(fecha);
    
    // Verifica si la fecha es válida
    if (isNaN(date.getTime())) {
      return '';
    }
    
    // Obtiene el día, el mes y el año
    const day = date.getDate();
    const month = date.toLocaleString('es-ES', { month: 'long' });
    const year = date.getFullYear();
    
    // Devuelve la fecha formateada
    return `${day} ${month} ${year}`;
  }

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleShare = () => {
    // Codifica el nombre de la empresa para la URL
    const nombreEmpresaCodificado = encodeURIComponent(empresaData.nombre_empresa);
    
    // Construye la URL para compartir
    const shareURL = `/empresa/${nombreEmpresaCodificado}/${empresaData.id}/${id_qr}`;
    
    // Usa la API de compartir del navegador
    window.navigator.share({ url: shareURL })
        .then(() => {
           
        })
        .catch((error) => {
            console.error('Error al compartir:', error);
        });
};

  /* COLORES MEMORIAL */
  const getImageSrc = (fondo) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return tombverde;
      case 'atardecerVerano':
        return tombnaranjo;
      default:
        return tombmorado;
    }
  };

  const imageSrc = getImageSrc(memorialData?.fondo);


  const getImageObituario = (fondo) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return verdeluto;
      case 'atardecerVerano':
        return naranjoluto;
      default:
        return moradoluto;
    }
  };

  const imageObituario = getImageObituario(memorialData?.fondo);

  const getColorByFondo = (fondo) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return '#E1EDDD';
      case 'atardecerVerano':
        return '#F9E7D7';
      default:
        return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#E1DBE6';
    }
  };
  const getColorByborder = (fondo) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return '#68A354';
      case 'atardecerVerano':
        return '#E28939';
      default:
        return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81';
    }
  };

  const getColorByborderperfil = (fondo) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return '#E1EDDD';
      case 'atardecerVerano':
        return '#F9E7D7';
      default:
        return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#E1DBE6';
    }
  };

  const borderColor = getColorByFondo(memorialData?.fondo);
  const backgroundColor = getColorByFondo(memorialData?.fondo);

  const getLightBackgroundByFondo = (fondo) => {
    switch (fondo) {
      case 'naturalezaAustral':
        return '#F3F8F1';
      case 'atardecerVerano':
        return '#FDF5EF';
      default:
        return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#E1DBE6';
    }
  };

  const backborderColor = getLightBackgroundByFondo(memorialData?.fondo);
  const StyleFondo = {
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: 'auto',
    transition: 'filter 0.3s ease', // Transición suave
    filter: backgroundImage.includes('_low') ? 'blur(5px)' : 'none', // Aplica desenfoque mientras sea de baja calidad
  };

  const styles = {
    titleStyle: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81';
        }
      })(),
    },
    fondomensajeStyle: {
      background: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81';
        }
      })(),
    },
    fondosStyle: {
      background: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#F3F8F1';
          case 'atardecerVerano':
            return '#FDF5EF';
          default:
            return (marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 : '#F0EDF3';
        }
      })(),
    },
    titulodosStyle: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#F3F8F1';
          case 'atardecerVerano':
            return '#FDF5EF';
          default:
            return (marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 : '#F0EDF3';
        }
      })(),
    },
    titulooscuroStyle: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#27632D';
          case 'atardecerVerano':
            return '#A04424';
          default:
            return (marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 :  '#D26999';
        }
      })(),
    },
    botonoscuroStyle: {
      backgroundColor: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#27632D';
          case 'atardecerVerano':
            return '#A04424';
          default:
            return (marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 :  '#D26999';
        }
      })(),
    },
    texto: {
      color: "#fff"
    },
    selectedIconStyle: {

      cursor: "pointer",
      width: '40px',
      height: '40px',
      color: '#fff',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81';
        }
      })(),
    },
    iconStyle: {
      cursor: "pointer",
      width: '40px',
      height: '40px',
      borderRadius: '50%',
      background: 'transparent',  // Aquí está el error corregido
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81';
        }
      })(),
    },
    modalContent: {
      maxHeight: '80vh', // Limitar la altura máxima
      overflowY: 'auto', // Hacer el contenido desplazable si es necesario
      position: 'relative',
    },
    modalImage: {
      width: '100%',
      maxHeight: '60vh',
      objectFit: 'contain',
    },
    buttonmas: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#68A354';
          case 'atardecerVerano':
            return '#E28939';
          default:
            return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81';
        }
      })(),
      border: `1px solid ${getColorByborder(memorialData?.fondo)}`,
      '--button-bg-hover': backborderColor,
      '--button-border-hover': borderColor,
    },
    styleborder: {
      border: `8px solid ${getColorByborderperfil(memorialData?.fondo)}`,

    },
    styleiconoblose: {
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#E1EDDD';
          case 'atardecerVerano':
            return '#F9E7D7';
          default:
            return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#E1DBE6';
        }
      })(),

    }
  };

  if (loading || loadings) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh', // Pantalla completa
          flexDirection: 'column',
        }}
      >
        <CircularProgress
          size={100}
          thickness={4}
          sx={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' }}
        />
      </Box>
    );
  }
    return(
        <section style={StyleFondo}>
           {marcas ? (
  <Navbar
  marcas={marcas}
  color1={(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : ''}  // Proveer color por defecto
  color2={(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  ''}
  logomarca={`${backendURL}/${marcas?.logo}`}
  />
) : (
  <Navbar
  />
)}
            
          <Helmet>
       <title> Empresa {empresaData.id_tipo_empresa == 1 ? 'Cementerio' : 'Funeraria' || ""} {empresaData.nombre_empresa || "Empresa"}  </title>
        <meta name="description" content="Empresa asociada con smart memorial para mejorar el servicio de nuestros clientes" />
        
      </Helmet>
      <Container component="main" maxWidth="xs" >
      {grupoFamiliar && grupoFamiliar.length > 0 ? (
      <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            background: "#ffff",
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: (() => {
              switch (memorialData?.fondo) {
                case 'naturalezaAustral':
                  return '#E1EDDD';
                case 'atardecerVerano':
                  return '#F9E7D7';
                default:
                  return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 :  '#E1DBE6';
              }
            })(),
          }}
        >

          <section className="contienehistoriademo" ref={messagesSectionRef} >


            <div className="vistamemorial">
            
            {marcas?.fondo_memorial ? (
                    <img 
                    src={`${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}`} 
                        alt="smart memorial" 
                    />
                ) : (
                    <img 
                        src={fondo} 
                        alt="smart memorial" 
                    />
                )}

                
              <div className="titulodemomerorial">
              <Box
        sx={{
          marginTop: '-40px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          marginBottom:'0px',
          
        }}
      >
        <div className='contienenEmpresaInicio'>
                      
                      <div className='marcologoempresademo'>
                      {marcas?.logo ? (
                            <img src={`${backendURL}/${marcas.logo}`} className='marcoimglogoempresademo' alt='Smart Memorial' title='SmartMemorial' />
                        ) : <>
                        </>}  
                      </div>  
                      <div className="titulogrupoempresa">
                        <h3 style={{
                          color:(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 :  '#694B81'
                        }}> {empresaData.nombre_empresa || "Empresa"} </h3>
                        <div className="segundotitulodemofinal">
                        <h1 style={{
                          color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#D26999'
                        }}> {grupoFamiliar[0].nombre_familia} </h1>
                        </div>
                      </div>
                  
                
                </div>
      </Box>
             <Box sx={{
              marginTop:'20px'
             }}> 
                <h1 className="memorialtitulo" translate="no" style={styles.titleStyle}>Memorial</h1>
                <h1 className="nombre" style={styles.titulooscuroStyle}>{memorialData?.nombre}</h1>
              </Box>
              </div>
              <div className="imagendemopersona" style={styles.styleborder}>
              {memorialData && (
              <img src={`${backendURL}/imagenes/memoriales/${memorialData.carpeta_perfil}/${memorialData.imagen_perfil}`} alt="smart memorial" style={styles.fondosStyle} />
)}
              </div>
            </div>
            {memorialData?.historia && (<div className="texto-historia" style={styles.fondosStyle}>
              <div className="titulohistoriademo" style={styles.titleStyle} ><PiScrollFill /> <h1>Su historia</h1>  </div>
              <p className="textohistoriademo">
                {memorialData?.historia ? (
                  <DangerouslySetHTMLContent html={capitalizeFirstLetter(memorialData.historia)} />
                ) : (
                  <span></span>
                )}
              </p>
            </div>)}


          </section>
          {memorialData && memorialData.galeria && memorialData.galeria.length > 0 && (
            <section className="demogaleriarecuerdo">
              {memorialData && memorialData.galeria && memorialData.galeria.length > 0 && (
                <div className="titulogaleriabonito" style={styles.titleStyle}>
                  <FaPhotoVideo /> <h1>Galería de recuerdos</h1>
                </div>
              )}
              <section className="contienensliderdemogaleria">
                {memorialData && memorialData.galeria && memorialData.galeria.length > 0 && (
                  <div className="titulodemofoto">
                    <div style={styles.titleStyle}><p>Fotos</p></div>
                    <div className="contienenvistafotos">
                      <p>Vista</p>
                      <div
                        className="iconosfotos"
                        style={selectedIcon === 'list' ? styles.selectedIconStyle : styles.iconStyle}
                        onClick={() => handleIconClick('list')}
                      >
                        <IoList className="list" />
                      </div>
                      <div
                        className="iconosfotos"
                        style={selectedIcon === 'grid' ? styles.selectedIconStyle : styles.iconStyle}
                        onClick={() => handleIconClick('grid')}
                      >
                        <GridViewIcon />
                      </div>
                    </div>
                  </div>
                )}
                <div className="contiendedemogaleria">
                  {memorialData && memorialData.galeria && memorialData.galeria.length > 0 && (
                    selectedIcon === 'list' ? (
                      memorialData.galeria.map((imagen, index) => (
                        <div style={styles.fondosStyle} key={index}>
                          <div className="marcodefotodemo">
                            <div className="caratulademogaleria">
                            <LazyLoadImage
                              src={`${backendURL}/${imagen.imagen}`}
                              alt="smart memorial"
                              effect="blur" // Aplica un efecto de desenfoque mientras carga
                              placeholderSrc={`${backendURL}/${imagen.imagen}`} // Opcional: Imagen de baja calidad como placeholder
                            />
                            </div>
                            <div className="textocarddemogaleria">
                              <h3 style={styles.titulooscuroStyle}>{formatFecha(imagen.fecha)}</h3>
                              <h1 style={styles.titleStyle}>{capitalizeFirstLetter(imagen.titulo)}</h1>
                              <h2>{capitalizeFirstLetter(imagen.descripcion)}</h2>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {memorialData.galeria.map((imagen, index) => (
                          <Grid item xs={4} key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "5px -5px" }}>
                            <div className="contienenmarcofotosdos" onClick={() => handleOpenModals(index)}>
                              <div className="marcodefotosdosdemo">
                                <div className="caratulademodosgaleria">
                                  <img src={`${backendURL}/${imagen.imagen}`} alt="smart memorial" />
                                </div>
                              </div>
                            </div>
                          </Grid>
                        ))}
                      </Grid>
                    )
                  )}
                </div>
              </section>
            </section>)}
            {memorialData && memorialData.video && (
  <section className="contienenvideosdemo" >
    <div className="videotitulodemo" style={styles.titleStyle}> Video</div>

    <div className="video-container">
     

      {isLoading && (
        <div className="loader">
          <div className="in"></div>
          <div className="out-1"></div>
          <div className="out-2"></div>
          <div className="out-3"></div>
        </div>
      )}

<ReactPlayer
  url={`${backendURL}/video/${memorialData.video}?t=${new Date().getTime()}`}
  playing={isPlaying}
  controls={true}
  width="100%"
  height="200px"
  light={isPreviewImageLoaded ? previewImage : (marcas.fondo_video ? `${backendURL}/${marcas.fondo_video}` : fotovideo)}
  onPlay={() => setIsPlaying(true)}
  onPause={() => setIsPlaying(false)}
  onReady={() => setIsLoading(false)}
  onError={() => {
    setIsLoading(false);
    setIsPlaying(false);
  }}
/>
    </div>
  </section>
)}
<section style={{width:'100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'Column'}}>
{memorialData?.comentarios && (   
        <div className="titulodemomensaje" style={styles.titleStyle}>  <h1> Sus mensajes y recuerdos</h1> <h1> para compartir</h1></div>
       )}

<div className="contienentodoslosmensajedemodos" >
                    <section className="demorecuerdomensaje">

                     
                    {memorialData && memorialData.comentarios && memorialData.comentarios
  .filter(comentario => comentario.visible === '1') 
  .reverse()
  .slice(0, showMoreCount) 
  .map((comentario, index) => (
    <div key={index} style={{ width: "100%" }}>
      <div className="contienencarddemomensaje" style={styles.fondomensajeStyle}>
        <div className="contienentextomensajedemo">
          <h1 style={styles.titulooscuroStyle}>{capitalizeFirstLetter(comentario.titulo)}</h1>
          <h2 style={styles.texto}>
            {comentario.mensaje ? (
              <DangerouslySetHTMLContent html={capitalizeFirstLetter(comentario.mensaje)} />
            ) : (
              <span></span>
            )}
          </h2>
        </div>
        <div className="quienloescriviodemo" style={styles.texto}>
          <h3>{comentario.creador}</h3>
          <p>{formatDate(comentario.fecha)}</p>
        </div>
        <div className="imagendemmensajedemo">
        {comentario.imagen && (
  <div className="imagendemmensajedemo">
    <LazyLoadImage
      src={`${backendURL}/${comentario.imagen}`} // URL de la imagen
      alt="smart memorial" // Texto alternativo
      effect="blur" // Efecto de desenfoque mientras carga
      placeholderSrc="ruta/de/imagen_placeholder.jpg" // Imagen de baja calidad como placeholder (opcional)
    />
  </div>
)}
        </div>
      </div>
                            <section className="corazonmemorialdenmo">
                                <div className="containerheart">
                                    <div className="heart">
                                        <svg width="50" height="50" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z"/>
                                        </svg>
                                    </div>
                                    <div className="heart">
                                        <svg width="40" height="40" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z"/>
                                        </svg>
                                    </div>
                                    <div className="heart">
                                        <svg width="35" height="35" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z"/>
                                        </svg>
                                    </div>
                                </div>
                            </section>
                                </div>
               
                     
              ))}
                        <section>
                        {(showMoreCount < (memorialData?.comentarios?.length || 0)) && (
  <button className="ver-mas-mensajes" style={styles.buttonmas} onClick={handleShowMore}>
    Mostrar más mensajes
  </button>
)}          </section>
                        
                    </section>
                   
 </div></section>
 {memorialData?.cementerio && (         
<section className="contienencementerio">
    

        <section className="titulocementerio" style={styles.titleStyle}>
            <img src={imageSrc} className="iconocementerio" alt="smart memorial" title="smart memorial" />
            <div className="titulocementerio"> <h1>Donde nos encontramos</h1></div> 
        </section>
   
    <section className="contieneninformacioncementerio">
        <p>{capitalizeFirstLetter(memorialData.cementerio)}</p>

    </section>

</section>  )}

<section
           className={`enviarmensajememorialpaginainicial ${isButtonVisible ? 'enviarmensajememorialpaginainicial-visible' : 'enviarmensajememorialpaginainicial-hidden'}`}
          style={{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            flexDirection:"column",
            border: `1px solid ${borderColor}`,
            background: backgroundColor,
          }}
          ref={buttonRef}
        >
          <button className="enviarmensajeusuarioexterno" onClick={handleOpenModal} style={styles.botonoscuroStyle}>
          <BsEnvelopePaperHeartFill /> Comparte un recuerdo o mensaje de amor
          </button>

          <button onClick={handleShare}   className="butoncompartirmemorial" style={styles.fondomensajeStyle}   > <FaShareAlt /> Compartir memorial</button>
        </section>
  
        </Box>) : (
    // Contenido cuando NO hay un grupo familiar
    <Box
      sx={{
        marginTop: '20px',
       
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '80%',
        background: (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : "#F0EDF3",
        borderRadius: "10px",
        padding: "30px 30px",
        '@media (max-width: 375px)': {
          padding: "30px 10px",
          width: '95%',
        },
      }}
    >
      <div className="memorialfamiliavacio">
        <h1 style={{
          color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81'
        }}>
          Este grupo familiar se encuentra
        </h1>
        <h1 style={{
          color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81'
        }}>
          en proceso de creación
        </h1>
      </div>
      <div className="contienenimagenvaciomemorial" style={{ width: '200px', height: '100px' }}>
        {marcas?.icono_registro ? (
          <LazyLoadImage
            src={`${backendURL}/${marcas.icono_registro}`}
            placeholderSrc={`${backendURL}/${marcas.icono_registro}`}
            alt="smart memorial"
            title="smart memorial"
            effect="blur"
          />
        ) : (
          <LazyLoadImage
            src={herramientas}
            placeholderSrc={herramientas}
            alt="smart memorial"
            title="smart memorial"
            effect="blur"
          />
        )}
      </div>
    </Box>
  )}
    

      </Container>
      <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px",  height: '80vh', bgcolor: 'background.paper', boxShadow: 24, p: 1, borderRadius:'10px',  overflowY: 'auto', ' @media (max-width: 500px)': {
            width:"90%"
          } }}>
                    <section className="closemodal" onClick={handleCloseModal} style={{color:marcas.color_1 || '#694B81'}}> <IoClose /></section>
                    <section className="contienentodomesjeneviar">
                    <section className="titulodentromodalmensaje" style={{color:marcas.color_1 || '#694B81'}}>  <IoIosMail /> <h2 style={{ fontSize:'1rem'}} id="modal-title">Dejar un mensaje</h2> </section>
                      <section className="subtituloinfomensaje"><span>Si deseas compartir un recuerdo en video, puedes incluir un enlace de video en tu mensaje. Simplemente haz clic en el botón "Agregar video", ingresa la URL del video que deseas compartir y selecciona "Guardar". El enlace aparecerá en tu mensaje como "Ver Video", permitiendo a otros ver tu video mientras leen tu mensaje. </span></section>
                  </section> 
                   <form>
                        <div className="formulariocoment">
                            <label>Ingrese su nombre</label>
                            <input type="text" name="nombre" value={formData.nombre} onChange={handleChange} className="desinput" />
                            <label>Telefono</label>
                            <input type="tel" name="telefono" value={formData.telefono} onChange={handleChange} className="desinput" />
                            <label>Correo Electrónico</label>
                            <input type="mail" name="email" value={formData.email} onChange={handleChange} className="desinput" />
                            <label>Título</label>
                            <input type="text" name="titulo" value={formData.titulo} onChange={handleChange} className="desinput" />
                            <label>Descripción</label>
                            <textarea  
                            className="inputmesnajeneviar"
                            value={formData.descripcion}
                            onChange={handleChange}
                            name="descripcion"
                            id="descripcion"
                            />
                            <button className="videodescripcionmensaje"  onClick={handleVideoModalOpen}>    <IoPlayCircleOutline/> Agregar video </button>
                         
                            <label>Agregar imagen (opcional)</label>
                            <div className="cardimagencom">
                                <div className="todocardimganecomt">
                                    <input type="file" accept="image/*" onChange={handleImageChange} className="desimagen" />
                                    <div className="iconoagregarcomimagen">
                                        <FaArrowUpFromBracket className="imgicono" />
                                        <span>Subir Foto</span>
                                    </div>
                                </div>
                                {formData.imagen && (
                                    <img src={URL.createObjectURL(formData.imagen)} alt="Vista previa de la imagen" className="imagenselecion" />

                                )}
                            </div>
                            {marcas.terminos_condiciones && (
                            <div style={{marginBottom:'10px',display:'flex'}}>
                                <label style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                  <label className="cl-checkbox">
                                  <input checked={formData.aceptaTerminos} onChange={handleTerminosChange} type="checkbox" />
                                  <span></span>
                                </label>
                                    
                                  <span onClick={handleOpenTerminosModal} style={{ paddingLeft:'13px', cursor: 'pointer' }}>  Acepto los términos y condiciones</span>
                                </label>
                                {/* Modal de términos y condiciones */}
                                <Modal
                                    open={openTerminosModal}
                                    onClose={handleCloseTerminosModal}
                                    aria-labelledby="modal-terminos-title"
                                    aria-describedby="modal-terminos-description"
                                >
                                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: '80vh', bgcolor: 'background.paper', boxShadow: 24, p: 1, borderRadius: '10px', overflowY: 'auto', '@media (max-width: 500px)': { width: "90%" } }}>
                                        <section className="closemodal" onClick={handleCloseTerminosModal}> <IoClose /></section>
                                        <section className="contienentodomesjeneviar">
                                        <Box
                                            sx={{
                                              marginTop: '0px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              marginBottom:'0px',
                                            
                                              
                                            }}
                                          >
                                            <div className='contienenEmpresaInicio'>
                                                          
                                                          <div className='marcologoempresademo'>
                                                          {marcas?.logo ? (
                                                                <img src={`${backendURL}/${marcas.logo}`} className='marcoimglogoempresademo' alt='Smart Memorial' title='SmartMemorial' />
                                                            ) : <>
                                                            </>}  
                                                          </div>  
                                                          <div className="titulogrupoempresa">
                                                            <h3 style={{
                                                              color:(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 :  '#694B81'
                                                            }}>{empresaData.id_tipo_empresa == 1 ? 'Cementerio' : 'Funeraria' || ""} </h3>
                                                            <div className="segundotitulodemofinal">
                                                            <h1 style={{
                                                              color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#D26999'
                                                            }}> {empresaData.nombre_empresa} </h1>
                                                            </div>
                                                          </div>
                                                      
                                                    
                                                    </div>
                                          </Box>
                                                                                <h2 id="modal-terminos-title" className='tituloterminoycondicionmemorialemrpesa' style={{color:(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 :  '#694B81'}}>Términos y condiciones</h2>
                                            <section id="modal-terminos-description" style={{width:'90%'}}>
                                            {marcas.terminos_condiciones ? (
                                                  <iframe
                                                      src={`${backendURL}/${marcas.terminos_condiciones}`}
                                                      title="PDF de Términos y Condiciones"
                                                      width="100%"
                                                      height="450px"
                                                      style={{ border: "none", marginTop: '8px' }}
                                                  />
                                              ) : (
                                                  <span>No hay PDF disponible</span>
                                              )}
                                            </section>
                                        </section>
                                    </Box>
                                </Modal>
                            </div>
                        )}

                            <button style={{background:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81'}} className="botonguardarcomt" onClick={handleSubmit}> 
    Enviar mensaje
   </button>
                        </div>
                    </form>
                    <section className="scroolmensaje">
          <div className="scrolldown" >
            <div className="chevrons">
              <div className="chevrondown"></div>
              <div className="chevrondown"></div>
            </div>
          </div>
        </section>
                </Box>
            </Modal>
            <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <h2 id="modal-title" style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600',  marginTop:"-10px" }}>Mensaje enviado Correctamente</h2>
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando mensaje...
            </p>
          </section>
        </Box>
      </Modal>

      {modalOpen && selectedImageIndex !== null && (
        <Modal open={modalOpen} onClose={handleCloseModals}>
            <Box
             style={styles.fondosStyle}
            sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            height: 'auto',
            boxShadow: 24,
            
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}>
          <div style={styles.modalContent}>
            <div className="contienenclosefotosmemorial">
            <IconButton style={styles.titleStyle} onClick={handleCloseModals}>
              <CloseIcon />
            </IconButton>
           </div>
            <div className="marcodefotodemo">
            <div className="caratulademogaleria">
            <img
              src={`${backendURL}/${memorialData.galeria[selectedImageIndex].imagen}`}
              alt={memorialData.galeria[selectedImageIndex].titulo}
              style={styles.modalImage}
            />
            </div>
            <div className="textocarddemogaleria">
              <h3 style={styles.titulooscuroStyle}>{formatFecha(memorialData.galeria[selectedImageIndex].fecha)}</h3>
              <h1 style={styles.titleStyle}>{capitalizeFirstLetter(memorialData.galeria[selectedImageIndex].titulo)}</h1>
              <h2>{capitalizeFirstLetter(memorialData.galeria[selectedImageIndex].descripcion)}</h2>
            </div>
          </div>
          </div>
           <div  className="contienenflechasfotos">
              <IconButton onClick={handlePrevImage}>
                <IoIosArrowBack style={styles.titleStyle} /> <p style={styles.titleStyle} className="tectoiconoflechas">Anterior</p>
              </IconButton>
              <IconButton onClick={handleNextImage}>
               <p  style={styles.titleStyle} className="tectoiconoflechas">Siguiente</p> <IoIosArrowForward style={styles.titleStyle}/> 
              </IconButton>
            </div>
          </Box>
        </Modal>
      )}

            <Snackbar 
                open={openSnackbar} 
                autoHideDuration={6000} 
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Posicionamiento
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        
        </section>
    )
}

