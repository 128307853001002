import React from 'react';
import { Helmet } from 'react-helmet';

const VideoPlayer = () => {
  const videoId = "1Ml4hYHKb1dleGd3sKANPJ90Fop4XK62Z";  // ID de tu video en Google Drive

  return (
    <div style={{
        marginBottom:'-10px'
    }}>
        <Helmet>
        <title>Bienvenido a smart empresas</title>
        <meta name="description" content="video interactivo de como hacer uso del sistema que ofrece smart memorial" />
      </Helmet>
      <iframe
        src={`https://drive.google.com/file/d/${videoId}/preview`}
        width="100%"
        height="616px"
        frameBorder="0"
        allow="autoplay"
        title="Video de Google Drive"
      ></iframe>
    </div>
  );
};

export default VideoPlayer;