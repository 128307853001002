import React, { useState, useEffect } from "react";
import { AppBar, Box, Container, IconButton, Toolbar, Tooltip, Drawer, List, ListItem, ListItemButton, ListItemText, Divider, Grid } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';
import "../../Style/navbar/navbar.css"
import LogoutIcon from '@mui/icons-material/Logout';
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa";
import { FaSquareInstagram } from "react-icons/fa6";
import { GrInstagram } from "react-icons/gr";
import PropTypes from 'prop-types';
import { BsTwitterX } from "react-icons/bs";
import { IoLogoLinkedin } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa6";


/* imagenes */
import logo from "../../Image/navbar/movil-smart-memorial-header.png"
import { Grid3x3Sharp } from "@mui/icons-material";



const AnchorTemporaryDrawer = ({ open, onClose,  color1, logomarca, marcas}) => {
  
 

  return (
    <StyledDrawer
      anchor={'right'}
      open={open}
      onClose={onClose}
      sx={{
        '& .MuiPaper-root': { // Selecciona el Paper dentro del Drawer
    background: color1 || '#431E62', // Cambia el color de fondo
  },
      }}
    >
      <Box
        sx={{ width:400, padding: '50px 0px',  background: color1 || '#431E62', color: '#fff', '@media (max-width: 400px)': {
          width: '100%',
        }, }}
        role="presentation"
        onClick={onClose}
        onKeyDown={onClose}
        id="menunavbaradmi"
      >
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop:'-20px' }}>  
          <IconButton onClick={onClose} color="inherit">
            <CloseIcon className="iconoclosenav" />
          </IconButton>
        </Box>
        <List sx={{marginTop:"50px"}}>
        <ListItem disablePadding >
        
          </ListItem>
          <ListItem disablePadding sx={{display:'flex', justifyContent:'center', alignItems:'center', marginTop:'-15%', marginBottom:'5%'}} >
          <Link to={marcas?.web || '/Smart-Memorial'} style={{ width: '80px' }}>
        <img
            src={logomarca || logo} 
            alt='Smart Memorial'
            width='100%'
        />
    </Link>
          </ListItem>
          {!marcas || marcas.length == 0 ? (
              <Link to='/personas-smart-memorial'>
              <ListItem disablePadding  >
                <StyledListItemButton sx={{display:'flex', justifyContent:'center', alignItems:'center',  fontSize:'0.9rem', color:'#fff'}}>
                Memorial Personas
                </StyledListItemButton>
              </ListItem>
              </Link>
              ) : null}
                {!marcas || marcas.length == 0 ? (
              <Link to='/'>
              <ListItem disablePadding  >
                <StyledListItemButton sx={{display:'flex', justifyContent:'center', alignItems:'center',  fontSize:'0.9rem', color:'#fff'}}>
                Memorial Empresa
                </StyledListItemButton>
              </ListItem>
              </Link>
              ) : null}
              {!marcas || marcas.length == 0 ? (
              <Link to ='/SmartPersonas'>
              <ListItem disablePadding >
                <StyledListItemButton sx={{display:'flex', justifyContent:'center', alignItems:'center',  fontSize:'0.9rem', color:'#fff'}}>
                  Acceso Personas
                </StyledListItemButton>
              </ListItem>
              </Link>
              ) : <Link to ={`/empresa/${marcas.id_empresa}/login/usuario`}>
              <ListItem disablePadding >
                <StyledListItemButton sx={{display:'flex', justifyContent:'center', alignItems:'center',  fontSize:'0.9rem', color:'#fff'}}>
                Acceso Personas
                </StyledListItemButton>
              </ListItem>
              </Link>}
              <Link to ='/Inicio-Sesion-Empresas'>
              <ListItem disablePadding >
                <StyledListItemButton sx={{display:'flex', justifyContent:'center', alignItems:'center',  fontSize:'0.9rem', color:'#fff'}}>
                Acceso Empresas
                </StyledListItemButton>
              </ListItem>
              </Link>
              {!marcas || marcas.length == 0 ? (
              <Link to='/contacto-smart-memorial'>
              <ListItem disablePadding  >
                <StyledListItemButton sx={{display:'flex', justifyContent:'center', alignItems:'center',  fontSize:'0.9rem', color:'#fff'}}>
                Contacto
                </StyledListItemButton>
              </ListItem>
              </Link>
              ) : null}
          {!marcas || marcas.length === 0 ? (
          <Link to='/solicitarmemorial'>
          <ListItem disablePadding  >
            <StyledListItemButton sx={{display:'flex', justifyContent:'center', alignItems:'center',  fontSize:'0.9rem', color:'#fff'}}>
            Solicitar memorial
            </StyledListItemButton>
          </ListItem>
           </Link>
           ) : null}
         {!marcas || marcas.length === 0 ? ( 
           <Link to="/smartmemorial-terminos-y-condiciones">    
    <ListItem disablePadding>
        <StyledListItemButton sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '0.9rem', color: '#fff' }}>
            Términos y condiciones
        </StyledListItemButton>
    </ListItem>
         </Link>
        ) : null}
        </List>
        <Divider sx={{ background: "#fff", margin:"10px 10px" }} />
        <List>
          <ListItem disablePadding>
            <StyledListItemButtonfinal >
            <div className="informacionnav">
  <span>Si necesita más información,</span>
  {marcas?.web ? (
    <>
      <span>visítanos a nuestra página web:</span>
      <a href={marcas.web} target="_blank" rel="noopener noreferrer">
        <button>{marcas.web}</button>
      </a>
    </>
  ) : (
    <>
      <span>escríbanos un correo a:</span>
      <a href="mailto:info@smartmemorial.cl">
        <button>info@smartmemorial.cl</button>
      </a>
    </>
  )}
</div>
            
            </StyledListItemButtonfinal>
            </ListItem>
            <ListItem disablePadding>
            <StyledListItemButtonfinal >
              <div className="redessociales">
              <div className="tituloredesnav">
                <h3>Síguenos en:</h3>
              </div>
              <Grid container sx={{ width: "90%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                    {marcas ? (
          marcas.facebook && marcas.facebook !== 'undefined' && marcas.facebook !== null && marcas.facebook !== "null" && marcas.facebook !== "" ? (
            <Grid item xs={2} sx={{ width: "50%" }}>
              <a className="nulla" target="_blank" rel="noopener noreferrer" href={marcas.facebook}>
                <div className="rednav"><FaFacebook /></div>
              </a>
            </Grid>
          ) : null // No mostrar nada si la marca existe pero no tiene Facebook válido
        ) : (
          <Grid item xs={2} sx={{ width: "50%" }}>
            <a className="nulla" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/smartmemorial">
              <div className="rednav"><FaFacebook /></div>
            </a>
          </Grid>
        )}

        {/* Instagram */}
        {marcas ? (
          marcas.instagram && marcas.instagram !== 'undefined'  && marcas.instagram !== null && marcas.instagram !== "" && marcas.instagram !== "null" ? (
            <Grid item xs={2} sx={{ width: "50%" }}>
              <a className="nulla" target="_blank" rel="noopener noreferrer" href={marcas.instagram}>
                <div className="rednav"><GrInstagram /></div>
              </a>
            </Grid>
          ) : null // No mostrar nada si la marca existe pero no tiene Instagram válido
        ) : (
          <Grid item xs={2} sx={{ width: "50%" }}>
            <a className="nulla" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/smart.memorial/">
              <div className="redimstanav"><GrInstagram /></div>
            </a>
          </Grid>
        )}
                  {marcas ? (
    marcas.tiktok && marcas.tiktok !== 'undefined' && marcas.tiktok !== null && marcas.tiktok !== "" && marcas.tiktok !== "null" ? (
      <Grid item xs={2} sx={{ width: "50%" }}>
        <a className="nulla" target="_blank" rel="noopener noreferrer" href={marcas.tiktok}>
          <div className="rednav"><FaTiktok /></div>
        </a>
      </Grid>
    ) : null // Si la marca existe pero no tiene TikTok válido, no se muestra nada
  ) : (
    <Grid item xs={2} sx={{ width: "50%" }}>
      <a className="nulla" target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@smart.memorial">
        <div className="rednav"><FaTiktok /></div>
      </a>
    </Grid>
  )}
                  {marcas ? (
    marcas.youtube && marcas.youtube !== 'undefined' && marcas.youtube !== null && marcas.youtube !== "" && marcas.youtube !== "null" ? (
      <Grid item xs={2} sx={{ width: "50%" }}>
        <a className="nulla" target="_blank" rel="noopener noreferrer" href={marcas.youtube}>
          <div className="rednav"><FaYoutube /></div>
        </a>
      </Grid>
    ) : null // No mostrar nada si la marca existe pero no tiene YouTube válido
  ) : (
    <Grid item xs={2} sx={{ width: "50%" }}>
      <a className="nulla" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@SmartMemorial">
        <div className="rednav"><FaYoutube /></div>
      </a>
    </Grid>
  )}
                  {/* Mostrar LinkedIn solo si existe */}
                  {marcas?.linkedin && marcas.linkedin !== 'undefined' && marcas.linkedin !== 'null' && (
                      <Grid item xs={2} sx={{ width: "50%" }}>
                          <a className="nulla" target="_blank" href={marcas.linkedin}>
                              <div className="rednav"><IoLogoLinkedin /></div>
                          </a>
                      </Grid>
                  )}
                  {/* Mostrar Twitter solo si existe */}
                  {marcas?.twiter_x && marcas.twiter_x !== 'undefined' && marcas.twiter_x !== 'null' && (
                      <Grid item xs={2} sx={{ width: "50%" }}>
                          <a className="nulla" target="_blank" href={marcas.twiter_x}>
                              <div className="rednav"><BsTwitterX /></div>
                          </a>
                      </Grid>
                  )}
              </Grid>
              </div>
            </StyledListItemButtonfinal>
          </ListItem>
        </List>

        
      </Box>
    </StyledDrawer>
  );
};


export const Navbar =({ color1, logomarca, marcas }) => {
    const {id} = useParams();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 1100);
  
    const handleDrawerToggle = () => {
      setDrawerOpen(!drawerOpen);
    };
  
    useEffect(() => {
      const handleResize = () => {
        setIsMobileView(window.innerWidth <= 1100);
      };
  
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, []);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    const toggleDrawer = (open) => () => {
      setDrawerOpen(open);
    };
  
 

  return (
    <>
    <StyledAppBar position="static" className='nav' sx={{ background: color1 || '#431E62' }}>
     
         <Toolbar disableGutters sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
         
          <Box sx={{ flexGrow: 0,  width: '50px' }}>
          <Link to={marcas?.web || '/Smart-Memorial'}>
          <img
              src={logomarca || logo} 
              alt='Smart Memorial'
              width='100%'
          />
      </Link>
          
              </Box>
        {!isMobileView && (
           <>         
              <Box
              sx={{
                flexGrow: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems:'center',

              
              }}
            > 
            <List sx={{display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'row', width:'100%'}}>
            {!marcas || marcas.length == 0 ? (
              <Link to='/personas-smart-memorial'>
              <ListItem disablePadding  >
                <StyledListItemButton sx={{ fontSize:'0.8rem', color:'#fff',  '@media (max-width: 1300px)': {
                fontSize:'0.6rem'
                },}}>
                Memorial Personas
                </StyledListItemButton>
              </ListItem>
              </Link>
              ) : null}
                {!marcas || marcas.length == 0 ? (
              <Link to='/'>
              <ListItem disablePadding  >
                <StyledListItemButton sx={{ fontSize:'0.8rem', color:'#fff', '@media (max-width: 1300px)': {
                fontSize:'0.6rem'
                },}}>
                Memorial Empresa
                </StyledListItemButton>
              </ListItem>
              </Link>
              ) : null}
              {!marcas || marcas.length == 0 ? (
              <Link to ='/SmartPersonas'>
              <ListItem disablePadding >
                <StyledListItemButton sx={{ fontSize:'0.8rem', color:'#fff', '@media (max-width: 1300px)': {
                fontSize:'0.6rem'
                },}}>
                  Acceso Personas
                </StyledListItemButton>
              </ListItem>
              </Link>
              ) : <Link to ={`/empresa/${marcas.id_empresa}/login/usuario`}>
              <ListItem disablePadding >
                <StyledListItemButton sx={{ fontSize:'0.8rem', color:'#fff', '@media (max-width: 1300px)': {
                fontSize:'0.6rem'
                },}}>
                Acceso Personas
                </StyledListItemButton>
              </ListItem>
              </Link>}
              <Link to ='/Inicio-Sesion-Empresas'>
              <ListItem disablePadding >
                <StyledListItemButton sx={{ fontSize:'0.8rem', color:'#fff', '@media (max-width: 1300px)': {
                fontSize:'0.6rem'
                },}}>
                Acceso Empresas
                </StyledListItemButton>
              </ListItem>
              </Link>
              {!marcas || marcas.length == 0 ? (
              <Link to='/contacto-smart-memorial'>
              <ListItem disablePadding  >
                <StyledListItemButton sx={{ fontSize:'0.8rem', color:'#fff', '@media (max-width: 1300px)': {
                fontSize:'0.6rem'
                },}}>
                Contacto
                </StyledListItemButton>
              </ListItem>
              </Link>
              ) : null}
              {!marcas || marcas.length == 0 ? (
              <Link to='/solicitarmemorial'>
              <ListItem disablePadding  >
                <StyledListItemButton sx={{ fontSize:'0.8rem', color:'#fff', '@media (max-width: 1300px)': {
                fontSize:'0.6rem'
                },}}>
                Solicitar memorial
                </StyledListItemButton>
              </ListItem>
              </Link>
              ) : null}
            {!marcas || marcas.length == 0 ? ( 
              <Link to="/smartmemorial-terminos-y-condiciones">    
        <ListItem disablePadding>
            <StyledListItemButton sx={{ fontSize: '0.8rem', color: '#fff' , '@media (max-width: 1300px)': {
                fontSize:'0.6rem'
                },}}>
                Términos y condiciones
            </StyledListItemButton>
        </ListItem>
            </Link>
            ) : null}
            </List>
            </Box>
            <Box
              sx={{
                flexGrow: 0.5,
                display: 'flex',
                alignItems: 'center',
              }}
            >
            <ListItem disablePadding>
                <StyledListItemButtonfinal >
                  <div className="redessociales">
                  <Grid container sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {marcas ? (
              marcas.facebook && marcas.facebook !== 'undefined' && marcas.facebook !== null && marcas.facebook !== "null" && marcas.facebook !== "" ? (
                <Grid item xs={2} sx={{ width: "70%" }}>
                  <a className="nulla" target="_blank" rel="noopener noreferrer" href={marcas.facebook}>
                    <div className="rednav"><FaFacebook /></div>
                  </a>
                </Grid>
              ) : null // No mostrar nada si la marca existe pero no tiene Facebook válido
            ) : (
              <Grid item xs={2} sx={{ width: "50%" }}>
                <a className="nulla" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/smartmemorial">
                  <div className="rednav"><FaFacebook /></div>
                </a>
              </Grid>
            )}

            {/* Instagram */}
            {marcas ? (
              marcas.instagram && marcas.instagram !== 'undefined'  && marcas.instagram !== null && marcas.instagram !== "" && marcas.instagram !== "null" ? (
                <Grid item xs={2} sx={{ width: "50%" }}>
                  <a className="nulla" target="_blank" rel="noopener noreferrer" href={marcas.instagram}>
                    <div className="rednav"><FaInstagram/></div>
                  </a>
                </Grid>
              ) : null // No mostrar nada si la marca existe pero no tiene Instagram válido
            ) : (
              <Grid item xs={2} sx={{ width: "50%" }}>
                <a className="nulla" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/smart.memorial/">
                  <div className="rednav"><FaInstagram/></div>
                </a>
              </Grid>
            )}
                      {marcas ? (
        marcas.tiktok && marcas.tiktok !== 'undefined' && marcas.tiktok !== null && marcas.tiktok !== "" && marcas.tiktok !== "null" ? (
          <Grid item xs={2} sx={{ width: "50%" }}>
            <a className="nulla" target="_blank" rel="noopener noreferrer" href={marcas.tiktok}>
              <div className="rednav"><FaTiktok /></div>
            </a>
          </Grid>
        ) : null // Si la marca existe pero no tiene TikTok válido, no se muestra nada
      ) : (
        <Grid item xs={2} sx={{ width: "50%" }}>
          <a className="nulla" target="_blank" rel="noopener noreferrer" href="https://www.tiktok.com/@smart.memorial">
            <div className="rednav"><FaTiktok /></div>
          </a>
        </Grid>
      )}
                      {marcas ? (
        marcas.youtube && marcas.youtube !== 'undefined' && marcas.youtube !== null && marcas.youtube !== "" && marcas.youtube !== "null" ? (
          <Grid item xs={2} sx={{ width: "50%" }}>
            <a className="nulla" target="_blank" rel="noopener noreferrer" href={marcas.youtube}>
              <div className="rednav"><FaYoutube /></div>
            </a>
          </Grid>
        ) : null // No mostrar nada si la marca existe pero no tiene YouTube válido
      ) : (
        <Grid item xs={2} sx={{ width: "50%" }}>
          <a className="nulla" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/@SmartMemorial">
            <div className="rednav"><FaYoutube /></div>
          </a>
        </Grid>
      )}
                      {/* Mostrar LinkedIn solo si existe */}
                      {marcas?.linkedin && marcas.linkedin !== 'undefined' && marcas.linkedin !== 'null' && (
                          <Grid item xs={2} sx={{ width: "50%" }}>
                              <a className="nulla" target="_blank" href={marcas.linkedin}>
                                  <div className="rednav"><IoLogoLinkedin /></div>
                              </a>
                          </Grid>
                      )}
                      {/* Mostrar Twitter solo si existe */}
                      {marcas?.twiter_x && marcas.twiter_x !== 'undefined' && marcas.twiter_x !== 'null' && (
                          <Grid item xs={2} sx={{ width: "50%" }}>
                              <a className="nulla" target="_blank" href={marcas.twiter_x}>
                                  <div className="rednav"><BsTwitterX /></div>
                              </a>
                          </Grid>
                      )}
                  </Grid>
                  </div>
                </StyledListItemButtonfinal>
              </ListItem>
            </Box>
          </>
         )}
          {isMobileView && (
          <IconButton
          sx={{
            marginRight:'30px',
          }}
            edge="end"
            color="inherit"
            aria-label="menu"
            onClick={handleDrawerToggle}
          >
            <MenuIcon />
          </IconButton>
        )}
        </Toolbar>
      
    </StyledAppBar>
    
    <AnchorTemporaryDrawer
        open={drawerOpen}
        onClose={handleDrawerToggle}
        color1={color1}
        logomarca={logomarca}
        marcas={marcas}
      />
    </>
  );
}


const StyledAppBar = styled(AppBar)({
  boxShadow: "none",
});


const StyledDrawer = styled(Drawer)({
  
});

const StyledListItemButton = styled(ListItemButton)({
  fontSize: '0.9rem',
  color: '#fff',
  position: 'relative',
  overflow: 'hidden',
  fontFamily:'Poppins',
  textTransform:'capitalize',
  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: 0,
    left: '50%',
    width: '0%',
    height: '2px',
    backgroundColor: '#fff',
    transition: 'all 0.4s ease-in-out',
    transform: 'translateX(-50%)',
  },
  '&:hover::after': {
    width: '100%',
  },
});

const StyledListItemButtonfinal = styled(ListItemButton)({
  display: "flex",
  flexDirection:'row',
   alignItems:"center",
   justifyContent:"center",
 
});

const StyledGrid  = styled(Grid )({
  display: "flex",
   alignItems:"center",
   justifyContent:"center",
   flexDirection:"row,"
});
