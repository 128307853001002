import React from "react";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import { Container, Box, CircularProgress, Grid } from "@mui/material";

/* imagenes data previsualizacion  morado*/
import icono24 from "../../../../../../Image/obituario/m24.png";
import icono25 from "../../../../../../Image/obituario/m25.png";
import icono26 from "../../../../../../Image/obituario/m26.png";
import foto from "../../../../../../Image/generales/foto-predeterminada-memorial.jpg"
import icono from "../../../../../../Image/obituario/m1.png"

export const DiseñoSmart = () => {
    const { Token, UserDataMarca } = useUserEmpresa();
    const imageizquierdo = (icono25);
    const imagederecho = (icono26);

    const styles = {
        titleStyle: {
          color: UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined' ? UserDataMarca.color_2 :'#694B81',
        },
        fondomensajeStyle: {
          background: UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined' ? UserDataMarca.color_2 :'#F0EDF3',
        },
        fondosStyle: {
          background: UserDataMarca?.color_3 && UserDataMarca.color_3 !== 'undefined' ? UserDataMarca.color_3 :'#F0EDF3',
        },
        titulooscuroStyle: {
          color: UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined' ? UserDataMarca.color_2 :'#D26999',
        },
        texto: {
          color: "#fff"
        },
        selectedIconStyle: {
      
          cursor: "pointer",
          width: '40px',
          height: '40px',
          color: '#fff',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined' ? UserDataMarca.color_2 :'#694B81',
        },
        iconStyle: {
          cursor: "pointer",
          width: '40px',
          height: '40px',
          borderRadius: '50%',
          background: 'transparent',  // Aquí está el error corregido
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined' ? UserDataMarca.color_2 :'#694B81',
        },
        
      };
      
      
    
  
    
    const StyleFondo={
      backgroundImage: `url(${UserDataMarca?.fondo_memorial ? `${process.env.REACT_APP_BACKEND_URL}/${UserDataMarca.fondo_memorial}` : ''})`,
        backgroundSize: 'cover',           // Asegurar que cubra toda la sección
        backgroundPosition: 'center',      // Centrar la imagen
        width: '100%',
        height: 'auto',                   // Ajustar la altura de la sección
        
    }
    

    return (
        <Container component="main" maxWidth="xxl" sx={{
            paddingLeft: "0px", paddingRight: "0px", ' @media (max-width: 700px)': {
                marginTop: '0px'
            }
        }}>
            <Box sx={{
                backgroundColor: UserDataMarca?.color_4 && UserDataMarca.color_4 !== 'undefined' ? UserDataMarca.color_4 : '#F0EDF3',
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                paddingTop: 2

            }}>
               
                    <Box sx={{
                        width: "100%",
                        textAlign: 'center',
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flexDirection: "column"
                    }}>
                        <img
                            className="iconoselecionobform"
                            src={icono}
                            alt="smart memorial"
                            title="smart memorial"
                        />

                        <div className="tituloobituario">
                            <h4 style={styles.titleStyle}>Obituario</h4>
                        </div>

                        <img
                            src={icono24}
                            className="subrayadoobuno"
                            alt="smart memorial"
                            title="smart memorial"
                        />
                    </Box>
                    

                <Box sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center"
                    , justifyContent: "center"
                    , flexDirection: "column",
                    marginTop: "20px",
                    marginBottom: "30px"

                }}>
                    <span className="informacionfallecimientoobparteuno"> 
                        <>
                            A la edad de <p style={styles.titleStyle}>10 años</p>
                        </>
             
                        el día <p style={styles.titleStyle}>10/03/2020</p> </span>
                    <span className="informacionfallecimientoobparteuno">Lamentamos informar  el fallecimiento de:</span>
                </Box>

                <Box sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center"
                    , justifyContent: "center"
                    , flexDirection: "column",
                    position: "relative"
                }}>

                    <div className="imagenobituario" style={{
                        borderColor: UserDataMarca?.color_3 && UserDataMarca.color_3 !== 'undefined' ? UserDataMarca.color_3 : '#F0EDF3',
                    }} >
                        
                            <img src={foto} alt="smart memorial" />

                        
                    </div>
                    <div className="cuadradoobituario" style={{
                        backgroundColor:UserDataMarca?.color_3 && UserDataMarca.color_3 !== 'undefined' ? UserDataMarca.color_3 : '#E1DBE6'
                    }}>

                        <div className="titulonombreob"> <h3 style={styles.titulooscuroStyle}  >Mario Riquelme</h3></div>
                        <div className="fechaobipersona" style={styles.titleStyle}> 10/12/2000 • 30/11/2024 </div>

                        <div className="descripcionob"> <span>
                          Con profundo pesar, 
                          anunciamos el fallecimiento
                           de Mario Riquelme quien partió 
                           de este mundo rodeada del amor de sus seres queridos.
                           Mario Riquelme dejó una huella imborrable en las vidas 
                           de quienes la conocieron, destacándose siempre por su bondad, 
                           fortaleza y el cariño con el que compartía cada momento. 
                           Su vida fue un ejemplo de entrega y 
                           dedicación hacia su familia y amigos, 
                           dejando un legado lleno de memorias entrañables y aprendizajes.
                           Aunque su partida nos llena de tristeza, su recuerdo permanecerá eterno en nuestros corazones.
                           La familia agradece profundamente las muestras de apoyo y solidaridad recibidas en este difícil momento 
                           y extiende la invitación a todos aquellos que deseen acompañar en la despedida de Mario Riquelme, 
                           cuya vida será celebrada con amor y respeto.</span> </div>

                        <div className="parentescoovit"><span> Fue padre, tio, primo y hijo quien despediremos en compañía de su familia y amigos.</span></div>
                    </div>

                </Box>
                <Box sx={{
                    marginTop: "-50px"
                }}>
                    <section className="infovelarotioob">
                        <div className="tituloinfoobt">
                            <img src={imageizquierdo} className="cerrarizqob" alt="smart memorial" title="smart memorial" />
                            <h2 style={styles.titleStyle}>Velatorio</h2>
                            <img src={imagederecho} className="cerrarderqob" alt="smart memorial" title="smart memorial" /> </div>
                        <div className="estructurafechaob">
                            <div className="titulofechaob" style={styles.titulooscuroStyle}>Fecha</div>
                            <div className="infodosfechaob" > <div className="contienefechao"> <span> 14/12/2020</span> <p>14/12/2020</p></div> • <div className="contienefechaprevo" ><span> 20/12/2020</span> <p>25/12/2020 </p></div></div>
                        </div>
                        <div className="estructurafechaob">
                            <div className="titulofechaob" style={styles.titulooscuroStyle}>Dirección</div>
                            <div className="infodosob">Las Pascuala, Santiago de Chile</div>
                        </div>

                    </section>
                    <section className="infovelarotioob">
                        <div className="tituloinfoobt">
                            <img src={imageizquierdo} className="cerrarizqob" alt="smart memorial" title="smart memorial" />
                            <h2 style={styles.titleStyle}>Cortejo fúnebre</h2>
                            <img src={imagederecho} className="cerrarderqob" alt="smart memorial" title="smart memorial" />
                        </div>
                        <div className="estructurafechaob">
                            <div className="titulofechaob" style={styles.titulooscuroStyle}>Hora</div>
                            <div className="infodosfechaob" > 9:00 hrs.</div>
                        </div>
                    </section>
                    <section className="infovelarotioob">
                        <div className="tituloinfoobt">
                            <img src={imageizquierdo} className="cerrarizqob" alt="smart memorial" title="smart memorial" />
                            <h2 style={styles.titleStyle}>Funeral</h2>
                            <img src={imagederecho} className="cerrarderqob" alt="smart memorial" title="smart memorial" />
                        </div>
                        <div className="estructurafechaob">
                            <Grid Container sx={{
                                display: "flex", width: "100%", ' @media (max-width: 271px)': {
                                    flexDirection: "column", alignItems: "center", justifyContent: "center"
                                }
                            }}>
                                <Grid item xs={6} sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center", position: "relative", left: "-37px", ' @media (max-width: 271px)': { left: "0px" } }}>
                                    <div className="estructurafechaob">
                                        <div className="titulofechaob" style={styles.titulooscuroStyle}>Fecha</div>
                                        <div className="infodosfechaob" >  <div className="contienefechao"> <span> 10/12/2020</span> <p>20/12/2020</p></div> </div>
                                    </div></Grid>
                                <Grid item xs={6} sx={{ display: "flex", width: "100%", alignItems: "center", justifyContent: "center" }}>
                                    <div className="estructurafechaob">
                                        <div className="titulofechaob" style={styles.titulooscuroStyle}>Hora</div>
                                        <div className="infodosfechaob" > 9:00 hrs.</div>
                                    </div></Grid>
                            </Grid>
                        </div>
                        <div className="estructurafechaob">
                            <div className="titulofechaob" style={styles.titulooscuroStyle}>Dirección</div>
                            <div className="infodosob" >Calle central 123, Santiago de Chile </div>


                        </div>
                    </section>
                </Box>

            </Box>
        </Container>
    )
}