import React, { useState, useEffect, useRef  } from "react";
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";
import axios from "axios";



/* material UI */
import { Box, TextField, IconButton, Button, Container, CircularProgress, Grid, Snackbar, Checkbox, FormControlLabel, FormGroup, Accordion, AccordionSummary, AccordionDetails, Typography, AppBar, Toolbar } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
/* react icons */
import { IoClose } from "react-icons/io5";
import { TbHomeEdit } from "react-icons/tb";
import { FiPlusCircle } from "react-icons/fi";
import { FaRegTrashAlt } from "react-icons/fa";
import { FaRibbon } from "react-icons/fa";
import { PiScrollFill } from "react-icons/pi";

import fondo from "../../../../../Image/generales/fondo-memorial.jpg"
import perfil from "../../../../../Image/generales/foto-predeterminada-memorial.jpg"

export const Marca = () => {
    const { Usuario, Token, Empresa} = useUserEmpresa();
    const [formData, setFormData] = useState({
        logo: null,
        color_1: '',
        color_2: '',
        color_3: '',
        color_4: '',
        imagen_carta: null,
        facebook: '',
        tiktok: '',
        youtube: '',
        instagram: '',
        twiter_x: '',
        linkedin: '',
        web: '',
        numero_telefono: '',
        terminos_condiciones: null,
        id_empresa: null,
        fondo_memorial: null,
        fondo_video: null,
        icono_registro: null,
        servicios: [],
        tipo_comentario: null,
        tipo_qr: null
    });
    
    const [marcas, setMarcas] = useState([]);
    const [loading, setLoading] = useState(false);
    const fileInputRefLogo = useRef(null); // Ref para logo
    const fileInputRefCarta = useRef(null);
    const fileInputRefpdf = useRef(null);
    const fileInputReffondo = useRef(null);
    const fileInputReffondovideo = useRef(null);
    const fileInputReficonoregistro = useRef(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState(marcas?.color_1);
    const [alertSeverity, setAlertSeverity] = useState('success'); // 'success' or 'error'
    const [servicioActual, setServicioActual] = useState("");
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [serviciosArray, setServiciosArray] = useState([]); 
    const [selectedValue, setSelectedValue] = useState(null); 
    const [selectedcomentario, setSelectedcomentario] = useState(null); 


    useEffect(() => {
        if (marcas) {
            setFormData((prevData) => ({
                ...prevData,
                color_1: marcas.color_1 || '',
                color_2: marcas.color_2 || '',
                color_3: marcas.color_3 || '',
                color_4: marcas.color_4 || '',
                facebook: marcas.facebook || '',
                tiktok: marcas.tiktok || '',
                youtube: marcas.youtube || '',
                instagram: marcas.instagram || '',
                twiter_x: marcas.twiter_x || '',
                linkedin: marcas.linkedin || '',
                web: marcas.web || '',
                numero_telefono: marcas.numero_telefono || '',
                id_empresa: marcas.id || null,
                tipo_comentario: marcas.tipo_comentario !== null ? parseInt(marcas.tipo_comentario) : null,
                tipo_qr: marcas.tipo_qr !== null ? parseInt(marcas.tipo_qr) : null,
            }));
    
            // Actualiza los estados de los checkboxes
            setSelectedcomentario(marcas.tipo_comentario !== null ? parseInt(marcas.tipo_comentario) : null);
            setSelectedValue(marcas.tipo_qr !== null ? parseInt(marcas.tipo_qr) : null);
        }
    }, [marcas]);
  
    const fetchMarcas = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/marcaempresa/${Empresa.id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${Token}`,
                },
            });
            
            const fetchedMarca = response.data.data[0];
            setMarcas(fetchedMarca);
    
            // Inicializa serviciosList
            let serviciosList;
    
            // Verifica si servicios es un array o una cadena JSON
            if (Array.isArray(fetchedMarca.servicios)) {
                serviciosList = fetchedMarca.servicios; // Asigna directamente si es un array
            } else if (typeof fetchedMarca.servicios === 'string') {
                try {
                    // Convierte la cadena JSON a un array
                    serviciosList = JSON.parse(fetchedMarca.servicios);
                } catch (error) {
                    console.error("Error al parsear servicios:", error);
                    serviciosList = []; // Si hay un error, asigna un array vacío
                }
            } else {
                // Si no es ni array ni string, asigna un array vacío
                serviciosList = [];
            }
    
            // Actualiza el estado de formData y serviciosArray con el array de servicios
            setFormData((prevData) => ({
                ...prevData,
                servicios: serviciosList,
            }));
            setServiciosArray(serviciosList);
    
   
        } catch (err) {
            console.error("Error al obtener marcas:", err);
        }
    };
    
    // useEffect para actualizar el estado de serviciosArray cuando formData cambia
    useEffect(() => {
        if (Array.isArray(formData.servicios)) {
  
            setServiciosArray(formData.servicios);
        }
    }, [formData.servicios]);
    
    // useEffect para llamar a fetchMarcas cuando empresa.id cambia
    useEffect(() => {
        if (Empresa?.id) {
            fetchMarcas();
        }
    }, [Empresa?.id]);
    // useEffect to load MarcaEmpresa data on component mount
    useEffect(() => {
        const fetchMarcaEmpresa = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/get/marca/empresa`, {
                    params: { id_empresa: Empresa.id },
                    headers: { Authorization: `Bearer ${Token}` },
                });
                if (response.status === 200) {
                    setFormData({ ...formData, ...response.data.data[0] });
                }
            } catch (error) {
                console.error("Error al obtener los datos de Marca Empresa", error);
            }
        };
        fetchMarcaEmpresa();
    }, [Empresa?.id, Token]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle file input for logo
 // Handle file input for logo and imagen_carta
 const handleFileChange = (e) => {
    const { name, files } = e.target;

    if (name === "logo") {
        setSelectedImage(URL.createObjectURL(files[0]));
    }

    setFormData({
        ...formData,
        [name]: files[0],  // Actualizar dinámicamente el logo o la imagen_carta
    });
};

 // Función para agregar servicio
 const handleAddServicio = () => {
    if (servicioActual.trim() !== "") {
        setFormData((prevData) => {
            const servicios = Array.isArray(prevData.servicios) ? prevData.servicios : [];
            const updatedServicios = [...servicios, servicioActual];

            return {
                ...prevData,
                servicios: updatedServicios, // Actualiza servicios con el nuevo array
            };
        });

        setServiciosArray((prevArray) => {
            const servicios = Array.isArray(prevArray) ? prevArray : [];
            return [...servicios, servicioActual]; // Agrega el nuevo servicio al array existente
        });

        setServicioActual(""); // Limpiar el campo después de agregar
    }
};

const handleRemoveServicio = (index) => {
    setFormData((prevData) => {
        const servicios = Array.isArray(prevData.servicios) ? prevData.servicios : [];
        const updatedServicios = servicios.filter((_, i) => i !== index);

        return {
            ...prevData,
            servicios: updatedServicios,
        };
    });

    setServiciosArray((prevArray) => {
        const servicios = Array.isArray(prevArray) ? prevArray : [];
        return servicios.filter((_, i) => i !== index); // Asegúrate de que esto esté eliminando correctamente
    });
};
  

/* checkbox */

const handleCheckChange = (event) => {
    const { name } = event.target;
    let value = null;

    if (name === "grupoFamiliar") value = 0;
    else if (name === "individual") value = 1;
    else if (name === "ambos") value = 2;

    setSelectedValue(value); // Actualiza el valor seleccionado
    setFormData((prevData) => ({
        ...prevData,
        tipo_qr: value // Sincroniza el valor seleccionado con el estado formData
    }));
};


const handleCheckComentarioChange = (event) => {
    const { name } = event.target;
    let value = null;

    if (name === "visible") value = 0;
    else if (name === "invisible") value = 1;


    setSelectedcomentario(value); // Actualiza el valor seleccionado
    setFormData((prevData) => ({
        ...prevData,
        tipo_comentario: value // Sincroniza el valor seleccionado con el estado formData
    }));
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const data = new FormData();
    Object.keys(formData).forEach((key) => {
        if (key === "servicios") {
            // Asegúrate de que 'servicios' se esté añadiendo como un string JSON
            data.append(key, JSON.stringify(serviciosArray)); // Usa serviciosArray aquí
        } else {
            data.append(key, formData[key]);
        }
    });

   

    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/marca-empresa/${Empresa.id}`, data, {
        headers: {
          Authorization: `Bearer ${Token}`,
          "Content-Type": "multipart/form-data"
        }
      });

      if (response.status === 200) {
        setAlertSeverity("success");
        setAlertMessage("Marca Empresa actualizada con éxito.");
        await fetchMarcas();
      } else {
        setAlertSeverity("error");
        setAlertMessage("Error al actualizar Marca Empresa.");
      }
    } catch (error) {
      console.error("Respuesta de error de la API:", error.response);
      setAlertSeverity("error");
      setAlertMessage("Error al actualizar Marca Empresa.");
    } finally {
      setLoading(false);
      setAlertOpen(true);
      setTimeout(() => {
        setAlertOpen(false);
      }, 3000);
    }
  };
    /* Accordeon */
    const [expanded, setExpanded] = useState(false);
    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
      };
      const navbarStyle = {
        backgroundColor: formData.color_1, // Aplica el color seleccionado
        color: "#fff",
    };

  
    return (
        <Box sx={formStyle}>
            <form onSubmit={handleSubmit} >
                <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')} sx={StyleAcordeon}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{
                        color:
                          marcas?.color_1 &&
                          marcas.color_1 !== "undefined"
                            ? marcas.color_1
                            : "#694B81",
                      }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography sx={{
                        fontFamily:'Poppins',
                        fontWeight:'500',
                    color:
                      marcas?.color_1 &&
                      marcas.color_1 !== "undefined"
                        ? marcas.color_1
                        : "#694B81",
                  }}>Logo Empresa</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={StyleBox}>
                        <Box sx={StyleBoxcontienelogo}>
                        <div className="input-crearadminsmartempresa">
                            <label htmlFor="logo">Logo</label>
                            <div className="contieneinputempresaregister">
                            {/* Ocultar el input original */}
                            <input
                                className="input-crear-empresa-adminsmart"
                                type="file"
                                id="logo"
                                name="logo"
                                accept="image/*"
                                onChange={handleFileChange} 
                                ref={fileInputRefLogo}   // Referencia para disparar el clic
                            />

                            {/* Botón personalizado para seleccionar archivo */}
                            <div
                                className="custom-file-upload"
                                onClick={() => fileInputRefLogo.current.click()} // Dispara el clic del input
                            >
                                Seleccionar archivo
                            </div>
                            </div>

                            {/* Vista previa de la imagen seleccionada */}
                            {selectedImage && (
                            <div className="image-preview-select-logo-empresa">
                                <img
                                src={selectedImage}
                                alt="Selected"
                                className="imagenlogoselect"
                                />
                            </div>
                            )}
                        </div>
                        </Box>
                        <Box sx={StyleBoxcontienelogo}>
                                        <label className='Labellogoactual'>Logo Actual:</label>
                                        {marcas.logo ? (
                                            <img 
                                                src={`${process.env.REACT_APP_BACKEND_URL}/${marcas.logo}`} 
                                                alt="Logo de la Empresa" 
                                                style={{ width: '100%', maxWidth: '200px', height: 'auto', marginTop: '8px' }} 
                                            />
                                        ) : (
                                            <span>No hay logo disponible</span>
                                        )}
                        </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')} sx={StyleAcordeon}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{
                        color:
                          marcas?.color_1 &&
                          marcas.color_1 !== "undefined"
                            ? marcas.color_1
                            : "#694B81",
                      }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography sx={{
                        fontFamily:'Poppins',
                        fontWeight:'500',
                    color:
                      marcas?.color_1 &&
                      marcas.color_1 !== "undefined"
                        ? marcas.color_1
                        : "#694B81",
                  }}>Colores Empresa</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={StyleBox}>
                          <Box sx={StyleBoxcontienecolor}>
                            <section> 
                                <div className="tituloSessiocncolorempresa">
                                 <h3>Color del Menu</h3>
                                </div>
                                
                            </section>  
                            <Box sx={StyleBoxcontienelogo} >
                                <Box sx={StyleBoxcontienelogo}>
                                     <TextField
                                        label="Color 1"
                                        name="color_1"
                                        type="color"  // Cambiamos el tipo a "color"
                                        value={formData.color_1}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        />
                                    </Box>
                                    <Box sx={StyleBoxcontienelogo}> 
                                    <div className="contieneprevisualizacionnavbar">
                                    <Box sx={{ flexGrow: 1 }}>
                                <AppBar position="static" sx={navbarStyle}>
                            <Toolbar>
                            <Typography variant="h6" sx={{ flexGrow: 1 }}>
                                {Empresa?.logo ? (
                                            <img 
                                                src={`${process.env.REACT_APP_BACKEND_URL}/${Empresa?.logo}`} 
                                                alt="Logo de la Empresa" 
                                                width={40}
                                            />
                                        ) : (
                                            <span></span>
                                        )}
                                </Typography>
                                <IconButton
                                    size="large"
                                    edge="end"
                                    color="inherit"
                                    aria-label="menu"
                                    sx={{ mr: 2 }}
                                >
                                    <MenuIcon />
                                </IconButton>
                            </Toolbar>
                                </AppBar>
                                </Box>
                        </div>
                                </Box>
                            </Box>
                          </Box>                      
                        </Box>
                        <Box sx={StyleBoxcontienelogo}>
                            <Box sx={StyleBoxcontienecolor}>
                            <section style={{marginTop:'30px'}}> 
                                <div className="tituloSessiocncolorempresa">
                                 <h3>Colores personalización empresa para  clientes</h3>
                                </div>
                            </section>  
                            <Box sx={StyleBoxcontienelogo}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                <div className="input-crearadminsmartempresamarca">
                                <label htmlFor="logo" >Color letras</label>
                                    <TextField
                                        label=" "
                                        name="color_2"
                                        type="color"  // Cambiamos el tipo a "color"
                                        value={formData.color_2}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        />
                                        </div>

                                  <div className="input-crearadminsmartempresamarca">
                                        <label htmlFor="logo"> Color de cuadrados </label>
                                        <TextField
                                        label=" "
                                        name="color_3"
                                        type="color"  // Cambiamos el tipo a "color"
                                        value={formData.color_3}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        />
                                </div>   
                                   <div className="input-crearadminsmartempresamarca">
                      <label htmlFor="logo">Color de Fondos </label>
                      <TextField
                                        label=" "
                                        name="color_4"
                                        type="color"  // Cambiamos el tipo a "color"
                                        value={formData.color_4}
                                        onChange={handleInputChange}
                                        fullWidth
                                        margin="normal"
                                        />
                                        </div> 
                                </Grid>
                      <Grid item xs={12} md={6}>
                      <div className="recuadroejemplovistamemorial"style={{
                         height: '400px', /* Altura fija */
                         overflowY: 'auto', /* Habilitar scroll vertical */
                         overflowx: 'hidden', /* Ocultar scroll horizontal (opcional) */
                      }}>
                      <Container component="main" maxWidth="xs" sx={{ paddingLeft:"0px", paddingRight:"0px", ' @media (max-width: 700px)': {
            marginTop: '0px'
          }}} > 
          
          <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        background: "#ffff",
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor:  formData.color_4 || '#E1DBE6',    
                    }}
                >
                    <section className="contienehistoriademo" >
                        <div className="vistamemorial">
                        {marcas?.fondo_memorial ? (
                    <img 
                    src={`${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}`} 
                        alt="smart memorial" 
                    />
                ) : (
                    <img 
                        src={fondo} 
                        alt="smart memorial" 
                    />
                )}
                     <div className="titulodemomerorial">
                                <h1 className="memorialtitulo" translate="no" style={{
                                    color: formData.color_2 ||'#694B81'
                                }}>Memorial</h1>
                                <h1 className="nombre" style={{
                                    color:formData.color_2 || '#D26999'
                                }}> Nombre de la persona</h1>
                                <h3 className="fechadefusion"style={{
                                    color:formData.color_2 ||'#694B81'
                                }}> 2024-01-1 • 2025-01-1</h3>
                            </div>
                            <div className="imagendemopersona" style={{border:'8px solid', borderColor:formData.color_4 ||'#E1DBE6'}}>
                              

                                    <img src={perfil} alt="smart memorial" style={{
                                        background:formData.color_3 || '#F0EDF3'
                                    }} />

                            </div>
                </div>
                 <div className="texto-historia" style={{
                    background:formData.color_3 || '#F0EDF3'
                 }}>
                            <div className="titulohistoriademo" style={{
                                    color:formData.color_2 || '#694B81'
                                }} ><PiScrollFill /> <h1>Su historia</h1>  </div>
                            <p className="textohistoriademo">
  
                            <span> </span>
                        </p>
                        </div> 
                    </section>
                    <section style={{width:"100%", marginTop:'40px'}}>
              <section className="tituloobituario" style={{
                                    color:formData.color_2 || '#694B81'
                                }}>
              <FaRibbon style={{fontSize:'1.5rem'}} />
              <div className="titulocementerio"> <h1>Obituario</h1> </div> 
          </section>
            <section className="obituariobuttonmemorialpage" >
            <div className="tituloobituarioenelmemorial">

            </div>
                <button 
                className="buttonovituariomemorial"
                style={{
                borderColor:formData.color_2 || '#694B81', 
                color: formData.color_2 || '#694B81',
                background:"trasparent",
                        }} >
                    Obituario
                </button>
            </section>
            </section>


                </Box>
          
          
          
          </Container>

                      </div>
                      </Grid>
                      
                                    
                                        </Grid>
                                    </Box>
                            </Box>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleAccordionChange('panel3')} sx={StyleAcordeon}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{
                        color:
                          marcas?.color_1 &&
                          marcas.color_1 !== "undefined"
                            ? marcas.color_1
                            : "#694B81",
                      }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography sx={{
                        fontFamily:'Poppins',
                        fontWeight:'500',
                    color:
                      marcas?.color_1 &&
                      marcas.color_1 !== "undefined"
                        ? marcas.color_1
                        : "#694B81",
                  }}>Redes Sociales</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                    <Box sx={StyleBoxcontienelogo}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} md={6}>
                                <div className="input-crearadminsmartempresamarca">
                                <label htmlFor="logo" >Facebook</label>
                                <TextField 
                                    sx={{
                                        background: marcas?.color_1 &&
                                        marcas.color_1 !== "undefined"
                                          ? marcas.color_1
                                          : "#694B81",
                                        fontFamily:'Poppins',
                                        fontWeight:'500',
                                   
                                  }}
                                    name="facebook" 
                                    value={formData.facebook} 
                                    onChange={handleInputChange} 
                                    fullWidth 
                                    margin="normal" 
                                />
                                        </div>
                                </Grid>
                                
                                 <Grid item xs={12} md={6}>
                                 <div className="input-crearadminsmartempresamarca">
                                 <label htmlFor="logo" >Tiktok</label>
                                    <TextField 
                                     sx={{
                                        background: marcas?.color_1 &&
                                        marcas.color_1 !== "undefined"
                                          ? marcas.color_1
                                          : "#694B81",
                                        fontFamily:'Poppins',
                                        fontWeight:'500',
                                   
                                  }}
                                        name="tiktok" 
                                        value={formData.tiktok} 
                                        onChange={handleInputChange} 
                                        fullWidth 
                                        margin="normal" 
                                    />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <div className="input-crearadminsmartempresamarca">
                                <label htmlFor="logo" >Youtube</label>
                                    <TextField 
                                      sx={{
                                        background: marcas?.color_1 &&
                                        marcas.color_1 !== "undefined"
                                          ? marcas.color_1
                                          : "#694B81",
                                        fontFamily:'Poppins',
                                        fontWeight:'500',
                                   
                                  }}
                                        name="youtube" 
                                        value={formData.youtube} 
                                        onChange={handleInputChange} 
                                        fullWidth 
                                        margin="normal" 
                                    />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <div className="input-crearadminsmartempresamarca">
                                <label htmlFor="logo" >Instagram</label>
                                    <TextField 
                                        sx={{
                                            background: marcas?.color_1 &&
                                            marcas.color_1 !== "undefined"
                                              ? marcas.color_1
                                              : "#694B81",
                                            fontFamily:'Poppins',
                                            fontWeight:'500',
                                       
                                      }}
                                        name="instagram" 
                                        value={formData.instagram} 
                                        onChange={handleInputChange} 
                                        fullWidth 
                                        margin="normal" 
                                    />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <div className="input-crearadminsmartempresamarca">
                                <label htmlFor="logo" >Twitter</label>
                                    <TextField 
                                      sx={{
                                        background: marcas?.color_1 &&
                                        marcas.color_1 !== "undefined"
                                          ? marcas.color_1
                                          : "#694B81",
                                        fontFamily:'Poppins',
                                        fontWeight:'500',
                                   
                                  }}
                                        name="twiter_x" 
                                        value={formData.twiter_x} 
                                        onChange={handleInputChange} 
                                        fullWidth 
                                        margin="normal" 
                                    />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <div className="input-crearadminsmartempresamarca">
                                <label htmlFor="logo" >LinkedIn</label>
                                    <TextField 
                                      sx={{
                                        background: marcas?.color_1 &&
                                        marcas.color_1 !== "undefined"
                                          ? marcas.color_1
                                          : "#694B81",
                                        fontFamily:'Poppins',
                                        fontWeight:'500',
                                   
                                  }}
                                        name="linkedin" 
                                        value={formData.linkedin} 
                                        onChange={handleInputChange} 
                                        fullWidth 
                                        margin="normal" 
                                    />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <div className="input-crearadminsmartempresamarca">
                                <label htmlFor="logo" >Web</label>
                                    <TextField 
                                   sx={{
                                        background: marcas?.color_1 &&
                                        marcas.color_1 !== "undefined"
                                          ? marcas.color_1
                                          : "#694B81",
                                        fontFamily:'Poppins',
                                        fontWeight:'500',
                                   
                                  }}
                                        name="web" 
                                        value={formData.web} 
                                        onChange={handleInputChange} 
                                        fullWidth 
                                        margin="normal" 
                                    />
                                    </div>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                <div className="input-crearadminsmartempresamarca">
                                <label htmlFor="logo" >Teléfono</label>
                                    <TextField 
                                   sx={{
                                        background: marcas?.color_1 &&
                                        marcas.color_1 !== "undefined"
                                          ? marcas.color_1
                                          : "#694B81",
                                        fontFamily:'Poppins',
                                        fontWeight:'500',
                                   
                                  }}
                                        name="numero_telefono" 
                                        value={formData.numero_telefono} 
                                        onChange={handleInputChange} 
                                        fullWidth 
                                        margin="normal" 
                                    />
                                    </div>
                                </Grid>
                            </Grid>
                            </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} onChange={handleAccordionChange('panel4')} sx={StyleAcordeon}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{
                        color:
                          marcas?.color_1 &&
                          marcas.color_1 !== "undefined"
                            ? marcas.color_1
                            : "#694B81",
                      }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography sx={{
                        fontFamily:'Poppins',
                        fontWeight:'500',
                    color:
                      marcas?.color_1 &&
                      marcas.color_1 !== "undefined"
                        ? marcas.color_1
                        : "#694B81",
                  }}>Carta Empresa</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box sx={StyleBox}>
                        <Box sx={StyleBoxcontienelogo}>
                        <div className="input-crearadminsmartempresa">
                            <label htmlFor="logo">Logo</label>
                            <div className="contieneinputempresaregister">
                            {/* Ocultar el input original */}
                            <input
                                className="input-crear-empresa-adminsmart"
                                type="file"
                                id="imagen_carta"
                                name="imagen_carta" 
                                onChange={handleFileChange} 
                                accept="image/*"
                                ref={fileInputRefCarta} // Referencia para disparar el clic
                            />

                            {/* Botón personalizado para seleccionar archivo */}
                            <div
                                className="custom-file-upload"
                                onClick={() => fileInputRefCarta.current.click()} // Dispara el clic del input
                            >
                                Seleccionar archivo
                            </div>
                            </div>

                           
                        </div>
                        </Box>
                        
                    <Box sx={StyleBoxcontienelogo}>
                    <label className='Labellogoactual'>Carta Actual:</label>
                            {marcas.imagen_carta ? (
                                <img 
                                    src={`${process.env.REACT_APP_BACKEND_URL}/${marcas.imagen_carta}`} 
                                    alt="Imagen carta" 
                                    style={{ width: '100%', maxWidth: '200px', height: 'auto', marginTop: '8px' }} 
                                />
                            ) : (
                                <span>No hay imagen disponible</span>
                            )}
                        </Box>
                        </Box>
                        </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel5'} onChange={handleAccordionChange('panel5')} sx={StyleAcordeon}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{
                        color:
                          marcas?.color_1 &&
                          marcas.color_1 !== "undefined"
                            ? marcas.color_1
                            : "#694B81",
                      }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography sx={{
                        fontFamily:'Poppins',
                        fontWeight:'500',
                    color:
                      marcas?.color_1 &&
                      marcas.color_1 !== "undefined"
                        ? marcas.color_1
                        : "#694B81",
                  }} >Terminos y condiciones Empresa</Typography>
                    </AccordionSummary>
                    <AccordionDetails> 

                    <Box sx={StyleBox}>
                        <Box sx={StyleBoxcontienelogo}>
                        <div className="input-crearadminsmartempresa">
                            <label htmlFor="logo">Pdf de terminos y condiciones</label>
                            <div className="contieneinputempresaregister">
                            {/* Ocultar el input original */}
                            <input
                                    className="input-crear-empresa-adminsmart"
                                    type="file"
                                    id="terminos_condiciones"
                                    name="terminos_condiciones" 
                                    onChange={handleFileChange} 
                                    accept="application/pdf"
                                    ref={fileInputRefpdf}
                                />
                                <div
                                    className="custom-file-upload"
                                    onClick={() => fileInputRefpdf.current.click()}
                                >
                                    Seleccionar archivo
                                </div>
                            </div>

                           
                        </div>
                        </Box>
                        
                    <Box sx={StyleBoxcontienelogo}>
                    <label className='Labellogoactual'>Terminos y condiciones:</label>
                    {marcas.terminos_condiciones ? (
                            <iframe
                                src={`${backendURL}/${marcas.terminos_condiciones}`}
                                title="PDF de Términos y Condiciones"
                                width="100%"
                                height="500px"
                                style={{ border: "none", marginTop: '8px' }}
                            />
                        ) : (
                            <span>No hay PDF disponible</span>
                        )}
                        </Box>
                        </Box>
                   
                        </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel6'} onChange={handleAccordionChange('panel6')} sx={StyleAcordeon}>
                    <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{
                        color:
                          marcas?.color_1 &&
                          marcas.color_1 !== "undefined"
                            ? marcas.color_1
                            : "#694B81",
                      }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography sx={{
                        fontFamily:'Poppins',
                        fontWeight:'500',
                    color:
                      marcas?.color_1 &&
                      marcas.color_1 !== "undefined"
                        ? marcas.color_1
                        : "#694B81",
                  }}>Fondo y iconos Memorial</Typography>
                    </AccordionSummary>
                    <AccordionDetails> 

                    <Box sx={StyleBox}>
                        <Box sx={StyleBoxcontienelogo}>
                        <div className="input-crearadminsmartempresa">
                            <label htmlFor="logo">Fondo Memorial</label>
                            <div className="contieneinputempresaregister">
                            {/* Ocultar el input original */}
                            <input
                                    className="input-crear-empresa-adminsmart"
                                    type="file"
                                    id="fondo_memorial"
                                    name="fondo_memorial" 
                                    onChange={handleFileChange} 
                                    accept="application/pdf"
                                    ref={fileInputReffondo}
                                />
                                <div
                                    className="custom-file-upload"
                                    onClick={() => fileInputReffondo.current.click()}
                                >
                                    Seleccionar archivo
                                </div>
                            </div>

                           
                        </div>
                        </Box>
                        
                    <Box sx={StyleBoxcontienelogo}>
                    <label className='Labellogoactual'>Fondo memorial actual:</label>
                            {marcas.fondo_memorial ? (
                                <img 
                                    src={`${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}`} 
                                    alt="Imagen carta" 
                                    style={{ width: '100%', maxWidth: '200px', height: 'auto', marginTop: '8px' }} 
                                />
                            ) : (
                                <span>No hay imagen disponible</span>
                            )}
                        </Box>
                        </Box>
                        <Box sx={StyleBox}>
                        <Box sx={StyleBoxcontienelogo}>
                        <div className="input-crearadminsmartempresa">
                            <label htmlFor="logo">Fondo video memorial</label>
                            <div className="contieneinputempresaregister">
                            {/* Ocultar el input original */}
                            <input
                                    className="input-crear-empresa-adminsmart"
                                    type="file"
                                    id="fondo_video"
                                    name="fondo_video" 
                                    onChange={handleFileChange} 
                                    accept="application/pdf"
                                    ref={fileInputReffondovideo}
                                />
                                <div
                                    className="custom-file-upload"
                                    onClick={() => fileInputReffondovideo.current.click()}
                                >
                                    Seleccionar archivo
                                </div>
                            </div>

                           
                        </div>
                        </Box>
                        
                    <Box sx={StyleBoxcontienelogo}>
                    <label className='Labellogoactual'>Fondo  video memorial actual:</label>
                            {marcas.fondo_video ? (
                                <img 
                                    src={`${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_video}`} 
                                    alt="Imagen carta" 
                                    style={{ width: '100%', maxWidth: '200px', height: 'auto', marginTop: '8px' }} 
                                />
                            ) : (
                                <span>No hay imagen disponible</span>
                            )}
                        </Box>
                        </Box>
                            
                        <Box sx={StyleBox}>
                        <Box sx={StyleBoxcontienelogo}>
                        <div className="input-crearadminsmartempresa">
                            <label htmlFor="logo">Icono Registro</label>
                            <div className="contieneinputempresaregister">
                            {/* Ocultar el input original */}
                            <input
                                    className="input-crear-empresa-adminsmart"
                                    type="file"
                                    id="icono_registro"
                                    name="icono_registro" 
                                    onChange={handleFileChange} 
                                    accept="application/pdf"
                                    ref={fileInputReficonoregistro}
                                />
                                <div
                                    className="custom-file-upload"
                                    onClick={() => fileInputReficonoregistro.current.click()}
                                >
                                    Seleccionar archivo
                                </div>
                            </div>

                           
                        </div>
                        </Box>
                        
                    <Box sx={StyleBoxcontienelogo}>
                    <label className='Labellogoactual'>Icono registro actual:</label>
                            {marcas.icono_registro ? (
                                <img 
                                    src={`${process.env.REACT_APP_BACKEND_URL}/${marcas.icono_registro}`} 
                                    alt="Imagen carta" 
                                    style={{ width: '100%', maxWidth: '200px', height: 'auto', marginTop: '8px' }} 
                                />
                            ) : (
                                <span>No hay imagen disponible</span>
                            )}
                        </Box>
                        </Box>
                        </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel7'} onChange={handleAccordionChange('panel7')} sx={StyleAcordeon}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{
                        color:
                          marcas?.color_1 &&
                          marcas.color_1 !== "undefined"
                            ? marcas.color_1
                            : "#694B81",
                      }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography sx={{
                        fontFamily:'Poppins',
                        fontWeight:'500',
                    color:
                      marcas?.color_1 &&
                      marcas.color_1 !== "undefined"
                        ? marcas.color_1
                        : "#694B81",
                  }}>Tipos de Servicios {Empresa?.nombre_empresa}</Typography>
                    </AccordionSummary>
                    <AccordionDetails> 

                    <Box sx={StyleBox}>
                        <Box sx={StyleBoxcontienelogo}>
                        <div className="input-crearadminsmartempresa">
                            <label htmlFor="logo">Tipos de Servicios</label>
                            <div className="contieneinputempresaregister">
                           
                            <input
                            style={{
                                background:'#ffff'
                            }}
                            type="text"
                            id="servicios"
                            name="servicios"
                            className="input-crear-empresa-adminsmart"
                            value={servicioActual}
                            onChange={(e) => setServicioActual(e.target.value)}
                            placeholder="Escribe un servicio"
                            />
                            <button 
                            style={{
                                background: marcas?.color_1 &&
                                    marcas.color_1 !== "undefined"
                                      ? marcas.color_1
                                      : "#694B81",
                                    fontFamily:'Poppins',
                                    fontWeight:'500',
                                    color:'#ffff'
                               
                            
                            }}
                                className="editusuariosucursal" 
                                onClick={() => {
                                    handleAddServicio(servicioActual); // Asegúrate de pasar el servicio actual
                                    setServicioActual(""); // Limpiar el campo después de agregar
                                }} 
                            >
                                <FiPlusCircle />
                                <p style={{ fontSize: '0.8rem', margin: '0' }}>Agregar</p>
                            </button>
                        
                            </div>

                           
                        </div>
                        </Box>
                        
                    <Box sx={StyleBoxcontienelogo}>
                    <div className="input-crearadminsmartempresa">
                    <label htmlFor="logo">Servicios Agregado:</label>
                    <ul>
                    {loading ? (
    <CircularProgress size={24} />
) : (
    Array.isArray(serviciosArray) && serviciosArray.length > 0 ? (
        serviciosArray.map((servicio, index) => (
            <li key={index} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                {servicio}
                <button className="editusuariosucursal" style={{padding:'0px 5px', marginLeft:'5px', fontSize:'1.3rem', background: 'transparent'}}  onClick={() => handleRemoveServicio(index)}>
                    <FaRegTrashAlt />
                </button>
            </li>
        ))
    ) : (
        <p>No hay servicios ingresados</p>
    )
)}
            </ul>
            </div>
                         </Box>
                        </Box>
                   
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel8'} onChange={handleAccordionChange('panel8')} sx={StyleAcordeon}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{
                        color:
                          marcas?.color_1 &&
                          marcas.color_1 !== "undefined"
                            ? marcas.color_1
                            : "#694B81",
                      }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    >
                    <Typography sx={{
                        fontFamily:'Poppins',
                        fontWeight:'500',
                    color:
                      marcas?.color_1 &&
                      marcas.color_1 !== "undefined"
                        ? marcas.color_1
                        : "#694B81",
                  }}>Tipos de Comentarios </Typography>
                    </AccordionSummary>

                    

                    <AccordionDetails> <Typography
                        sx={{
                            marginBottom: '20px',
                            fontFamily: 'Poppins',
                            fontWeight: '500',
                            color: marcas?.color_1 && marcas.color_1 !== "undefined" ? marcas.color_1 : "#694B81"
                        }}
                    >
                        Selecciona el tipo comentario que se utilizará para asignar los códigos a tu empresa:
                        <ul>
                            <li>visible: los comentarios externos se subiran inmediatamente y no seran aprobados o eliminados por el administradro del memorial</li>
                            <li> no visible: los comentarios deben ser aprobado por el administrador del memorial  para que se puedan visualizar.</li>
                        
                        </ul>
                    </Typography>
                    <FormGroup >
                        <FormControlLabel
                            sx={{
                                "& .MuiFormControlLabel-label": {
                                    color: "#585857",
                                    fontFamily: "Poppins"
                                }
                            }}
                            control={
                                <Checkbox
                                    name="visible"
                                    checked={selectedcomentario === 0}
                                    onChange={handleCheckComentarioChange}
                                />
                            }
                            label="Comentarios Visibles"
                        />
                        <FormControlLabel
                            sx={{
                                "& .MuiFormControlLabel-label": {
                                    color: "#585857",
                                    fontFamily: "Poppins"
                                }
                            }}
                            control={
                                <Checkbox
                                    name="invisible"
                                    checked={selectedcomentario === 1}
                                    onChange={handleCheckComentarioChange}
                                />
                            }
                            label="Comentarios no visibles"
                        />
                       
                    </FormGroup>
                        </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel9'} onChange={handleAccordionChange('panel9')} sx={StyleAcordeon}>
                <AccordionSummary
                        expandIcon={<ExpandMoreIcon sx={{
                            color:
                            marcas?.color_1 &&
                            marcas.color_1 !== "undefined"
                                ? marcas.color_1
                                : "#694B81",
                        }} />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        >
                                <Typography sx={{
                                    fontFamily:'Poppins',
                                    fontWeight:'500',
                                color:
                                marcas?.color_1 &&
                                marcas.color_1 !== "undefined"
                                    ? marcas.color_1
                                    : "#694B81",
                            }}>Tipos de Qr </Typography>
                                </AccordionSummary>
                                <AccordionDetails> 
                                <Typography sx={{
                                    marginBottom:'20px',
                                    fontFamily:'Poppins',
                                    fontWeight:'500',
                                color:
                                marcas?.color_1 &&
                                marcas.color_1 !== "undefined"
                                    ? marcas.color_1
                                    : "#694B81",
                            }}>
                                Selecciona el tipo de código QR que se utilizará para asignar los códigos a tu empresa:
                                <Typography sx={{
                                    marginTop:'10px',
                                    fontFamily:'Poppins',
                                    fontWeight:'500',
                                color:
                                marcas?.color_1 &&
                                marcas.color_1 !== "undefined"
                                    ? marcas.color_1
                                    : "#694B81",
                            }}>
                                Grupo Familiar: Los códigos QR pueden contener hasta un máximo de 5 memoriales.
                                 </Typography>
                             <Typography sx={{
                                    fontFamily:'Poppins',
                                    fontWeight:'500',
                                color:
                                marcas?.color_1 &&
                                marcas.color_1 !== "undefined"
                                    ? marcas.color_1
                                    : "#694B81",
                            }}> 
                           
                           Individual: Cada código QR permitirá mostrar un único memorial.
                            </Typography>
                             <Typography sx={{
                                    fontFamily:'Poppins',
                                    fontWeight:'500',
                                color:
                                marcas?.color_1 &&
                                marcas.color_1 !== "undefined"
                                    ? marcas.color_1
                                    : "#694B81",
                            }}>  Ambos: Tu empresa podrá seleccionar, para cada cliente, el tipo de código QR que mejor se adapte a sus necesidades. </Typography>
                                
                            
                              
                            </Typography>
                                <FormGroup>
                                    <FormControlLabel
                                     sx={{
                                        "& .MuiFormControlLabel-label": {
                                          color: "#585857", // Aplica el color al texto del label
                                          fontFamily: "Poppins", // Opcional: ejemplo de tipografía
                                        },
                                      }}
                                        control={
                                        <Checkbox
                                            name="grupoFamiliar"
                                            checked={selectedValue === 0}
                                            onChange={handleCheckChange}
                                        />
                                        }
                                        label="Grupo Familiar"
                                    />
                                    <FormControlLabel
                                     sx={{
                                        "& .MuiFormControlLabel-label": {
                                          color: "#585857", // Aplica el color al texto del label
                                          fontFamily: "Poppins", // Opcional: ejemplo de tipografía
                                        },
                                      }}
                                        control={
                                        <Checkbox
                                            name="individual"
                                            checked={selectedValue === 1}
                                            onChange={handleCheckChange}
                                        />
                                        }
                                        label="Individual"
                                    />
                                    <FormControlLabel
                                    sx={{
                                        "& .MuiFormControlLabel-label": {
                                          color: "#585857", // Aplica el color al texto del label
                                          fontFamily: "Poppins", // Opcional: ejemplo de tipografía
                                        },
                                      }}
                                        control={
                                        <Checkbox
                                        
                                            name="ambos"
                                            checked={selectedValue === 2}
                                            onChange={handleCheckChange}
                                        />
                                        }
                                        label="Ambos"
                                    />
                                    </FormGroup>
                        </AccordionDetails>
                        </Accordion>
                        <button 
                            type="submit" 
                            className="botonmarcaeditar" 
                            disabled={loading} 
                            style={{ marginTop: '16px' }} // Cambié `sx` por `style`
                        >
                            {loading ? <CircularProgress size={24} /> : <TbHomeEdit />} Actualizar
                        </button>
            </form>

            {/* Snackbar for success and error messages */}
            <Snackbar
                open={alertOpen}
                autoHideDuration={3000}
                onClose={() => setAlertOpen(false)}
                message={alertMessage}
                action={
                    <Button color="inherit" onClick={() => setAlertOpen(false)}>
                        <IoClose />
                    </Button>
                }
                ContentProps={{
                    style: { backgroundColor: 'green' }, // Cambia 'green' por el color que prefieras
                }}
            />
        </Box>
    );
};

const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    marginTop:'50px'
};


const StyleAcordeon = {
    width:"100%",
    background:'#ffff'
}

const StyleBox ={
    display:'flex',
    alignItems:'flex-start',
    justifyContent:'center',
    flexDirection:'row',
    width:'100%',
    marginBottom:'50px'
}
const StyleBoxcontienelogo = {
     width:'100%',
     Padding:'0px 30px',
     display:'flex',
    
}
const StyleTitulo ={
    fontFamily:'Poppins',
    color: '#585857',
    fontWeight:'600'
}
const StyleBoxcontienecolor = {
    width:'100%',
    Padding:'0px 30px',
    display:'flex',
    flexDirection:'column',

}
