import React from "react";
import { useState, useEffect } from 'react';
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import { Iconos } from "../../../../../../data/dataicono";
import "../../../../../../Style/usuario/obituario.css"
/* diseños */
import { DiseñoSmart } from "./diseñosmart";
import { DiseñoRecuerdo } from "./DiseñoRecuerdo";


/* elemento */
import axios from 'axios';
import { Box, Select, CircularProgress, MenuItem, Modal, Button, Alert, Checkbox, FormControlLabel, AlertTitle, Snackbar, Chip, Grid, IconButton, TextField } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/system';
import { IoClose } from "react-icons/io5";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import CloseIcon from '@mui/icons-material/Close';

/* icono */
import { RxCalendar } from "react-icons/rx";
import { LuClock2 } from "react-icons/lu";


export const EditarObituarioEmpresa = ({obituarios, grupoFamiliar, empresa, onclose, onObituarioCreado}) => {
    const { Token } =useUserEmpresa()
    const [obituario, setObituario] = useState(null)
    const [loading, setLoading] = useState(false);
    const [memorialData, setMemorialData] = useState({});
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [edad, setEdad] = useState(null);
    const [selectedDesign, setSelectedDesign] = useState(null);
    const [genero, setGenero] = useState('');
    const [selectedChips, setSelectedChips] = useState([]);
    const [newParentesco, setNewParentesco] = useState(''); 
    const [additionalParentescos, setAdditionalParentescos] = useState([]); 
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [processingModal, setProcessingModal] = useState(false);
    const [alertType, setAlertType] = useState("info");
    const [parentescos, setParentesco] = useState([]);
 
    const [isOpen, setIsOpen] = useState(false); 
    const handleOpenModal = () => setIsOpen(true);
    const handleCloseModal = () => setIsOpen(false);
  
    const [formData, setFormData] = useState({
      descripcion: '',
      direccion: '',
      estado: '',
      fecha_desde: '',
      fecha_funeral: '',
      fecha_hasta: '',
      genero: '',
      hora_funeral: '',
      hora_salida: '',
      icono: '',
      id_memorial: '',
      edad:'',
      diseno:'',
      parentesco: [],
    });
    const [parentescoMasculino, setParentescoMasculino] = useState([
      'Papá', 'Hermano', 'Esposo', 'Abuelo', 'Tío', 'Hijo', 'Primo', 'Nieto',
    ]);
    const [parentescoFemenino, setParentescoFemenino] = useState([
      'Madre', 'Hermana', 'Esposa', 'Abuela', 'Tía', 'Hija', 'Prima', 'Nieta',
    ]);
    const fetchObituarios = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/obtenerObituarioidempresa/${obituarios}`,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${Token}`,
                  },
            });
            setObituario(response.data.obituario);
            setFormData(response.data.obituario);
            const idMemorial = response.data.obituario.id_memorial;
            if (idMemorial) {
                fetchMemorialData(idMemorial);
            }
        } catch (error) {
            console.error('Error fetching obituarios:', error);
        }
    };
  
    useEffect(() => {
        fetchObituarios();
    }, [obituarios, backendURL]);
    
  const fetchMemorialData = async (idMemorial) => {
    try {
        const response = await axios.get(`${backendURL}/api/getMemorialIdGeneral/${idMemorial}`);
        setMemorialData(response.data);
        
        const fechaNacimiento = new Date(response.data.fecha_nacimiento);
        const fechaDefuncion = new Date(response.data.fecha_defuncion);
        const calculatedEdad = calcularEdad(fechaNacimiento, fechaDefuncion);
        
        setEdad(calculatedEdad);
        setLoading(false);

      
    } catch (error) {
        console.error('Error fetching memorial data:', error);
        setLoading(false);
    }
};

const calcularEdad = (fechaNacimiento, fechaDefuncion) => {
    let edad = fechaDefuncion.getFullYear() - fechaNacimiento.getFullYear();
    const mes = fechaDefuncion.getMonth() - fechaNacimiento.getMonth();

    if (mes < 0 || (mes === 0 && fechaDefuncion.getDate() < fechaNacimiento.getDate())) {
        edad--;
    }

    return edad;
};
/* selecion del diseño  */
 const handleSelectDesign = (design) => {
        setSelectedDesign(design); // Cambiar el estado de selectedDesign
        setFormData((prevData) => ({
            ...prevData,
            diseno: design, // Actualizar el valor del diseño en el formulario
        }));
    };

  /* form editar */
  useEffect(() => {
    if (obituario) {
      const initialGenero = obituario.genero || '';
      const initialParentesco = typeof obituario.parentesco === 'string'
        ? JSON.parse(obituario.parentesco)
        : obituario.parentesco ? obituario.parentesco.split(',') : [];
  
      setGenero(initialGenero);
      setFormData({
        descripcion: obituario.descripcion || '',
        direccion: obituario.direccion || '',
        estado: obituario.estado || '',
        fecha_desde: obituario.fecha_desde || '',
        fecha_funeral: obituario.fecha_funeral || '',
        fecha_hasta: obituario.fecha_hasta || '',
        genero: initialGenero,
        hora_funeral: obituario.hora_funeral || '',
        hora_salida: obituario.hora_salida || '',
        icono: obituario.icono || '',
        id_memorial: obituario.id_memorial || '',
        parentesco: initialParentesco,
        edad: obituario.edad || '', 
        diseno: obituario.diseno || '1', 
      });
      setSelectedDesign(obituario.diseno || '1');
      // Sincronizar los chips seleccionados con el género inicial
      const initialParentescoOptions = initialGenero === 'Masculino' ? parentescoMasculino : parentescoFemenino;
      setSelectedChips(initialParentesco.filter(chip => initialParentescoOptions.includes(chip)));
    }
  }, [obituario]);
  
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  
  const handleSubmit = async (event) => {
    event.preventDefault();
  
    // Crear un nuevo objeto FormData y agregar los campos
    const formDataToSend = new FormData();
    formDataToSend.append('descripcion', formData.descripcion || '');
    formDataToSend.append('direccion', formData.direccion || '');
    formDataToSend.append('estado', formData.estado || '');
    formDataToSend.append('fecha_desde', formData.fecha_desde || '');
    formDataToSend.append('fecha_funeral', formData.fecha_funeral || '');
    formDataToSend.append('fecha_hasta', formData.fecha_hasta || '');
    formDataToSend.append('genero', formData.genero || '');
    formDataToSend.append('hora_funeral', formData.hora_funeral || '');
    formDataToSend.append('hora_salida', formData.hora_salida || '');
    formDataToSend.append('icono', formData.icono || '');
    formDataToSend.append('id_memorial', memorialData.id);
    formDataToSend.append('edad', formData.edad ? 1 : 0);
    formDataToSend.append('diseno', formData.diseno || '');

    
  
  
    // Convertir el array parentesco a una cadena JSON si no está vacío
    formDataToSend.append('parentesco', JSON.stringify(formData.parentesco) || '[]');

    setenviandoAlert(true);

    // Validar campos requeridos
 const requiredFields = {
  descripcion: "Descripción",
  direccion: "Dirección del Velatorio",
  estado: "Estado",
  fecha_desde: "Fecha de Inicio del Velatorio",
  fecha_funeral: "Fecha del Funeral",
  fecha_hasta: "Fecha de Fin del Velatorio",
  genero: "Género",
  hora_funeral: "Hora del Funeral",
  hora_salida: "Hora de Salida",
  diseno: "diseño selección",
};

const missingFields = Object.keys(requiredFields).filter(
  (field) => !formData[field]
);

if (missingFields.length > 0) {
  setLoading(false);
  setErrorMessage(
    `Faltan los siguientes campos: ${missingFields
      .map((field) => requiredFields[field])
      .join(", ")}`
  );
  setAlertType("warning");
  setProcessingModal(false);
  setIsOpen(true); // Abre el modal para mostrar errores
  return;
}
    setenviandoAlert(true);
    setLoading(true);
    setProcessingModal(true);
   
   
    // Enviar la solicitud PUT usando axios
    try {
      await axios.post(`${backendURL}/api/editar-obituarioempresa/${obituarios}`, formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${Token}`,
        },
      });
      
      setLoading(false);
      setProcessingModal(false);
      setSuccessMessage("Obituario editado exitosamente.");
      setAlertType("success");
      setIsOpen(true);
    // Retardo antes de navegar
    setTimeout(() => {
      onclose()
      onObituarioCreado()
  }, 2000);
    }catch (error) {
      if (error.response) {
        setProcessingModal(false);
      setLoading(false);
      setErrorMessage("Ocurrió un error al editar el obituario. Por favor, inténtalo de nuevo.");
      setAlertType("error");
      setIsOpen(true);
      } else {
        setProcessingModal(false);
        setLoading(false);
        setErrorMessage("Ocurrió un error al editar el obituario. Por favor, inténtalo de nuevo.");
        setAlertType("error");
        setIsOpen(true);
      }
    }
    finally {
      // Cierra las alertas después de un pequeño retardo para permitir que se vean
      setTimeout(() => {
          setErrorAlertOpen(false);
          setSuccessAlertOpen(false);
      }, 2000); 
  };
}


const handleEdadCheckChange = (event) => {
  const newEdad = event.target.checked; // Esto es booleano
  setFormData((prevData) => ({
    ...prevData,
    edad: newEdad, // Convertimos el booleano a cadena para mantener la consistencia
  }));
};
  const handleGeneroChange = (event) => {
    const newGenero = event.target.value;
  
    // Actualiza el estado del género y limpia los chips seleccionados
    setGenero(newGenero);
    setFormData((prevData) => ({
      ...prevData,
      genero: newGenero,
      parentesco: [] // Limpiar parentesco cuando se cambia el género
    }));
  
    // Actualiza los chips seleccionados de acuerdo al nuevo género
    const newParentesco = newGenero === 'Masculino' ? parentescoMasculino : parentescoFemenino;
    setSelectedChips(newParentesco.filter((chip) => selectedChips.includes(chip)));
  };
  
  
  // Agrega un parentesco adicional
  
  useEffect(() => {
    if (obituario?.parentesco) {
      const parentescoArray = JSON.parse(obituario.parentesco);  // Convierte el string JSON a un array
      setParentesco(parentescoArray);
    }
  }, [obituario]);  // Actualiza cuando obituario cambia

  const handleChipClick = (label) => {
    const updatedChips = selectedChips.includes(label)
      ? selectedChips.filter((chip) => chip !== label)
      : [...selectedChips, label];

    setSelectedChips(updatedChips);
    setFormData((prevData) => ({
      ...prevData,
      parentesco: updatedChips,
    }));
  };

  const handleAddParentesco = () => {
    if (
      newParentesco &&
      !parentescoMasculino.includes(newParentesco) &&
      !parentescoFemenino.includes(newParentesco) &&
      !additionalParentescos.includes(newParentesco)
    ) {
      setAdditionalParentescos((prev) => [...prev, newParentesco]);
      setNewParentesco('');
    }
  };

  const parentesco = [
    ...(genero === 'Masculino' ? parentescoMasculino : parentescoFemenino),
    ...additionalParentescos,
  ];

 
  
   
    return(
        <>
       <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            background: "#ffff",
            justifyContent: 'center',
            alignItems: 'center'

          }}
        >
          <section className="titulopaginacrearobituario">
            <h1 className="tituloobituariocrear">Editar obituario</h1>
            
          </section>
          <section className="formulariocrearobituario">
            <form onSubmit={handleSubmit} >
            <section className="seccionform" style={{display:"flex", alignItems:"center", flexDirection:'row', marginTop:'20px', marginBottom:'20px'}}>
          <div class="corazoncheckobituario" title="Like">
            <input type="checkbox" className="checkbox" id="Give-It-An-Id"  checked={formData.edad == 1}
            onChange={handleEdadCheckChange}/>
            <div class="svg-container">
                <svg viewBox="0 0 24 24" class="svg-outline" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z">
                    </path>
                </svg>
                <svg viewBox="0 0 24 24" class="svg-filled" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z">
                    </path>
                </svg>
                <svg class="svg-celebrate" width="100" height="100" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="10,10 20,20"></polygon>
                    <polygon points="10,50 20,50"></polygon>
                    <polygon points="20,80 30,70"></polygon>
                    <polygon points="90,10 80,20"></polygon>
                    <polygon points="90,50 80,50"></polygon>
                    <polygon points="80,80 70,70"></polygon>
                </svg>
            </div>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
        <span className="activaredadobituario">Selecciona si desea el usuario mostrar la edad</span>
        <span className="activaredadobituario">  <strong>Estado Actual:</strong> {formData.edad == '1' ? "Mostrar edad" : "Ocultar edad"} </span>
     
        </div>
        </section>
              <section className="seccionform">
                <h3 className="subtituloformcrearobiturio">Aviso del desceso</h3>
                <div className="inputformobituario">
                  <textarea
                  style={{
                    background:'#ffff',
          
                  }}
                    placeholder="Escriba información para quienes deseen asistir al velatorio y/o funeral..."
                    className="inputdescriopcionformcrearEmpresaobituario"
                     type="text"
                      name="descripcion"
                      value={formData.descripcion}
                onChange={handleChange}

                     />
                </div>
                
                <div className="inputformgenero">
          <label htmlFor='genero'>Género</label>
          <div className='contieneinputgenero'>
            <StyledSelect
              name='genero'
              id='genero'
              sx={{ textAlign: "left" }}
              value={formData.genero}
              onChange={handleGeneroChange}
              className="input-select-registro"
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              IconComponent={KeyboardArrowDownIcon}
            >
              <MenuItem value="">
                <em>Seleccionar género...</em>
              </MenuItem>
              <MenuItem value={"Masculino"}>Masculino</MenuItem>
              <MenuItem value={"Femenino"}>Femenino</MenuItem>
            </StyledSelect>
          </div>
        </div>
      </section>

      <section className="seccionform">
      <h3 className="subtituloformcrearobiturio">Título de parentesco</h3>
      <span className="infosuvtitulo">Puedes seleccionar varias opciones</span>
      <div className="contienechip">
        {parentescos.map((label) => (
        <Chip
          key={label}
          label={label}
          onClick={() => handleChipClick(label)}
          sx={{
            margin: '10px',
            fontSize: '0.8rem',
            fontWeight: '500',
            fontFamily: 'Poppins',
            backgroundColor: selectedChips.includes(label) ? '#694B81' : '#E1DBE6',
            color: selectedChips.includes(label) ? 'white' : '#694B81',
            '&:hover': {
              backgroundColor: selectedChips.includes(label) ? '#694B81' : '#E1DBE6',
            },
          }}
          variant={selectedChips.includes(label) ? 'filled' : 'outlined'} // Cambia el estilo según si está seleccionado
        />
      ))}
        </div>
      <div className="contienechip">
        {/* Mostrar parentescos masculinos o femeninos */}
        {parentesco.map((label) => (
          <Chip
            key={label}
            label={label}
            onClick={() => handleChipClick(label)}
            sx={{
              margin: '10px',
              fontSize: '0.8rem',
              fontWeight: '500',
              fontFamily: 'Poppins',
              backgroundColor: selectedChips.includes(label) ? '#694B81' : '#E1DBE6',
              color: selectedChips.includes(label) ? 'white' : '#694B81',
              '&:hover': {
                backgroundColor: selectedChips.includes(label) ? '#694B81' : '#E1DBE6',
              },
            }}
            variant={selectedChips.includes(label) ? 'default' : 'outlined'}
          />
        ))}

        {/* Mostrar los parentescos adicionales que no están en la lista predeterminada */}
        {selectedChips.filter((chip) => !parentesco.includes(chip)).map((label) => (
          <Chip
            key={label}
            label={label}
            onClick={() => handleChipClick(label)}
            sx={{
              margin: '10px',
              fontSize: '0.8rem',
              fontWeight: '500',
              fontFamily: 'Poppins',
              backgroundColor: selectedChips.includes(label) ? '#694B81' : '#E1DBE6',
              color: selectedChips.includes(label) ? 'white' : '#694B81',
              '&:hover': {
                backgroundColor: selectedChips.includes(label) ? '#694B81' : '#E1DBE6',
              },
            }}
            variant={selectedChips.includes(label) ? 'default' : 'outlined'}
          />
        ))}
      </div>

      {/* Agregar nuevo parentesco */}
      <TextField
        variant="outlined"
        value={newParentesco}
        onChange={(e) => setNewParentesco(e.target.value)}
        fullWidth
        sx={{
          borderRadius:'50px',
          color:'#694B81',
          marginBottom: '10px',
          fontFamily: 'Poppins',
          border: '3px solid #F0EDF3',
          '& .MuiOutlinedInput-input':{
            color:'#694B81',
          }
        }}
      />
      <Button
        variant="contained"
        color="primary"
        onClick={handleAddParentesco}
        sx={{
          color:'#ffff',
          textTransform:'capitalize',
          fontFamily: 'Poppins',
          backgroundColor: '#694B81',
          '&:hover': {
            backgroundColor: '#5a3a6c',
          },
        }}
      >
        Agregar
      </Button>
    </section>
    
              <section  className="seccionform">
                <h3 className="subtituloformcrearobiturio">Velatorio</h3>
                <div className="inputformobituario">
                  <label htmlFor="direccion" className="labelfromobituario"> Dirección del lugar</label>
                  <input type="text" id="direccion" 
                  name="direccion" 
                  className="inputcreaempresaobituario"
                  value={formData.direccion}
                  onChange={handleChange} />
                </div>
               
                <Grid container  sx={{
    display: "flex",
    flexDirection: "row",  // Dirección inicial en dispositivos más grandes
    '@media (max-width: 400px)': {
      flexDirection: "column",
      maxWidth:"100%"  // Cambia a 'column' cuando el ancho de la pantalla sea menor a 400px
    },
  }}>
                  <Grid item xs={12}> 
                     <label htmlFor="direccion" className="labelfromobituario">
                       Fecha
                     </label>
                  </Grid>
                  <Grid item xs={6}  sx={{
    display: "flex",
    flexDirection: "row",  // Dirección inicial en dispositivos más grandes
    '@media (max-width: 400px)': {
     maxWidth:"100%",  // Cambia a 'column' cuando el ancho de la pantalla sea menor a 400px
    },
  }}>
                    <div className="inputcrearEmpresaobituario">
                    <div className="contieneninputfechaobituariocrear">
                    <input  
                    style={{
                      width:'100%'
                    }}
                      type="date" 
                      id="fecha"
                      name="fecha_desde"
                      value={formData.fecha_desde}
                      onChange={handleChange}
                       className="inputcreaobituario" 
                        />
                    <div className="calendariocreacionobituarioicono">
                      <RxCalendar className="iconocrearobituariocalendario" />
                    </div>
                  </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}  sx={{
    display: "flex",
    flexDirection: "row",  // Dirección inicial en dispositivos más grandes
    '@media (max-width: 400px)': {
     maxWidth:"100%",  // Cambia a 'column' cuando el ancho de la pantalla sea menor a 400px
    },
  }}>
                    <div className="inputcrearEmpresaobituario">
                    <div className="contieneninputfechaobituariocrear">
                    <input
                      style={{
                        width:'100%'
                      }}
                      type="date" 
                      id="fecha" 
                     className="inputcreaobituario" 
                     name="fecha_hasta"
                     value={formData.fecha_hasta}
                     onChange={handleChange}
                    
                    />
                    <div className="calendariocreacionobituarioicono">
                      <RxCalendar className="iconocrearobituariocalendario" />
                    </div>
                  </div>
                    </div>
                  </Grid>
                </Grid>
              </section>
              <section  className="seccionform">  
                <h3 className="subtituloformcrearobiturio">Cortejo fúnebre</h3>
                <div>
                  <label htmlFor="direccion" className="labelfromobituario"> Hora de salida </label>
                  <div className="inputcrearobituario">
                      <div className="contienenrelojcrearobituario" style={{
                        justifyContent:'center'
                      }}>
                        <input  className="inputcreahorariodosobituario"
                             style={{
                              width:'100%'
                            }}
                          placeholder="Desde.."
                          type="time"
                          name="hora_salida"
                          value={formData.hora_salida}
                          onChange={handleChange}
                        />
                        <div className="relojiobituariocono">
                          <LuClock2 className="iconoeventoobituariocalendario" />
                        </div>
                      </div>
                    </div>
                </div>

              </section>
              <section  className="seccionform">
                <h3 className="subtituloformcrearobiturio">Funeral</h3>
               
                <Grid container sx={{
    display: "flex",
    flexDirection: "row",  // Dirección inicial en dispositivos más grandes
    '@media (max-width: 400px)': {
      flexDirection: "column",  // Cambia a 'column' cuando el ancho de la pantalla sea menor a 400px
    },
  }}>

                  <Grid item xs={6}  sx={{
    display: "flex",
    flexDirection: "row",  // Dirección inicial en dispositivos más grandes
    '@media (max-width: 400px)': {
      maxWidth:"100%",  // Cambia a 'column' cuando el ancho de la pantalla sea menor a 400px
    },
  }}>
                    <div className="conitnenetodoobedit" >
                      <label htmlFor="direccion" className="labelfromobituario"> Fecha</label>
                      <div className="contieneninputfechaobituariodoscrear">
                    <input
                      style={{
                        width:'100%'
                      }}
                      type="date" 
                      id="fecha" 
                       className="inputcreaobituario"
                       name="fecha_funeral"
                          value={formData.fecha_funeral}
                          onChange={handleChange}
                         />
                    <div className="calendariocreacionobituarioicono">
                      <RxCalendar className="iconocrearobituariocalendario" />
                    </div>
                  </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}  sx={{
    display: "flex",
    flexDirection: "row",  // Dirección inicial en dispositivos más grandes
    '@media (max-width: 400px)': {
     maxWidth:"100%",  // Cambia a 'column' cuando el ancho de la pantalla sea menor a 400px
    },
  }}>
                    <div   className="conitnenetodoobedit" >
                      <label htmlFor="direccion" className="labelfromobituario"> Hora</label>
                      <div className="inputcrearobituario">
                      <div className="contienenrelojcrearobituario">
                        <input  className="inputcreahorarioobituario"
                        style={{
                            width:'86%'
                        }}
                          placeholder="Desde.."
                          type="time"
                          name="hora_funeral"
                          value={formData.hora_funeral}
                          onChange={handleChange}
                        />
                        <div className="relojiobituariocono">
                          <LuClock2 className="iconoeventoobituariocalendario" />
                        </div>
                      </div>
                    </div>
                    </div>
                  </Grid>
                </Grid>

              </section>

              <section  className="seccionform">
                <div className="contieneiconoobitiario">
                <h3 className="subtituloformcrearobiturio"> Seleccione un icono</h3>
                <SelectItem
                className="seleciondefondofinal"
                IconComponent={KeyboardArrowDownIcon}
                name="icono"
                value={formData.icono}
                onChange={handleChange}
                displayEmpty
                inputProps={{ 'aria-label': 'Seleccione un icono' }}
                renderValue={(selected) => {
                  const selectedIconData = Iconos.find(icono => icono.value == selected);
                  return selectedIconData ? (
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                      <img
                        src={selectedIconData.src}
                        alt="Icon Preview"
                        style={{ width: '32px', height: '32px', objectFit: 'contain' }}
                      />
                    </div>
                  ) : (
                    <span>Seleccione un icono</span>
                  );
                }}
               >
          {Iconos.map((icono) => (
        
        <CustomMenu key={icono.value} value={icono.value}>
          <img
            src={icono.src}
            alt={icono.alt}
            style={{ width: '40px', height: '40px', objectFit: 'contain', marginRight: '10px' }}
          />
        </CustomMenu>
  
      ))}
         
      </SelectItem>
                </div>
        
                  <div style={{marginBottom:'40px'}}>
                  <div className="informacionalertaobituario">
                      <h3 className="subtituloformcrearobiturio">Selección del diseño del obituario</h3>
                      <Grid container spacing={2}>
                        {/* Diseño Regular */}
                        <Grid item xs={12} sm={6}>
                          <Box
                            sx={{
                              border: '2px solid',
                              borderColor: selectedDesign === '1' ? '#694B81' : '#E1DBE6',
                              borderRadius: '10px',
                              padding: '0px',
                              textAlign: 'center',
                              backgroundColor: selectedDesign === '1' ? '#F0EDF3' : 'white',
                            }}
                            onClick={() => handleSelectDesign('1')}
                          >
                            <div style={{
                              height:'400px', overflowY:'auto'
                            }}>
                              <DiseñoSmart/>
              
                            </div>
                           
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedDesign === '1'}
                                  onChange={() => handleSelectDesign('1')}
                                  sx={{ color: '#694B81', '&.Mui-checked': { color: '#694B81' } }}
                                />
                              }
                              label="Seleccionar"
                              sx={{ color: '#694B81', fontFamily: 'Poppins', '& .MuiTypography-root':{color: '#694B81',fontWeight:'500'} }}
                            />
                          </Box>
                        </Grid>
              
                        {/* Diseño Nuevo */}
                        <Grid item xs={12} sm={6}>
                          <Box
                            sx={{
                              border: '2px solid',
                              borderColor: selectedDesign === '2' ? '#694B81' : '#E1DBE6',
                              borderRadius: '10px',
                              padding: '0px',
                              textAlign: 'center',
                              backgroundColor: selectedDesign === '2' ? '#F0EDF3' : 'white',
                            }}
                            onClick={() => handleSelectDesign('2')}
                          >
                             <div style={{
                              height:'400px', overflowY:'auto'
                            }}>
                              <DiseñoRecuerdo/>
              
                            </div>
                           
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={selectedDesign === '2'}
                                  onChange={() => handleSelectDesign('2')}
                                  sx={{ color: '#694B81', '&.Mui-checked': { color: '#694B81'  } }}
                                />
                              }
                              label="Seleccionar"
                              sx={{ color: '#694B81', fontFamily: 'Poppins', '& .MuiTypography-root':{color: '#694B81',fontWeight:'500'} }}
                            />
                          </Box>
                        </Grid>
                      </Grid>
                    </div>
              
                    
                    
                  </div>
              

              </section>
              <section className="buttonovituario">
              <button className="previsualizacion-obituario" type="submit" style={{
  border: (() => {
    switch (memorialData.fondo) {
      case 'naturalezaAustral':
        return '#68A354';
      case 'atardecerVerano':
        return '#E28939';
      default:
        return '#694B81';
    }
  })(),
  backgroundColor: (() => {
    switch (memorialData.fondo) {
      case 'naturalezaAustral':
        return '#68A354';
      case 'atardecerVerano':
        return '#E28939';
      default:
        return '#694B81';
    }
  })()
}} >
  Actualizar
          </button>
              </section>

            </form>
          </section>

        </Box>
      
     {/* procesos */}
     <AlertaModal
        alertType={alertType}
        successMessage={successMessage}
        errorMessage={errorMessage}
        isOpen={isOpen}
        handleClose={handleCloseModal}
      />

<Modal
    open={processingModal}
    onClose={() => setProcessingModal(false)}
    aria-labelledby="processing-modal"
    aria-describedby="processing-description"
>
    <Box sx={{ ...modalStyle }}>
        <h3 id="processing-modal" style={{margin:'0'}} >Procesando solicitud...</h3>
        <p id="processing-description">Por favor, espera mientras procesamos tu solicitud.</p>
    </Box>
</Modal>
        </>
    )
}


/* alerta */
const AlertaModal = ({ alertType, successMessage, errorMessage, isOpen, handleClose }) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-modal-title"
      aria-describedby="alert-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, 0)',
          zIndex: 1000,
          width: '80%',
          maxWidth: '600px',
          bgcolor: '#ffff',
          boxShadow: 24,
          p: 0,
          borderRadius: 2,
          fontFamily:'Poppins'
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {alertType === 'success' && successMessage && (
          <Alert severity="success">
            <AlertTitle sx={{
              fontFamily:'Poppins'
            }}>Éxito</AlertTitle>
            {successMessage}
          </Alert>
        )}
        {alertType === 'warning' && errorMessage && (
          <Alert severity="warning" sx={{
            fontFamily:'Poppins'
          }}>
            <AlertTitle sx={{
              fontFamily:'Poppins'
            }}>Advertencia</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {alertType === 'error' && errorMessage && (
          <Alert severity="error" sx={{
            fontFamily:'Poppins'
          }}>
            <AlertTitle sx={{
              fontFamily:'Poppins'
            }}>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
      </Box>
    </Modal>
  );
};
  


  
const SelectItem = styled(Select)(({ theme }) => ({
    width:'40%',
    marginLeft:'10px',
    borderRadius:'50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    '@media (max-width: 310px)': {
      
      width: '70%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '70%',
    },
    
  }));
  
  // Estilo personalizado para el contenedor del menú
  const CustomMenu = styled(MenuItem)(({ theme }) => ({
    backgroundColor: '#ffff', // Fondo del `MenuItem` principal
    '&.MuiMenuItem-root': {
      backgroundColor: '#ffff',
    },
    // Estilos para el contenedor generado del `Popover` o `Menu`
    '& .MuiPaper-root': {
      backgroundColor: 'white', // Cambia el color de fondo del `Popover`
    },
  }));
  
  
  
  const StyledSelect = styled(Select)(({ theme }) => ({
    padding: '1px 13px',
    color:'#694B81',
    position:'relative',
    fontFamily: "Poppins",
    width:'95%',
    height:'55px',
    fontSize:'0.8rem',
    borderRadius: '50px',
    border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F0EDF3', // Color del borde por defecto
      },
      '&:hover fieldset': {
        borderColor: '#585857', // Color del borde al pasar el mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: '#585857', // Color del borde en foco
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#585857', // Color del borde en foco
      borderWidth: '2px', // Ancho del borde en foco
    },
    '@media (max-width: 310px)': {
      fontSize:'0.7rem',
      width: '100%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '100%',
    },
    
  }));

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#ffff',
    boxShadow: 24,
    p: 4,
    borderRadius:'10px',
    fontFamily:'poppins',
    fontSize:'1rem',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    color:'#694B81',
    flexDirection:'column'
};