import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, Container, Grid, Card, CardContent, TextField,CircularProgress, Alert} from "@mui/material";
import AOS from 'aos';
import axios from "axios";
import 'aos/dist/aos.css';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import { keyframes } from "styled-components";
import MuiAccordionSummary, {
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { border, styled } from "@mui/system";
import { Fab } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import '../../../Style/inicio/smartmemorial.css';
import BackgroundImage from "../../../Image/inicio/incio.jpg";
import Lapiz from"../../../Image/inicio/lapiz.png";
import qr from"../../../Image/inicio/qr.png";
import person from"../../../Image/inicio/person.png"
import portada from"../../../Image/inicio/portada.jpg"
import fondo from "../../../Image/inicio/fondo.jpg";
import celu from"../../../Image/inicio/celu.png"
/* imagenss galeria */
import uno from "../../../Image/inicio/1.jpg"
import dos from "../../../Image/inicio/2.jpg"
import tres from "../../../Image/inicio/3.jpg"
import cuatro from "../../../Image/inicio/4.jpg"
import cinco from "../../../Image/inicio/5.jpg"
import seis from "../../../Image/inicio/6.jpg"


/* imagenes */
import full from "../../../Image/generales/full.jpg"
import basico from "../../../Image/generales/basico.jpg"

import unos from "../../../Image/generales/1.png"
import doss from "../../../Image/generales/2.png"
import tress from "../../../Image/generales/3.png"
import cuatros from "../../../Image/generales/4.png"
import cincos from "../../../Image/generales/5.png"
import seiss from "../../../Image/generales/6.png"
import siete from "../../../Image/generales/7.png"
import ocho from "../../../Image/generales/8.png"
import nueve from "../../../Image/generales/moradoluto.png"

const images = [uno, dos, tres, cuatro, cinco, seis];

export const IndividualPersonas = () => {
     const [showScrollButton, setShowScrollButton] = useState(false);
     const backendURL = process.env.REACT_APP_BACKEND_URL;
     const [visible, setVisible] = useState(false);
     const [isVisible, setIsVisible] = useState(false);
     const [isSubmitting, setIsSubmitting] = useState(false);
     const [success, setSuccess] = useState(false);
     const [error, setError] = useState("");
     const [formData, setFormData] = useState({
         name: "",
         email: "",
         message: "",
          contact_type:"Persona"
       });

       const handleChanges = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError("");
        setSuccess(false);
    
        try {
            const response = await axios.post(
                `${backendURL}/api/contacto/formulario`, // Ruta del backend
                formData, // Datos enviados en el cuerpo de la solicitud
                {
                    headers: {
                        "Content-Type": "application/json", // Asegúrate de usar el tipo de contenido correcto
                    },
                }
            );
    
            // Si la respuesta es exitosa
            if (response.status === 200 || response.status === 201) {
                setSuccess(true);
                setFormData({ name: "", email: "", message: "", contact_type: "General" });
            }
        } catch (err) {
            // Captura errores del backend
            if (err.response && err.response.data.errors) {
                const backendErrors = Object.values(err.response.data.errors)
                    .flat()
                    .join(", ");
                setError(backendErrors);
            } else {
                setError(err.message || "Error desconocido al enviar el formulario");
            }
        } finally {
            setIsSubmitting(false);
        }
    };
     useEffect(() => {
         const observer = new IntersectionObserver(
           ([entry]) => setIsVisible(entry.isIntersecting),
           { threshold: 0.3 }
         );
     
         const section = document.getElementById("sec-e2ef");
         if (section) observer.observe(section);
     
         return () => observer.disconnect();
       }, []);
     
      
     
      // Manejar la visibilidad del botón de scroll
      const handleScrolls = () => {
        if (window.scrollY > 200) {
          setShowScrollButton(true); // Mostrar botón si se supera 200px de desplazamiento
        } else {
          setShowScrollButton(false); // Ocultar botón al volver arriba
        }
      };
    
      // Desplazar al inicio de la página
      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Scroll suave
        });
      };
    
      useEffect(() => {
        window.addEventListener("scroll", handleScrolls);
        return () => {
          window.removeEventListener("scroll", handleScrolls);
        };
      }, []);
     

      useEffect(() => {
        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                entry.target.classList.add('visible');
              } else {
                entry.target.classList.remove('visible');
              }
            });
          },
          { threshold: 0.1 } // Ajusta la sensibilidad si es necesario
        );
    
        const elements = document.querySelectorAll('.animatable');
        elements.forEach((el) => observer.observe(el));
    
        return () => {
          elements.forEach((el) => observer.unobserve(el));
        };
      }, []);

      const handleScroll = () => {
          // Detectar cuándo mostrar el contenido con transición
          if (window.scrollY > 500) {
            setVisible(true);
          } else {
            setVisible(false);
          }
        };
      
        useEffect(() => {
          window.addEventListener("scroll", handleScroll);
          return () => {
            window.removeEventListener("scroll", handleScroll);
          };
        }, []);

        const navigate = useNavigate();
            const handleClickfull = () => {
                navigate('/solicitarmemorialfull'); // Navegar a la ruta de inicio
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth', // Desplazamiento suave
                });
              };
        
              useEffect(() => {
                AOS.init({ duration: 1000 });
              }, []);
        
              const handleClickbasic = () => {
                navigate('/solicitarmemorialbasico'); // Navegar a la ruta de inicio
                window.scrollTo({
                  top: 0,
                  behavior: 'smooth', // Desplazamiento suave
                });
              };

    return(
        <>
         {showScrollButton && (
        <Fab
        onClick={scrollToTop}
          color="primary"
          aria-label="scroll-to-bottom"
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            zIndex: 1000,
          }}
        >
          <ArrowUpwardIcon />
        </Fab>
      )}

       <ParallaxSection>
              <ParallaxImage alt="Parallax Background" />
              <FixedContent>
                <Typography variant="h2" className="title">
                  Un memorial
                </Typography>
                <Typography variant="h2" className="title">
                para sus seres queridos
                </Typography>
                <Typography variant="body1" className="description">
                Para compartir y conectar, desempeña un papel significativo en el proceso de duelo y en mantener viva la memoria de alguien especial.
                </Typography>
                <Typography variant="body1" className="description">
                Brinda una forma innovadora y conveniente de preservar y honrar la memoria de un ser querido. 
                </Typography>
                <Button 
                    className="cta-button"
                    onClick={() => {
                    const contactSection = document.getElementById("contact");
                    if (contactSection) {
                    contactSection.scrollIntoView({ behavior: "smooth" });
                    }
                }}>Solicítelo aquí
                </Button>
              </FixedContent>
            </ParallaxSection>
            <section>
            <Container maxWidth="lg" sx={{ marginTop: '100px', marginBottom: '100px' }}>
                <Grid container sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
                <Grid item sm={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="contieneimagencelu animatable" id="imageContainer">
                    <img src={celu} alt="smart memorial" width="100%" />
                    </div>
                </Grid>
                <Grid item sm={12} md={6} className="animatable">
                    <div className="titulocelu animatable" id="textContainer">
                    <h3>Veamos un</h3>
                    <h3>memorial real</h3>
                    </div>
                    <div className="descricioncelu animatable" id="descriptionContainer">
                    <span>
                        Compartir un memorial, desempeña un papel significativo en el proceso de duelo y en mantener viva la memoria
                        de alguien especial.
                    </span>
                    </div>
                    <div>
                   <Link to="https://www.smartmemorial.cl/familiar/memorial/110"> <StyledButton >Revisar memorial</StyledButton></Link>
                    </div>
                </Grid>
                </Grid>
            </Container>
            </section>
            
                <Box sx={{ py: 4, marginTop: '0px', marginBottom: '80px' }}>
                    <Container
                        maxWidth="lg"
                        style={{
                        opacity: visible ? 1 : 0,
                        transform: visible ? "translateY(0)" : "translateY(50px)",
                        transition: "opacity 0.8s ease, transform 0.8s ease",
                        }}
                    >
                        <StyledTitle variant="h4" gutterBottom>
                        Nuestros planes
                        </StyledTitle>
                        <StyledTitle variant="h4" gutterBottom>
                        individuales
                        </StyledTitle>
                        <section className="tectogenerado" style={{ marginTop:'20px'}}>
                        <span >Elija el servicio que se adapte

a sus necesidades.
                              </span>
                         
                    </section>
                        <Grid container sx={{marginTop:'50px'}}>
                            <Grid item sm={12} md={6} sx={{padding:'10px'}}>
                            <section className="tituloselecion"  data-aos="fade-down">
                                <h3>Memorial Básico</h3>
                            </section> 
                            <section  data-aos="fade-down" >
                            <div className="recuadroinforcionmemorial">
                                <div style={{width:"100%", height:"100%", borderRadius:'10px'}}>
                                    <img src={basico} alt="smart memorial full" style={{width:"100%", height:"100%", borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}/>
                                </div>
                            <section className="listaservicio">
                                <div className="tituloservicioincluido" >
                                <h2>Servicio incluye</h2>
                                </div>
                    <div className="infolistaincluido">
                    <div className="iteminfoservicio" >
                        <div className="contieneimagensmart">
                        <img src={unos} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                        </div>
                        <span className="textoitemservicio">Memorial virtual para sus seres queridos</span>
                    </div>
                    <div className="iteminfoservicio">
                        <div className="contieneimagensmart">
                        <img src={doss} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                        </div>
                        <span className="textoitemservicio">Creación de grupo familiar de hasta 5 personas</span>
                    </div>
                    <div className="iteminfoservicio">
                        <div className="contieneimagensmart">
                        <img src={tress} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                        </div>
                        <span className="textoitemservicio">Código QR para descargar e imprimir</span>
                    </div>
                    <div className="iteminfoservicio">
                        <div className="contieneimagensmart">
                        <img src={cuatros} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                        </div>
                        <span className="textoitemservicio">Subir su historia, imágenes y videos</span>
                    </div>
                    <div className="iteminfoservicio">
                        <div className="contieneimagensmart">
                        <img src={cincos} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                        </div>
                        <span className="textoitemservicio">Dejar mensajes y condolencias</span>
                    </div>
                    <div className="iteminfoservicio">
                        <div className="contieneimagensmart">
                        <img src={seiss} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                        </div>
                        <span className="textoitemservicio">Edición del memorial cuando desee</span>
                    </div>
                    <div className="iteminfoservicio">
                        <div className="contieneimagensmart">
                        <img src={siete} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                        </div>
                        <span className="textoitemservicio">Creación de eventos conmemorativos</span>
                    </div>
                    <div className="iteminfoservicio">
                        <div className="contieneimagensmart">
                        <img src={ocho} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                        </div>
                        <span className="textoitemservicio">Compartir memoriales con familiares y amigos</span>
                    </div>
                    <div className="iteminfofinalservicio">
                        <div className="contieneimagensmart">
                        <img src={nueve} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                        </div>
                        <span className="textoitemservicio">Creación de Obituario </span>
                    </div>
                    </div>

                </section>
                <div className="solicitaryprecio"  >
                                    <div className="preciounasmart">
                                    <div className="contienenpreciounico"> <p>$12.990</p></div>
                                    
                                    </div>
                                    <div className="botonsmartsolicitar" onClick={handleClickbasic}>
                                        <button > Solicitar</button>
                                    </div>
                                    
                                </div>

                                </div>

                            </section>    
                            </Grid>
                            <Grid item sm={12} md={6} sx={{padding:'10px'}}>
                            <section className="tituloselecion"  data-aos="fade-down">
                            <h3>Memorial Full</h3>
                        </section> 
                        <section  data-aos="fade-down" >
                        <div className="recuadroinforcionmemorial">
                            <div style={{width:"100%", height:"100%",  borderRadius:'10px'}}>
                                <img src={full} alt="smart memorial full" style={{width:"100%", height:"100%", borderTopLeftRadius: '10px', borderTopRightRadius: '10px'}}/>
                            </div>
                            <section className="listaservicio">
                <div className="tituloservicioincluido" >
                  <h2>Servicio incluye</h2>
                </div>
                <div className="infolistaincluido">
                  <div className="iteminfoservicio" >
                    <div className="contieneimagensmart">
                      <img src={unos} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                      <span className="textoitemservicio">Memorial virtual para sus seres queridos</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={doss} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Creación de grupo familiar de hasta 5 personas</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={tress} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">2 Placas metálicas con QR para lápida o ánfora</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={cuatros} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Subir su historia, imágenes y videos</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={cincos} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Dejar mensajes y condolencias</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={seiss} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Edición del memorial cuando desee</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={siete} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Creación de eventos conmemorativos</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={ocho} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Compartir memoriales con familiares y amigos</span>
                  </div>
                  <div className="iteminfofinalservicio">
                    <div className="contieneimagensmart">
                      <img src={nueve} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Creación de Obituario </span>
                  </div>
                </div>

              </section>
              <div className="solicitaryprecio"  >
                                 <div className="preciounasmart">
                                <div className="contienenpreciounico"> <p>$39.990</p></div>
                                 
                                </div>
                                <div className="botonsmartsolicitar" onClick={handleClickfull}>
                                    <button > Solicitar</button>
                                </div>
                                
                            </div>

                            </div>
                                  
                           
                            
                            
                        </section>
                       
                        

                     </Grid>
                        </Grid>
                        </Container>
                    </Box>
                    <section
 className={`sectioninfoportadasmart ${isVisible ? "visible" : ""}`}
  id="sec-e2ef"
>
  {/* Columna izquierda: Texto */}
  <Container
    maxWidth="md"
    sx={{
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: { xs: "center", md: "flex-start" },
      textAlign: { xs: "center", md: "left" },
      padding: "0px",
    }}
  >
    <StyledTitleportada
      variant="h4"
      sx={{
        marginBottom: "20px",
      }}
    >
      Por todo lo que nos entregaron...
    </StyledTitleportada>
    <StyledTitleTextportada
      variant="body1"
      sx={{
        marginBottom: "20px",
      }}
    >
      Preserve la historia de sus seres queridos con Smart Memorial.
      Comparta sus recuerdos, fotografías, vídeos e historias.
      Simplemente ponga su código QR en su lápida y escanéelo para recordar a
      sus seres queridos. Con Smart Memorial su legado se mantendrá por siempre.
    </StyledTitleTextportada>
    <StyledButton
      href="Smart-Memorial.html#sec-ec39" 
    >
      Solicítelo aquí
    </StyledButton>
  </Container>

  {/* Columna derecha: Imagen */}
  <Box
    sx={{
      flex: 1,
      position: "relative",
      height: "100%", // La altura de la sección
      backgroundImage: `url(${portada})`, // Reemplaza con tu imagen
      backgroundSize: "cover", // Escala la imagen para cubrir el espacio
      backgroundPosition: "center", // Centra la imagen
     
    }}
  />
</section>
 <section >

      <div className="galeria-container">
        {images.map((image, index) => (
          <div className="image-wrapper" key={index}>
            <img className="image" src={image} alt={`Imagen ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
    <ParallaxSectiontres  id="contact">
    <ParallaxImagedos alt="Parallax Background" />
    <Container maxWidth='sm'>
    <Box
    
      component="form"
      onSubmit={handleSubmit}
      sx={{
        position:'relative',
        marginTop:'40px',
        display: "flex",
        flexDirection: "column",
        gap: 2,
        p: 3,
        backgroundColor: "rgba(255, 255, 255, 0.7)",
        borderRadius: 2,
        boxShadow: 3,
      }}
    >
      <Typography variant="h4" align="center" gutterBottom sx={{fontFamily:'Poppins', fontWeight:'600',fontSize:'1.2rem',color:'#585857'}}>
        Contáctenos
      </Typography>

      {success && (
        <Alert severity="success">¡Mensaje enviado correctamente!</Alert>
      )}
      {error && <Alert severity="error">{error}</Alert>}

      <TextField
      sx={{background:"#ffff", fontFamily:'Poppins', color:'#585857'}}
        label="Nombre"
        name="name"
        value={formData.name}
        onChange={handleChanges}
        fullWidth
        required
      />

      <TextField
      sx={{background:"#ffff", fontFamily:'Poppins', color:'#585857'}}
        label="Email"
        name="email"
        type="email"
        value={formData.email}
        onChange={handleChanges}
        fullWidth
        required
      />

      <TextField
      sx={{background:"#ffff", fontFamily:'Poppins', color:'#585857'}}
        label="Mensaje"
        name="message"
        value={formData.message}
        onChange={handleChanges}
        multiline
        rows={4}
        fullWidth
        required
      />

      <Box display="flex" justifyContent="center" alignItems="center">
        {isSubmitting ? (
          <CircularProgress />
        ) : (
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{ px: 4, fontFamily:'Poppins', textTransform:'capitalize'  }}
          >
            Enviar
          </Button>
        )}
      </Box>
    </Box>
    </Container>
    </ParallaxSectiontres>
        
        </>
    )
}


// Styled Components
const ParallaxSection = styled(Box)({
    position: "relative",
    height: "80vh",
    overflow: "hidden",
    '@media (max-width: 850px)': {
    overflow:'auto'
    },
  });
  
  const ParallaxImage = styled("div")({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${fondo})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed",
    '@media (max-width: 850px)': {
    backgroundAttachment: "scroll", // Imagen estática
   
  }, // Imagen fija
  });
  
  const FixedContent = styled(Container)({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "#fff",
    zIndex: 2,
    "& .title": {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "2.5rem",
      marginBottom: "0",
      '@media (max-width: 750px)': {
        fontSize: "1.5rem",
      },
    },
    "& .description": {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "1.2rem",
      marginTop:'0.7rem',
      '@media (max-width: 750px)': {
        fontSize: "0.8rem",
        marginTop:'9px'
      },
    },
    "& .cta-button": {
      fontFamily:'Poppins',
      backgroundColor: "#fff",
      color: "#694B81",
      fontWeight: "500",
      textTransform: "capitalize",
      borderRadius: "50px",
      padding: "10px 20px",
      transition: "all 0.3s ease",
      margin:'20px',
      "&:hover": {
        backgroundColor: "#694B81",
        color: "#fff",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      },
      '@media (max-width: 750px)': {
        fontSize: "0.8rem",
       
      },
    },
  });

  const StyledButton = styled(Button)({
    fontFamily:'Poppins',
    backgroundColor: "#694B81",
    color: "#ffff",
    fontWeight: "500",
    textTransform: "capitalize",
    borderRadius: "50px",
    padding: "10px 20px",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#ffff",
      color: "#694B81",
      border:'1px solid #694B81',
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    },
    })
    
    const StyledTitle = styled(Typography)({
        fontFamily: "Poppins",
        fontWeight: 600,
        color: "#585857",
        textAlign: "center",
        marginBottom:'0px',
        '@media (max-width: 750px)': {
            fontSize: "1.3rem",
           
          },
      });

      /* portada */

  const StyledTitleportada = styled(Typography)({
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#585857",
    textAlign: "center",
    marginBottom:'40px',
    '@media (max-width: 750px)': {
        fontSize: "1.2rem",
      },
  });
  
  const StyledTitleTextportada = styled(Typography)({
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#585857",
    textAlign: "justify",
    marginBottom:'40px',
    '@media (max-width: 750px)': {
      fontSize: "0.8rem",
      padding:'0px 10px'
    },
  });
  const ParallaxSectiontres = styled(Box)({
    position: "relative",
    height: "80vh",
    overflow: "hidden",
    '@media (max-width: 850px)': {
    overflow:'auto' // Para asegurar que la posición cambie
  },
  });
  const ParallaxImagedos = styled("div")({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${fondo})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed", // Imagen fija
    '@media (max-width: 850px)': {
    backgroundAttachment: "scroll", // Imagen estática
    
  },
  });
  