import React, { useEffect, useState } from "react";
import axios from "axios";
import rateLimit from "axios-rate-limit";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";

/* libreria UI */
import { Modal, Box, Grid } from "@mui/material";
import Skeleton from '@mui/material/Skeleton';

/* react */
import { FaRegUser } from "react-icons/fa";
import { IoSettings } from "react-icons/io5";
import { IoMdLogOut } from "react-icons/io";
import { BiHomeHeart } from "react-icons/bi";
import { PiPencilCircle } from "react-icons/pi";
import { BsQrCodeScan } from "react-icons/bs";
import { MdOutlineDataSaverOff } from "react-icons/md";



const http = rateLimit(axios.create(), {
  maxRequests: 1,
  perMilliseconds: 3000,
});

export const NavTareasSucursal = ({ user, sucursal }) => {
  const { Token, setAdminUserEmpresaToken } = useUserEmpresa();
  const { id } = useParams();
  const navigate = useNavigate();
  const [tareas, setTareas] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para manejar la carga
  const [error, setError] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  const handleLogout = () => {
    sessionStorage.removeItem("Token"); // Elimina el token de sessionStorage
    setAdminUserEmpresaToken(null); // Elimina el token del contexto
    navigate("/Inicio-Sesion-Empresas"); // Redirige al usuario
  };  

  const handleNavigateReporte = () => {
    navigate(`/sucursal/${id}/bd/tarea`, {
        state: { user, sucursal, Token, id },
    });
};

  const handlePerfilUser = () => {
    navigate(`/sucursal/perfil/${id}`, { state: { sucursal, user } });
  };

  useEffect(() => {
    const fetchTareas = async () => {
      if (!sucursal?.id || !Token) return;
      try {
        const response = await http.get(
          `${backendURL}/api/tareasuscripcionSucursal/${sucursal.id_empresa}`, // Usamos la variable de entorno aquí
          {
            headers: {
              Authorization: `Bearer ${Token}`, // Pasa el token de autenticación
            },
          }
        );

        setTareas(response.data.empresas); // Almacena los datos obtenidos en el estado
        setLoading(false); // Cambia el estado de carga
      } catch (error) {
        setError("Error al cargar las tareas"); // Manejo de error
        setLoading(false); // Cambia el estado de carga
      }
    };

    fetchTareas(); // Llamada a la función para obtener los datos
  }, [sucursal.id_empresa, Token]); // Se ejecutará cuando `empresa.id` o `Token` cambien

  if (loading) {
    return(
      <>
      <Box display="flex" justifyContent="center" alignItems="center"  >
        <Skeleton variant="circular" width={30} height={30} sx={{marginRight:'10px'}}  />
      <Skeleton variant="text" width="50%" height="50px" />  
  </Box>
  <Box display="flex" justifyContent="center" alignItems="center"  >
        <Skeleton variant="circular" width={30} height={30} sx={{marginRight:'10px'}}  />
      <Skeleton variant="text" width="50%" height="50px" />  
  </Box>
  <Box display="flex" justifyContent="center" alignItems="center"  >
        <Skeleton variant="circular" width={30} height={30} sx={{marginRight:'10px'}}  />
      <Skeleton variant="text" width="50%" height="50px" />
  </Box>
  <Box display="flex" justifyContent="center" alignItems="center"  >
        <Skeleton variant="circular" width={30} height={30} sx={{marginRight:'10px'}}  />
      <Skeleton variant="text" width="50%" height="50px" />
  </Box>
  
   </>)
  }
  const handleHistorialQR = () => {
    navigate(`/sucursal/${id}/qr`, { state: { sucursal, user } });
  };

  return (
    <Box sx={StyleBoxBlanco}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Link to={`/Inicio-Sucursal/${id}`}>
            <div className="navmenusucursal">
              <div style={{paddingRight:'10px'}}>
                <BiHomeHeart className="SvgMenuAdmiEmpresahome" />
              </div>
              <div className="nombretareaempresa">
                <h3>Inicio</h3>
              </div>
            </div>
          </Link>
        </Grid>
        <Grid item xs={12}>
          <div className="navmenusucursal" onClick={handlePerfilUser}>
            <div style={{paddingRight:'10px'}}>
              <FaRegUser className="SvgMenuAdmiEmpresa" />
            </div>
            <div className="nombretareaempresa">
              <h3>Perfil</h3>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <div className="navmenusucursal" onClick={handleHistorialQR}>
          <div style={{paddingRight:'10px'}} >
          <BsQrCodeScan   className="SvgMenuAdmiEmpresa"/> 
                      </div>
                      <div className="nombretareaempresa">
                        <h3>Qr Sucursal</h3>
                      </div>
                   </div>
          </Grid>
        <Grid item xs={12}>
          <div className="navmenusucursal">
            <div style={{paddingRight:'10px'}}>
              <IoSettings className="SvgMenuAdmiEmpresa" />
            </div>
            <div className="nombretareaempresa">
              <h3>Soporte</h3>
            </div>
          </div>
        </Grid>

        {tareas.length > 0 ? (
  tareas.map((tarea) => (
    <Grid item key={tarea.id} xs={12}>
      {tarea.nombre_tarea === "Marca" ? (
        <div className="navmenusucursal">
          <div style={{ paddingRight: '10px' }}>
            <PiPencilCircle className="SvgMenuAdmiEmpresa" />
          </div>
          <div className="nombretareaempresa">
            <h3>{tarea.nombre_tarea}</h3>
          </div>
        </div>
      ) : tarea.nombre_tarea === "Reporte" ? (  // Nueva condición para "Reporte"
       <div className="navmenusucursal" onClick={handleNavigateReporte}>
          <div style={{ paddingRight: '10px' }}>
          <MdOutlineDataSaverOff className="SvgMenuAdmiEmpresa" />
          </div>
          <div className="nombretareaempresa">
            <h3>{tarea.nombre_tarea}</h3>
          </div>
        </div>
      ) : (
        <div className="navmenusucursal">
          <div>
          <MdOutlineDataSaverOff className="SvgMenuAdmiEmpresa" />
          </div>
          <div className="nombretareaempresa">
            <h3>{tarea.nombre_tarea}</h3>
          </div>
        </div>
      )}
    </Grid>
  ))
) : (
          <div></div>
        )}
      </Grid>
      <Grid item xs={12} onClick={handleLogout} sx={{
        marginTop:'20px'}
      }>
        <div className="navmenusucursal">
          <div style={{paddingRight:'10px'}}>
            <IoMdLogOut className="SvgMenuAdmiEmpresa" />
          </div>
          <div className="nombretareaempresa">
            <h3>Cerrar Sesión</h3>
          </div>
        </div>
      </Grid>
    </Box>
  );
};

const StyleBox = {
  width: "90%",
  border: "1px solid #F0EDF3",
  marginTop: "30px",
  borderRadius: "10px",
  p: 2,
  background: "#F0EDF3",
};

const StyleBoxBlanco = {
  width: "95%",
  border: "1px solid #FFFF",
  margin: "0px",
  borderRadius: "10px",
  paddingLeft: "10px",
  background: "#FFFF",
  margin: "50px 0px",
};
