import React, { useState, useEffect } from "react";
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import IconButton from '@mui/material/IconButton';
import { useNavigate } from "react-router-dom";
import { FiAlertCircle } from "react-icons/fi";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from "react-router-dom";
import InputLabel from '@mui/material/InputLabel';
import "../../Style/login/login.css"
import { useAuth } from "../context/usecontext";
import { useUsers } from "../context/userscontexts";
import { styled } from "@mui/system";
import { CircularProgress } from '@mui/material';
import logoinstagram from "../../Image/generales/siguenos-en-instagram.png"
import AOS from 'aos';
import 'aos/dist/aos.css';

/* imagenes */
import uno from "../../Image/generales/1.png"
import dos from "../../Image/generales/2.png"
import tres from "../../Image/generales/3.png"
import cuatro from "../../Image/generales/4.png"
import cinco from "../../Image/generales/5.png"
import seis from "../../Image/generales/6.png"
import ocho from "../../Image/generales/8.png"
import nueve from "../../Image/generales/moradoluto.png"

/* video */
import gif from "../../Image/video/memorial-intro.gif"
import celular from "../../Image/generales/celular.png"


export default function Login({ onLogin }) {
  const [showPassword, setShowPassword] = React.useState(false);
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { setAdminAuthToken } = useAuth();
  const { setUserToken, setUserInformation } = useUsers(); 
  const [userData, setUserData] = useState(null);
  const backendURL = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const handleClick = () => {
    navigate('/solicitarmemorial'); // Navegar a la ruta de inicio
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Desplazamiento suave
    });
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.currentTarget);
    const email = formData.get('email');
    const password = formData.get('password');

    if (!validateEmail(email)) {
      setEmailError('El correo electrónico no es válido');
      setLoading(false);
      return;
    } else {
      setEmailError('');
    }

    try {
      const response = await fetch(`${backendURL}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        setPasswordError('El correo o contraseña está incorrecta');
        setLoading(false);
        return;
      }

      const data = await response.json();
     
      const { token, user, marca_empresa } = data;
      const userId = user.id;
      
      if (!data || !data.token || !data.user) {
        setPasswordError('El servidor no respondió como se esperaba.');
        setLoading(false);
        return;
      }

      if (user.id_rol == 1) {
        sessionStorage.setItem('adminToken', token);
        setAdminAuthToken(token);
        sessionStorage.setItem('onLogin', 1);
        onLogin(1);
        navigate('/Administrador');
      } else {
        const clientTokenData = { token, userId };
        sessionStorage.setItem('ClientToken', JSON.stringify(clientTokenData));
        setUserToken(clientTokenData);
        setUserInformation(marca_empresa); 
        sessionStorage.setItem('onLogin', 2);
        onLogin(2);
        navigate(`/usuario/${userId}`);
      }
    } catch (error) {
      console.error('Error al procesar la solicitud:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Comprueba si el usuario ya ha iniciado sesión al cargar el componente
    const token = sessionStorage.getItem("adminToken");
    if (token) {
      navigate("/Administrador");
    }
    else {
      navigate("/SmartPersonas")
    }
  }, []);

  useEffect(() => {
    // Comprueba si el usuario ya ha iniciado sesión al cargar el componente
    const token = sessionStorage.getItem("ClientToken");
    if (token && userData) { // Verificamos si hay un token y datos de usuario disponibles
      navigate(`/usuario/${userData.user.id}`);
    }
  }, [userData]);

  const validateEmail = (email) => {
    // Expresión regular para validar correo electrónico
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  return (
    <>

      <Container component="main" maxWidth="xs" sx={{
        paddingLeft: '0px',
        paddingRight: '0px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (min-width: 600px)': {
          paddingLeft: '0px',
          paddingRight: '0px',
        },
      }}>

        <Box sx={{
          width:'100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginBottom: "30px",
          position:"relative",
          zIndex:"11"

        }}>

          <img src={gif} alt="smart memorial" style={{width:'100%'}} />

        </Box>
<section className="botonsolicitarhomememorial">
           
            <div className="contienenbotonssolicitud">
            <button onClick={handleClick} data-aos="fade-down">
            Solicitar memorial
              </button>
              <section className="listaservicio">
                <div className="tituloservicioincluido" >
                  <h2 data-aos="fade-down">Servicio incluye</h2>
                </div>
                <div className="infolistaincluido" data-aos="fade-down">
                  <div className="iteminfoservicio" >
                    <div className="contieneimagensmart">
                      <img src={uno} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                      <span className="textoitemservicio">Memorial virtual para sus seres queridos</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={dos} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Creación de grupo familiar de hasta 5 personas</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={tres} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">2 Placas metálicas con QR para lápida o ánfora</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={cuatro} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Subir su historia, imágenes y videos</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={cinco} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Dejar mensajes y condolencias</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={seis} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Editar el memorial cuando desee</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={cinco} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Creación de eventos conmemorativos</span>
                  </div>
                  <div className="iteminfoservicio">
                    <div className="contieneimagensmart">
                      <img src={ocho} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Compartir memoriales con familiares y amigos</span>
                  </div>
                  <div className="iteminfofinalservicio">
                    <div className="contieneimagensmart">
                      <img src={nueve} alt="smart memorial" title="smart memorial" className="imagensmartmemorial" width="100%" height="100%"/>
                      </div>
                    <span className="textoitemservicio">Creación de Obituario </span>
                  </div>
                </div>

              </section>
              
            </div>
            
            <section className="buttonmemorialejemplo" data-aos="fade-down">
             <a className="nulla"  href="https://www.smartmemorial.cl/familiar/group/99">
              <button> 
                <div className="imagenbuttonmemorialejemplo">
                  <img src={celular} alt="smart memorial" />
                  </div>
                   <div className="infobotonejemplomemorial">
                     <p> Ver ejemplo</p>
                      <p> de un memorial</p>
                      <p>familiar</p>
                      </div> 
                      </button>
                      </a>
            </section>
            <section className="botonsegundorosa" >
            <button onClick={handleClick} data-aos="fade-down">
            Solicitar memorial
              </button>
            </section>
          </section>
        <CssBaseline />
        <Box
          sx={{
            marginTop:'30px',
            width: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          data-aos="fade-down"
        >

          <Typography component="h1" variant="h5" sx={{ fontSize: '1.5rem', fontWeight: '600', color: '#D26999', fontFamily:"Poppins", marginBottom:"-10px" }}>
            Iniciar Sesión
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1, width: '100%' }}>
            <Box className="contiene-inputlogin" sx={{ position: 'relative', marginTop: '25px', width: '100%' }}>
              <InputLabel className="label-login" htmlFor="email" sx={{ position: 'absolute', top: -5, color: '#585857', marginBottom: '0px', fontSize: "0.8rem", left: "25px" }}>
                Correo electrónico
              </InputLabel>
              <StyledTextField
                margin="normal"
                required
                id="email"
                name="email"
                autoComplete="email"
                className={`input-login ${emailError && 'input-error'}`}
                InputProps={{
                  sx: { borderRadius: '50px', border: `1px solid ${passwordError ? '#D26999' : '3px solid ##F0EDF3'}`, fontSize: "0.8rem", color: "#694B81" },
                  style: { border: emailError ? '1px solid #D26999' : '3px solid ##F0EDF3', }
                }}
                sx={{ marginBottom: '10px' }}
              />

            </Box>

            <Box className="contiene-inputlogin" sx={{ position: 'relative', marginTop: '10px', width: '100%' }}>
              <InputLabel className="labellogin" htmlFor="password" sx={{ position: 'absolute', top: -5, color: '#585857', marginBottom: '0px', fontSize: "0.8rem", left: "25px" }}>
                Contraseña
              </InputLabel>
              <StyledTextFields
                className={`input-login ${passwordError && 'input-error'}`}
                margin="normal"
                required
                name="password"
                id="password"
                autoComplete="current-password"
                type={showPassword ? "text" : "password"}
                InputProps={{
                  sx: { borderRadius: '50px', border: `1px solid ${passwordError ? '#D26999' : '3px solid ##F0EDF3'}`, fontSize: "0.8rem", color: "#694B81" },
                  endAdornment: (
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      className="password-toggle-btn"
                    >
                      {showPassword ? <FaRegEyeSlash className="eye-icon" /> : <FaRegEye className="eye-icon" />}
                    </IconButton>
                  )
                }}

              />

            </Box>
            <div className="contiene-error-mensaje">  {passwordError && <span className="errormessagelogin"><FiAlertCircle className='icon-error' />{passwordError}</span>} </div>
            <Box sx={{ width: '80%', display: 'flex', alignItems: 'flex-start', position: 'relative', }}>
              <div className="aceptar-terminos">
                <FormControlLabel

                  control={
                    <div className="unoterminos">
                      <label className="container" htmlFor="checkbox">
                        <input type="checkbox" name="checkbox" id="checkbox" />
                        <svg viewBox="0 0 64 64" height="1.8em" width="1.8em">
                          <path
                            d="M 0 16 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 16 L 32 48 L 64 16 V 8 A 8 8 90 0 0 56 0 H 8 A 8 8 90 0 0 0 8 V 56 A 8 8 90 0 0 8 64 H 56 A 8 8 90 0 0 64 56 V 16"
                            pathLength="575.0541381835938"
                            className="path"
                          ></path>
                        </svg>
                      </label>
                    </div>
                  }
                  label="Mantener sesión iniciada"
                  sx={{
                    width: '100%',
                    color: '#585857',
                    '& .MuiTypography-root': {
                      fontSize: '0.7rem',

                    }
                  }}
                />
              </div>
              </Box>
            <div className="contienenbotons-login">
              <button
                type="submit"

                variant="contained"
                sx={{ mt: 3, mb: 2, background: '#694B81', borderRadius: '40px', padding: '10px 60px', fontWeight: '600', marginBottom: '30px' }}
              >
                {loading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : 'Iniciar Sesión'}
              </button>

              <Grid container>
                <Grid item xs>
                  <Link to="/restaurarcontraseña" className="link" >
                    <h5 className="Olvidar">He olvidado mi contraseña</h5>
                  </Link>
                </Grid>

              </Grid>
             </div>
          </Box>

          <section className="registrarscion">
            <span className="negroregistroinfo">¿No tiene una cuenta?</span><Link to="/Registro"><span className="registrorosainfo">Regístrese aquí</span></Link>
          </section>
            <section className="botoninstagram">
            <a className="nulla" target="black" href="https://www.instagram.com/smart.memorial/">
            <div className="imagenintagram">
              <img src={logoinstagram} alt="smart memorial"/>
            </div></a>
            </section>
          
        </Box>

      </Container>

    </>
  );
}


const StyledTextField = styled(TextField)({
  width: '100%',

  color: "#694B81",
  fontFamily: "Poppins",
  fontSize: '0.9rem',
  fontWeight: "500",
  '& .MuiInputBase-root': {
    transition: 'all 0.3s ease',
    color: "#694B81",
    fontFamily: "Poppins",
    fontSize: '0.9rem',
    fontWeight: "500",
  },
  '& .MuiInputBase-root.Mui-focused': {
    border: ' 1px solid #585857', // Cambia esto al color que quieras

  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: ' 1px solid #585857',  // Cambia esto al color que quieras
  },
});

const StyledTextFields = styled(TextField)({
  width: '100%',
  color: "#694B81",
  fontFamily: "Poppins",
  fontSize: '0.9rem',
  fontWeight: "500",

  '& .MuiInputBase-root': {
    transition: 'all 0.3s ease',
    color: "#694B81",
    fontFamily: "Poppins",
    fontSize: '0.9rem',
    fontWeight: "500",
  },
  '& .MuiInputBase-root.Mui-focused': {
    bborder: ' 1px solid #585857', // Cambia esto al color que quieras
    color: "#694B81",
  },
  '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: ' 1px solid #585857',  // Cambia esto al color que quieras
    color: "#694B81",
  },
});