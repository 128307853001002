import React from "react";
import { useEffect, useState, useRef  } from 'react'
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import { useNavigate } from "react-router-dom";
import { Container, Box, CircularProgress, Modal,Snackbar  } from "@mui/material";
import { Navbar } from "../nav-fotter/navbar";
import { BsEnvelopePaperHeartFill } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";
import "../../Style/paginavista/DiseñodosObituario.css"
import { BiSolidBookmarkHeart } from "react-icons/bi";
import { BsPersonHearts } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";
import { IoPlayCircleOutline } from 'react-icons/io5';
import MuiAlert from '@mui/material/Alert';
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import { FaArrowUpFromBracket } from "react-icons/fa6";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { RiContactsBook2Line } from "react-icons/ri";

const http = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 100 });

export const DiseñoObituarioDos = ({marcas, id, obituario, memorialData, grupoFamiliar, edad, hayTerminos}) => {
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
    const buttonRef = useRef(null);
    const messagesSectionRef = useRef(null);
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [openVideoModal, setOpenVideoModal] = useState(false);
    const [openTerminosModal, setOpenTerminosModal] = useState(false);
    const [videoURL, setVideoURL] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [comentarios, setComentarios] = useState([]);
    const [visibleComments, setVisibleComments] = useState([]);
    const [showMoreCount, setShowMoreCount] = useState(3);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [formData, setFormData] = useState({
                   nombre: '',
                   titulo: '',
                   telefono:'',
                   email:'',
                   descripcion: '',
                   imagen: null
               });

               const handleShowMore = () => {
                const newCount = showMoreCount + 3;
                setVisibleComments(prevComments => {
                    const newComments = comentarios.slice(0, newCount); // Usa comentarios ordenados
                    return newComments;
                });
                setShowMoreCount(newCount);
                setShowMoreButton(comentarios.length > newCount); // Usa comentarios ordenados
            };

       useEffect(() => {
                  const handleScroll = () => {
                      if (messagesSectionRef.current && buttonRef.current) {
                          const messagesSectionTop = messagesSectionRef.current.getBoundingClientRect().top;
                          const messagesSectionBottom = messagesSectionRef.current.getBoundingClientRect().bottom;
                          const buttonHeight = buttonRef.current.offsetHeight;
          
                          setIsButtonVisible(
                              messagesSectionTop < window.innerHeight && messagesSectionBottom > buttonHeight
                          );
                      }
                  };
          
                  window.addEventListener('scroll', handleScroll);
                  return () => {
                      window.removeEventListener('scroll', handleScroll);
                  };
              }, []);
              const handleChange = (e) => {
                const { name, value } = e.target;
            
                if (name === "telefono") {
                    // Permitir solo números
                    if (!/^\d*$/.test(value)) return;
                    // Validar longitud mínima de 8 dígitos (opcional)
                    if (value.length < 8) {
                        console.log("El número de teléfono debe tener al menos 8 dígitos.");
                    }
                }
            
                if (name === "email") {
                  setFormData((prevFormData) => ({
                      ...prevFormData,
                      [name]: value
                  }));
              }
            
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: value
                }));
            };
       
            const handleImageChange = (e) => {
                setFormData(prevFormData => ({
                    ...prevFormData,
                    imagen: e.target.files[0]
                }));
            };
            const Alert = React.forwardRef(function Alert(props, ref) {
              return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
          });
            const handleVideoModalOpen = (e) => {
                e.preventDefault();
                setOpenVideoModal(true);
            };

            const handleVideoModalClose = () => {
                setOpenVideoModal(false);
            };

            const handleVideoURLChange = (e) => {
                setVideoURL(e.target.value);
            };

            const handleSaveVideoURL = () => {
                if (videoURL) {
                const videoLink = `<a href="${videoURL}" target="_blank"> <IoPlayCircleOutline /> Ver Video</a>`;
                const updatedDescription = formData.descripcion + videoLink;
                setFormData(prevFormData => ({
                    ...prevFormData,
                    descripcion: updatedDescription
                }));
                setVideoURL('');
                setOpenVideoModal(false);
                }
            }; 
            
            const handleTerminosChange = () => {
                setFormData({ ...formData, aceptaTerminos: !formData.aceptaTerminos });
              };
          
              const handleOpenTerminosModal = () => {
                setOpenTerminosModal(true);
            };
          
            const handleCloseTerminosModal = () => {
                setOpenTerminosModal(false);
            };

            const handleSubmit = async (e) => {
                e.preventDefault();
    
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
                  if (!emailRegex.test(formData.email)) {
                     setSnackbarMessage("Por favor, ingresa un correo electrónico válido.");
                     setSnackbarSeverity('error');
                  setOpenSnackbar(true);
                      return;
                  }
    
                 
                 // Validación de términos solo si existen
                 if (hayTerminos && !formData.aceptaTerminos) {
                  setSnackbarMessage("Debes aceptar los términos y condiciones antes de enviar.");
                  setSnackbarSeverity('error');
                  setOpenSnackbar(true);
                  return;
              }
                if (!formData.email) {
                    setSnackbarMessage("Debes ingresar correo electrónico.");
                    setSnackbarSeverity('error');
                    setOpenSnackbar(true);
                    return;
                }
                if (!formData.telefono) {
                    setSnackbarMessage("Debes ingresar telefono.");
                    setSnackbarSeverity('error');
                    setOpenSnackbar(true);
                    return;
                }
                if (!formData.nombre) {
                    setSnackbarMessage("Debes ingresar nombre.");
                    setSnackbarSeverity('error');
                    setOpenSnackbar(true);
                    return;
                }
                if (!formData.titulo) {
                    setSnackbarMessage("Debes ingresar título.");
                    setSnackbarSeverity('error');
                    setOpenSnackbar(true);
                    return;
                }
                if (!formData.descripcion) {
                    setSnackbarMessage("Debes ingresar descripción.");
                    setSnackbarSeverity('error');
                    setOpenSnackbar(true);
                    return;
                }
              
                // Desestructurar datos del formulario
                const { nombre, titulo, telefono, email, descripcion, imagen } = formData;
        
                const formDataToSend = new FormData();
                formDataToSend.append('telefono', telefono);
                formDataToSend.append('email', email);
                formDataToSend.append('titulo', titulo);
                formDataToSend.append('creador', nombre);
                formDataToSend.append('mensaje', descripcion);
                formDataToSend.append('imagen', imagen);
                formDataToSend.append('id_memorial', memorialData.id);
        
                try {
                    setenviandoAlert(true);
                    const response = await axios.post(`${backendURL}/api/comentarioStores`, formDataToSend);
                    setComentarios(prevComentarios => [...prevComentarios, response.data]);
                    setAlertMessage('Su mensaje se a enviado.');
                    setSuccessAlertOpen(true);
                    await fetchComentarios();
                    setTimeout(() => {
                      setSuccessAlertOpen(false);
                      setFormData({
                          nombre: '',
                          telefono:'',
                          email:'',
                          titulo: '',
                          descripcion: '',
                          imagen: null
                      });
                  }, 5000);
                } catch (error) {
                    console.error('Error al enviar el comentario:', error);
                    setAlertMessage('Error al enviar el mensaje');
                    setErrorAlertOpen(true);
                    setTimeout(() => setErrorAlertOpen(false), 5000);
                } finally {
                    setOpenModal(false);
                    setenviandoAlert(false);
                }
            };
        
             /* comentarios memorial */
             const fetchComentarios = async () => {
              try {
                  const response = await axios.get(`${backendURL}/api/get/comentariosmemorial/${id}`);
                  const comentariosVisibles = response.data.filter(comentario => comentario.visible === '1');
                  
                  // Ordena los comentarios por fecha de manera descendente
                  const sortedComentarios = comentariosVisibles.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
                  
                  setComentarios(sortedComentarios);
                  setVisibleComments(sortedComentarios.slice(0, showMoreCount));
                  setShowMoreButton(sortedComentarios.length > showMoreCount); 
              } catch (error) {
                  console.error('Error al obtener comentarios:', error);
              }
          };
          
          // Llama a esta función en el useEffect para obtener comentarios al cargar la página
          useEffect(() => {
              fetchComentarios();
          }, [id]);

        useEffect(() => {
          if (comentarios) {
              const visible = comentarios.filter(comentario => comentario.visible === '1');
              setVisibleComments(visible.slice(0, showMoreCount));
              setShowMoreButton(visible.length > showMoreCount);
          }
      }, [comentarios, showMoreCount]);
  
        const handleClickvistas = (memorialId) => {
            const state = { familia: grupoFamiliar, memorial: memorialId }; 
            navigate(`/familiar/memorial/${memorialId}`, { state });
        };
        const handleShare = () => {
            // Lógica para compartir el memorial, por ejemplo:
            const shareURL = `/Obituario/${obituario.id}`;
            window.navigator.share({ url: shareURL });
        };
        const handleClickvista = () => {
            // Lógica para compartir el memorial, por ejemplo:
            const state = { familia: grupoFamiliar, memorial: memorialData }; 
            navigate(`/familiar/memorial/${memorialData.id}`, { state });
        };
        useEffect(() => {
            AOS.init({ duration: 1000 });
        }, []);
        
        const handleScroll = () => {
            if (ref.current) {
            const rect = ref.current.getBoundingClientRect();
            if (rect.top <= window.innerHeight && rect.bottom >= 0) {
                setIsVisible(true);
            }
            }
        };
        
        useEffect(() => {
            window.addEventListener('scroll', handleScroll);
            return () => window.removeEventListener('scroll', handleScroll);
        }, []);

        const handleOpenModal = () => setOpenModal(true);
        const handleCloseModal = () => {
            setOpenModal(false);
            setenviandoAlert(false);
        };
        const handleShares = (url) => {
            if (navigator.share) {
            navigator.share({
                title: 'Comparte el recuerdo del video',
                url: url
            }).catch((error) => {
                console.error('Error al compartir el video:', error);
            });
            } else {
            alert('La funcionalidad de compartir no está soportada en este navegador.');
            }
        };

        const getColorByFondo = (fondo) => {
            switch (fondo) {
            case 'naturalezaAustral':
                return '#E1EDDD';
            case 'atardecerVerano':
                return '#F9E7D7';
            default:
                return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#E1DBE6';
            }
        };
        const borderColor = getColorByFondo(memorialData.fondo);
        const backgroundColor = getColorByFondo(memorialData.fondo);
            const styleHr = {
                width:'98%',
                border:'1px solid #ffff',
                background:'#ffff',
                height:'3px',
                borderRadius:'60px',
                marginBottom:'10px',
                animation: isVisible ? 'growHr 1s ease-out' : '',

            }
        const styleHrinicio = {
            width:'100%',
            border:'1px solid rgb(255 255 255 / 16%)',
            background:'rgb(255 255 255 / 16%)',
            height:'1px',
            borderRadius:'60px',
            margin:'0px',
            animation: isVisible ? 'growHr 1s ease-out' : '',
        };
        const styles = {
            titleStyle: {
                color: marcas?.color_2 && marcas.color_2 !== 'undefined' ? marcas.color_2 :'#694B81',
                fontSize:'1rem',
                fontWeight:'600',
                marginTop:'5px',
                marginBottom:'0px',
                textAlign:'center'
            
            
            },
            titleFecha: {
                color: marcas?.color_1 && marcas.color_1 !== 'undefined' ? marcas.color_1 :'#694B81',
                fontSize:'0.8rem',
                fontWeight:'600',
                marginTop:'2px',
                textAlign:'center'
                },
            fondomensajeStyle: {
            background: (() => {
                switch (memorialData?.fondo) {
                case 'naturalezaAustral':
                    return '#68A354';
                case 'atardecerVerano':
                    return '#E28939';
                default:
                    return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
                }
            })(),
            },
            fondosStyle: {
            background: (() => {
                switch (memorialData?.fondo) {
                case 'naturalezaAustral':
                    return '#F3F8F1';
                case 'atardecerVerano':
                    return '#FDF5EF';
                default:
                    return (marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 :'#F0EDF3';
                }
            })(),
            },
            fondosTotal: {
                background: marcas?.color_4 && marcas.color_4 !== 'undefined' ? marcas.color_4 :'#F0EDF3',
                display:'flex',
                alignItems:'center', 
                justifyContent:'center',
                flexDirection:'column'
                
            },
            titulooscuroStyle: {
            color: (() => {
                switch (memorialData?.fondo) {
                case 'naturalezaAustral':
                    return '#27632D';
                case 'atardecerVerano':
                    return '#A04424';
                default:
                    return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 :'#D26999';
                }
            })(),
            },
            texto: {
            color: "#fff"
            },
            selectedIconStyle: {
        
            cursor: "pointer",
            width: '40px',
            height: '40px',
            color: '#fff',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: (() => {
                switch (memorialData?.fondo) {
                case 'naturalezaAustral':
                    return '#68A354';
                case 'atardecerVerano':
                    return '#E28939';
                default:
                    return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
                }
            })(),
            },
            iconStyle: {
            cursor: "pointer",
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            background: 'transparent',  // Aquí está el error corregido
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: (() => {
                switch (memorialData?.fondo) {
                case 'naturalezaAustral':
                    return '#68A354';
                case 'atardecerVerano':
                    return '#E28939';
                default:
                    return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
                }
            })(),
            },
            
        };
        
        const capitalizeFirstLetter = (string) => {
            if (!string) return ''; // Manejar el caso cuando la cadena es null o undefined
            return string.charAt(0).toUpperCase() + string.slice(1);
        };
        
        
        const formatDate = (dateString) => {
            const date = new Date(dateString);
            const day = date.getUTCDate().toString().padStart(2, '0');
            const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
            const year = date.getUTCFullYear();
            return `${day}/${month}/${year}`;
        };
        
        const getDayOfWeek = (dateString) => {
        const date = new Date(dateString + 'T00:00:00'); 
        const days = ['Domingo', 'Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
        return days[date.getDay()];
        };
        
        let parentescoArray;
        try {
        parentescoArray = JSON.parse(obituario.parentesco);
        } catch (e) {
        parentescoArray = []; // Manejar el error si no es un JSON válido
        }
        
        // Ahora puedes usar join si se convierte correctamente
        const parentescoString = parentescoArray.join(', ');
        
        const StyleFondo={
        backgroundImage: `url(${marcas?.fondo_memorial ? `${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}` : ''})`,
            backgroundSize: 'cover',           // Asegurar que cubra toda la sección
            backgroundPosition: 'center',      // Centrar la imagen
            width: '100%',
            height: 'auto',                   // Ajustar la altura de la sección
            
        }
    return(
       <section className="contienenpaginainiciousuario" style={StyleFondo}>
                   {marcas ? (
                   <Navbar
                   marcas={marcas}
                   color1={(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : ''}  // Proveer color por defecto
                   color2={(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  ''}
                   logomarca={`${backendURL}/${marcas?.logo}`}
                   />
                 ) : (
                   <Navbar
                   />
                 )}
         <Container component="main" maxWidth="xs" sx={{ width:'100%',paddingLeft:"0px", paddingRight:"0px",  background: marcas?.color_4 && marcas.color_4 !== 'undefined' ? marcas.color_4 :'#F0EDF3', ' @media (max-width: 700px)': {
                    marginTop: '0px',paddingLeft:"0px", paddingRight:"0px", 
                  },
                  '& .MuiContainer-root': {
                    paddingLeft:"0px", paddingRight:"0px", 
                  }
                  }}>

<Box
  sx={{
    width: "100%",
    height: '100%',
    position: "relative",
    overflow: "hidden",
  }}
>
{memorialData && (

<img src={`${backendURL}/imagenes/memoriales/${memorialData?.carpeta_perfil}/${memorialData?.imagen_perfil}`} alt="Recuerdo" className="imagen-obituario"
     />

)}
  
  <Box
    sx={{
      position: "absolute",
      bottom: 5,
      left: 0,
      width: "100%",
      height: "100px",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // Fondo negro con transparencia
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      color: "#fff", // Letras blancas
     
      textAlign: "center",
    }}
  >
    <div style={{ display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column'}}>
        <hr   
            ref={ref}
            style={styleHrinicio}
            className={`scrollFade ${isVisible ? 'scrollFadeVisible' : ''}`}
        />
        <h3  
        className={`animateFadeInUp ${isVisible ? 'scrollFadeVisible' : ''}`} 
        style={{color:'#ffff', fontSize:'0.9rem', marginBottom:'2px', fontStyle:'italic', fontWeight:'600'}} >
            {memorialData?.nombre}
        </h3>
    <div  className={`animateFadeInUp ${isVisible ? 'scrollFadeVisible' : ''}`} style={{ marginBottom:'10px', color:'#ffff', fontSize:'0.8rem', fontStyle:'italic'}}> {formatDate(memorialData?.fecha_nacimiento)} • {formatDate(memorialData?.fecha_defuncion)} </div>
    <hr  ref={ref}
          style={styleHrinicio}
          className={`scrollFade ${isVisible ? 'scrollFadeVisible' : ''}`}/>
    </div>

  </Box>
  </Box>
  <Box
            sx={{
              width:'100%',
              borderRadius:'10px',
             
              marginTop:'20px',
              display:'flex',
               alignItems:'center',
               justifyContent:'center',
               flexDirection:'column'
            }}>
              <hr style={styleHr}    ref={ref}  className={`scrollFade ${isVisible ? 'scrollFadeVisible' : ''}`}/>
                <div className="contieneinformacion-obituario-diseño-dos" data-aos="fade-down" >
                  <h4 style={{
                      fontWeight:'500',
                      fontStyle:'italic',
                      fontSize:'0.8rem',
                      margin:'5px' ,
                      color:'#585857',
                      textAlign:'center'  
                  }}>
                  Con profundo pesar, comunicamos el fallecimiento
                  </h4>
                  <h3 style={styles.titleStyle} > {memorialData?.nombre}</h3>
                  <div className="fechaobipersona" style={styles.titleFecha}> {formatDate(memorialData?.fecha_nacimiento)} • {formatDate(memorialData?.fecha_defuncion)} </div>
                  <span className="informacionfallecimientoobparteuno"> {obituario?.edad === '1' ? (
    <>
      A la edad de <p>{edad}</p>
    </>
  ) : null} 
  </span>
                  
                  <h4
                   style={{
                      fontWeight:'500',
                      fontStyle:'italic',
                      fontSize:'0.8rem',
                      margin:'5px', 
                      color:'#585857'   
                  }}> {capitalizeFirstLetter(obituario.descripcion)} </h4>
                </div>
                
                <hr style={styleHr}   ref={ref}   className={`scrollFade ${isVisible ? 'scrollFadeVisible' : ''}`}/>
             
            <div className="parentescoovit" data-aos="fade-down"    ref={ref}>
                <span> Fue {parentescoString}, quien despediremos en compañía de su familia y amigos.</span>
            </div>
            <div 
            data-aos="fade-down" 
            style={{
                marginBottom:'50px',
              marginTop:'20px',
              background: marcas?.color_3 && marcas.color_3 !== 'undefined' ? marcas.color_3 :'#F0EDF3',
              width:'100%',
              display:"flex",
              alignItems:'center',
              justifyContent:'center',
              flexDirection:'column'
  
            }}>
              <h5 style={{
                textAlign:'center',
                  fontSize:'0.75rem',
                  fontWeight:'500',
                  padding:'0px 10px',
                  fontStyle:'italic',
                  marginBottom:'0px',
                  color:'#585857' 
              }}>El velatorio se llevará a cabo el día {getDayOfWeek(obituario.fecha_desde)} {formatDate(obituario.fecha_desde)}  hasta el día {getDayOfWeek(obituario.fecha_hasta)} {formatDate(obituario.fecha_hasta)} en {obituario.direccion} el horario del cortejo funebre será a las  {obituario.hora_salida}.</h5>

              <h5 style={{
                textAlign:'center',
                  fontSize:'0.75rem',
                  fontWeight:'500',
                  padding:'0px 10px',
                  fontStyle:'italic', color:'#585857' 
              }}> Posteriormente, el funeral se realizará el día {getDayOfWeek(obituario.fecha_funeral)} {formatDate(obituario.fecha_funeral)} a las  {obituario.hora_funeral} en {memorialData?.cementerio}.</h5>
            </div>
            </Box>
            <section>
            {visibleComments && visibleComments.length > 0 && ( 
        <div className="titulodemomensaje" style={styles.titleStyle}>  <h1> Sus mensajes y recuerdos</h1> <h1> para compartir</h1></div>
       )}

<div className="contienentodoslosmensajedemodos" >
                    <section className="demorecuerdomensaje">

                     
                    {visibleComments && [...visibleComments]
                    
                    .slice(0, showMoreCount)
                    .map((comentario, index) => (
                       <>
                         { comentario.visible === '1' && (
                         <div key={index} style={{width:"100%"}}> 
                          <div className="contienencarddemomensaje"  style={styles.fondomensajeStyle} >
                                <div className="contienentextomensajedemo">
                                    <h1 style={styles.titulooscuroStyle}>{capitalizeFirstLetter(comentario.titulo)}</h1>
                                    <h2 style={styles.texto} >{comentario.mensaje ? (
    <DangerouslySetHTMLContent html={capitalizeFirstLetter(comentario.mensaje)} />
  ) : (
    <span></span>
  )}</h2></div>
                                <div className="quienloescriviodemo" style={styles.texto}><h3>{comentario.creador}</h3>-<p> {formatDate(comentario.fecha)}</p></div>
                                <div className="imagendemmensajedemo">
                                    {comentario.imagen && (
                                        <div className="imagendemmensajedemo">
                                            <img src={`${backendURL}/${comentario.imagen}`} alt="smart memorial" />
                                        </div>
                                    )}
                                </div>
                               
                            </div>
                            <section className="corazonmemorialdenmo">
                                <div className="containerheart">
                                    <div className="heart">
                                        <svg width="50" height="50" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z"/>
                                        </svg>
                                    </div>
                                    <div className="heart">
                                        <svg width="40" height="40" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z"/>
                                        </svg>
                                    </div>
                                    <div className="heart">
                                        <svg width="35" height="35" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z"/>
                                        </svg>
                                    </div>
                                </div>
                            </section>
                                </div>
                        )}
                     </>
                       ))}
                        <section>
                        {showMoreButton && (
            <button className="ver-mas-mensajes" style={styles.buttonmas} onClick={handleShowMore}>
              Mostrar más mensajes
            </button>
          )}
                        </section>
                        
                    </section>
                   
 </div>
    
    </section>
              
              <section
                         className={`enviarmensajeobituario ${isButtonVisible ? 'enviarmensajeobituario-visible' : 'enviarmensajeobituario-hidden'}`}
                        style={{
                            width:'99.5%',
                          display:"flex",
                          alignItems:"center",
                          justifyContent:"space-evenly",
                          flexDirection:"row",
                          border: `1px solid ${borderColor}`,
                          background: backgroundColor,
                        }}
                        ref={buttonRef}
                      >
                        <div className="imageniconoempresaobituario">
                         {marcas?.logo ? (
                            <img src={`${backendURL}/${marcas.logo}`} className='marcoimglogoempresademo' alt='Smart Memorial' title='SmartMemorial' />
                            ) : <>
                            </>}  </div>
                        <button className="enviomensajeobituariocompartir" onClick={handleOpenModal} style={styles.fondomensajeStyle} >
                        <BsEnvelopePaperHeartFill /> 
                        </button>
              
                        <button onClick={handleShare}   className="enviomensajeobituariocompartir" style={styles.fondomensajeStyle}   > <FaShareAlt /></button>
                        <button onClick={handleClickvista}   className="enviomensajeobituariocompartir" style={styles.fondomensajeStyle}   > <RiContactsBook2Line/></button>
                      </section>
                    
                      
                  </Container>

                  <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px",  height: '80vh', bgcolor: 'background.paper', boxShadow: 24, p: 1, borderRadius:'10px',  overflowY: 'auto', ' @media (max-width: 500px)': {
            width:"90%"
          } }}>
                    <section className="closemodal" onClick={handleCloseModal}> <IoClose /></section>
                    <section className="contienentodomesjeneviar">
                    <section className="titulodentromodalmensaje">  <IoIosMail style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}}/> <h2 style={{ fontSize:'1rem', color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}} id="modal-title">Dejar un mensaje</h2> </section>
                      <section className="subtituloinfomensaje"><span >Si deseas compartir un recuerdo en video, puedes incluir un enlace de video en tu mensaje. Simplemente haz clic en el botón "Agregar video", ingresa la URL del video que deseas compartir y selecciona "Guardar". El enlace aparecerá en tu mensaje como "Ver Video", permitiendo a otros ver tu video mientras leen tu mensaje. </span></section>
                  </section> 
                   <form>
                        <div className="formulariocoment">
                            <label>Ingrese su nombre</label>
                            <input type="text" name="nombre" style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}} value={formData.nombre} onChange={handleChange} className="desinput" />
                            <label>Telefono</label>
                            <input type="tel" name="telefono" style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}} value={formData.telefono} onChange={handleChange} className="desinput" />
                            <label>Correo Electrónico</label>
                            <input  type="email" name="email" style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}} value={formData.email} onChange={handleChange} className="desinput" />
                            <label>Título</label>
                            <input type="text" name="titulo" style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}} value={formData.titulo} onChange={handleChange} className="desinput" />
                            <label>Descripción</label>
                            <textarea  
                            style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}}
                            className="inputmesnajeneviar"
                            value={formData.descripcion}
                            onChange={handleChange}
                            name="descripcion"
                            id="descripcion"
                            />
                            <button className="videodescripcionmensaje"  onClick={handleVideoModalOpen}>    <IoPlayCircleOutline/> Agregar video </button>
                         
                            <label >Agregar imagen (opcional)</label>
                            <div className="cardimagencom" style={{background:(marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 :'#E1DBE6'}}>
                                <div className="todocardimganecomt">
                                    <input type="file" accept="image/*" onChange={handleImageChange} className="desimagen" />
                                    <div className="iconoagregarcomimagen">
                                        <FaArrowUpFromBracket className="imgicono" style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}} />
                                        <span style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}}>Subir Foto</span>
                                    </div>
                                </div>
                                {formData.imagen && (
                                    <img src={URL.createObjectURL(formData.imagen)} alt="Vista previa de la imagen" className="imagenselecion" />

                                )}
                            </div>
                            {marcas?.terminos_condiciones && (
                            <div style={{marginBottom:'10px',display:'flex'}}>
                                <label style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                  <label className="cl-checkbox">
                                  <input checked={formData.aceptaTerminos} onChange={handleTerminosChange} type="checkbox" />
                                  <span></span>
                                </label>
                                    
                                  <span onClick={handleOpenTerminosModal} style={{ paddingLeft:'13px', cursor: 'pointer' }}>  Acepto los términos y condiciones</span>
                                </label>
                                {/* Modal de términos y condiciones */}
                                <Modal
                                    open={openTerminosModal}
                                    onClose={handleCloseTerminosModal}
                                    aria-labelledby="modal-terminos-title"
                                    aria-describedby="modal-terminos-description"
                                >
                                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: '80vh', bgcolor: 'background.paper', boxShadow: 24, p: 1, borderRadius: '10px', overflowY: 'auto', '@media (max-width: 500px)': { width: "90%" } }}>
                                        <section className="closemodal" onClick={handleCloseTerminosModal}> <IoClose /></section>
                                        <section className="contienentodomesjeneviar">
                                        <Box
                                            sx={{
                                              marginTop: '0px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              marginBottom:'0px',
                                            
                                              
                                            }}
                                          >
                                            <div className='contienenEmpresaInicio'>
                                                          
                                                          <div className='marcologoempresademo'>
                                                          {marcas?.logo ? (
                                                                <img src={`${backendURL}/${marcas.logo}`} className='marcoimglogoempresademo' alt='Smart Memorial' title='SmartMemorial' />
                                                            ) : <>
                                                            </>}  
                                                          </div>  
                                                          
                                                      
                                                    
                                                    </div>
                                          </Box>
                                                                                <h2 id="modal-terminos-title" className='tituloterminoycondicionmemorialemrpesa' style={{color:(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 :  '#694B81'}}>Términos y condiciones</h2>
                                            <section id="modal-terminos-description" style={{width:'90%'}}>
                                            {marcas?.terminos_condiciones ? (
                                                  <iframe
                                                      src={`${backendURL}/${marcas.terminos_condiciones}`}
                                                      title="PDF de Términos y Condiciones"
                                                      width="100%"
                                                      height="450px"
                                                      style={{ border: "none", marginTop: '8px' }}
                                                  />
                                              ) : (
                                                  <span>No hay PDF disponible</span>
                                              )}
                                            </section>
                                        </section>
                                    </Box>
                                </Modal>
                            </div>
                        )}

                            <button style={{background:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81'}} className="botonguardarcomt" onClick={handleSubmit}> 
    Enviar mensaje
   </button>
                          
                        </div>
                    </form>
                    <section className="scroolmensaje">
          <div className="scrolldown" >
            <div className="chevrons">
              <div className="chevrondown"></div>
              <div className="chevrondown"></div>
            </div>
          </div>
        </section>
                </Box>
            </Modal>
           
            <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <h2 id="modal-title" style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600',  marginTop:"-10px" }}>Mensaje enviado Correctamente</h2>
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando mensaje...
            </p>
          </section>
        </Box>
      </Modal>
        <Modal
              open={openVideoModal}
              onClose={handleVideoModalClose}
              aria-labelledby="video-modal-title"
              aria-describedby="video-modal-description"
            >
              <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "300px", bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: '10px' }}>
                <h2 id="video-modal-title" className="titlevideourl">Agregar link del Video</h2>
                <input 
                 className="desinput"
                  type="text" 
                  value={videoURL} 
                  onChange={handleVideoURLChange} 
                  placeholder="Ingrese la URL del video" 
                  style={{ width: '100%', marginBottom: '10px', padding: '8px' }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <button className="crrarlinkvideo" onClick={handleVideoModalClose}>Cerrar</button>
                    <button className="guardarlinkvideo" onClick={handleSaveVideoURL}>Guardar</button>
                </div>
              </Box>
            </Modal>
                 
            <Snackbar 
                      open={openSnackbar} 
                      autoHideDuration={6000} 
                      onClose={() => setOpenSnackbar(false)}
                      anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Posicionamiento
                  >
                      <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
                          {snackbarMessage}
                      </Alert>
                  </Snackbar> 
        </section>
    )
}




