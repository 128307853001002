import React from "react";
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import Box from "@mui/material/Box";
import { useState, useEffect,  useRef, } from 'react'
import { Container, Grid } from "@mui/material";
import "../../Style/usuario/demo.css";
import { PiScrollFill } from "react-icons/pi";
import { FaPhotoVideo } from "react-icons/fa";
import ReactPlayer from 'react-player';
import { IoIosMail } from "react-icons/io";
import Modal from '@mui/material/Modal';
import { FaArrowUpFromBracket } from "react-icons/fa6";
import { IoClose } from "react-icons/io5";
import { BiSolidCalendarStar } from "react-icons/bi";
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { CircularProgress,  Snackbar } from '@mui/material';
import { FaRegCircleCheck } from "react-icons/fa6";
import { BiErrorCircle } from "react-icons/bi";
import { IoList } from "react-icons/io5";
import GridViewIcon from '@mui/icons-material/GridView';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import DangerouslySetHTMLContent from 'dangerously-set-html-content';
import { BsEnvelopePaperHeartFill } from "react-icons/bs";
import { FaShareAlt } from "react-icons/fa";
import { Helmet } from 'react-helmet';
import { PiVideoBold } from "react-icons/pi";
import { IoPlayCircleOutline } from 'react-icons/io5';
import { Navbar } from "../nav-fotter/navbar";
import MuiAlert from '@mui/material/Alert';
import { FaRibbon } from "react-icons/fa";

/* imagenes */
import fondo from "../../Image/generales/fondo-memorial.jpg"
import tombmorado from "../../Image/generales/icono-tumba-morado.png"
import tombverde from "../../Image/generales/icono-tumba-verde.png"
import tombnaranjo from "../../Image/generales/icono-tumba-naranjo.png"
import fotovideo from "../../Image/video/foto.jpg"
import verdeluto from "../../Image/generales/verdeluto.png"
import naranjoluto from "../../Image/generales/naranjoluto.png"
import moradoluto from "../../Image/generales/moradoluto.png"
const https = rateLimit(axios.create(), {
  maxRequests: 1, // Máximo de solicitudes
  perMilliseconds: 100, // Cada segundo
});

export const Memorialpagina = ({ setShowNavbarFooter }) => {
    const [logoUrl, setLogoUrl] = useState('');
    const messagesSectionRef = useRef(null);
    const buttonRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [error, setError] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const [visibleComments, setVisibleComments] = useState([]);
    const [showMoreButton, setShowMoreButton] = useState(false);
    const [showMoreCount, setShowMoreCount] = useState(3);
    const [loadings, setLoadings] = useState(true);
    const [memorialData, setMemorialData] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [evento, setEvento] = useState(null)
    const { id } = useParams();
    const [link, setLink] = useState(null); 
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [selectedIcon, setSelectedIcon] = useState('grid');
    const [data, setData] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    const [idFuneraria, setIdFuneraria] = useState(null);
    const [comentarios, setComentarios] = useState([]);
    const [eventosmode, setEventosmode] =useState([]);
    const [loading, setLoading] = useState(true);
  const [erroreven, setErroreven] = useState(null);
  const [isModalOpeneven, setIsModalOpeneven] = useState(false);
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [videoURL, setVideoURL] = useState('');
  const [obituarioId, setObituarioId] = useState(null);
  const [marcas, setMarcas] = useState(null)
  const [openTerminosModal, setOpenTerminosModal] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [hayTerminos, setHayTerminos] = useState(true); 
  const [snackbarSeverity, setSnackbarSeverity] = useState('error');
    const [formData, setFormData] = useState({
              nombre: '',
              titulo: '',
              telefono:'',
              email:'',
              descripcion: '',
              imagen: null
          });
          const videoRef = useRef(null);
          const [previewImage, setPreviewImage] = useState(null);
          const [isPreviewImageLoaded, setIsPreviewImageLoaded] = useState(false);
          const [isPlaying, setIsPlaying] = useState(false);
          const [isLoading, setIsLoading] = useState(false);

          useEffect(() => {
            setShowNavbarFooter(false);
            return () => {
                setShowNavbarFooter(true);
            };
        }, [setShowNavbarFooter]);
          
          const handleTerminosChange = () => {
            setFormData({ ...formData, aceptaTerminos: !formData.aceptaTerminos });
          };
      
          const handleOpenTerminosModal = () => {
            setOpenTerminosModal(true);
        };
      
        const handleCloseTerminosModal = () => {
            setOpenTerminosModal(false);
        };
      
          const fetchMemorialData = async () => {
            try {
              const response = await https.get(`${backendURL}/api/getEventosFecha/${id}`);
              setEventosmode(response.data);
              
            } catch (error) {
              setErroreven(error.message);
             
            }
            finally {
              setLoading(false);
            }
          };
        
          useEffect(() => {
            fetchMemorialData();
          }, [id]);

          useEffect(() => {
            if (eventosmode.length > 0) {
              setIsModalOpeneven(true);
            }
          }, [eventosmode]);
        
          const closeModal = () => {
            setIsModalOpeneven(false);
          };
          
         

          useEffect(() => {
            const video = videoRef.current;
            if (video) {
              video.addEventListener('loadeddata', () => {
                const canvas = document.createElement('canvas');
                canvas.width = video.videoWidth;
                canvas.height = video.videoHeight;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
                canvas.toBlob((blob) => {
                  if (blob) {
                    const url = URL.createObjectURL(blob);
                    setPreviewImage(url);
                    setIsPreviewImageLoaded(true);
                  }
                }, 'image/jpeg');
              });
              video.load();
            }
          }, []);
        
          const handlePlayPause = () => {
            const video = videoRef.current;
            if (video) {
              if (video.paused) {
                video.play();
                setIsPlaying(true);
                setIsLoading(true); // Mostrar el loader al hacer clic en play
              } else {
                video.pause();
                setIsPlaying(false);
              }
            }
          };
          
          useEffect(() => {
            const fetchData = async () => {
              setLoadings(true);
              try {
                // Verificar si el memorial existe
                const memorialResponse = await https.get(`${backendURL}/api/verificarmemorial/${id}`);
                if (!memorialResponse.data.exists) {
                  setLoadings(false);
                  navigate('/Registro'); // Redirigir si el memorial no existe
                  return;
                }
          
                // Obtener los datos del memorial
                const memorialDataResponse = await https.get(`${backendURL}/api/getMemorialIdGeneral/${id}`);
                const memorialData = memorialDataResponse.data;
                setMemorialData(memorialData);
                setError(false);
          
                // Verificar si el diseño del obituario es 2
                const obituarioDiseno = memorialData.obituario?.diseno; // Asegúrate de que `diseno` es el nombre correcto de la propiedad
                if (obituarioDiseno == 2) {
                  console.log('El diseño del obituario es 2. No se aplica la lógica adicional del obituario.');
                  return; // Salir si el diseño del obituario es 2
                }
          
                // Verificar si hay un obituario asociado al memorial
                const obituarioId = memorialData.obituario?.id;
                if (obituarioId) {
                  const fechaCreacionObituario = new Date(memorialData.obituario.created_at);
                  const currentDate = new Date();
                  const sevenDaysInMilliseconds = 7 * 24 * 60 * 60 * 1000;
          
                  // Si el obituario fue creado hace menos de 7 días y la URL no es la del obituario
                  if ((currentDate - fechaCreacionObituario) <= sevenDaysInMilliseconds) {
                    // Verificar si el usuario está en la URL del obituario
                    const isOnObituarioPage = location.pathname.includes(`/Obituario/${obituarioId}`);
          
                    // Solo redirigir si no está en la página del obituario
                    if (!isOnObituarioPage) {
                      const cameFromObituario = sessionStorage.getItem(`cameFromObituario_${obituarioId}`);
                      if (!cameFromObituario) {
                        sessionStorage.setItem(`cameFromObituario_${obituarioId}`, 'true');
                        navigate(`/Obituario/${obituarioId}`);
                        return;
                      }
                    }
                  }
                }
              } catch (error) {
                console.error('Error fetching memorial data:', error);
                setError(true);
              } finally {
                setLoadings(false);
              }
            };
          
            fetchData();
          }, [id, navigate, backendURL]);
          
        
       /* comentarios memorial */
       const fetchComentarios = async () => {
        try {
            const response = await axios.get(`${backendURL}/api/get/comentariosmemorial/${id}`);
            const comentariosVisibles = response.data.filter(comentario => comentario.visible === '1');
            
            // Ordena los comentarios por fecha de manera descendente
            const sortedComentarios = comentariosVisibles.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
            
            setComentarios(sortedComentarios);
            setVisibleComments(sortedComentarios.slice(0, showMoreCount));
            setShowMoreButton(sortedComentarios.length > showMoreCount); 
        } catch (error) {
            console.error('Error al obtener comentarios:', error);
        }
    };
    
    // Llama a esta función en el useEffect para obtener comentarios al cargar la página
    useEffect(() => {
        fetchComentarios();
    }, [id]);

        // Effect: Fetch Grupo Familiar Data
        useEffect(() => {
          setLoadings(true);
            const getGrupoFamiliar = async () => {
                if (memorialData?.id_grupo_familiar) {
                    try {
                        const response = await https.get(`${backendURL}/api/get/GrupoFamiliar/public/${memorialData.id_grupo_familiar}`, {
                            headers: {
                                'Content-Type': 'application/json'
                            }
                        });
                        setData(response.data);
                       
    
                        if (response.data?.id_funeraria) {
                            setIdFuneraria(response.data.id_funeraria);
                        }
                    // Verifica si existe id_empresa en los datos y realiza otra solicitud
                if (response.data?.id_empresa) {
                  const idEmpresa = response.data.id_empresa;

                  // Realiza la solicitud con la nueva ruta y el id_empresa
                  const empresaResponse = await axios.get(`${backendURL}/api/marcaempresa/especifica/${idEmpresa}`, {
                      headers: {
                          'Content-Type': 'application/json'
                      }
                  });
                  setMarcas(empresaResponse.data.data[0]);
                 
                  setHayTerminos(!!empresaResponse.data.data[0]?.terminos_condiciones);
                } else {
                    setHayTerminos(false); // Si no hay marca, `hayTerminos` será `false`
                }
                  setLoadings(false);
              
          } catch (error) {
              console.error('Error fetching data:', error);
              setLoadings(false);
          }
          finally{
            setLoadings(false);
          }
      }
  };

  getGrupoFamiliar();
}, [memorialData, backendURL]);
    
        // Effect: Fetch Funeraria Logo
        useEffect(() => {
            const fetchFunerariaLogo = async () => {
                if (idFuneraria) {
                    try {
                        const response = await https.get(`${backendURL}/api/funeraria/${idFuneraria}`);
                        const funerariaData = response.data;
                          setLink(funerariaData);

                          if (funerariaData ) {
                            if (funerariaData.nombre === 'smart memorial') {
                              setLogoUrl(null); // No mostrar logo
                            } else if (funerariaData.logo) {
                            setLogoUrl(funerariaData.logo);
                            } else {
                              setLogoUrl(null); // No hay logo disponible
                            }
                        
                          }
                    } catch (error) {
                        console.error('Error fetching funeraria data:', error);
                    }
                    finally{
                      setLoadings(false);
                    }
                }
            };
    
            fetchFunerariaLogo();
        }, [idFuneraria, backendURL]);

       

    
        useEffect(() => {
          const fetcheventos = async () => {
            try {
              const response = await https.get(`${backendURL}/api/geteventos/${id}`);
              setEvento(response.data);
            } catch (error) {
              if (error.response && error.response.status == 404) {
                // Si no se encuentran eventos (404), establecemos una lista vacía y mostramos un mensaje opcional
                setEvento([]); 
                setError("No se encontraron eventos para este memorial.");
                
              } else {
                // Aquí puedes decidir si quieres ocultar este error o manejarlo de otra forma
                // console.error("Error al obtener los datos del evento:", error); // Elimina esta línea para evitar el error en la consola
                setError("Hubo un error al obtener los eventos. Por favor, inténtelo de nuevo más tarde.");
              }
            }
          };
        
          fetcheventos();
        }, [id, backendURL]);

        // Effect: Update Icon Based on Gallery
        useEffect(() => {
            if (memorialData?.galeria?.length > 0) {
                setSelectedIcon(memorialData.galeria.length <= 3 ? 'list' : 'grid');
            }
        }, [memorialData]);
    
        // Effect: Handle Scroll for Button Visibility
        useEffect(() => {
            const handleScroll = () => {
                if (messagesSectionRef.current && buttonRef.current) {
                    const messagesSectionTop = messagesSectionRef.current.getBoundingClientRect().top;
                    const messagesSectionBottom = messagesSectionRef.current.getBoundingClientRect().bottom;
                    const buttonHeight = buttonRef.current.offsetHeight;
    
                    setIsButtonVisible(
                        messagesSectionTop < window.innerHeight && messagesSectionBottom > buttonHeight
                    );
                }
            };
    
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', handleScroll);
            };
        }, []);
    
        // Effect: Update Visible Comments
        useEffect(() => {
    if (comentarios) {
        const visible = comentarios.filter(comentario => comentario.visible === '1');
        setVisibleComments(visible.slice(0, showMoreCount));
        setShowMoreButton(visible.length > showMoreCount);
    }
}, [comentarios, showMoreCount]);


      const handleClickobituario = (obituarioId) => {
            if (obituarioId) {
              navigate(`/Obituario/${obituarioId}`);
            } else {
              console.error('El ID del obituario es undefined');
            }
          };
        const handleIconClick = (icon) => setSelectedIcon(icon);
    
        const handleOpenModals = (index) => {
            setSelectedImageIndex(index);
            setModalOpen(true);
        };
    
        const handleCloseModals = () => {
            setModalOpen(false);
            setSelectedImageIndex(null);
        };
    
        const handlePrevImage = () => {
            setSelectedImageIndex((prevIndex) => (prevIndex === 0 ? memorialData.galeria.length - 1 : prevIndex - 1));
        };
    
        const handleNextImage = () => {
            setSelectedImageIndex((prevIndex) => (prevIndex === memorialData.galeria.length - 1 ? 0 : prevIndex + 1));
        };
    
        const handleShowMore = () => {
          const newCount = showMoreCount + 3;
          setVisibleComments(prevComments => {
              const newComments = comentarios.slice(0, newCount); // Usa comentarios ordenados
              return newComments;
          });
          setShowMoreCount(newCount);
          setShowMoreButton(comentarios.length > newCount); // Usa comentarios ordenados
      };
    
        const handleOpenModal = () => setOpenModal(true);
        const handleCloseModal = () => {
            setOpenModal(false);
            setenviandoAlert(false);
        };
    
        const handleChange = (e) => {
          const { name, value } = e.target;
      
          if (name === "telefono") {
              // Permitir solo números
              if (!/^\d*$/.test(value)) return;
              // Validar longitud mínima de 8 dígitos (opcional)
              if (value.length < 8) {
                setSnackbarMessage("El número de teléfono debe tener al menos 8 dígitos.");
                 setSnackbarSeverity('error');
              }
          }
      
          if (name === "email") {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value
            }));
        }
        
      
          setFormData((prevFormData) => ({
              ...prevFormData,
              [name]: value
          }));
      };
      

      
      const handleImageChange = (e) => {
          setFormData(prevFormData => ({
              ...prevFormData,
              imagen: e.target.files[0]
          }));
      };
      const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });
    
        const handleSubmit = async (e) => {
            e.preventDefault();

            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

              if (!emailRegex.test(formData.email)) {
                 setSnackbarMessage("Por favor, ingresa un correo electrónico válido.");
                 setSnackbarSeverity('error');
              setOpenSnackbar(true);
                  return;
              }

             
             // Validación de términos solo si existen
             if (hayTerminos && !formData.aceptaTerminos) {
              setSnackbarMessage("Debes aceptar los términos y condiciones antes de enviar.");
              setSnackbarSeverity('error');
              setOpenSnackbar(true);
              return;
          }
            if (!formData.email) {
                setSnackbarMessage("Debes ingresar correo electrónico.");
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                return;
            }
            if (!formData.telefono) {
                setSnackbarMessage("Debes ingresar telefono.");
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                return;
            }
            if (!formData.nombre) {
                setSnackbarMessage("Debes ingresar nombre.");
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                return;
            }
            if (!formData.titulo) {
                setSnackbarMessage("Debes ingresar título.");
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                return;
            }
            if (!formData.descripcion) {
                setSnackbarMessage("Debes ingresar descripción.");
                setSnackbarSeverity('error');
                setOpenSnackbar(true);
                return;
            }
          
            // Desestructurar datos del formulario
            const { nombre, titulo, telefono, email, descripcion, imagen } = formData;
            
             // Lógica para determinar el valor de 'visible'
              let visible = 0; // valor predeterminado
              if (marcas?.tipo_comentario == '0') {
                  visible = 1; // Si tipo_comentario es 0, 'visible' debe ser 1
              }
            const formDataToSend = new FormData();
            formDataToSend.append('telefono', telefono);
            formDataToSend.append('email', email);
            formDataToSend.append('titulo', titulo);
            formDataToSend.append('creador', nombre);
            formDataToSend.append('mensaje', descripcion);
            formDataToSend.append('imagen', imagen);
            formDataToSend.append('id_memorial', id);
            formDataToSend.append('visible', visible); 
    
            try {
                setenviandoAlert(true);
                const response = await axios.post(`${backendURL}/api/comentarioStores`, formDataToSend);
                setComentarios(prevComentarios => [...prevComentarios, response.data]);

                const successMessage = marcas?.tipo_comentario == '0' 
            ? 'Su mensaje se ha enviado.' 
            : 'Su mensaje se ha enviado y será verificado por el administrador del memorial.';
            setAlertMessage(successMessage);
                setSuccessAlertOpen(true);
                await fetchComentarios();
                setTimeout(() => {
                  setSuccessAlertOpen(false);
                  setFormData({
                      nombre: '',
                      telefono:'',
                      email:'',
                      titulo: '',
                      descripcion: '',
                      imagen: null
                  });
              }, 5000);
            } catch (error) {
                console.error('Error al enviar el comentario:', error);
                setAlertMessage('Error al enviar el mensaje');
                setErrorAlertOpen(true);
                setTimeout(() => setErrorAlertOpen(false), 5000);
            } finally {
                setOpenModal(false);
                setenviandoAlert(false);
            }
        };

       
    
        if (loadings) {
            return (
              <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh', // Pantalla completa
                flexDirection: 'column',
              }}
            >
              <CircularProgress
                size={100}
                thickness={4}
                sx={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  '#694B81' }}
              />
            </Box>
            );
        }
    
        if (error) {
            return (
                <div style={{ position: 'fixed', zIndex: 9999, width: "100%", height: "100%", backgroundColor: 'rgba(255, 255, 255, 0.8)' }}>
                    <Box sx={{ display: "flex", justifyContent: 'center', alignItems: 'center', height: '100%', textAlign: 'center' }}>
                        <BiErrorCircle style={{ color: "red", fontSize: "1.8rem" }} />
                        <p>Error en cargar la página memorial</p>
                    </Box>
                </div>
            );
        }     


        const handleShares = (url) => {
          if (navigator.share) {
            navigator.share({
              title: 'Comparte el recuerdo del video',
              url: url
            }).catch((error) => {
              console.error('Error al compartir el video:', error);
            });
          } else {
            alert('La funcionalidad de compartir no está soportada en este navegador.');
          }
        };

        

const handleVideoModalOpen = (e) => {
            e.preventDefault();
            setOpenVideoModal(true);
          };
        
          const handleVideoModalClose = () => {
            setOpenVideoModal(false);
          };
        
          const handleVideoURLChange = (e) => {
            setVideoURL(e.target.value);
          };
        
          const handleSaveVideoURL = () => {
            if (videoURL) {
              const videoLink = `<a href="${videoURL}" target="_blank"> <IoPlayCircleOutline /> Ver Video</a>`;
              const updatedDescription = formData.descripcion + videoLink;
              setFormData(prevFormData => ({
                ...prevFormData,
                descripcion: updatedDescription
              }));
              setVideoURL('');
              setOpenVideoModal(false);
            }
          };
          


    const capitalizeFirstLetter = (string) => {
        if (!string) return ''; // Manejar el caso cuando la cadena es null o undefined
        return string.charAt(0).toUpperCase() + string.slice(1);
      };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear();
        return `${day}/${month}/${year}`;
    };

    const handleShare = () => {
      // Lógica para compartir el memorial, por ejemplo:
      const shareURL = `/familiar/memorial/${memorialData.id}`;
      window.navigator.share({ url: shareURL });
    };

   function formatFecha(fecha) {
        // Verifica si la fecha es nula o indefinida
        if (!fecha) {
          return '';
        }
        
        // Convierte la fecha a un objeto Date
        const date = new Date(fecha);
        
        // Verifica si la fecha es válida
        if (isNaN(date.getTime())) {
          return '';
        }
        
        // Obtiene el día, el mes y el año
        const day = date.getDate();
        const month = date.toLocaleString('es-ES', { month: 'long' });
        const year = date.getFullYear();
        
        // Devuelve la fecha formateada
        return `${day} ${month} ${year}`;
      }
      const getImageSrc = (fondo) => {
        switch (fondo) {
          case 'naturalezaAustral':
            return tombverde;
          case 'atardecerVerano':
            return tombnaranjo;
          default:
            return tombmorado;
        }
      };

    const imageSrc = getImageSrc(memorialData?.fondo);


     const getImageObituario = (fondo) => {
        switch (fondo) {
          case 'naturalezaAustral':
            return verdeluto;
          case 'atardecerVerano':
            return naranjoluto;
          default:
            return moradoluto;
        }
      };

    const imageObituario = getImageObituario(memorialData?.fondo);

    const getColorByFondo = (fondo) => {
      switch (fondo) {
        case 'naturalezaAustral':
          return '#E1EDDD';
        case 'atardecerVerano':
          return '#F9E7D7';
        default:
          return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 : '#E1DBE6';
      }
    };
    const getColorByborder = (fondo) => {
      switch (fondo) {
        case 'naturalezaAustral':
          return '#68A354';
      case 'atardecerVerano':
          return '#E28939';
      default:
          return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
      }
    };

    const getColorByborderperfil = (fondo) => {
      switch (fondo) {
        case 'naturalezaAustral':
            return '#E1EDDD';
        case 'atardecerVerano':
            return '#F9E7D7';
          default:
            return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 :'#E1DBE6';
      }
    };
  
    const borderColor = getColorByFondo(memorialData.fondo);
    const backgroundColor = getColorByFondo(memorialData.fondo);

    const getLightBackgroundByFondo = (fondo) => {
      switch (fondo) {
          case 'naturalezaAustral':
              return '#F3F8F1';
          case 'atardecerVerano':
              return '#FDF5EF';
          default:
              return (marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 :'#F0EDF3';
      }
  };

  const backborderColor = getLightBackgroundByFondo(memorialData.fondo);

    const styles = {
        titleStyle: {
            color: (() => {
                switch (memorialData?.fondo) {
                    case 'naturalezaAustral':
                        return '#68A354';
                    case 'atardecerVerano':
                        return '#E28939';
                    default:
                        return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
                }
            })(),
        },
        fondomensajeStyle: {
            background: (() => {
                switch (memorialData?.fondo) {
                    case 'naturalezaAustral':
                        return '#68A354';
                    case 'atardecerVerano':
                        return '#E28939';
                    default:
                        return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
                }
            })(),
        },
        botonoscuroStyle: {
          backgroundColor: (() => {
            switch (memorialData?.fondo) {
              case 'naturalezaAustral':
                return '#27632D';
              case 'atardecerVerano':
                return '#A04424';
              default:
                return (marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 :  '#D26999';
            }
          })(),
        },
        fondosStyle: {
            background: (() => {
                switch (memorialData?.fondo) {
                    case 'naturalezaAustral':
                        return '#F3F8F1';
                    case 'atardecerVerano':
                        return '#FDF5EF';
                    default:
                        return (marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 : '#F0EDF3';
                }
            })(),
        },
        titulodosStyle: {
          color: (() => {
              switch (memorialData?.fondo) {
                  case 'naturalezaAustral':
                      return '#F3F8F1';
                  case 'atardecerVerano':
                      return '#FDF5EF';
                  default:
                      return (marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 :'#F0EDF3';
              }
          })(),
      },
        titulooscuroStyle: {
            color: (() => {
                switch (memorialData?.fondo) {
                    case 'naturalezaAustral':
                        return '#27632D';
                    case 'atardecerVerano':
                        return '#A04424';
                    default:
                        return (marcas?.color_3 && marcas.color_3 !== 'undefined') ? marcas.color_3 : '#D26999';
                }
            })(),
        },
        titulooscuroStyleuno: {
          color: (() => {
              switch (memorialData?.fondo) {
                  case 'naturalezaAustral':
                      return '#27632D';
                  case 'atardecerVerano':
                      return '#A04424';
                  default:
                      return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#D26999';
              }
          })(),
      },
        texto: {
            color: "#fff"
        },
        selectedIconStyle: {
            
            cursor:"pointer",
            width: '40px',
            height: '40px',
            color: '#fff',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: (() => {
                switch (memorialData?.fondo) {
                    case 'naturalezaAustral':
                        return '#68A354';
                    case 'atardecerVerano':
                        return '#E28939';
                    default:
                        return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
                }
            })(),
          },
          iconStyle: {  
            cursor:"pointer",
            width: '40px',
            height: '40px', 
            borderRadius: '50%',   
            background: 'transparent',  // Aquí está el error corregido
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: (() => {
                switch (memorialData?.fondo) {
                    case 'naturalezaAustral':
                        return '#68A354';
                    case 'atardecerVerano':
                        return '#E28939';
                    default:
                        return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
                }
            })(),
          },
          modalContent: {
            maxHeight: '80vh', // Limitar la altura máxima
            overflowY: 'auto', // Hacer el contenido desplazable si es necesario
            position: 'relative',
          },
          modalImage: {
            width: '100%',
            maxHeight: '60vh',
            objectFit: 'contain',
          },
          buttonmas:{
            color: (() => {
              switch (memorialData?.fondo) {
                  case 'naturalezaAustral':
                      return '#68A354';
                  case 'atardecerVerano':
                      return '#E28939';
                  default:
                      return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
              }
          })(),
          border: `1px solid ${getColorByborder(memorialData.fondo)}`,
          '--button-bg-hover': backborderColor,
          '--button-border-hover':borderColor,
        },
    styleborder:{
      border: `8px solid ${getColorByborderperfil(memorialData.fondo)}`,

    },
    styleiconoblose:{
      color: (() => {
        switch (memorialData?.fondo) {
          case 'naturalezaAustral':
            return '#E1EDDD';
        case 'atardecerVerano':
            return '#F9E7D7';
        default:
            return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 :'#E1DBE6';
        }
    })(),
     
    }
  
      };
      const StyleFondo={
        backgroundImage: `url(${marcas?.fondo_memorial ? `${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}` : fondo})`,
          backgroundSize: 'cover',           // Asegurar que cubra toda la sección
          backgroundPosition: 'center',      // Centrar la imagen
          width: '100%',
          height: 'auto',                   // Ajustar la altura de la sección
          
      }
    
      
    return (
        <section className="contienenpaginainiciousuario" style={StyleFondo}>
                    {marcas ? (
            <Navbar
            marcas={marcas}
            color1={(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 : ''}  // Proveer color por defecto
            color2={(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :  ''}
            logomarca={`${backendURL}/${marcas?.logo}`}
            />
          ) : (
            <Navbar
            />
          )}
           
            <Helmet>
        <title>{`Bienvenido al memorial${memorialData ? ` de ${memorialData.nombre}` : ''}`}</title>
        <meta name="description" content="Conoce de su vida" />
      </Helmet>
         <> 
         {logoUrl && (
          <Link to={link.web} className="logofunerariadosmemorial " target="black"> 
        
         <img
           src={`${backendURL}/${logoUrl}`}
           alt="Logo de la funeraria"
           className="logofun"

         />
       </Link>
        )}</> 
    

            <Container component="main" maxWidth="xs" sx={{ paddingLeft:"0px", paddingRight:"0px", ' @media (max-width: 700px)': {
            marginTop: '0px'
          }}} >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        background: "#ffff",
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: (() => {
                            switch (memorialData.fondo) {
                                case 'naturalezaAustral':
                                    return '#E1EDDD';
                                case 'atardecerVerano':
                                    return '#F9E7D7';
                                default:
                                    return (marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 :'#E1DBE6';
                            }
                        })(),
                    }}
                >
                    <section className="contienehistoriademo" ref={messagesSectionRef} >
                        <div className="vistamemorial">
                        {marcas?.fondo_memorial ? (
                    <img 
                    src={`${process.env.REACT_APP_BACKEND_URL}/${marcas.fondo_memorial}`} 
                        alt="smart memorial" 
                    />
                ) : (
                    <img 
                        src={fondo} 
                        alt="smart memorial" 
                    />
                )}
                            <div className="titulodemomerorial">
                                <h1 className="memorialtitulo" translate="no" style={styles.titleStyle}>Memorial</h1>
                                <h1 className="nombre" style={styles.titulooscuroStyleuno}>{memorialData.nombre}</h1>
                                <h3 className="fechadefusion" style={styles.titleStyle}>{formatDate(memorialData.fecha_nacimiento)} • {formatDate(memorialData.fecha_defuncion)}</h3>
                            </div>
                            <div className="imagendemopersona" style={styles.styleborder}>
                                {memorialData && (

                                    <img src={`${backendURL}/imagenes/memoriales/${memorialData.carpeta_perfil}/${memorialData.imagen_perfil}`} alt="smart memorial" style={styles.fondosStyle} />

                                )}
                            </div>
                        </div>
                        {memorialData.historia && (       <div className="texto-historia" style={styles.fondosStyle}>
                            <div className="titulohistoriademo" style={styles.titleStyle} ><PiScrollFill /> <h1>Su historia</h1>  </div>
                            <p className="textohistoriademo">
  {memorialData.historia ? (
    <DangerouslySetHTMLContent html={capitalizeFirstLetter(memorialData.historia)} />
  ) : (
    <span></span>
  )}
</p>
                        </div> )}
                    </section>
                    {memorialData.obituario && (  
              <section style={{width:"100%", marginTop:'40px'}}>
              <section className="tituloobituario" style={styles.titleStyle}>
              <FaRibbon style={{fontSize:'1.5rem'}} />
              <div className="titulocementerio"> <h1>Obituario</h1> </div> 
          </section>
 <section className="obituariobuttonmemorialpage" onClick={() => handleClickobituario(memorialData.obituario.id)} style={{}} >
  <div className="tituloobituarioenelmemorial">

  </div>
     <button 
      className="buttonovituariomemorial"
     style={{
       borderColor: (() => {
         switch (memorialData?.fondo) {
           case 'naturalezaAustral':
             return '#68A354';
           case 'atardecerVerano':
             return '#E28939';
           default:
             return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
         }
       })(), color: (() => {
         switch (memorialData?.fondo) {
           case 'naturalezaAustral':
             return '#68A354';
           case 'atardecerVerano':
             return '#E28939';
           default:
             return (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81';
         }
       })(),
                  background:"trasparent",
               }} >
         Obituario
     </button>
 </section>
 </section>
)}
                    {memorialData && memorialData.galeria && memorialData.galeria.length > 0 && (
                    <section className="demogaleriarecuerdo">
      {memorialData && memorialData.galeria && memorialData.galeria.length > 0 && (
        <div className="titulogaleriabonito" style={styles.titleStyle}>
          <FaPhotoVideo /> <h1>Galería de recuerdos</h1>
        </div>
      )}
      <section className="contienensliderdemogaleria">
        {memorialData && memorialData.galeria && memorialData.galeria.length > 0 && (
          <div className="titulodemofoto">
            <div style={styles.titleStyle}><p>Fotos</p></div>
            <div className="contienenvistafotos">
              <p>Vista</p>
              <div
                className="iconosfotos"
                style={selectedIcon === 'list' ? styles.selectedIconStyle : styles.iconStyle}
                onClick={() => handleIconClick('list')}
              >
                <IoList className="list" />
              </div>
              <div
                className="iconosfotos"
                style={selectedIcon === 'grid' ? styles.selectedIconStyle : styles.iconStyle}
                onClick={() => handleIconClick('grid')}
              >
                <GridViewIcon />
              </div>
            </div>
          </div>
        )}
        <div className="contiendedemogaleria">
          {memorialData && memorialData.galeria && memorialData.galeria.length > 0 && (
            selectedIcon === 'list' ? (
              memorialData.galeria.map((imagen, index) => (
                <div style={styles.fondosStyle} key={index}>
                  <div className="marcodefotodemo">
                    <div className="caratulademogaleria">
                      <img src={`${backendURL}/${imagen.imagen}`} alt="smart memorial" />
                    </div>
                    <div className="textocarddemogaleria">
                      <h3 style={styles.titulooscuroStyle}>{formatFecha(imagen.fecha)}</h3>
                      <h1 style={styles.titleStyle}>{capitalizeFirstLetter(imagen.titulo)}</h1>
                      <h2>{capitalizeFirstLetter(imagen.descripcion)}</h2>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <Grid container sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {memorialData.galeria.map((imagen, index) => (
                  <Grid item xs={4} key={index} sx={{ display: "flex", alignItems: "center", justifyContent: "center", margin: "5px -5px" }}>
                    <div className="contienenmarcofotosdos" onClick={() => handleOpenModals(index)}>
                      <div className="marcodefotosdosdemo">
                        <div className="caratulademodosgaleria">
                          <img src={`${backendURL}/${imagen.imagen}`} alt="smart memorial" />
                        </div>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            )
          )}
        </div>
      </section>
    </section>)}

 {memorialData && memorialData.video && (
                    <section className="contienenvideosdemo">
                     
                            <div className="videotitulodemo" style={styles.titleStyle}> Video</div>
                        
  
        <div className="video-container">
        <div
          className="preview-container"
          onClick={handlePlayPause}
          style={{
            display: !isPlaying ? 'flex' : 'none', // Mostrar solo cuando no esté reproduciendo
            position: 'relative',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            backgroundImage: `url(${isPreviewImageLoaded ? previewImage : (marcas?.fondo_video ? `${backendURL}/${marcas.fondo_video}` : fotovideo)})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '200px',
          }}
        >
          {!isLoading && (
            <div className="playicono">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="50"
                viewBox="0 0 448 512"
                fill="currentColor"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M424.4 214.7L72.4 6.6C43.8-10.3 0 6.1 0 47.9V464c0 37.5 40.7 60.1 72.4 41.3l352-208c31.4-18.5 31.5-64.1 0-82.6z"></path>
              </svg>
            </div>
          )}
        </div>
  
        {isLoading && (
          <div className="loader">
            <div className="in"></div>
            <div className="out-1"></div>
            <div className="out-2"></div>
            <div className="out-3"></div>
          </div>
        )}
  
        <video
          ref={videoRef}
          width="100%"
          height="auto"
          poster={isPreviewImageLoaded ? previewImage : (marcas?.fondo_video ? `${backendURL}/${marcas.fondo_video}` : fotovideo)}
          onClick={handlePlayPause}
          playsInline
          onWaiting={() => setIsLoading(true)} // Mostrar loader cuando el video está cargando
          onCanPlay={() => setIsLoading(false)} // Ocultar loader cuando el video puede reproducirse
          onPlaying={() => setIsLoading(false)} // Ocultar loader cuando el video está reproduciéndose
          onPause={() => setIsPlaying(false)} // Restaurar el estado de no reproducción si el video se pausa
          onError={() => {
            setIsLoading(false); // Ocultar loader si ocurre un error
            setIsPlaying(false); // Asegurarse de que el video esté en estado de no reproducción
          }}
          style={{ display: isPlaying ? 'block' : 'none', position: 'relative' }} // Mostrar solo cuando esté reproduciendo
        >
          <source src={`${backendURL}/video/${memorialData.video}?t=${new Date().getTime()}`} type="video/mp4" />
        </video>
      </div>
     
       </section> )}  
       <section>
       {visibleComments && visibleComments.length > 0 && (  
        <div className="titulodemomensaje" style={styles.titleStyle}>  <h1> Sus mensajes y recuerdos</h1> <h1> para compartir</h1></div>
       )}

<div className="contienentodoslosmensajedemodos" >
                    <section className="demorecuerdomensaje">

                     
                    {visibleComments && [...visibleComments]
                      
                    .slice(0, showMoreCount)
                    .map((comentario, index) => (
                       <>
                         { comentario.visible === '1' && (
                         <div key={index} style={{width:"100%"}}> 
                          <div className="contienencarddemomensaje"  style={styles.fondomensajeStyle} >
                                <div className="contienentextomensajedemo">
                                    <h1 style={styles.titulooscuroStyle}>{capitalizeFirstLetter(comentario.titulo)}</h1>
                                    <h2 style={styles.texto} >{comentario.mensaje ? (
    <DangerouslySetHTMLContent html={capitalizeFirstLetter(comentario.mensaje)} />
  ) : (
    <span></span>
  )}</h2></div>
                                <div className="quienloescriviodemo" style={styles.texto}><h3>{comentario.creador}</h3>-<p> {formatDate(comentario.fecha)}</p></div>
                                <div className="imagendemmensajedemo">
                                    {comentario.imagen && (
                                        <div className="imagendemmensajedemo">
                                            <img src={`${backendURL}/${comentario.imagen}`} alt="smart memorial" />
                                        </div>
                                    )}
                                </div>
                               
                            </div>
                            <section className="corazonmemorialdenmo">
                                <div className="containerheart">
                                    <div className="heart">
                                        <svg width="50" height="50" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z"/>
                                        </svg>
                                    </div>
                                    <div className="heart">
                                        <svg width="40" height="40" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z"/>
                                        </svg>
                                    </div>
                                    <div className="heart">
                                        <svg width="35" height="35" viewBox="0 0 800 700" xmlns="http://www.w3.org/2000/svg">
                                            <path d="m263.42 235.15c-66.24 0-120 53.76-120 120 0 134.76 135.93 170.09 228.56 303.31 87.574-132.4 228.56-172.86 228.56-303.31 0-66.24-53.76-120-120-120-48.048 0-89.402 28.37-108.56 69.188-19.161-40.817-60.514-69.188-108.56-69.188z"/>
                                        </svg>
                                    </div>
                                </div>
                            </section>
                                </div>
                        )}
                     </>
                       ))}
                        <section>
                        {showMoreButton && (
            <button className="ver-mas-mensajes" style={styles.buttonmas} onClick={handleShowMore}>
              Mostrar más mensajes
            </button>
          )}
                        </section>
                        
                    </section>
                   
 </div>
    
    </section>
 <section className="contienenenventomemorialselecion">
      <div className="contieneninternoevento">
      {evento && evento.length > 0 && (
        <section className="titulo-evento-memorial">
          <div className="titulodemoeventomensaje" style={styles.titleStyle}>
            <BiSolidCalendarStar />
            <h1>Eventos</h1>
          </div>
        </section>
)}
 
        {evento && evento.map(evento => (
          <div key={evento.id} className="cardeventomemorialvista" style={styles.fondosStyle}>
            <h2 className="tituloeventomemorialnombre" style={styles.titleStyle}>
              {capitalizeFirstLetter(evento.titulo)}
            </h2>
            <div className="horayfechamemorialenvento" style={styles.titleStyle}>
              <p className="fecha">{formatDate(evento.fecha)}</p>
              <p className="hora">{evento.hora}hrs.</p>
            </div>
            <p className="descripcionmermorialdelevento">{capitalizeFirstLetter(evento.descripcion)}</p>
            {evento.url && (
        <>
          <div className="divreactplayersevento">
            <ReactPlayer url={evento.url} width="100%" height="100%" />
          </div>
          <div className="buttoncompartirvideodosevento">
            <button style={styles.buttonmas} onClick={() => handleShares(evento.url)} >
              <PiVideoBold /> Comparta el recuerdo del video
            </button>
          </div>
        </>
      )}
          </div>
          
        ))}
      </div>
    </section>

             
  
{memorialData.cementerio && (         
<section className="contienencementerio">
    

        <section className="titulocementerio" style={styles.titleStyle}>
            <img src={imageSrc} className="iconocementerio" alt="smart memorial" title="smart memorial" />
            <div className="titulocementerio"> <h1>Cementerio donde nuestro</h1> <h1>ser querido descansa</h1></div> 
        </section>
   
    <section className="contieneninformacioncementerio">
        <p>{capitalizeFirstLetter(memorialData.cementerio)}</p>

    </section>

</section>  )}
<section
           className={`enviarmensajememorialpaginainicial ${isButtonVisible ? 'enviarmensajememorialpaginainicial-visible' : 'enviarmensajememorialpaginainicial-hidden'}`}
          style={{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            flexDirection:"column",
            border: `1px solid ${borderColor}`,
            background: backgroundColor,
          }}
          ref={buttonRef}
        >
          <div style={{
            width:'40px',
          
          }}>
           {marcas?.logo ? (
              <img src={`${backendURL}/${marcas.logo}`} className='marcoimglogoempresademo' alt='Smart Memorial' title='SmartMemorial' />
              ) : <>
              </>}  </div>
          <button className="enviarmensajeusuarioexterno" onClick={handleOpenModal} style={styles.botonoscuroStyle}>
          <BsEnvelopePaperHeartFill /> Comparte un recuerdo o mensaje de amor
          </button>

          <button onClick={handleShare}   className="butoncompartirmemorial" style={styles.fondomensajeStyle}   > <FaShareAlt /> Compartir memorial</button>
        </section>
                </Box>


            </Container>

            {/* Modal */}
            <Modal
                open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px",  height: '80vh', bgcolor: 'background.paper', boxShadow: 24, p: 1, borderRadius:'10px',  overflowY: 'auto', ' @media (max-width: 500px)': {
            width:"90%"
          } }}>
                    <section className="closemodal" onClick={handleCloseModal}> <IoClose /></section>
                    <section className="contienentodomesjeneviar">
                    <section className="titulodentromodalmensaje">  <IoIosMail style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}}/> <h2 style={{ fontSize:'1rem', color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}} id="modal-title">Dejar un mensaje</h2> </section>
                      <section className="subtituloinfomensaje"><span >Si deseas compartir un recuerdo en video, puedes incluir un enlace de video en tu mensaje. Simplemente haz clic en el botón "Agregar video", ingresa la URL del video que deseas compartir y selecciona "Guardar". El enlace aparecerá en tu mensaje como "Ver Video", permitiendo a otros ver tu video mientras leen tu mensaje. </span></section>
                  </section> 
                   <form>
                        <div className="formulariocoment">
                            <label>Ingrese su nombre</label>
                            <input type="text" name="nombre" style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}} value={formData.nombre} onChange={handleChange} className="desinput" />
                            <label>Telefono</label>
                            <input type="tel" name="telefono" style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}} value={formData.telefono} onChange={handleChange} className="desinput" />
                            <label>Correo Electrónico</label>
                            <input 
                                  type="email" 
                                  name="email" 
                                  style={{ color: (marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81' }} 
                                  value={formData.email} 
                                  onChange={handleChange} 
                                  className="desinput" 
                              />
                            <label>Título</label>
                            <input type="text" name="titulo" style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}} value={formData.titulo} onChange={handleChange} className="desinput" />
                            <label>Descripción</label>
                            <textarea  
                            style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}}
                            className="inputmesnajeneviar"
                            value={formData.descripcion}
                            onChange={handleChange}
                            name="descripcion"
                            id="descripcion"
                            />
                            <button className="videodescripcionmensaje"  onClick={handleVideoModalOpen}>    <IoPlayCircleOutline/> Agregar video </button>
                         
                            <label >Agregar imagen (opcional)</label>
                            <div className="cardimagencom" style={{background:(marcas?.color_4 && marcas.color_4 !== 'undefined') ? marcas.color_4 :'#E1DBE6'}}>
                                <div className="todocardimganecomt">
                                    <input type="file" accept="image/*" onChange={handleImageChange} className="desimagen" />
                                    <div className="iconoagregarcomimagen">
                                        <FaArrowUpFromBracket className="imgicono" style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}} />
                                        <span style={{color:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 :'#694B81'}}>Subir Foto</span>
                                    </div>
                                </div>
                                {formData.imagen && (
                                    <img src={URL.createObjectURL(formData.imagen)} alt="Vista previa de la imagen" className="imagenselecion" />

                                )}
                            </div>
                            {marcas?.terminos_condiciones && (
                            <div style={{marginBottom:'10px',display:'flex'}}>
                                <label style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                  <label className="cl-checkbox">
                                  <input checked={formData.aceptaTerminos} onChange={handleTerminosChange} type="checkbox" />
                                  <span></span>
                                </label>
                                    
                                  <span onClick={handleOpenTerminosModal} style={{ paddingLeft:'13px', cursor: 'pointer' }}>  Acepto los términos y condiciones</span>
                                </label>
                                {/* Modal de términos y condiciones */}
                                <Modal
                                    open={openTerminosModal}
                                    onClose={handleCloseTerminosModal}
                                    aria-labelledby="modal-terminos-title"
                                    aria-describedby="modal-terminos-description"
                                >
                                    <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "400px", height: '80vh', bgcolor: 'background.paper', boxShadow: 24, p: 1, borderRadius: '10px', overflowY: 'auto', '@media (max-width: 500px)': { width: "90%" } }}>
                                        <section className="closemodal" onClick={handleCloseTerminosModal}> <IoClose /></section>
                                        <section className="contienentodomesjeneviar">
                                        <Box
                                            sx={{
                                              marginTop: '0px',
                                              display: 'flex',
                                              alignItems: 'center',
                                              justifyContent: 'center',
                                              marginBottom:'0px',
                                            
                                              
                                            }}
                                          >
                                            <div className='contienenEmpresaInicio'>
                                                          
                                                          <div className='marcologoempresademo'>
                                                          {marcas?.logo ? (
                                                                <img src={`${backendURL}/${marcas.logo}`} className='marcoimglogoempresademo' alt='Smart Memorial' title='SmartMemorial' />
                                                            ) : <>
                                                            </>}  
                                                          </div>  
                                                          
                                                      
                                                    
                                                    </div>
                                          </Box>
                                                                                <h2 id="modal-terminos-title" className='tituloterminoycondicionmemorialemrpesa' style={{color:(marcas?.color_1 && marcas.color_1 !== 'undefined') ? marcas.color_1 :  '#694B81'}}>Términos y condiciones</h2>
                                            <section id="modal-terminos-description" style={{width:'90%'}}>
                                            {marcas?.terminos_condiciones ? (
                                                  <iframe
                                                      src={`${backendURL}/${marcas.terminos_condiciones}`}
                                                      title="PDF de Términos y Condiciones"
                                                      width="100%"
                                                      height="450px"
                                                      style={{ border: "none", marginTop: '8px' }}
                                                  />
                                              ) : (
                                                  <span>No hay PDF disponible</span>
                                              )}
                                            </section>
                                        </section>
                                    </Box>
                                </Modal>
                            </div>
                        )}

                            <button style={{background:(marcas?.color_2 && marcas.color_2 !== 'undefined') ? marcas.color_2 : '#694B81'}} className="botonguardarcomt" onClick={handleSubmit}> 
    Enviar mensaje
   </button>
                          
                        </div>
                    </form>
                    <section className="scroolmensaje">
          <div className="scrolldown" >
            <div className="chevrons">
              <div className="chevrondown"></div>
              <div className="chevrondown"></div>
            </div>
          </div>
        </section>
                </Box>
            </Modal>
           
            <Modal open={successAlertOpen} onClose={() => setSuccessAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setSuccessAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <h2 id="modal-title" style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600',  marginTop:"-10px" }}>Mensaje enviado Correctamente</h2>
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando mensaje...
            </p>
          </section>
        </Box>
      </Modal>

      {modalOpen && selectedImageIndex !== null && (
        <Modal open={modalOpen} onClose={handleCloseModals}>
            <Box
             style={styles.fondosStyle}
            sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '400px',
            height: 'auto',
            boxShadow: 24,
            
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}>
          <div style={styles.modalContent}>
            <div className="contienenclosefotosmemorial">
            <IconButton style={styles.titleStyle} onClick={handleCloseModals}>
              <CloseIcon />
            </IconButton>
           </div>
            <div className="marcodefotodemo">
            <div className="caratulademogaleria">
            <img
              src={`${backendURL}/${memorialData.galeria[selectedImageIndex].imagen}`}
              alt={memorialData.galeria[selectedImageIndex].titulo}
              style={styles.modalImage}
            />
            </div>
            <div className="textocarddemogaleria">
              <h3 style={styles.titulooscuroStyle}>{formatFecha(memorialData.galeria[selectedImageIndex].fecha)}</h3>
              <h1 style={styles.titleStyle}>{capitalizeFirstLetter(memorialData.galeria[selectedImageIndex].titulo)}</h1>
              <h2>{capitalizeFirstLetter(memorialData.galeria[selectedImageIndex].descripcion)}</h2>
            </div>
          </div>
          </div>
           <div  className="contienenflechasfotos">
              <IconButton onClick={handlePrevImage}>
                <IoIosArrowBack style={styles.titleStyle} /> <p style={styles.titleStyle} className="tectoiconoflechas">Anterior</p>
              </IconButton>
              <IconButton onClick={handleNextImage}>
               <p  style={styles.titleStyle} className="tectoiconoflechas">Siguiente</p> <IoIosArrowForward style={styles.titleStyle}/> 
              </IconButton>
            </div>
          </Box>
        </Modal>
      )}
   
   <Modal
        open={isModalOpeneven}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      > 
     
      
        <div  style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "90%",  height: '100vh',   padding: '0px 15px',  borderRadius:'0px',  border:'0px',  overflowY: 'auto', overflowX: 'hidden',  ' @media (max-width: 500px)': {
            width:"90%"
          } }}><section className="titulonuevoenvento">
            <section className="buttonclosemodalenvent">
            <button onClick={closeModal} style={styles. styleiconoblose} > <IoClose/></button></section>
            
              <h1  style={styles.titulodosStyle}>Nuevo video de eventos</h1>
            
            </section>
            <div  className="zoom-in-from-top" style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection:"column" }}>
            
         <div style={{display:"flex", alignItems:"center", justifyContent:"center",  flexDirection:"column"}}>
          {eventosmode.map((evento) => (
            <div key={evento.id} className="cardeventomemorialvista" style={styles.fondosStyle}>
              <h2 className="tituloeventomemorialnombre"  style={styles.titleStyle}>
                {capitalizeFirstLetter(evento.titulo)}
              </h2>
              <div className="horayfechamemorialenvento" style={{color:"#585857"}}>
                <p className="fecha">{formatDate(evento.fecha)}</p>
                <p className="hora">{evento.hora}hrs.</p>
              </div>
             
              {evento.url && (
                <>
                  <div className="divreactplayersevento">
                    <ReactPlayer url={evento.url} width="100%" height="100%" />
                  </div>
                  <div className="buttoncompartirvideodosevento">
                    <button style={styles.buttonmas} onClick={() => handleShares(evento.url)}>
                      <PiVideoBold /> Comparta el recuerdo del video
                    </button>
                  </div>
                </>
              )}
            </div>
          ))}</div>
         </div>
        </div>
      </Modal>

      <Modal
        open={openVideoModal}
        onClose={handleVideoModalClose}
        aria-labelledby="video-modal-title"
        aria-describedby="video-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: "300px", bgcolor: 'background.paper', boxShadow: 24, p: 4, borderRadius: '10px' }}>
          <h2 id="video-modal-title" className="titlevideourl">Agregar link del Video</h2>
          <input 
           className="desinput"
            type="text" 
            value={videoURL} 
            onChange={handleVideoURLChange} 
            placeholder="Ingrese la URL del video" 
            style={{ width: '100%', marginBottom: '10px', padding: '8px' }}
          />
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <button className="crrarlinkvideo" onClick={handleVideoModalClose}>Cerrar</button>
              <button className="guardarlinkvideo" onClick={handleSaveVideoURL}>Guardar</button>
          </div>
        </Box>
      </Modal>
           
      <Snackbar 
                open={openSnackbar} 
                autoHideDuration={6000} 
                onClose={() => setOpenSnackbar(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }} // Posicionamiento
            >
                <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar> 

        </section>
    )
}