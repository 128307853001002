import React, { useEffect,useState } from "react";
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";
import { useNavigate,useParams } from "react-router-dom";
import { Container, Grid, Box, CircularProgress} from '@mui/material';
import { EncabezadoAdmiSucursal } from "../../AdministradorSucursal/Index/ComponenteEstructura/EncabezadoSucursalAdmin";
import { CodigosQrSucursalAdmi } from "./ComponenteEstructura/CodigosQrSucursalAdmin";
import { NavTareasSucursal } from "../../AdministradorSucursal/Index/ComponenteEstructura/navTareas";
import { CalendarioMemorialsucursal } from "./ComponenteEstructura/CalendarioMemorialsucursal";
import { CodigosQrInnicioSucursalAdmi } from "./ComponenteEstructura/InfoQr";
import SucursalLayout from "../../../../../SucursalLayout"

const https = rateLimit(axios.create(), {
    maxRequests: 2, // Número máximo de solicitudes permitidas
    perMilliseconds: 1000, // Tiempo en milisegundos
});

export const IndexSucursalAdmin = ({ setShowNavbarFooter }) => {
    const { Token, User, setAdminUserEmpresaToken } = useUserEmpresa();
    const navigate = useNavigate(); // Inicializa navigate
    const { id } = useParams();
    const [usuarioData, setUsuarioData] = useState([]);
    const [sucursalData, setSucursalData] = useState([]);
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [loading, setLoading] = useState(false);


useEffect(() => {
    if (!Token) return; 

    const fetchEmpresaData = async () => {
        setLoading(true);
        try {
            const response = await https.get(`${backendURL}/api/usuarioSucursal/${id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${Token}` // Agregar el token en los headers
                }
            });

            if (response.status === 200) {
                setUsuarioData(response.data.user); 
                setSucursalData(response.data.sucursal);// Guarda los datos de la empresa
            } else {
                console.error("Error al obtener datos de la empresa");
            }
        } catch (error) {
            console.error("Error en la solicitud:", error);
        } finally {
            setLoading(false);
        }
    };
   
    if (Token && id) {
        fetchEmpresaData(); // Llamar a la función si el token y el id están presentes
    }
}, [Token, id, backendURL]);


    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);
   
    useEffect(() => {
        const storedToken = sessionStorage.getItem('Token');  
        if (storedToken) {
            setAdminUserEmpresaToken(storedToken);
        } else {    
            navigate("/Inicio-Sesion-Empresas");
        }
        
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [navigate, setAdminUserEmpresaToken, Token, setShowNavbarFooter]); 
    
    if (loading) {
        return(
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px", marginBottom:'100px' }}>
              <CircularProgress sx={{ color: "D26999" }} />
          </Box>);
      }

    
    
    return(
        <SucursalLayout>
            <Container maxWidth="lg">
            
            <Grid container sx={styleGrid}>
                <Grid item xs={12} sm={8}>                     
            <CodigosQrInnicioSucursalAdmi />
                
                </Grid>
                <Grid item xs={12} sm={4}>
                <CalendarioMemorialsucursal />
                </Grid>
            </Grid>
            

            </Container>
        </SucursalLayout>
    )
};
const styleGrid = {
    maxWidth:'100%',
    display:'flex',
    alignItems:'start',
    justifyContent:'center',

}
const styleGriddos = {
    maxWidth:'100%',
    display:'flex',
    alignItems:'start',
    justifyContent:'flex-start',

}