import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../../Style/navbar/footer.css";
import { useAuth } from "../context/usecontext";
import { Link } from "react-router-dom";
export const FooterSmart =() => {
   
    return(
        <section className="footersmart" style={{background:'#333333'}}>
            <div className="titulofooter">
            <h2>
          Desarrollado por{" "}
          <a
            href="https://agenciaorbita.cl/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "#ffffff" }}
          >
            Orbita Spa
          </a>
        </h2>
            </div>
            <hr className="lineafooter"/>
        
        
        </section>
    )
}