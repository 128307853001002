import React, { useEffect, useState } from "react"; 
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import EmpresaLayout from "../../../../../../../EmpresaLayout";
import { useUserEmpresa } from "../../../../../../context/UsuarioEmpresa";
import {
  Container,
  Grid,
  Box,
  CircularProgress,
  Modal,
  Typography,
  Button,
  IconButton,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Avatar,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Fade,
  Snackbar
} from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import { CgCardHearts } from "react-icons/cg";
import { format } from "date-fns";
import { es } from "date-fns/locale";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from '@mui/icons-material/Close';
import foto from "../../../../../../../Image/generales/ejemplo.jpg"



export const CorreoPersonalizado  = ({ setShowNavbarFooter }) => {
        const { UserDataMarca, Empresa, Token } = useUserEmpresa();
        const { id } = useParams();
        const [memoriales, setMemoriales] = useState([]);
        const [loadings, setLoadings] = useState(true); // Para mostrar un indicador de carga
        const [error, setError] = useState(null);
        const backendURL = process.env.REACT_APP_BACKEND_URL; 
        const [filtroTiempo, setFiltroTiempo] = useState(""); // Estado para el filtro de años y meses
        const [memorialesFiltrados, setMemorialesFiltrados] = useState(memoriales); 
        const [isAdded, setIsAdded] = useState(false); // Estado para saber si se agregó
        const [loading, setLoading] = useState(true); // Para mostrar un indicador de carga
        const [snackbarOpen, setSnackbarOpen] = useState(false);
        const [addedEmails, setAddedEmails] = useState([]);
        const [asunto, setAsunto] = useState('Recuerdo de nuestro querido');
        const [messagePart0, setMessagePart0] = useState('Mantengamos vivo su legado');
        const [messagePart1, setMessagePart1] = useState('Queremos invitarles a recordar y honrar a su ser querido que ya no esta ccon nosotros');
        const [messagePart2, setMessagePart2] = useState(
          'Cada aniversario es una oportunidad para reflexionar sobre el amor y los momentos compartidos, y aunque su ausencia se siente profundamente sus recuerdos permaneceran vivos en nuestros corazones.'
        );
        const [messagePart3, setMessagePart3] = useState(
          'Los invitamos a compartir sus historias, anécdotas y cualquier recuerdo especial que deseen conmemorar a través de su memorial.'
        );
        const [messagePart4, setMessagePart4] = useState(
          'Con cariño'
        );
        const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
        const [isProcessing, setIsProcessing] = useState(false);
        const [successSnackbar, setSuccessSnackbar] = useState(false);
        const [errorSnackbar, setErrorSnackbar] = useState(false);
        const [selectedMemorials, setSelectedMemorials] = useState([]);
        
     
        useEffect(() => {
            const fetchMemoriales = async () => {
              try {
                const response = await axios.get(`${backendURL}/api/enviar/correo/comentarios/${Empresa.id}`, {
                  headers: {
                    Authorization: `Bearer ${Token}`, // Token para autenticar
                  },
                });
          
              
          
                if (response.data) {
                  // Filtra los memoriales que tengan comentarios
                  const memorialesConComentarios = response.data.filter(item => item.comentarios.length > 0);
               
                  // Mapear solo los memoriales que tienen comentarios
                  const memorialesExtraidos = memorialesConComentarios.map(item => ({
                    memorial: {
                      id_memorial: item.id_memorial,
                      nombre_memorial: item.nombre_memorial,
                      fecha_nacimiento: item.fecha_nacimiento,
                      fecha_defuncion: item.fecha_defuncion,
                    },
                    comentarios: item.comentarios.map(comentario => ({
                      id:comentario.id_comentario,
                      nombre: comentario.creador,
                      email: comentario.email,
                    })),
                  }));
                 
          
                  setMemoriales(memorialesExtraidos); // Guardamos solo lo necesario
                } else {
                  setError("No se pudieron cargar los memoriales.");
                }
              } catch (err) {
                setError("Hubo un error al cargar los memoriales.");
              } finally {
                setLoadings(false);
              }
            };
          
            if (Empresa && Token) {
              fetchMemoriales();
            }
          }, [backendURL, Empresa?.id, Token]);
        
        useEffect(() => {
          setShowNavbarFooter(false);
          return () => {
            setShowNavbarFooter(true);
          };
        }, [setShowNavbarFooter]);
      
        if (loadings) return <section  className="loudingsmartmemorial">
                <div className="smartmemorial">
        
              <CircularProgress  size={100}
                  thickness={4} sx={{marginBottom:'50px', color: (UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined') ? UserDataMarca.color_2 :  '#694B81'}}/>
            </div>
                </section>;
       /*  if (error) return <div>{error}</div>; */

        
   
    
      // Función para calcular los años y meses transcurridos
      const calcularTiempoTranscurrido = (fechaDefuncion) => {
      const fecha = new Date(fechaDefuncion);
      const fechaActual = new Date();
  
      const diferenciaAnios = fechaActual.getFullYear() - fecha.getFullYear();
      const diferenciaMeses = fechaActual.getMonth() - fecha.getMonth();
  
      // Si la diferencia de meses es negativa, ajustamos
      const totalMeses = diferenciaAnios * 12 + diferenciaMeses;
      return {
        anios: totalMeses >= 12 ? Math.floor(totalMeses / 12) : 0,
        meses: totalMeses >= 12 ? totalMeses % 12 : totalMeses,
      };
    };
  
    // Agrupar memoriales por los años y meses transcurridos
    const gruposPorTiempo = memoriales.reduce((acc, item) => {
        if (!item.memorial || !item.memorial.fecha_defuncion) return acc; // Verifica si memorial y fecha_defuncion existen
      
        const { anios, meses } = calcularTiempoTranscurrido(item.memorial.fecha_defuncion);
        const key = `${anios > 0 ? anios + " año" + (anios > 1 ? "s" : "") : ""} ${meses > 0 ? meses + " mes" + (meses > 1 ? "es" : "") : ""}`.trim(); // Creando una clave para cada grupo
      
        // Solo agregar grupos con valores válidos de años o meses
        if (key) {
          if (!acc[key]) {
            acc[key] = [];
          }
          acc[key].push(item);
        }
      
        return acc;
      }, {});
  
    // Ordenar las claves (años y meses) de menor a mayor
   
  
    const handleFiltrar = () => {
        // Filtrar los memoriales por tiempo
        const filtrados = memoriales.filter(item => {
          if (filtroTiempo === "") return true;
      
          const { anios, meses } = calcularTiempoTranscurrido(item.memorial.fecha_defuncion);
          const tiempoTranscurrido = `${anios > 0 ? anios + " año" + (anios > 1 ? "s" : "") : ""} ${meses > 0 ? meses + " mes" + (meses > 1 ? "es" : "") : ""}`.trim();
      
          return tiempoTranscurrido.includes(filtroTiempo);
        });
      
        // Crear un array con todos los comentarios de los memoriales filtrados
        const comentariosFiltrados = filtrados.flatMap(item => item.comentarios);
      
        console.log("Comentarios filtrados", comentariosFiltrados);
      
        // Establecer los comentarios filtrados
        setMemorialesFiltrados(comentariosFiltrados);
      };
  
    const handleSnackbarClose = () => {
      setSnackbarOpen(false);
    };
    
    const handleToggleSelection = (email, id) => {
      if (addedEmails.includes(email)) {
        handleRemoveEmail(email, id);
      } else {
        handleAddEmail(email, id);
      }
    };
     
    const handleAddEmail = (email, id) => {
      if (addedEmails.length >= 10) {
        setError('No puedes agregar más de 10 correos electrónicos.');
        setSnackbarOpen(true);
        return;
      }
    
      // Agregar solo si no existen en los estados
      if (email && !addedEmails.includes(email)) {
        setAddedEmails((prev) => [...prev, email]);
      }
      if (id && !selectedMemorials.includes(id)) {
        setSelectedMemorials((prev) => [...prev, id]);
      }
    
      setError(''); // Limpiar errores si existían
    };
    
    const handleRemoveEmail = (email, id) => {
     setAddedEmails((prev) => prev.filter((item) => item !== email));
    setSelectedMemorials((prev) => prev.filter((memorialId) => memorialId !== id));
    };
    
    const handleSendEmail = async () => {
      if (selectedMemorials.length === 0) {
        setErrorSnackbar(true);
        return;
      }
      setIsConfirmModalOpen(false);
      setIsProcessing(true);
      const payload = {
        id_comentario: selectedMemorials,
        id_empresa: Empresa.id,
        asunto,
        mensaje0: messagePart0,
        mensaje1: messagePart1,
        mensaje2: messagePart2,
        mensaje3: messagePart3,
        mensaje4: messagePart4
      };
    
      try {
        // Primera solicitud: enviar correo a los usuarios
        const response1 = await axios.post(
          `${backendURL}/api/enviar/comentarios/correo/memoriales`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          }
        );
    

          setSuccessSnackbar(true);
       
      } catch (error) {
        console.error('Error al enviar el correo:', error);
        setErrorSnackbar(true);
      } finally {
        setIsProcessing(false);
      }
    };
    
 
       const styles = {
       
        titleStyle: {
          color: UserDataMarca?.color_2 && UserDataMarca.color_2 !== 'undefined' ? UserDataMarca.color_2 :'#694B81',
          fontSize:'1rem',
          fontWeight:'600',
          marginTop:'5px'
         
        },
        titleFecha: {
            color: UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined' ? UserDataMarca.color_1 :'#694B81',
            fontSize:'0.8rem',
            fontWeight:'600',
            marginTop:'2px'
          }, 
        
      };

      return (
        <EmpresaLayout>
          <Container maxWidth="lg">
            <Box sx={formStyle}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                }}
              >
                <CgCardHearts
                  style={{
                    fontSize: 60,
                    color:
                      UserDataMarca?.color_1 && UserDataMarca?.color_1 !== "undefined"
                        ? UserDataMarca?.color_1
                        : "#585857",
                  }}
                />
                <Typography
                  variant="h4"
                  align="center"
                  gutterBottom
                  sx={tituloStyle}
                >
                  Correo fidelización
                </Typography>
  
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  sx={titulosubStyle}
                >
                  selecciona el memorial que quieres hacer recordar el aniversario y luego seleciona los comentarios al cual enviaras (recordar que es un maximo de 10 personas por mensaje para evitar el spam) y finalmente edita tu mensaje en el asunto y los texto que corresponde el envio del mensaje 
                </Typography>
              </div>
            </Box>
    
            <Box >
              <Box sx={{ marginTop: "20px", textAlign: "center", marginBottom:'100px' }}>
                <Typography variant="h4" gutterBottom>
                  Memoriales Próximos a Recordar
                </Typography>
                  <Grid container>
                      <Grid item xs={12} sm={6}>
                      <Box sx={{
              height:'800px',
              overflowY:'auto'
            }}>
                      <Box sx={{ marginBottom: "30px", display: "flex", alignItems: "center" }}>
          {/* Filtro por años y meses */}
          <FormControl sx={{ width: "350px", marginRight: "16px" }}>
    <InputLabel sx={{ color:'#585857'}}>Selecciona el memorial para el recordatorio</InputLabel>
    <Select value={filtroTiempo} onChange={(e) => setFiltroTiempo(e.target.value)} sx={{border:'1px solid #585857', color:'#585857'}}>
      <MenuItem  value="">Todos</MenuItem>
      {Object.keys(gruposPorTiempo)
        .filter((key) => key !== "") // Filtrar claves vacías
        .sort((a, b) => {
          const [aAnios, aMeses] = a.split(" ").map((x) => parseInt(x) || 0); // Extraemos los valores de años y meses de la clave
          const [bAnios, bMeses] = b.split(" ").map((x) => parseInt(x) || 0); // Lo mismo para la otra clave
  
          if (aAnios === bAnios) {
            return aMeses - bMeses; // Si los años son iguales, ordenamos por los meses
          }
          return aAnios - bAnios; // Si no, ordenamos por los años
        })
        .map((key) => (
          <MenuItem key={key} value={key}>
            {key}
          </MenuItem>
        ))}
    </Select>
  </FormControl>
  
          {/* Botón de filtro */}
          <Button variant="contained" color="primary" sx={{ marginLeft: "16px", color:'#ffff', borderRadius:'40px' }} onClick={handleFiltrar}>
            Filtrar
          </Button>
        </Box>
        {/* Mostrar los memoriales filtrados */}
        {memorialesFiltrados.length === 0 ? (
  <Typography variant="h6" color="textSecondary">No se encontraron memoriales.</Typography>
) : (
  memorialesFiltrados.map((item) => (
    // Verificar que 'item' tiene los datos necesarios
    item.id ? (
      <Box key={item.id} sx={{ display: "flex",
        width:'80%',
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "16px",
        padding: "16px",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",  }}>

        <Box sx={{ display: "flex", alignItems: "center", marginBottom: "8px" }}>
          <div>
            <Typography variant="h6" sx={{ marginBottom: "8px", fontSize:'0.9rem', color:'#585857', textAlign:'start'}}>
              {item.nombre}
            </Typography>
          </div>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Button
            variant="contained"
            color={addedEmails.includes(item.email) ? "success" : "warning"}
            onClick={() => handleToggleSelection(item.email, item.id)}
            sx={{
              backgroundColor: addedEmails.includes(item.email) ? "#4caf50" : "#ffb300",
              transition: "all 0.3s ease-in-out",
              display: "flex",
              alignItems: "center",
              textTransform:'capitalize',
              fontFamily:'Poppins',
              fontSize:'0.8rem'
            }}
            disabled={loading[item.id]} 
          >
            {loading[item.id] ? (
              <CircularProgress size={24} sx={{ color: "#fff", marginRight: "8px" }} />
            ) : (
              <Fade in={addedEmails.includes(item.email)}>
                <CheckCircleIcon sx={{ color: "#fff", marginRight: "8px" }} />
              </Fade>
            )}
            {addedEmails.includes(item.email) ? "Agregado" : "Agregar"}
          </Button>
        </Box>
      </Box>
    ) : null // Si no existe 'item.id', no renderiza nada para ese 'item'
  ))
)}
      </Box>
  
                      </Grid>
                      <Grid item xs={12} sm={6}>
                          
                      <div
          style={{
            width: '100%',
            margin: '20px',
            borderRadius: '10px',
            height: '100%',
            border: '1px solid #585857',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              backgroundColor: '#f9f9f9',
            }}
          >
            <InputLabel
              sx={{
                color: '#585857',
                marginLeft: '20px',
                padding: '10px',
                fontWeight: '500',
              }}
            >
              Nuevo Mensaje
            </InputLabel>
          </div>
          <div
    style={{
      borderBottom: '1px solid #585857',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'start',
      width: '100%', // Hace que el div ocupe todo el ancho disponible
    }}
  >
    <InputLabel
      sx={{
        color: '#585857',
        marginLeft: '20px',
        fontWeight: '500',
        marginRight: '10px', // Opcional para agregar un espacio entre el label y el campo
      }}
    >
      Asunto
    </InputLabel>
    <TextField
      variant="outlined"
      value={asunto}
          onChange={(e) => setAsunto(e.target.value)}
      InputProps={{
        sx: { color: '#585857',
          border: 'none', // Elimina el borde
        },
      }}
      sx={{
        width:'80%',
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            border: 'none', // Elimina el borde del TextField
          },
        },
      }}
    />
  </div>
        
  
  <div
    style={{
      width: '100%',
      borderBottom: '1px solid #585857',
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'start',
      padding: '0px 2px',
    }}
  >
    <InputLabel
      sx={{
        color: '#585857',
        marginLeft: '20px',
        padding: '10px 0px',
        fontWeight: '500',
      }}
    >
      Para
    </InputLabel>
    <div
      style={{
        width: '80%',
        padding: '10px 0px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {addedEmails.length > 0 && (
        <div>
          {addedEmails.map((email, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '5px',
                borderRadius: '8px',
                backgroundColor: '#f9f9f9',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              }}
            >
              <span style={{ marginRight: '8px', color: '#585857' }}>{email}</span>
              <Button
                onClick={() => handleRemoveEmail(email)}
                sx={{ padding: '5px', borderRadius: '50px', minWidth: '20px' }}
                size="small"
                variant="outlined"
              >
                <CloseIcon sx={{ color: '#585857', fontSize: '1rem' }} />
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  </div>
         <InputLabel
          sx={{
            color: '#585857',
            marginLeft: '20px',
            fontWeight: '500',
          }}
        >
          Mensaje
        </InputLabel>
          {/* Mostrar los correos agregados */}
          
          <Box sx={{ width: '100%', display:'flex', alignItems:'center', justifyContent:'center', flexDirection:'column' }}>
            <Box sx={{
              width:'120px'
            }}>
            {UserDataMarca?.logo ? (
               <img src={`${backendURL}/${UserDataMarca.logo}`} width='100%' />
                 ) : <>
                  </>}  
            </Box>
          
     <Box sx={{
      width:'100%',
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      marginBottom:'30px'
     }}>
        <textarea
      value={messagePart0}
      onChange={(e) => setMessagePart0(e.target.value)}
      rows={messagePart0.split('\n').length} // Ajusta el número de filas según las líneas
      style={{
        textAlign:'center',
        fontFamily:'Poppins',
        background:'#ffff',
        width: '90%', // Ancho completo
        color: '#585857', // Color del texto
        height: '100%', // Altura dinámica
        resize: 'none', // Deshabilita el ajuste manual del tamaño
        fontSize: '0.8rem', // Tamaño de fuente
        outline: 'none', // Elimina el borde al enfocar
        border:'0px'
       
      }}
    />
    </Box>
  <Box sx={{
      width:'85%',
      display:'flex',
      alignItems:'flex-start',
      justifyContent:'start',
      marginBottom:'15px'
     }}>
    <Typography sx={{
      fontFamily:'Poppins',
       color: '#585857', // Color del texto
        fontSize: '0.8rem', }}>
              Estimado/a [nombre del destinario]
            </Typography>
          </Box>
    <Box sx={{
      width:'100%',
      display:'flex',
      alignItems:'center',
      justifyContent:'center'
     }}>
        <textarea
      value={messagePart1}
      onChange={(e) => setMessagePart1(e.target.value)}
      rows={messagePart1.split('\n').length} // Ajusta el número de filas según las líneas
      style={{
        textAlign:'justify',
        fontFamily:'Poppins',
        background:'#ffff',
        width: '86%', // Ancho completo
        color: '#585857', // Color del texto
        height: '60px', // Altura dinámica
        overflowY:'auto',
        resize: 'none', // Deshabilita el ajuste manual del tamaño
        fontSize: '0.8rem', // Tamaño de fuente
        outline: 'none', // Elimina el borde al enfocar
        border:'0px'
       
      }}
    />
    </Box>
    <Box
    sx={{
      width: "300px",
      height: 'auto',
      position: "relative",
      borderRadius: "10px",
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
      marginBottom:'10px'
    }}
  >
    <img
      src={foto}
      alt="Recuerdo"
      style={{
        borderRadius: "10px 10px 0px 0px",
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }}
    />
     <Box
              sx={{
                width:'100%',
                borderRadius:'10px',
              
                marginTop:'0px',
                display:'flex',
                 alignItems:'center',
                 justifyContent:'center',
                 flexDirection:'column'
              }}>
                <hr style={styleHr}/>
                  <div>
                   
                    <h3 style={styles.titleStyle} > Narciza Riquelme</h3>
                    <div className="fechaobipersona" style={styles.titleFecha}> 10/12/2000 • 30/11/2024 </div>
                   
                  </div>
                  
                  <hr style={styleHr}/>
               
           
             
               
              </Box>
              </Box>
    <Box sx={{
      width:'100%',
      display:'flex',
      alignItems:'center',
      justifyContent:'center'
     }}>
        <textarea
      value={messagePart2}
      onChange={(e) => setMessagePart2(e.target.value)}
      rows={messagePart2.split('\n').length} // Ajusta el número de filas según las líneas
      style={{
        textAlign:'justify',
        fontFamily:'Poppins',
        background:'#ffff',
        width: '86%', // Ancho completo
        color: '#585857', // Color del texto
        height: '90px', // Altura dinámica
        overflowY:'auto',
        resize: 'none', // Deshabilita el ajuste manual del tamaño
        fontSize: '0.8rem', // Tamaño de fuente
        outline: 'none', // Elimina el borde al enfocar
        border:'0px'
       
      }}
    />
    </Box>
  
    <Box sx={{
      width:'100%',
      display:'flex',
      alignItems:'center',
      justifyContent:'center'
     }}>
        <textarea
      value={messagePart3}
      onChange={(e) => setMessagePart3(e.target.value)}
      rows={messagePart3.split('\n').length} // Ajusta el número de filas según las líneas
      style={{
        textAlign:'justify',
        fontFamily:'Poppins',
        background:'#ffff',
        width: '86%', // Ancho completo
        color: '#585857', // Color del texto
        height: '90px', // Altura dinámica
        overflowY:'auto',
        resize: 'none', // Deshabilita el ajuste manual del tamaño
        fontSize: '0.8rem', // Tamaño de fuente
        outline: 'none', // Elimina el borde al enfocar
        border:'0px'
       
      }}
    />
    </Box>
    <Box sx={{
      width:'85%',
      display:'flex',
      alignItems:'flex-start',
      justifyContent:'start',
      flexDirection:'column'
      
     }}>
    <textarea
      value={messagePart4}
      onChange={(e) => setMessagePart4(e.target.value)}
      rows={messagePart4.split('\n').length} // Ajusta el número de filas según las líneas
      style={{
        textAlign:'justify',
        fontFamily:'Poppins',
        background:'#ffff',
        width: '100%', // Ancho completo
        color: '#585857', // Color del texto
        height:'auto',
        overflowY:'auto',
        resize: 'none', // Deshabilita el ajuste manual del tamaño
        fontSize: '0.8rem', // Tamaño de fuente
        outline: 'none', // Elimina el borde al enfocar
        border:'0px'
       
      }}
    />
  <Typography sx={{
      fontFamily:'Poppins',
       color: '#585857', // Color del texto
        fontSize: '0.8rem'}}>
             {Empresa.nombre_empresa}
            </Typography>
     </Box>
      
      </Box>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'end', mt: 4 ,p:1}}>
        {/* Botón de enviar correo */}
        <Button
          variant="contained"
          color="primary"
          onClick={() => setIsConfirmModalOpen(true)}
          sx={{
            background: UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined' ? UserDataMarca.color_1 :'#694B81',
            textTransform:'capitalize',
            color:'#ffff'
          }}
        >
          Enviar Correo Electrónico
        </Button>
       
      </Box>
        </div>
        <div>
  
          </div>
      </Grid>
                  </Grid>
               
              </Box>
            </Box>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000} // Duración en milisegundos
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }} // Posición del Snackbar
              >
                <Alert
                  onClose={handleSnackbarClose}
                  severity="error" // Tipo de alerta (error, warning, info, success)
                  sx={{ width: '100%' }}
                >
                  {error}
                </Alert>
              </Snackbar>
               {/* Modal de confirmación */}
        <Modal
          open={isConfirmModalOpen}
          onClose={() => setIsConfirmModalOpen(false)}
          aria-labelledby="confirm-modal-title"
          aria-describedby="confirm-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 400,
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Typography id="confirm-modal-title" variant="h6" component="h2">
              ¿Está seguro de enviar el correo?
            </Typography>
            <Typography id="confirm-modal-description" sx={{ mt: 2 }}>
              Esta acción enviará el correo electrónico a los destinatarios seleccionados.
            </Typography>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end', gap: 2 }}>
              <Button
                variant="outlined"
                onClick={() => setIsConfirmModalOpen(false)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSendEmail}
              >
                Confirmar
              </Button>
            </Box>
          </Box>
        </Modal>
  
        {/* Modal de procesamiento */}
        <Modal
          open={isProcessing}
          aria-labelledby="processing-modal-title"
          aria-describedby="processing-modal-description"
        >
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 200,
              height: 100,
              bgcolor: 'background.paper',
              borderRadius: 2,
              boxShadow: 24,
              p: 4,
            }}
          >
            <CircularProgress />
            <Typography sx={{ ml: 2 }}>Procesando...</Typography>
          </Box>
        </Modal>
  
        {/* Snackbar de éxito */}
        <Snackbar
          open={successSnackbar}
          autoHideDuration={4000}
          onClose={() => setSuccessSnackbar(false)}
        >
          <Alert onClose={() => setSuccessSnackbar(false)} severity="success">
            ¡El correo se envió correctamente!
          </Alert>
        </Snackbar>
  
        {/* Snackbar de error */}
        <Snackbar
          open={errorSnackbar}
          autoHideDuration={4000}
          onClose={() => setErrorSnackbar(false)}
        >
          <Alert onClose={() => setErrorSnackbar(false)} severity="error">
            Hubo un problema al enviar el correo. Inténtelo de nuevo.
          </Alert>
        </Snackbar>
          </Container>
        </EmpresaLayout>
      );
    };
    
    const formStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      gap: "16px",
      marginTop: "50px",
      padding: "10px",
    };
    
    const tituloStyle = {
      fontFamily: "Poppins",
      color: "#585857",
      fontSize: "1.3rem",
      fontWeight: "600",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
    };
    const titulosubStyle = {
      fontFamily: "Poppins",
      color: "#585857",
      fontSize: "0.9rem",
      fontWeight: "500",
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "center",
    };
  
    const styleHr = {
      width:'98%',
      border:'1px solid #ffff',
      background:'#ffff',
      height:'3px',
      borderRadius:'60px',
      marginBottom:'10px'
  
  }