import React, { useState, useEffect } from "react";
import { Box, Typography, Button, Container, TextField,CircularProgress, Alert} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import fondo from "../../Image/inicio/fondo.jpg";


export const ContactoSmart = () => {
  const backendURL = process.env.REACT_APP_BACKEND_URL;
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState("");
    
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
         contact_type:"General"
        });
    
        const handleChanges = (e) => {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
          };
          const handleSubmit = async (e) => {
            e.preventDefault();
            setIsSubmitting(true);
            setError("");
            setSuccess(false);
        
            try {
                const response = await axios.post(
                    `${backendURL}/api/contacto/formulario`, // Ruta del backend
                    formData, // Datos enviados en el cuerpo de la solicitud
                    {
                        headers: {
                            "Content-Type": "application/json", // Asegúrate de usar el tipo de contenido correcto
                        },
                    }
                );
        
                // Si la respuesta es exitosa
                if (response.status === 200 || response.status === 201) {
                    setSuccess(true);
                    setFormData({ name: "", email: "", message: "", contact_type: "General" });
                }
            } catch (err) {
                // Captura errores del backend
                if (err.response && err.response.data.errors) {
                    const backendErrors = Object.values(err.response.data.errors)
                        .flat()
                        .join(", ");
                    setError(backendErrors);
                } else {
                    setError(err.message || "Error desconocido al enviar el formulario");
                }
            } finally {
                setIsSubmitting(false);
            }
        };
    
    return(
        <>
          <ParallaxSectiontres  id="contact">
                <ParallaxImagedos alt="Parallax Background" />
                <Container maxWidth='sm'>
                <Box
                
                  component="form"
                  onSubmit={handleSubmit}
                  sx={{
                    position:'relative',
                    marginTop:'40px',
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    p: 3,
                    backgroundColor: "rgba(255, 255, 255, 0.7)",
                    borderRadius: 2,
                    boxShadow: 3,
                  }}
                >
                  <Typography variant="h4" align="center" gutterBottom sx={{fontFamily:'Poppins', fontWeight:'600',fontSize:'1.5rem',color:'#585857', marginBottom:'0px',  '@media (max-width: 750px)': {
                fontSize: "1rem",
                
              }, }}>
                  Solicite asesoría
                  </Typography>
                  <Typography variant="h6" align="center" gutterBottom sx={{fontFamily:'Poppins', fontWeight:'500',fontSize:'0.9rem',color:'#585857', '@media (max-width: 750px)': {
                fontSize: "0.7rem",
              
              },}}>
                  Si está interesado en hacer crecer tu negocio o desea más información de nuestro servicio, contáctanos completando el siguiente formulario. Responderemos a la brevedad.
                  </Typography>
            
                  {success && (
                    <Alert severity="success">¡Mensaje enviado correctamente!</Alert>
                  )}
                  {error && <Alert severity="error">{error}</Alert>}
            
                  <TextField
                  sx={{background:"#ffff", fontFamily:'Poppins', color:'#585857'}}
                    label="Nombre"
                    name="name"
                    value={formData.name}
                    onChange={handleChanges}
                    fullWidth
                    required
                  />
            
                  <TextField
                  sx={{background:"#ffff", fontFamily:'Poppins', color:'#585857'}}
                    label="Email"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChanges}
                    fullWidth
                    required
                  />
            
                  <TextField
                  sx={{background:"#ffff", fontFamily:'Poppins', color:'#585857'}}
                    label="Mensaje"
                    name="message"
                    value={formData.message}
                    onChange={handleChanges}
                    multiline
                    rows={4}
                    fullWidth
                    required
                  />
            
                  <Box display="flex" justifyContent="center" alignItems="center">
                    {isSubmitting ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{ px: 4, fontFamily:'Poppins', textTransform:'capitalize' }}
                      >
                        Enviar
                      </Button>
                    )}
                  </Box>
                </Box>
                </Container>
                </ParallaxSectiontres>
        
        </>
    )
}



const ParallaxSectiontres = styled(Box)({
    position: "relative",
    height: "100vh",
    overflow: "hidden",
  });
  const ParallaxImagedos = styled("div")({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${fondo})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed", // Imagen fija
  });
  
  