import React, { useState, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider } from "./Components/context/usecontext";
import { AuthEmpresasProvider } from "./Components/context/UsuarioEmpresa";
import { UsersProvider } from "./Components/context/userscontexts";
import { GrupoFamiliarProvider } from "./Components/context/estadofamiliar";
import { LogoutProvider } from "./Components/context/logoutcontext";

/* Inicio informativo */
import { HomeSmart } from "./Components/Inicio/home";
import { IndividualPersonas } from "./Components/Inicio/ComponenetesHome/individual";
import { EmpresasSmart } from "./Components/Inicio/empresas";
import { ContactoSmart } from "./Components/Inicio/contacto";
import VideoPlayer from "./Pages/VideoTutorial";
/* Generales */
import { Navbar } from "./Components/nav-fotter/navbar";
import { Navbarcliente } from "./Components/nav-fotter/navbar-cliente";
import { Navbaradmi } from "./Components/nav-fotter/navbar-admi";
import { HomeInicioSesion } from "./Pages/SmartPersonasHome";
import { Louding } from "./Components/generales/louding";
import { Footer } from "./Components/nav-fotter/footer";
import { FooterSmart } from "./Components/nav-fotter/footersmart";
import { RegistroUsuarioMarca } from "./Components/pagenPublic/registrousuariodosempresas";
import { LoginUserMarca } from "./Components/pagenPublic/loginmarca";
/* restarurar contraseña */
import { Restaurarcontraseña } from "./Components/generales/restaurarcontraseña";
import { Generarnuevacontraseña } from "./Components/Log/contraseña/contraseñanuevagenerar";
import { Finalgenerarcontraseña } from "./Components/Log/contraseña/finalverficarcontraseña";


/* registro */
import { Seleccionmemorial } from "./Pages/tipomemorial";
import { RegistroPrincipal } from "./Pages/Registro";
import { Principalformregistro } from "./Pages/Registro-nuevo-usuario";
import { Memorialfull } from "./Components/Log/Registrar/formularios/full";
import { Memorialbasico } from "./Components/Log/Registrar/formularios/basico";
import { Terminoscondiciones } from "./Pages/terminos";
import { Verificarregistrousuario } from "./Components/Log/Registrar/verificarregistro";
import { Registroexitoso } from "./Pages/registroexitoso";
import { Validacioncorreo } from "./Components/Log/Registrar/validaremail";


/* creacion de memorial */
import { Memorialcrear } from "./Components/Users/Usuariogeneral/creacion memorial/crear memorial";
import { Memorialcreardos } from "./Components/Users/Usuariogeneral/creacion memorial/memorialcreardos";
import { Memorialcreartres } from "./Components/Users/Usuariogeneral/creacion memorial/crearmemorialtres";
import { Demomemorial } from "./Components/Users/Usuariogeneral/demo/demo";
import { Finalcrearmemorial } from "./Components/Users/Usuariogeneral/creacion memorial/finalcreacionmemorial";



/* perfiles */
import { PerfilAdmi } from "./Components/Users/Administrador/perfiladmi";
import { PerfilUsuario } from "./Components/Users/Usuario/Perfilcliente";


/* editar memorial */
import { Editarmemorial } from "./Components/Users/Usuario/funciones-usuario/editarmemorial";
import { Demomemorialdos } from "./Components/Users/Usuariogeneral/demo/demodos";

/* grupofamiliar vista todos */
import GrupoFamiliar from "./Components/pagenPublic/grupoFamiliar";
import { Memorialpagina } from "./Components/pagenPublic/memorial";

/* usuario amdinistrador */
import { Codigos } from "./Components/Users/Administrador/codigos";
import { Generarcodigo } from "./Components/Users/Administrador/generarcodigo";

/* usuarios admin smart home */
import { Todosuser } from "./Components/Users/Administrador/funciones inicio smart memorial/allusers";
import { Generarqrsmartmemorial } from "./Components/Users/Administrador/funciones inicio smart memorial/Generador-qr-smart-memorial";
import { Userdelmessmart } from "./Components/Users/Administrador/funciones inicio smart memorial/qrmesessmart";

/* usuario admi smart memorial funciones empresa */
import { AllEmpresas } from "./Components/Users/Administrador/empresas smart memorial admi/todoslasempresas";
import { CrearEmpresas } from "./Components/Users/Administrador/empresas smart memorial admi/empresacrear";
import { EmpresasSelecion } from "./Components/Users/Administrador/empresas smart memorial admi/empresaselecion";
/* sucrusal smartmemorial admin */
import { SucursalIndex } from "./Components/Users/Administrador/sucursalesSmartMemorial/sucursalesIndex";
import { Crearsucursal } from "./Components/Users/Administrador/sucursalesSmartMemorial/crearSucursales";
import { SucursalSelecion } from "./Components/Users/Administrador/sucursalesSmartMemorial/sucursalseleccion";

/* t6areas herramienta smart emorial admi */
import { TareasEmpresas } from "./Components/Users/Administrador/TareasSmartMemorialAdmin/tareasadmismartmemorial";

/* usuario cliente */
import { Usuariocrearfamilia } from "./Components/Users/Usuariogeneral/creacion memorial/creacr-familia";
import { InicioUsuario } from "./Components/Users/Usuario/funciones-usuario/pagina-inicio";
import { MemorialUsuario } from "./Components/Users/Usuario/funciones-usuario/memoriales";
import { Compartirqrusuario } from "./Components/Users/Usuario/funciones-usuario/compartirqrusuario";
import { Terminosusuario } from "./Components/Users/Usuario/funciones-usuario/terminos";
import { Evento } from "./Components/Users/Usuario/funciones-usuario/evento";
import { Crearevento } from "./Components/Users/Usuario/funciones-usuario/crearevento";
import { Perfilcliente } from "./Components/Users/Usuario/funciones-usuario/perfilusuariocliente";
import { Editarevento } from "./Components/Users/Usuario/funciones-usuario/editarevento";


/* obituario */
import { Obituariocrear } from "./Components/Users/Usuariogeneral/creacion obituario/obituario";
import { Obituariopagina } from "./Components/pagenPublic/obituariopublic";
import { Obituarioeditar } from "./Components/Users/Usuariogeneral/creacion obituario/editarobituario";
import { Obituariocreardos } from "./Components/Users/Usuariogeneral/creacion obituario/obituariocreardos";


/* paginas de error */
import { Errormemorial } from "./Pages/errormemorial";
import { Paginaerror } from "./Pages/404";
import { Inactivoprivado } from "./Pages/inactivoprivado";
import { Inactivopublico } from "./Pages/inactivopublico";


/* Administrador Empresas */
import { LoginEmpresas } from "./Pages/LoginEmpresas";
import { IndexEmpresaAdmin } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/Index/IndexEmpresa";
import { IndexSucursalAdmin } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorSucursal/Index/IndexSucursal";
import { IndexRegistroUsuarioQrEmpresa } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/IndexRegistroUsuarioQr";
import { MemorialRegistroEmpresa } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/IndexRegistroMemorial";
import { MarcaEmpresa } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/Tareas/PaginaMarca";
import { CrearsucursalAdminEmpresa } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/Sucursales/CrearSucursalEmpresa";
import { DemoQr } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/QR/DemoQr";
import { CrearMemorialDemo } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/memorialesEmpresaCrear/MemorialDemo";
import { VerQrDemoAdminEmpresa } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/memorialesEmpresaCrear/VerQrDemo";
import { SucursalesEmpresaEspecifico } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/Sucursales/indexSucursalesempresa";
import { HomeCorreo } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/Tareas/Correo/Correo";
import { CorreoMemoriales } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/Tareas/Correo/CorreoMemoriales";
import { CorreoInformativo } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/Tareas/Correo/CorreoInformativo";
import { CorreoPersonalizado } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/Tareas/Correo/CorreoPersonalizado";

/* empresa memorial */
import { MemorialNuevoCrearEmpresa } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/memoriales/CrearNuevoMemorial";
/* paginas admin empresa */
import { QrHistorialEmpresaAdmi } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/QR/HistorialQr";
import { IndexMemorialEmpresa } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/IndexMemorial";
import { PerfilUsuarioEmpresa } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/IndexPerfil";
import { IndexTareaBaseDatos } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/Tareas/IndexBaseDatosEMpresa";
import { PaginaTodasSucursales } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/Sucursales/IdexPaginaTodasSucursales";
import { PaginaIndexCrearQR } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/QR/PaginaCrearQr";
import { PaginaIndexQREmpresa } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/QR/PaginaQrEmpresa";
import { IndexFidelizacion } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginasAdministradorEmpresa/Tareas/Fidelizacion/PaginaIndexFidelizacion";
/* pagina demo empresa */
import { PaginaEmpresaDemo } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorEmpresa/PaginaExternaMemorialDemo/PaginaEmpresaDemo";
import { EncuestaPublicaUsuario } from "./Components/pagenPublic/EncuestaUsuario";

/* ADMINISTRADOR SUCURSAL */
import { IndexRegistroUsuarioQrSucursal } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorSucursal/PaginasAdministradorSucursal/IndesRegistroUsuarioQr";
import { MemorialRegistroSucursal } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorSucursal/PaginasAdministradorSucursal/IndexRegistroMemorial";
import { IndexMemorialSucursal } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorSucursal/PaginasAdministradorSucursal/IndexMemorial";
import { MemorialNuevoCrearSucursal } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorSucursal/PaginasAdministradorSucursal/memoriales/CrearNuevoMemorial";
import { QrHistorialsucursalAdmi } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorSucursal/PaginasAdministradorSucursal/HistorialQr";
import { PerfilUsuarioSucursal } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorSucursal/PaginasAdministradorSucursal/IndexPerfil";
import { IndexTareaBaseDatosSucursal } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorSucursal/TareasAdminSucursal/IndexBaseDatosSucursal";
import { PaginaCodigoQrSucursal } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorSucursal/PaginasAdministradorSucursal/CrearCodigoQr/PaginaCrearCodigoQr";
import { HomeCorreoIndexSucursal } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorSucursal/TareasAdminSucursal/correo/CorreoIndexTarea";
import { CorreoMemorialesSucursal } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorSucursal/TareasAdminSucursal/correo/CorreoSucursalMemorial";
import { CorreoInformativoSucursal } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorSucursal/TareasAdminSucursal/correo/CorreoSucursalInformativo";
import { CorreoPersonalizadoSucursal } from "./Components/Users/AdministradoresSuperEmpresas/AdministradorSucursal/TareasAdminSucursal/correo/CorreoSucursalInteligente";


const Layout = () => {
  const basename = process.env.REACT_APP_BASENAME || "";
  const [showNavbarFooter, setShowNavbarFooter] = useState(true);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    const currentVersion = process.env.REACT_APP_VERSION;
    const storedVersion = localStorage.getItem('appVersion');

    if (storedVersion !== currentVersion) {
        // Guarda la nueva versión y recarga la página solo si la versión ha cambiado
        localStorage.setItem('appVersion', currentVersion);
        window.location.reload();
    }
}, []);


  const handleLogin = (role) => {
   
    setUserRole(role);
    sessionStorage.setItem('userRole', role.toString()); // Convertir a cadena y guardar en el almacenamiento local
  };

  const handleLogout = () => {
    setUserRole(null);
    sessionStorage.removeItem('userRole'); // Eliminar el rol del usuario del almacenamiento local
    sessionStorage.removeItem('adminToken'); // Eliminar el token de administrador del almacenamiento de sesión
    sessionStorage.removeItem('ClientToken'); // Eliminar el token del cliente del almacenamiento de sesión

  };

  useEffect(() => {
    const storedUserRole =  sessionStorage.getItem('userRole');
    if (storedUserRole) {
      setUserRole(parseInt(storedUserRole, 10)); // Convertir a número y establecer el estado
    }
  }, []);

  return (
    <BrowserRouter basename={basename}>
      <LogoutProvider>
      <UsersProvider>
       <AuthProvider>
        <AuthEmpresasProvider>
       <GrupoFamiliarProvider>
      
      {userRole === 1 && <Navbaradmi onLogin={handleLogin} onLogout={handleLogout}/>}
      {userRole === 2 && <Navbarcliente onLogin={handleLogin} onLogout={handleLogout} />}
      {showNavbarFooter && !userRole && <Navbar /> }
      <Routes>
      {/* INICIO INFORMATIVO  */}
      
      <Route element={<VideoPlayer />} path="/tutorial-empresas" />
      <Route element={<HomeSmart/>} path="/Smart-Memorial" />
      <Route element={<IndividualPersonas/>} path="/personas-smart-memorial" />
      <Route element={<EmpresasSmart/>} path="/" />
      <Route element={<ContactoSmart/>} path="/contacto-smart-memorial" />
      <Route element={<HomeInicioSesion onLogin={handleLogin}/>} path="/SmartPersonas"/>
      {/* FINAL INFORMATIVO */}
        
        <Route element={<RegistroPrincipal  setShowNavbarFooter={setShowNavbarFooter} />} path="/Registro" />
        <Route element={<Principalformregistro />} path="/Registroformusuario" />
        <Route element={<Codigos />} path="/codigos" />
        <Route element={<Generarcodigo />} path="/generar-codigo" />
        <Route element={<Usuariocrearfamilia/>} path="/crearfamilia" />
        <Route element={<PerfilAdmi/>} path="/Administrador" />
        <Route element={<PerfilUsuario/>} path="/usuario/:id" />
        <Route element={<Editarmemorial/>} path="/editarmemorial" />
        <Route element={<Louding/>} path="/carga" />
        <Route element={<Demomemorial/>} path="/demomemorial" />
        <Route element={<Demomemorialdos/>} path="/demomemorialvista" />
        <Route element={<Usuariocrearfamilia/>} path="/familia" />
        <Route element={<InicioUsuario/>} path="/usuariosmart" />
        <Route element={<MemorialUsuario/>} path="/usuariomemoriales" />
        <Route element={<Compartirqrusuario/>} path="/compartirusuarioqr" />
        <Route element={<Memorialcrear/>} path="/crearmemorial" />
        <Route element={<Memorialcreardos/>} path="/crearmemorial/galeriavideo" />
        <Route element={<Memorialcreartres/>} path="/crearmemorial/mensajefondo" />
        <Route element={<Finalcrearmemorial/>} path="/qrmemoriales" />
        <Route element={<GrupoFamiliar setShowNavbarFooter={setShowNavbarFooter} />} path="/familiar/group/:grupofamiliarId" />
        <Route element={<Memorialpagina setShowNavbarFooter={setShowNavbarFooter} />} path="/familiar/memorial/:id" />
        <Route element={<Terminosusuario/>} path="/terminosycondicionessmart" />
        <Route element={<Evento/>} path="/evento" />
        <Route element={<Crearevento/>} path="/crearevento" />
        <Route element={<Editarevento/>} path="/editarevento" />
        <Route element={<Perfilcliente/>} path="/perfilsmart" />
        <Route element={<Restaurarcontraseña/>} path="/restaurarcontraseña" />
        <Route element={<Generarnuevacontraseña/>} path="/nueva-contraseña" />
        <Route element={<Finalgenerarcontraseña/>} path="/contraseña-nueva-generada" />
        <Route element={<Seleccionmemorial/>} path="/solicitarmemorial" />
        <Route element={<Memorialfull/>} path="/solicitarmemorialfull" />
        <Route element={<Memorialbasico/>} path="/solicitarmemorialbasico" />
        <Route element={<Terminoscondiciones/>} path="/smartmemorial-terminos-y-condiciones" />
        <Route element={<Verificarregistrousuario/>} path="/verificacion-de-registro" />
        <Route element={<Errormemorial/>} path="/memorialnoexiste" />
        <Route element={<Inactivoprivado/>} path="/usuarioinactivo" />
        <Route element={<Inactivopublico/>} path="/user-renew" />
        <Route element={<Registroexitoso/>} path="/registro-correcto" />
        <Route element={<Validacioncorreo/>} path="/validarcorreo" />
        <Route element={<Obituariocrear/>} path="/crear-obituario-memorial" />
        <Route element={<Obituariopagina setShowNavbarFooter={setShowNavbarFooter}/>} path="/Obituario/:id" />
        <Route element={<Obituarioeditar/>} path="/Obituario-editar/:id" />
        <Route element={<Obituariocreardos/>} path="/Obituario-crear" />

        {/* administrador smart memorial */}
        <Route element={<Todosuser/>} path="/usuariossuscritos" />
        <Route element={<Generarqrsmartmemorial/>} path="/generar-qr-smart-memorial" />
        <Route element={<Userdelmessmart/>} path="/usuarios-mes" />
        <Route element={<AllEmpresas/>} path="/todas-empresas" />
        <Route element={<CrearEmpresas/>} path="/crear-empresas" />
        <Route element={<EmpresasSelecion/>} path="/empresa-admin-smart/:id" />
        <Route element={<SucursalIndex/>} path="/sucursal-index" />
        <Route element={<Crearsucursal/>} path="/crear-sucursal" />
        <Route element={<SucursalSelecion/>} path="/sucursal-admin-smart/:id" />
        <Route element={<TareasEmpresas/>} path="/Crear-Tareas" />
        
        {/* Administrador Empresas */}
        <Route element={<LoginEmpresas setShowNavbarFooter={setShowNavbarFooter}/>} path="/Inicio-Sesion-Empresas"   />
        <Route element={<IndexEmpresaAdmin setShowNavbarFooter={setShowNavbarFooter}/>} path="/Inicio-Empresa/:id"   />
        <Route element={<IndexSucursalAdmin setShowNavbarFooter={setShowNavbarFooter}/>} path="/Inicio-Sucursal/:id"   />
        <Route element={<IndexRegistroUsuarioQrEmpresa setShowNavbarFooter={setShowNavbarFooter}/>} path="/registro/:id/usuario/empresa"   />
        <Route element={<MemorialRegistroEmpresa setShowNavbarFooter={setShowNavbarFooter}/>} path="/registro/memorial/:id/empresa"   />
        <Route element={<SucursalesEmpresaEspecifico setShowNavbarFooter={setShowNavbarFooter}/>} path="/sucursales/:id/empresa"   />
        <Route element={<MarcaEmpresa setShowNavbarFooter={setShowNavbarFooter}/>} path="/empresa/tarea/marca"   />
        <Route element={<CrearsucursalAdminEmpresa setShowNavbarFooter={setShowNavbarFooter}/>} path="/empresa/:id/crear-sucursal"/>
        <Route element={<DemoQr setShowNavbarFooter={setShowNavbarFooter}/>} path="/crear/demo/empresa/:id"   />
        <Route element={<CrearMemorialDemo setShowNavbarFooter={setShowNavbarFooter}/>} path="/crear/demo/memorial/empresa/:id"/>
        <Route element={<VerQrDemoAdminEmpresa setShowNavbarFooter={setShowNavbarFooter}/>} path="/ver/demo/memorial/empresa/:id"/>
        <Route element={<QrHistorialEmpresaAdmi setShowNavbarFooter={setShowNavbarFooter}/>} path="/empresa/historial/qr/:id"/>
        <Route element={<IndexMemorialEmpresa setShowNavbarFooter={setShowNavbarFooter}/>} path="/empresa/:id/qr/memorial/:id_qr"/>
        <Route element={<MemorialNuevoCrearEmpresa setShowNavbarFooter={setShowNavbarFooter}/>} path="/empresa/:id/memorial/crear/nuevo"/>
        
        {/* pagina empresa */}
        <Route element={<PaginaEmpresaDemo setShowNavbarFooter={setShowNavbarFooter}/>} path="/empresa/:nombre_empresa/:id/:id_qr"/>
        <Route element={<PerfilUsuarioEmpresa setShowNavbarFooter={setShowNavbarFooter}/>} path="/empresa/perfil/:id"/>
        <Route element={<IndexTareaBaseDatos setShowNavbarFooter={setShowNavbarFooter}/>} path="/empresa/:id/bd/tarea"/>
        <Route element={<PaginaTodasSucursales setShowNavbarFooter={setShowNavbarFooter}/>} path="/empresa/Index/Sucursales/:id"/>
        <Route element={<PaginaIndexCrearQR setShowNavbarFooter={setShowNavbarFooter}/>} path="/empresa/creacion/qr/:id"/>
        <Route element={<PaginaIndexQREmpresa setShowNavbarFooter={setShowNavbarFooter}/>} path="/empresa/creacion/qrempresa/:id"/>
        <Route element={<IndexFidelizacion setShowNavbarFooter={setShowNavbarFooter}/>} path="/empresa/fidelizacion/inicio/:id"/>
        <Route element={<EncuestaPublicaUsuario setShowNavbarFooter={setShowNavbarFooter}/>} path="/encuesta/:id_empresa/:id_encuesta/:id_usuario"/>
        <Route element={<HomeCorreo setShowNavbarFooter={setShowNavbarFooter}/>} path="/correo/fidelizacion/:id"/>
        <Route element={<CorreoMemoriales setShowNavbarFooter={setShowNavbarFooter}/>} path="/correo/memorial/:id_empresa"/>
        <Route element={<CorreoInformativo setShowNavbarFooter={setShowNavbarFooter}/>} path="/correo/informacion/:id_empresa"/>
        <Route element={<CorreoPersonalizado setShowNavbarFooter={setShowNavbarFooter}/>} path="/correo/personalizado/:id_empresa"/>

        
        {/* administrador sucursal */}
        <Route element={<IndexRegistroUsuarioQrSucursal setShowNavbarFooter={setShowNavbarFooter}/>} path="/registro/:id/usuario/sucursal"/>
        <Route element={<MemorialRegistroSucursal setShowNavbarFooter={setShowNavbarFooter}/>} path="/registro/memorial/:id/sucursal"   />
        <Route element={<IndexMemorialSucursal setShowNavbarFooter={setShowNavbarFooter}/>} path="/sucursal/:id/qr/memorial/:id_qr"/>
        <Route element={<MemorialNuevoCrearSucursal setShowNavbarFooter={setShowNavbarFooter}/>} path="/sucursal/:id/memorial/crear/nuevo"/>
        <Route element={<QrHistorialsucursalAdmi setShowNavbarFooter={setShowNavbarFooter}/>} path="/sucursal/:id/qr"/>
        <Route element={<PerfilUsuarioSucursal setShowNavbarFooter={setShowNavbarFooter}/>} path="/sucursal/perfil/:id"/>
        <Route element={<IndexTareaBaseDatosSucursal setShowNavbarFooter={setShowNavbarFooter}/>} path="/sucursal/:id/bd/tarea"/>
        <Route element={<PaginaCodigoQrSucursal setShowNavbarFooter={setShowNavbarFooter}/>} path="/sucursal/crearqr/:id"/>
        <Route element={<HomeCorreoIndexSucursal setShowNavbarFooter={setShowNavbarFooter}/>} path="/sucursal/correo/fidelizacion/:id"/>
        <Route element={<CorreoMemorialesSucursal setShowNavbarFooter={setShowNavbarFooter}/>} path="/sucursal/correo/memorial/:id"/>
        <Route element={<CorreoInformativoSucursal setShowNavbarFooter={setShowNavbarFooter}/>} path="/sucursal/correo/informativo/:id"/>
        <Route element={<CorreoPersonalizadoSucursal setShowNavbarFooter={setShowNavbarFooter}/>} path="/sucursal/correo/general/:id"/>
       
        {/* pagina registro usuario empresa */}
        <Route element={<RegistroUsuarioMarca setShowNavbarFooter={setShowNavbarFooter}/>} path="/empresa/:id/registro/:id_marca/usuario"/>
        <Route element={<LoginUserMarca setShowNavbarFooter={setShowNavbarFooter} onLogin={handleLogin}/>} path="/empresa/:id/login/usuario"/>
       
       
        {/* paginas error */}
        <Route element={<Paginaerror/>} path="*" />

      </Routes>
      {userRole === 1 && <Footer onLogin={handleLogin} onLogout={handleLogout}/>}
      {showNavbarFooter && !userRole && <FooterSmart /> }
      </GrupoFamiliarProvider>
      </AuthEmpresasProvider>
      </AuthProvider>
      </UsersProvider>
      </LogoutProvider>
    </BrowserRouter>
  );
};

export default Layout;