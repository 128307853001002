import React from "react";
import axios from "axios";
import rateLimit from 'axios-rate-limit';
import { useUserEmpresa } from "../../../../../context/UsuarioEmpresa";
import { useState, useEffect } from 'react'
import {
  Box,
  Select, 
  CircularProgress,
  MenuItem,
  Modal,
  Button,
  Alert,
  AlertTitle, 
  Chip,
  Grid,
  IconButton,
  TextField,
  Checkbox,
  FormControlLabel, 
  Typography } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
/* icono */
import { RxCalendar } from "react-icons/rx";
import { LuClock2 } from "react-icons/lu";
import { FiAlertCircle } from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import { useLocation, useNavigate, Link } from "react-router-dom";
import "../../../../../../Style/usuario/obituario.css"
import "../../../../../../Style/AdministradorSuperEmpresa/adminmemorialempresapagina.css"
import { Iconos, Iconosnaranjos, Iconosverdes } from "../../../../../../data/dataicono";
import { LuHeart } from "react-icons/lu";
/* diseños */
import { DiseñoSmart } from "./diseñosmart";
import { DiseñoRecuerdo } from "./DiseñoRecuerdo";

const http = rateLimit(axios.create(), { maxRequests: 1, perMilliseconds: 3000 });





export const CrearObituarioEmpresa = ({memorial, grupoFamiliar, empresa, onclose, onObituarioCreado}) => {
  const {Token, UserDataMarca} = useUserEmpresa()
  const [loading, setLoading] = useState(false);
  const [newParentesco, setNewParentesco] = useState(''); // Estado para el nuevo parentesco
  const [additionalParentescos, setAdditionalParentescos] = useState([]); 
  const [memorialData, setMemorialData] = useState({});
  const [selectedDesign, setSelectedDesign] = useState(null);
  const [selectedOption, setSelectedOption] = useState('clasicoSmartMemorial');
  const backendURL = process.env.REACT_APP_BACKEND_URL;
  const [selectedIcon, setSelectedIcon] = React.useState(Iconos[0].value);
  const [direccionVelatorio, setDireccionVelatorio] = useState('');
  const [fechadesdeVelatorio, setFechadesdeVelatorio] = useState('');
  const [edadcheck, setEdadcheck] = useState(false); 
  const [fechahastaVelatorio, setFechahastaVelatorio] = useState('');
  const [horaSalida, setHoraSalida] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [fechaFuneral, setFechaFuneral] = useState('');
  const [horaFuneral, setHoraFuneral] = useState('');
  const [selectedChips, setSelectedChips] = useState([]);
  const [genero, setGenero] = useState('');
  const [direccion, setDireccion] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [edad, setEdad] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [processingModal, setProcessingModal] = useState(false);
  const [alertType, setAlertType] = useState("info");
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);


  const handleOpenModal = () => setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);
  
  const handleChange = (event) => {
    setSelectedIcon(event.target.value);
  };

  /* memorial */
  const fetchMemorialData = async (id) => {
    if (!empresa?.id || !Token) return;
    try {
      const response = await http.get(`${backendURL}/api/getMemorialIddemo/${memorial.id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${Token}`,
        }
      });
      setMemorialData(response.data);
      setDireccion(response.data.cementerio);
      const fechaNacimiento = new Date(response.data.fecha_nacimiento);
      const fechaDefuncion = new Date(response.data.fecha_defuncion);
      const calculatedEdad = calcularEdad(fechaNacimiento, fechaDefuncion);

      setEdad(calculatedEdad);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching memorial data:', error);
      setLoading(false);
    }
  };

  const calcularEdad = (fechaNacimiento, fechaDefuncion) => {
    let edad = fechaDefuncion.getFullYear() - fechaNacimiento.getFullYear();
    let meses = fechaDefuncion.getMonth() - fechaNacimiento.getMonth();
    let dias = fechaDefuncion.getDate() - fechaNacimiento.getDate();

    // Ajustar los días si la diferencia es negativa
    if (dias < 0) {
        meses--;  // Reducimos un mes
        const ultimoDiaMesAnterior = new Date(
            fechaDefuncion.getFullYear(),
            fechaDefuncion.getMonth(),
            0
        ).getDate();
        dias += ultimoDiaMesAnterior;  // Sumamos los días restantes del mes anterior
    }

    // Ajustar los meses si la diferencia es negativa
    if (meses < 0) {
        edad--;  // Reducimos un año
        meses += 12;  // Sumamos 12 meses para ajustar
    }

    // Mostrar solo el valor más relevante
    if (edad > 0) {
        return `${edad} ${edad === 1 ? 'año' : 'años'}`;
    } else if (meses > 0) {
        return `${meses} ${meses === 1 ? 'mes' : 'meses'}`;
    } else {
        return `${dias} ${dias === 1 ? 'día' : 'días'}`;
    }
};

  useEffect(() => {
    fetchMemorialData(memorial.id);
  }, [memorial.id]);

/* editar fondo y cementerio selecion */

const handleSelectChange = (event) => {
  const selectedValue = event.target.value;
  setSelectedOption(selectedValue);
  updatefondo(selectedValue, direccion); // Actualiza fondo y dirección
};

const handleDireccionChange = (event) => {
  const newDireccion = event.target.value;
  setDireccion(newDireccion);

  updatefondo(selectedOption, newDireccion); // Actualiza fondo y dirección
};

const updatefondo = async (selectedOption, newDireccion) => {
  try {
    const response = await axios.post(`${backendURL}/api/updateMemorial/${memorial.id}`,
      { fondo: selectedOption, cementerio: newDireccion }, 
      {
        headers: {
          'Authorization': `Bearer ${Token}`
        }
      }
    );

    fetchMemorialData(memorial.id);
  } catch (error) {
    console.error('Error al editar el memorial:', error);
  }
};
/* selecion del diseño  */
const handleSelectDesign = (design) => {
  setSelectedDesign(design);
};

/* click */
   // Manejar la selección del género
   const handleGeneroChange = (event) => {
    setGenero(event.target.value);
    setSelectedChips([]); // Reinicia los chips seleccionados al cambiar el género
  };

  // Manejar el clic en el Chip
  const handleChipClick = (label) => {
    setSelectedChips((prev) =>
      prev.includes(label) ? prev.filter((chip) => chip !== label) : [...prev, label]
    );
  };

  const handleAddParentesco = () => {
    if (newParentesco.trim() !== '') {
      setAdditionalParentescos((prev) => [...prev, newParentesco.trim()]);
      setSelectedChips((prev) => [...prev, newParentesco.trim()]); // Añadir al array de chips seleccionados
      setNewParentesco(''); // Limpiar el campo de texto
    }
  };

  // Listas de parentesco según el género
  const parentescoMasculino = [
    'Papá',
    'Hermano',
    'Esposo',
    'Abuelo',
    'Tío',
    'Hijo',
    'Primo',
    'Nieto',
  ];

  const parentescoFemenino = [
    'Madre',
    'Hermana',
    'Esposa',
    'Abuela',
     'Tía',
    'Hija',
    'Prima',
    'Nieta',
   
  ];

  const parentesco = genero === 'Masculino' ? parentescoMasculino : parentescoFemenino;

 const handleEdadCheckChange = (event) => {
    setEdadcheck(event.target.checked);
};

  /* formulario obituario  */
  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setProcessingModal(true); 

    const missingFields = [];
    if (!descripcion) missingFields.push("Descripción");
    if (!genero) missingFields.push("Género");
    if (selectedChips.length === 0) missingFields.push("Parentesco");
    if (!direccionVelatorio) missingFields.push("Dirección del Velatorio");
    if (!fechadesdeVelatorio) missingFields.push("Fecha de Inicio del Velatorio");
    if (!fechahastaVelatorio) missingFields.push("Fecha de Fin del Velatorio");
    if (!horaSalida) missingFields.push("Hora de Salida");
    if (!fechaFuneral) missingFields.push("Fecha del Funeral");
    if (!horaFuneral) missingFields.push("Hora del Funeral");
    if (!selectedDesign) {
      setErrorMessage("Debes seleccionar un diseño para continuar.");
      setAlertType("warning");
      setIsOpen(true);
      setProcessingModal(false);
      setLoading(false);
      return;
    }

    if (missingFields.length > 0) {
      setLoading(false);
      setErrorMessage(`Faltan los siguientes campos: ${missingFields.join(", ")}`);
      setAlertType("warning");
      setProcessingModal(false);
      setIsOpen(true); // Abre el modal
      return;
    }
  
    const combinedParentescos = [...new Set([...selectedChips, ...additionalParentescos])];

    const obituarioData = {
      descripcion: descripcion,
      genero: genero,
      estado: 1,
      parentesco: JSON.stringify(combinedParentescos),
      direccion: direccionVelatorio,
      fecha_desde: fechadesdeVelatorio,
      fecha_hasta : fechahastaVelatorio,
      hora_salida: horaSalida,
      fecha_funeral: fechaFuneral,
      hora_funeral: horaFuneral,
      icono: selectedIcon,
      edad: edadcheck, 
      diseno: selectedDesign, 
      id_memorial: memorial.id
    };


    try {
      const response = await axios.post(`${backendURL}/api/crearObituarioempresa`, obituarioData, {
        headers: {
          'Authorization': `Bearer ${Token}`,
          'Content-Type': 'application/json'
        }
      });
      setLoading(false);
      setProcessingModal(false);
      setSuccessMessage("Obituario creado exitosamente.");
      setAlertType("success");
      setIsOpen(true);
      onObituarioCreado();
      onclose();
      // Redirige a una página después de crear el obituario
    } catch (error) {
      setProcessingModal(false);
      setLoading(false);
      setErrorMessage("Ocurrió un error al crear el obituario. Por favor, inténtalo de nuevo.");
      setAlertType("error");
      setIsOpen(true);
    }
  };
  
  

    return(
        <section style={{
            width:'100%',
            fontFamily:'Poppins'
        }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            background: "#ffff",
            justifyContent: 'center',
            alignItems: 'center',

          }}
        >
              <section className="titulopaginacrearobituario">
            <h1 className="tituloobituariocrear">Creación del obituario {memorial.nombre}</h1>          
          </section>
          <form onSubmit={handleSubmit}  style={{
            width:'100%'
        }}>
          
          <section className="seccionform" style={{display:"flex", alignItems:"center", flexDirection:'row', marginTop:'20px', marginBottom:'20px'}}>
          <div class="corazoncheckobituario" title="Like">
            <input type="checkbox" className="checkbox" id="Give-It-An-Id" checked={edadcheck}
            onChange={handleEdadCheckChange}/>
            <div class="svg-container">
                <svg viewBox="0 0 24 24" class="svg-outline" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Zm-3.585,18.4a2.973,2.973,0,0,1-3.83,0C4.947,16.006,2,11.87,2,8.967a4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,11,8.967a1,1,0,0,0,2,0,4.8,4.8,0,0,1,4.5-5.05A4.8,4.8,0,0,1,22,8.967C22,11.87,19.053,16.006,13.915,20.313Z">
                    </path>
                </svg>
                <svg viewBox="0 0 24 24" class="svg-filled" xmlns="http://www.w3.org/2000/svg">
                    <path d="M17.5,1.917a6.4,6.4,0,0,0-5.5,3.3,6.4,6.4,0,0,0-5.5-3.3A6.8,6.8,0,0,0,0,8.967c0,4.547,4.786,9.513,8.8,12.88a4.974,4.974,0,0,0,6.4,0C19.214,18.48,24,13.514,24,8.967A6.8,6.8,0,0,0,17.5,1.917Z">
                    </path>
                </svg>
                <svg class="svg-celebrate" width="100" height="100" xmlns="http://www.w3.org/2000/svg">
                    <polygon points="10,10 20,20"></polygon>
                    <polygon points="10,50 20,50"></polygon>
                    <polygon points="20,80 30,70"></polygon>
                    <polygon points="90,10 80,20"></polygon>
                    <polygon points="90,50 80,50"></polygon>
                    <polygon points="80,80 70,70"></polygon>
                </svg>
            </div>
        </div>
        <div style={{display:'flex', flexDirection:'column'}}>
        <span className="activaredadobituario"> Haz clic en el  <LuHeart /> si desea mostrar en el obituario la edad de {memorial.nombre}</span>
        <span className="activaredadobituario"><strong>Estado Actual:</strong> {edadcheck ? "Mostrar edad" : "Ocultar edad"} </span>
        <span className="activaredadobituario"><strong>Edad que se mostrara en el obituario de {memorial.nombre}:</strong>  {edad}  </span>
        </div>
        </section>
          <section className="seccionform">
                <h3 className="subtituloformcrearobiturio">Aviso del desceso</h3>
                <div className="inputformobituario">
                  <textarea
                  style={{
                    background:'#ffff',
                    color:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                                                    ? UserDataMarca.color_1 
                                                    : '#694B81',
                  }}
                    placeholder="Escriba información para quienes deseen asistir al velatorio y/o funeral..."
                    className="inputdescriopcionformcrearEmpresaobituario"
                    onChange={handleInputChange(setDescripcion)} />
                </div>

                {/* sector de selecionar mostrar la edad del difunto  */}
                
                
                <div className="inputformgenero">
                    <label htmlFor='genero'>Género</label>
                    <div className='contieneinputEmpresaobituariogenero'> < StyledSelect
                      name='genero'
                      id='genero'
                      sx={{textAlign:"left"}}
                      value={genero}
                      onChange={handleGeneroChange}
                      className="input-select-registro"
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                      IconComponent={KeyboardArrowDownIcon}
                    >
                      <MenuItem value="">
                        <em>Seleccionar género...</em>
                      </MenuItem>
                      <MenuItem value={"Masculino"}>Masculino</MenuItem>
                      <MenuItem value={"Femenino"}>Femenino</MenuItem>
                    
                    </ StyledSelect>
                  </div>  
                  </div>
              </section>
             {genero && ( 
              <section className="seccionform">
                <h3 className="subtituloformcrearobiturio">Titulo de parentesco</h3>
                <span className="infosuvtitulo">Puedes seleccionar varias opciones</span>
                
                <div className="contienechip">
                {[...parentesco, ...additionalParentescos].map((label) => (
              <Chip
                key={label}
                label={label}
                onClick={() => handleChipClick(label)}
                sx={{
                  margin: '10px',
                  fontSize: '0.8rem',
                  fontWeight: '500',
                  fontFamily: 'Poppins',
                  backgroundColor: selectedChips.includes(label) ? '#694B81' : '#E1DBE6',
                  color: selectedChips.includes(label) ? 'white' : '#694B81',
                  '&:hover': {
                    backgroundColor: selectedChips.includes(label) ? '#694B81' : '#E1DBE6',
                  },
                }}
              />
            ))}
                </div>
                <div style={{ marginTop: '20px' }}>
                <TextField
                  
                  variant="outlined"
                  value={newParentesco}
                   onChange={(e) => setNewParentesco(e.target.value)}
                  fullWidth
                  sx={{
                    borderRadius:'50px',
                    color:'#694B81',
                    marginBottom: '10px',
                    fontFamily: 'Poppins',
                    border: '3px solid #F0EDF3',
                    '& .MuiOutlinedInput-input':{
                      color:'#694B81',
                    }
                  }}
                />
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddParentesco}
                  sx={{
                    color:'#ffff',
                    textTransform:'capitalize',
                    fontFamily: 'Poppins',
                    backgroundColor: '#694B81',
                    '&:hover': {
                      backgroundColor: '#5a3a6c',
                    },
                  }}
                >
                  Agregar
                </Button>
                </div>
                
  
                </section>)}
          <section  className="seccionform">
                <h3 className="subtituloformcrearobiturio">Velatorio</h3>
                <div className="inputformobituario">
                  <label htmlFor="direccion" className="labelfromobituario"> Dirección del lugar</label>
                  <input type="text" id="direccion" name="direccion"  className="inputcreaempresaobituario" onChange={handleInputChange(setDireccionVelatorio)}/>
                </div>
               
                <Grid container>
                  <Grid item xs={12}> 
                     <label htmlFor="direccion" className="labelfromobituario">
                       Fecha veltario
                     </label>
                  </Grid>
                  <Grid item xs={6}>
                  <label htmlFor="direccion" className="labelfromobituario">
                       Fecha inicio
                     </label>
                    <div className="inputcrearEmpresaobituario">
                    <div className="contieneninputfechaobituariocrear">
                    <input  type="date"   id="fecha" name="fecha" className="inputcreaempresaobituario" onChange={handleInputChange(setFechadesdeVelatorio)} />
                    <div className="calendariocreacionobituarioicono">
                      <RxCalendar className="iconocrearobituariocalendario" />
                    </div>
                  </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                  <label htmlFor="direccion" className="labelfromobituario">
                       Fecha termino
                     </label>
                    <div className="inputcrearEmpresaobituario">
                    <div className="contieneninputfechaobituariocrear">
                    <input  type="date"   id="fecha" name="fecha" className="inputcreaempresaobituario" onChange={handleInputChange(setFechahastaVelatorio)}/>
                    <div className="calendariocreacionobituarioicono">
                      <RxCalendar className="iconocrearobituariocalendario" />
                    </div>
                  </div>
                    </div>
                  </Grid>
                </Grid>
          </section>
          <section  className="seccionform">  
                <h3 className="subtituloformcrearobiturio">Cortejo fúnebre</h3>
                <div>
                  <label htmlFor="direccion" className="labelfromobituario"> Hora de salida </label>
                  <div className="inputcrearobituario">
                      <div className="contienenrelojcrearobituario" style={{justifyContent:"flex-start"}}>
                        <input  className="inputcreahorariodosoempresabituario"
                          placeholder="Desde.."
                          type="time"
                          name="hora"
                          onChange={handleInputChange(setHoraSalida)}
                        />
                        <div className="relojiempresabituariocono" >
                          <LuClock2 className="relojempresaiobituariocono" />
                        </div>
                      </div>
                    </div>
                </div>

          </section>
          <section  className="seccionform">
                <h3 className="subtituloformcrearobiturio">Funeral</h3>
                <div className="inputformobituario">
                  <label htmlFor="direccion" className="labelfromobituario"> Dirección del lugar</label>
                  <input style={{
                    background:'#ffff',
                    color:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                                                    ? UserDataMarca.color_1 
                                                    : '#694B81',
                  }} type="text" id="direccion" name="direccion" className="inputcrearformEmpresaobituario" value={direccion} 
            onChange={handleDireccionChange}  />
                </div>

                <Grid container>

                  <Grid item xs={6}>
                    <div >
                      <label htmlFor="direccion" className="labelfromobituario"> Fecha</label>
                      <div className="contieneninputfechaobituariodoscrear">
                    <input  type="date"   id="fecha" name="fecha" className="inputcreaempresaobituario" onChange={handleInputChange(setFechaFuneral)}/>
                    <div className="calendariocreacionobituarioicono">
                      <RxCalendar className="iconocrearobituariocalendario" />
                    </div>
                  </div>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div  >
                      <label htmlFor="direccion" className="labelfromobituario"> Hora</label>
                      <div className="inputcrearEmpresaobituario">
                      <div className="contienenrelojcrearempresaobituario">
                        <input  className="inputcreahorarioempresaobituario"
                          placeholder="Desde.."
                          type="time"
                          name="hora"
                          onChange={handleInputChange(setHoraFuneral)}
                        />
                        <div className="relojiobituariocono">
                          <LuClock2 className="iconoeventoobituariocalendario" />
                        </div>
                      </div>
                    </div>
                    </div>
                  </Grid>
                </Grid>

              </section>
              <section  className="seccionform">
                <div className="contieneiconoobitiario">
                <h3 className="subtituloformcrearobiturio"> Seleccione un icono</h3>
                <SelectItem
                 className="seleciondefondofinal"
                 IconComponent={KeyboardArrowDownIcon}   
                 value={selectedIcon}
                 onChange={handleChange}
                 displayEmpty
                 inputProps={{ 'aria-label': 'Seleccione un icono' }}
                 renderValue={(selected) => {
                   const selectedIconData = Iconos.find(icono => icono.value === selected);
                   return selectedIconData ? (
                     <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                       <img
                         src={selectedIconData.src}
                         alt="Icon Preview"
                         style={{ width: '32px', height: '32px', objectFit: 'contain' }}
                       />
                     </div>
                   ) : (
                     <span>Seleccione un icono</span>
                   );
                 }}
               >
          {Iconos.map((icono) => (
        
        <CustomMenu key={icono.value} value={icono.value} >
          <img
            src={icono.src}
            alt={icono.alt}
            style={{ width: '40px', height: '40px', objectFit: 'contain', marginRight: '10px' }}
          />
        </CustomMenu>
  
      ))}
         
      </SelectItem>
                </div>
                <div>
      <div className="informacionalertaobituario">
        <h3 className="subtituloformcrearobiturio">Selección del diseño del obituario</h3>
        <Grid container spacing={2}>
          {/* Diseño Regular */}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                border: '2px solid',
                borderColor: selectedDesign === '1' ? '#694B81' : '#E1DBE6',
                borderRadius: '10px',
                padding: '0px',
                textAlign: 'center',
                backgroundColor: selectedDesign === '1' ? '#F0EDF3' : 'white',
              }}
              onClick={() => handleSelectDesign('1')}
            >
              <div style={{
                height:'400px', overflowY:'auto'
              }}>
                <DiseñoSmart/>

              </div>
             
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedDesign === '1'}
                    onChange={() => handleSelectDesign('1')}
                    sx={{ color: '#694B81', '&.Mui-checked': { color: '#694B81' } }}
                  />
                }
                label="Seleccionar"
                sx={{ color: '#694B81', fontFamily: 'Poppins', '& .MuiTypography-root':{color: '#694B81',fontWeight:'500'} }}
              />
            </Box>
          </Grid>

          {/* Diseño Nuevo */}
          <Grid item xs={12} sm={6}>
            <Box
              sx={{
                border: '2px solid',
                borderColor: selectedDesign === '2' ? '#694B81' : '#E1DBE6',
                borderRadius: '10px',
                padding: '0px',
                textAlign: 'center',
                backgroundColor: selectedDesign === '2' ? '#F0EDF3' : 'white',
              }}
              onClick={() => handleSelectDesign('2')}
            >
               <div style={{
                height:'400px', overflowY:'auto'
              }}>
                <DiseñoRecuerdo/>

              </div>
             
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedDesign === '2'}
                    onChange={() => handleSelectDesign('2')}
                    sx={{ color: '#694B81', '&.Mui-checked': { color: '#694B81'  } }}
                  />
                }
                label="Seleccionar"
                sx={{ color: '#694B81', fontFamily: 'Poppins', '& .MuiTypography-root':{color: '#694B81',fontWeight:'500'} }}
              />
            </Box>
          </Grid>
        </Grid>
      </div>

      
      
    </div>


              </section>
              <section className="alertainformacion">
                 <div className="iconoalertaobit"><FiAlertCircle  /></div> 
                 <div className="informacionalertaobituario">
                  <span>El Obituario se mostrará durante los siguientes </span>
                  <span className="bold">7 días desde la fecha de creación</span>
                  </div>
              </section>
              <section className="buttonovituario">
              <button className="previsualizacion-obituario"  onClick={handleSubmit}>
              {loading ? <CircularProgress size={24} /> : 'Crear Obituario'}
                </button>
                </section>
          </form>
            </Box>
    {/* procesos */}
    <AlertaModal
        alertType={alertType}
        successMessage={successMessage}
        errorMessage={errorMessage}
        isOpen={isOpen}
        handleClose={handleCloseModal}
      />

<Modal
    open={processingModal}
    onClose={() => setProcessingModal(false)}
    aria-labelledby="processing-modal"
    aria-describedby="processing-description"
>
    <Box sx={{ ...modalStyle }}>
    <h3 id="processing-modal" style={{margin:'0'}} >Procesando solicitud...</h3>
        <p id="processing-description">Por favor, espera mientras procesamos tu solicitud.</p>
    </Box>
</Modal>
        </section>
    )
}


/* alerta */
const AlertaModal = ({ alertType, successMessage, errorMessage, isOpen, handleClose }) => {
  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="alert-modal-title"
      aria-describedby="alert-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '30%',
          left: '50%',
          transform: 'translate(-50%, 0)',
          zIndex: 1000,
          width: '80%',
          maxWidth: '600px',
          bgcolor: '#ffff',
          boxShadow: 24,
          p: 0,
          borderRadius: 2,
          fontFamily:'Poppins'
        }}
      >
        <IconButton
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
        {alertType === 'success' && successMessage && (
          <Alert severity="success">
            <AlertTitle sx={{
              fontFamily:'Poppins'
            }}>Éxito</AlertTitle>
            {successMessage}
          </Alert>
        )}
        {alertType === 'warning' && errorMessage && (
          <Alert severity="warning" sx={{
            fontFamily:'Poppins'
          }}>
            <AlertTitle sx={{
              fontFamily:'Poppins'
            }}>Advertencia</AlertTitle>
            {errorMessage}
          </Alert>
        )}
        {alertType === 'error' && errorMessage && (
          <Alert severity="error" sx={{
            fontFamily:'Poppins'
          }}>
            <AlertTitle sx={{
              fontFamily:'Poppins'
            }}>Error</AlertTitle>
            {errorMessage}
          </Alert>
        )}
      </Box>
    </Modal>
  );
};

/* diseño material iu elemento */

  
  const SelectItem = styled(Select)(({ theme }) => ({
    width:'40%',
    marginLeft:'10px',
    borderRadius:'50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent:'center',
    
  }));
  
  // Estilo personalizado para el contenedor del menú
  const CustomMenu = styled(MenuItem)(({ theme }) => ({
    backgroundColor: '#ffff', // Fondo del `MenuItem` principal
    '&.MuiMenuItem-root': {
      backgroundColor: '#ffff',
    },
    // Estilos para el contenedor generado del `Popover` o `Menu`
    '& .MuiPaper-root': {
      backgroundColor: 'white', // Cambia el color de fondo del `Popover`
    },
  }));
  
 
  
  const StyledSelect = styled(Select)(({ theme, error }) => ({
    padding: '1px 13px',
    color:'#694B81',
    position:'relative',
    fontFamily: "Poppins",
    width:'100%',
    height:'55px',
    fontSize:'0.8rem',
    borderRadius: '50px',
    border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F0EDF3', // Color del borde por defecto
      },
      '&:hover fieldset': {
        borderColor: '#585857', // Color del borde al pasar el mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: '#585857', // Color del borde en foco
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#585857', // Color del borde en foco
      borderWidth: '2px', // Ancho del borde en foco
    },
    '@media (max-width: 310px)': {
      fontSize:'0.7rem',
      width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '96%',
    },
    
  }));
  
  const StyledText = styled(TextField)(({ theme, error }) => ({
    padding: '1px 13px',
    color:'#694B81',
    position:'relative',
    fontFamily: "Poppins",
    width:'100%',
    height:'55px',
    fontSize:'0.8rem',
    borderRadius: '50px',
    border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F0EDF3', // Color del borde por defecto
      },
      '&:hover fieldset': {
        borderColor: '#585857', // Color del borde al pasar el mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: '#585857', // Color del borde en foco
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#585857', // Color del borde en foco
      borderWidth: '2px', // Ancho del borde en foco
    },
    '@media (max-width: 310px)': {
      fontSize:'0.7rem',
      width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '96%',
    },
    
  }));

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#fff',
    boxShadow: 24,
    p: 4,
    borderRadius:'10px',
    fontFamily:'poppins',
    fontSize:'1rem',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    color:'#694B81',
    flexDirection:'column'
};