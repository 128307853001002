import React, { useState, useEffect} from "react";
import axios from "axios";
import SucursalLayout from "../../../../../SucursalLayout"
import { useUserEmpresa } from "../../../../context/UsuarioEmpresa";
import { EncabezadoAdmiSucursal } from "../../AdministradorSucursal/Index/ComponenteEstructura/EncabezadoSucursalAdmin";
import { useNavigate, useParams, useLocation  } from "react-router-dom";
import dayjs from 'dayjs';
import "../../../../../Style/AdministradorSuperEmpresa/diseñoempresaadmi.css"
import { NavTareasSucursal } from "../Index/ComponenteEstructura/navTareas";
/* MATERIAL UI */
import { Container, Box, Modal, Grid, Select, MenuItem, CircularProgress, Snackbar, Alert} from '@mui/material';
import { styled } from '@mui/system';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

/* react icons*/
import { RxCalendar } from "react-icons/rx";
import { FaRegEye, FaRegEyeSlash } from 'react-icons/fa';
import { FiAlertCircle } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { BiErrorCircle } from "react-icons/bi";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoAlertCircleOutline } from "react-icons/io5";
import { IoClose } from "react-icons/io5";

/* imagenes */
import grupo from "../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/gente.png"
import memorial from "../.././../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/memorial.png"
import historico from "../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/historico.png"
import qr from "../../../../../Image/AdministradorSuperEmpresa/EmpresasySucursales/qrblanco.png"

export const IndexRegistroUsuarioQrSucursal = ({ setShowNavbarFooter }) =>{
    const { id } = useParams();
    const {Token, Usuario, UserDataMarca, Empresa } = useUserEmpresa();
    const location = useLocation();
    const Navigate = useNavigate();
    const { QR, sucursal, user } = location.state || {};
    const [step, setStep] = useState(1);
    const [selected, setSelected] = useState(null);
    const [selectedPack, setSelectedPack] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(true);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordcorrect, setShowPasswordcorrect] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [emailExistsError, setEmailExistsError] = useState(false);
    const [successAlertOpen, setSuccessAlertOpen] = useState(false);
    const [errorAlertOpen, setErrorAlertOpen] = useState(false);
    const [enviandoAlert, setenviandoAlert] = useState(false);
    const [avisoAlertOpen, setAvisoAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const backendURL = process.env.REACT_APP_BACKEND_URL;
    

    const [formData, setFormData] = useState({
        name: '',
        apellido: '',
        fecha_nacimiento: '',
        genero: '',
        email: '',
        telefono: '+56 ',
        password: '',
        rut: '',
        codigo: '',
        id_grupo_familiar: '',
        tipo_memorial: '',
        id_sucursal:sucursal.id,
        id_admin_sucursal:user.id,
    });
   


    useEffect(() => {
        setShowNavbarFooter(false);
        return () => {
            setShowNavbarFooter(true);
        };
    }, [setShowNavbarFooter]);

    useEffect(() => {
        const fetchSuscripciones = async () => {
            try {
                const response = await axios.get(`${backendURL}/api/getsuscripcionusuarionsucursal/${QR.id}`, {
                    headers: {
                        Authorization: `Bearer ${Token}`
                    }
                });
                
                if (response.data.length > 0) {
                    const suscripcionData = response.data[0];
                    setSelectedPack(suscripcionData.id_suscripcion);
                    
                    // Actualiza formData con el código y el id_grupo_familiar
                    setFormData((prevData) => ({
                        ...prevData,
                        codigo: suscripcionData.codigo, // Establece el código obtenido
                        id_grupo_familiar: suscripcionData.id_grupo_familiar // Establece el ID del grupo familiar
                    }));
                } else {
              
                }
                
            } catch (error) {
                console.error('Error al obtener los datos de las suscripciones:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchSuscripciones();
    }, [QR.id, backendURL, Token]);


    /* verficacion de correo */
    const handleBlur = async () => {
        try {
          const response = await fetch(`${backendURL}/api/checkemailexists`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ email: formData['email'] })
          });
      
          const data = await response.json();
      
          // Manejo del error basado en el código de estado
          if (response.status === 400) {
            setEmailExistsError(true);
           
          } else {
            setEmailExistsError(false);
           
          }
        } catch (error) {
          console.error('Error al verificar el correo electrónico:', error);
        }
      };
      const handleChange = (event) => {
        const { name, value } = event.target;
    
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '', // Limpiar el error del campo actual
        }));
    
        if (name === 'email') {
            setEmailExistsError(false);
        }
    };
    
    // Este manejador se utiliza para manejar el cambio de tipo de memorial
    const handleSelectTipoMemorial = (value) => {
        setFormData((prevData) => ({
            ...prevData,
            tipo_memorial: value, // Asigna el valor seleccionado a tipo_memorial
        }));
        setSelected(value); // Actualiza el estado del botón seleccionado
    };
    
    // Este manejador se utiliza para manejar el cambio de código
    const handleChangeCodigo = (event) => {
        const { value } = event.target;
        setFormData((prevData) => ({
            ...prevData,
            codigo: value, // Asigna el valor del input de código
        }));
    };

    const handleSelectGroup = (selectedGroupId) => {
        setFormData({
            ...formData,
            id_grupo_familiar: selectedGroupId, // Asegúrate de que aquí estás estableciendo el ID correcto
        });
    };
    
    
    // Ejemplo de cómo podrías implementar el uso de botones para seleccionar el tipo de memorial
    const handleButtonClick = (tipoMemorial) => {
        handleSelectTipoMemorial(tipoMemorial); // Actualiza el tipo de memorial
    };

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const togglePasswordcorrectVisibility = () => {
        setShowPasswordcorrect(!showPasswordcorrect);
    };

    const handleChangeInput = (event) => {
        const { name } = event.target;
        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: '',
        }));
    };

    const handleChangeFecha = (event) => {
        const { value } = event.target;
        const date = new Date(value);
    
        if (!isNaN(date)) {
            setErrors(prevErrors => ({
                ...prevErrors,
                fecha_nacimiento: '' // Limpiar el error de fecha
            }));

            const fechaFormateada = date.toISOString().split('T')[0];
    
            setFormData({
                ...formData,
                fecha_nacimiento: fechaFormateada
            });
    
            handleChange({ target: { name: 'fecha_nacimiento', value: fechaFormateada } });
        } else {
            console.error("Invalid date value");
        }
    };

    const handleChangeContraseña = (event) => {
        setPassword(event.target.value);
        if (event.target.value.length < 6) {
            setPasswordError('La contraseña debe tener al menos 6 caracteres');
        } else {
            setPasswordError('');
        }
    };

    const handleChangeRepetirContraseña = (event) => {
        const confirmPassword = event.target.value;
    
        if (confirmPassword.length < 6) {
            setPasswordError('La contraseña debe tener al menos 6 caracteres');
        } else {
            setPasswordError('');
        }
    
        if (confirmPassword !== password) {
            setPasswordError('Las contraseñas no coinciden');
        } else {
            setPasswordError('');
        }
    };



    const handleNextStep = () => {
        setStep(step + 1);
    };

    const handlePrevStep = () => {
        setStep(step - 1);
    };


    /* envio de la informacion  */
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formDataFromEvent = new FormData(event.target);
        const formDataObject = {};
    
        formDataFromEvent.forEach((value, key) => {
            formDataObject[key] = value;
        });
    
        const formattedDate = dayjs(formDataObject.fecha_nacimiento, 'DD-MM-YYYY').format('YYYY-MM-DD');
        formDataObject.fecha_nacimiento = formattedDate;
    
        let formErrors = {};
        let missingFields = [];

        for (let [key, value] of formDataFromEvent.entries()) {
            if (value.trim() === '' || value.trim() === '+56') {
                formErrors[key] = 'Campo no completado';
            }
        }
    
        setErrors(formErrors);
    
        if (Object.keys(formErrors).length > 0) {
            const missingFieldsMessage = `Faltan completar los siguientes campos: ${missingFields.join(', ')}`;
        setAlertMessage(missingFieldsMessage);
        setAvisoAlertOpen(true);
        return;
        }
     
    
        try {
            setenviandoAlert(true);
    
            const response = await fetch(`${backendURL}/api/sucursal/resgistro-usuario-qr`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${Token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formDataObject),
            });
    
            const data = await response.json();
    
            if (!response.ok || response.status !== 201) {
                const errorMessage = data.error || 'Error al registrar usuario';
                setAlertMessage(errorMessage);
                setErrorAlertOpen(true);
                return; 
            }
            if (data.error === 'El correo electrónico ya está registrado') {
                setAlertMessage('El correo electrónico ya está registrado. Por favor, utiliza otro.');
                setErrorAlertOpen(true);
                return;
            }
    
            if (!emailExistsError) {
                setSuccessAlertOpen(true);
                setAlertMessage('Su registro ha sido exitoso.');
                setTimeout(() => {
                    setSuccessAlertOpen(false);
                    Navigate(`/registro/memorial/${id}/sucursal`, { state: { grupoFamiliar: QR, sucursal, user } });
                }, 3000);
            } else {
                setFormData(prevState => ({
                    ...prevState,
                    emailExistsError: true,
                }));
            }
    
        } catch (error) {
            setErrorAlertOpen(true);
            setAlertMessage('Error al crear su registro, vuelva a intentar.');
        } finally {
            setenviandoAlert(false);
        }
    
        setTimeout(() => {
            setSuccessAlertOpen(false);
            setErrorAlertOpen(false);
        }, 5000);
    };
    

    if (loading) {
        return(
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "50px", marginBottom:'100px' }}>
              <CircularProgress sx={{ color: "D26999" }} />
          </Box>);
      }


      const renderOptions = () => {
        const tipoQr = UserDataMarca?.tipo_qr;

        if (tipoQr == 0) {
            return (
                <Grid item xs={12}>
                    <div
                        style={{ fontFamily: 'Poppins' }}
                        onClick={() => handleButtonClick(1)}
                        className={`CardSelecionMemorial ${selected === 1 ? 'selected' : ''}`}
                    >
                        <img src={grupo} alt="Grupo Familiar" width={'150px'} />
                        Grupo Familiar
                    </div>
                </Grid>
            );
        }

        if (tipoQr == 1) {
            return (
                <Grid item xs={12}>
                    <div
                        style={{ fontFamily: 'Poppins' }}
                        onClick={() => handleButtonClick(2)}
                        className={`CardSelecionMemorial ${selected === 2 ? 'selected' : ''}`}
                    >
                        <img src={memorial} alt="Memorial Individual" width={'150px'} />
                        Memorial Individual
                    </div>
                </Grid>
            );
        }
    // Si es 2, null o undefined, muestra ambos
    return (
        <>
            <Grid item xs={6}>
                <div
                    style={{ fontFamily: 'Poppins' }}
                    onClick={() => handleButtonClick(1)}
                    className={`CardSelecionMemorial ${selected === 1 ? 'selected' : ''}`}
                >
                    <img src={grupo} alt="Grupo Familiar" width={'150px'} />
                    Grupo Familiar
                </div>
            </Grid>
            <Grid item xs={6}>
                <div
                    style={{ fontFamily: 'Poppins' }}
                    onClick={() => handleButtonClick(2)}
                    className={`CardSelecionMemorial ${selected === 2 ? 'selected' : ''}`}
                >
                    <img src={memorial} alt="Memorial Individual" width={'150px'} />
                    Memorial Individual
                </div>
            </Grid>
        </>
    );
};
        return(
            <SucursalLayout>
             <Container maxWidth="lg">
             

 
                <Container maxWidth='lg' sx={StyleContainer}>
                {step === 1 && (
                    <Box sx={{width:'100%'}}>
                         <section className="TituloQRUser">
                                <h3>Selecciona un tipo de memorial</h3>
                            </section>
                        <section className="TipoMemorialSleccion">
                            <Grid container spacing={3} sx={StyleGrid}>
                            {renderOptions()}
                
                            </Grid>
                        </section>
                        <div className="buttonsiguientecrearempresa">
                        <button type="button" onClick={handleNextStep} disabled={selected === null || selected === 0}> Siguiente</button>
                        </div>
                    </Box>
                )}
                    {step === 2 && (
                        <Box sx={StyleBox}>
                            <section className="ContieneBtonAtras">
                            <div className="buttonretroceedercrearempresa" onClick={handlePrevStep}>
                                <IoIosArrowBack />
                            </div>
                            </section>
                            <section className="TituloQRUser">
                                <h3>Creación de Usuario para Qr {QR.id}</h3>
                            </section>
                            
                            <form className="ContieneFormEMpresaUserQr" onSubmit={handleSubmit}>
                                <div className="input-registor-formempresa">
                                     <label htmlFor='name'>Nombres</label>
                                        <div className='contieneinputregister'> 
                                            <input
                                            style={{
                                                background:'transparent'
                                            }}
                                             onChange={(e) => {
                                            handleChange(e);
                                            handleChangeInput(e);
                                            }} className={`input-registroempresa ${errors.name && 'input-error'}`} name="name" id="name"
                                            value={formData['name']} />
                                        </div>  {errors.name && <span className="error-message"> <FiAlertCircle className='icon-error' />{errors.name}</span>}
                                </div>
                                
                                <div className="input-registor-formempresa">
                                    <label htmlFor='apellido'>Apellidos</label>
                                    <div className='contieneinputregister'>
                                         <input style={{
                                                background:'transparent'
                                            }} onChange={handleChange} className={`input-registroempresa ${errors.apellido && 'input-error'}`} name="apellido" id="apellido" value={formData['apellido']} />
                                    </div> {errors.apellido && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.apellido}</span>}
                                </div>

                                <div className="input-registor-formempresa">
                                     <label htmlFor='rut'>Rut</label>
                                        <div className='contieneinputregister'>
                                             <input
                                             style={{
                                                background:'transparent'
                                            }}
                                            onChange={(e) => {
                                            handleChange(e);
                                            handleChangeInput(e);
                                            }} className={`input-registroempresa ${errors.rut && 'input-error'}`} name="rut" id="rut"
                                            value={formData['rut']} />
                                        </div>  {errors.rut && <span className="error-message"> <FiAlertCircle className='icon-error' />{errors.rut}</span>}
                                </div>
                                <div className="input-registor-formempresa">
                                    <label htmlFor="fecha_nacimiento">Fecha de Nacimiento</label>
                                    <div className='contieneinputregister'>
                                        <div className="inputregistrocontainerempresa">
                                            <input
                                            style={{
                                                background:'transparent',
                                                zIndex:'1111'
                                            }}
                                                type="date"
                                                className={`inputregistrofecha ${errors.fecha_nacimiento && 'input-error'}`}
                                                name='fecha_nacimiento'
                                                id='fecha_nacimiento'
                                                onChange={handleChangeFecha} 
                                                error={errors.fecha_nacimiento}   
                                                value={formData.fecha_nacimiento} // Asegúrate de que el valor sea el correcto del estado
                                            />
                                            <div className="calendariocreacioniconoempresa" style={{zIndex:'1'}}>
                                                <RxCalendar className="iconocrearmemorialcalendario" />
                                                </div>
                                        
                                        </div>
                                    </div>
                                    {errors.fecha_nacimiento && <span className="error-message"> <FiAlertCircle className='icon-error' />{errors.fecha_nacimiento}</span>}
                                </div>
                    
                                <div className="input-registor-formempresa">
                                    <label htmlFor='genero'>Género</label>
                                    <div className='contieneinputregister'> < StyledSelect
                                    name='genero'
                                    id='genero'
                                    sx={{textAlign:"left"}}
                                    className={`input-select-registro ${errors.genero && 'input-error'}`}
                                    value={formData.genero}
                                    onChange={handleChange}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    IconComponent={KeyboardArrowDownIcon}
                                    error={errors.genero}
                                    >
                                    <MenuItem value="">
                                        <em>Seleccionar género...</em>
                                    </MenuItem>
                                    <MenuItem value={"Masculino"}>Masculino</MenuItem>
                                    <MenuItem value={"Femenino"}>Femenino</MenuItem>
                                    <MenuItem value={"Me identifico con otro género"}>Me identifico con otro género </MenuItem>
                                    </ StyledSelect>
                                </div>
                                      {errors.genero && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.genero}</span>}
                                </div>
                    <div className="input-registor-formempresa">
                        <label htmlFor='email'>Correo electrónico</label>
                        <div className='contieneinputregister'>
                        <input
                        style={{
                            background:'transparent'
                        }}
                            type="email"
                            name="email"
                            value={formData['email']}
                            onChange={handleChange} // Manejador de cambios para actualizar formData
                            onBlur={handleBlur} // Se llama a handleBlur cuando se pierde el enfoque
                            className={`input-registroempresa ${emailExistsError && 'input-error'}`}
                            />
                        </div> 
                            {errors.email && (
                                <span className="error-message">
                                <FiAlertCircle className='icon-error' />
                                {errors.email}
                                </span>
                            )}
                            {emailExistsError && (
                                <span className="error-message">
                                <FiAlertCircle className='icon-error' />
                                El correo electrónico ya está registrado
                                </span>
                            )}
                        <div className='aletainformativacorreoempresa '>
                            <div className='svgaletainformativa'><IoAlertCircleOutline /></div>
                            <div className='infoaletainformativa'>
                            <span className='tituloaletainformativa'>
                                IMPORTANTE:
                            </span>
                            <span className='descripcionaletainformativa'>
                                El correo electrónico NO debe ser de carácter institucional
                            </span>
                            </div>
                        </div>
                    </div>
                    <div className="input-registor-formempresa">
                        <label htmlFor='telefono'>Número de celular</label>
                        
                        <div className='contieneinputregister'>
                            <input
                            style={{
                                background:'transparent'
                            }}
                                value={formData['telefono']}
                                onChange={(e) => {
                                handleChange(e);
                                handleChangeInput(e);
                                }}
                                className={`input-registroempresa ${errors.telefono && 'input-error'}`}
                                name="telefono"
                                id="telefono"
                            />
                        </div>
                    
                        {errors.telefono && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.telefono}</span>}
                    </div>
                    <div className="input-registor-formempresa">
                        <label htmlFor='pack'>Pack Memorial contratado</label>
                        <div className='contieneinputregister'>
                            <StyledSelect

                                name='codigo'
                                id='pack'
                                value={selectedPack}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                IconComponent={KeyboardArrowDownIcon}
                                disabled
                                className="input-select-registro"
                                sx={{ textAlign: "left", background:(UserDataMarca?.color_1 && UserDataMarca.color_1 !== 'undefined') 
                                    ? UserDataMarca.color_1 
                                    : '#694B81', }}
                            >
                                <MenuItem value="">
                                <em>Selecciona el tipo Pack Memorial...</em>
                                </MenuItem>
                                <MenuItem value={1}> Pack Smart Memorial Full</MenuItem>
                                <MenuItem value={2}> Pack SmartMemorial Básico</MenuItem>
                            </StyledSelect>
                        </div>                    
                    </div>
                    <div className="input-registor-formempresa">
                        <label>Contraseña</label>
                        <div className='input-contraseña'>
                        <input
                        style={{
                            background:'transparent'
                        }}
                            onChange={(e) => { handleChange(e); handleChangeInput(e); handleChangeContraseña(e) }}
                            type={showPassword ? 'text' : 'password'}
                            className={`input-registroempresa ${errors.password && 'input-error'} ${passwordError && 'input-error'}`}
                            name="password"
                            value={formData['password']}
                            placeholder="Contraseña"
                        />
                        <span className="togglepassword" onClick={togglePasswordVisibility}>
                            {showPassword ? <FaRegEyeSlash /> : <FaRegEye />}
                        </span></div>
                        {errors.password && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.password}</span>}
                        {passwordError && <span className="error-message"><FiAlertCircle className='icon-error' />{passwordError}</span>}
                    </div>

                    <div className="input-registor-formempresa">
                        <label>Repetir contraseña</label>
                        <div className='input-contraseña'>
                        <input
                        style={{
                            background:'transparent'
                        }}
                            onChange={(e) => { handleChangeInput(e); handleChangeRepetirContraseña(e); }}
                            type={showPasswordcorrect ? 'text' : 'password'}
                            className={`input-registroempresa ${errors.passwords && 'input-error'} ${passwordError && 'input-error'}`}
                            name="passwords"
                            placeholder="Contraseña"
                        />
                        <span className="togglepassword" onClick={togglePasswordcorrectVisibility}>
                            {showPasswordcorrect ? <FaRegEyeSlash /> : <FaRegEye />}
                        </span>
                        </div>
                        {errors.password && <span className="error-message"><FiAlertCircle className='icon-error' />{errors.password}</span>}
                        {passwordError && <span className="error-message"><FiAlertCircle className='icon-error' />{passwordError}</span>}
                    </div>
                    <input
                        type="text"
                        name="codigo"
                        value={formData.codigo}
                        placeholder="Ingrese el código"
                        onChange={handleChange} 
                        style={{ opacity: 0, position: 'absolute', zIndex: -1 }} 

                    />
                      <input
                        type="text"
                        name="id_grupo_familiar"
                        value={formData.id_grupo_familiar}
                        placeholder="grupo"
                        onChange={handleChange} 
                        style={{ opacity: 0, position: 'absolute', zIndex: -1 }} 

                    />
                     <input
                        type="text"
                        name="tipo_memorial"
                        value={formData.tipo_memorial}
                        placeholder="grupo"
                        onChange={handleChange} 
                        style={{ opacity: 0, position: 'absolute', zIndex: -1 }} 

                    />
                    
                    <input
                        type="text"
                        name="id_sucursal"
                        value={formData.id_sucursal}
                        placeholder="grupo"
                        onChange={handleChange} 
                        style={{ opacity: 0, position: 'absolute', zIndex: -1 }} 

                    />
                     <input
                type="text"
                name="id_admin_sucursal"
                value={formData.id_admin_sucursal}
                onChange={handleChange}
                style={{ opacity: 0, position: 'absolute', zIndex: -1 }} 
            />
                    
                  

                     <div className="boton-registro">

                        <button type="submit" disabled={emailExistsError}> Registrar </button>

                        </div>
                            </form>
                        </Box>
                    )}
                </Container>
              
                      
               
                
             </Container>
             <Modal open={successAlertOpen} >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
         
          <section style={{ textAlign: 'center' }}>
            <FaRegCircleCheck style={{ color: 'green', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#585857', fontSize: '0.8rem', fontWeight: '600', marginTop:"-5px" }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de error */}
      <Modal open={errorAlertOpen} onClose={() => setErrorAlertOpen(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section className="closemodal" onClick={() => setErrorAlertOpen(false)}>
            <IoClose />
          </section>
          <section style={{ textAlign: 'center' }}>
            <BiErrorCircle  style={{ color: 'red', fontSize: '2rem', marginBottom: '1rem' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600' }}>
              {alertMessage}
            </p>
          </section>
        </Box>
      </Modal>

      {/* Popup de carga */}
      <Modal open={enviandoAlert} onClose={() => setenviandoAlert(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '350px',
            height: 'auto',
            bgcolor: '#ffff',
            boxShadow: 24,
            p: 1,
            borderRadius: '10px',
            '@media (max-width: 500px)': {
              width: '90%',
            },
          }}
        >
          <section style={{ textAlign: 'center', padding: '1rem' }}>
            <CircularProgress style={{ color: '#694B81' }} />
            <p style={{ fontFamily: 'Poppins', color: '#694B81', fontSize: '0.8rem', fontWeight: '600', marginTop: '20px' }}>
              Procesando solicitud...
            </p>
          </section>
        </Box>
      </Modal>
      <Snackbar 
    open={errorAlertOpen} 
    autoHideDuration={5000} 
    onClose={() => setErrorAlertOpen(false)} 
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
    <Alert 
        onClose={() => setErrorAlertOpen(false)} 
        severity="error" 
        sx={{ width: '100%' }}
    >
        {alertMessage}
    </Alert>
</Snackbar>


<Snackbar 
    open={avisoAlertOpen} 
    autoHideDuration={5000} 
    onClose={() => setAvisoAlertOpen(false)} 
    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
>
    <Alert 
        onClose={() => setAvisoAlertOpen(false)} 
        severity="warning"
        sx={{ width: '100%' }}
    >
        {alertMessage}
    </Alert>
</Snackbar>

            </SucursalLayout>
        )
}


/* estilo mateiral UI */
const StyleContainer = {
    marginTop:'50px',
    display:'flex',
     alignItems: 'center',
    justifyContent:'center',
    flexDirection:'column',
     width:'100%'
}
const StyleGrid ={
    display:'flex',
    alignItems: 'center', 
    justifyContent:'center',
     maxWidth:'100%'
}
 
const StyleBox ={
    display:'flex',
    alignItems: 'center', 
    justifyContent:'center',
    flexDirection:'column',
    width:'100%'
}

const StyledSelect = styled(Select)(({ theme, error }) => ({
    padding: '1px 13px',
    color:'#694B81',
    position:'relative',
    left:'0.5%',
    fontFamily: "Poppins",
    width:'100%',
    height:'55px',
    fontSize:'0.8rem',
    borderRadius: '50px',
    border: '3px solid #F0EDF3', // Cambia el color del borde si hay un error
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#F0EDF3', // Color del borde por defecto
      },
      '&:hover fieldset': {
        borderColor: '#585857', // Color del borde al pasar el mouse
      },
      '&.Mui-focused fieldset': {
        borderColor: '#585857', // Color del borde en foco
      },
    },
    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: '#585857', // Color del borde en foco
      borderWidth: '2px', // Ancho del borde en foco
    },
    '@media (max-width: 310px)': {
      fontSize:'0.7rem',
      width: '96%', // Aplica el estilo que desees cuando el ancho de la pantalla sea menor o igual a 310px
      maxWidth: '96%',
    },
    
  }));