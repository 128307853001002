import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { Box, Typography, Button, Container, Grid, Divider, Card, List, ListItem, ListItemText, CardContent, TextField,CircularProgress, Alert} from "@mui/material";
import AOS from 'aos';
import 'aos/dist/aos.css';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import { keyframes } from "styled-components";
import MuiAccordionSummary, {
  accordionSummaryClasses,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import { positions, styled } from "@mui/system";
import { Fab } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import '../../Style/inicio/smartmemorial.css';
import BackgroundImage from "../../Image/inicio/incio.jpg";
import Lapiz from"../../Image/inicio/lapiz.png";
import qr from"../../Image/inicio/qr.png";
import person from"../../Image/inicio/person.png"
import portada from"../../Image/inicio/portada.jpg"
import fondo from "../../Image/inicio/fondo.jpg";
import celu from"../../Image/inicio/celu.png"

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&::before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    background:'#ffff',
    backgroundColor: 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    [`& .${accordionSummaryClasses.expandIconWrapper}.${accordionSummaryClasses.expanded}`]:
      {
        transform: 'rotate(90deg)',
      },
    [`& .${accordionSummaryClasses.content}`]: {
      marginLeft: theme.spacing(1),
    },
    ...theme.applyStyles('dark', {
      backgroundColor: 'rgba(255, 255, 255, .05)',
    }),
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));
  

export const EmpresasSmart = () => {
    const [expanded, setExpanded] = React.useState('panel1');
     const [showScrollButton, setShowScrollButton] = useState(false);
         const [visible, setVisible] = useState(false);
         const [isVisible, setIsVisible] = useState(false);
         const [isSubmitting, setIsSubmitting] = useState(false);
         const [success, setSuccess] = useState(false);
         const [error, setError] = useState("");
         const backendURL = process.env.REACT_APP_BACKEND_URL;
         const [formData, setFormData] = useState({
             name: "",
             email: "",
             message: "",
             contact_type:"Empresa"
           });

           const handleChange = (panel) => (event, newExpanded) => {
            setExpanded(newExpanded ? panel : false);
          };
    
           const handleChanges = (e) => {
            const { name, value } = e.target;
            setFormData({ ...formData, [name]: value });
          };
          const handleSubmit = async (e) => {
            e.preventDefault();
            setIsSubmitting(true);
            setError("");
            setSuccess(false);
        
            try {
                const response = await axios.post(
                    `${backendURL}/api/contacto/formulario`, // Ruta del backend
                    formData, // Datos enviados en el cuerpo de la solicitud
                    {
                        headers: {
                            "Content-Type": "application/json", // Asegúrate de usar el tipo de contenido correcto
                        },
                    }
                );
        
                // Si la respuesta es exitosa
                if (response.status === 200 || response.status === 201) {
                    setSuccess(true);
                    setFormData({ name: "", email: "", message: "", contact_type: "General" });
                }
            } catch (err) {
                // Captura errores del backend
                if (err.response && err.response.data.errors) {
                    const backendErrors = Object.values(err.response.data.errors)
                        .flat()
                        .join(", ");
                    setError(backendErrors);
                } else {
                    setError(err.message || "Error desconocido al enviar el formulario");
                }
            } finally {
                setIsSubmitting(false);
            }
        };
    
          useEffect(() => {
            const handleResize = () => {
              if (window.innerWidth > 850) {
                setIsVisible(false); // Resetea el estado si el ancho es mayor a 850px
              }
            };
        
            // Agregar el listener para resize
            window.addEventListener("resize", handleResize);
        
            // Comportamiento inicial
            if (window.innerWidth <= 850) {
              const observer = new IntersectionObserver(
                ([entry]) => setIsVisible(entry.isIntersecting),
                { threshold: 0.3 }
              );
        
              const section = document.getElementById("sec-e2ef");
              if (section) observer.observe(section);
        
              // Cleanup para IntersectionObserver
              return () => {
                observer.disconnect();
                window.removeEventListener("resize", handleResize);
              };
            }
        
            // Cleanup para el listener
            return () => {
              window.removeEventListener("resize", handleResize);
            };
          }, []);
         
          
         
          // Manejar la visibilidad del botón de scroll
          const handleScrolls = () => {
            if (window.scrollY > 200) {
              setShowScrollButton(true); // Mostrar botón si se supera 200px de desplazamiento
            } else {
              setShowScrollButton(false); // Ocultar botón al volver arriba
            }
          };
        
          // Desplazar al inicio de la página
          const scrollToTop = () => {
            window.scrollTo({
              top: 0,
              behavior: "smooth", // Scroll suave
            });
          };
        
          useEffect(() => {
            window.addEventListener("scroll", handleScrolls);
            return () => {
              window.removeEventListener("scroll", handleScrolls);
            };
          }, []);
         
        


          useEffect(() => {

            const observer = new IntersectionObserver(
              (entries) => {
                entries.forEach((entry) => {
                  if (entry.isIntersecting) {
                    entry.target.classList.add('visible');
                  } else {
                    entry.target.classList.remove('visible');
                  }
                });
              },
              { threshold: 0.1 } // Ajusta la sensibilidad si es necesario
            );
        
            const elements = document.querySelectorAll('.animatable');
            elements.forEach((el) => observer.observe(el));
        
            return () => {
              elements.forEach((el) => observer.unobserve(el));
            };
          }, []);
    
          const handleScroll = () => {
              // Detectar cuándo mostrar el contenido con transición
              if (window.scrollY > 500) {
                setVisible(true);
              } else {
                setVisible(false);
              }
            };
          
            useEffect(() => {
              window.addEventListener("scroll", handleScroll);
              return () => {
                window.removeEventListener("scroll", handleScroll);
              };
            }, []);
    
            const navigate = useNavigate();
                const handleClickfull = () => {
                    navigate('/solicitarmemorialfull'); // Navegar a la ruta de inicio
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth', // Desplazamiento suave
                    });
                  };
            
                  useEffect(() => {
                    AOS.init({ duration: 1000 });
                  }, []);
            
                  const handleClickbasic = () => {
                    navigate('/solicitarmemorialbasico'); // Navegar a la ruta de inicio
                    window.scrollTo({
                      top: 0,
                      behavior: 'smooth', // Desplazamiento suave
                    });
                  };

                 
                    const plans = [
                      {
                        title: 'Básico',
                        price: '20 Uf+IVA',
                        features: [' ​1 Administrador.', ' Generación hasta 20 QR mensuales.', ' Logo de empresa.',' Acceso a base de datos nuevos clientes.','Captación de nuevos clientes.','QR soporte para velatorio, misa y funeral.'],
                        placa:'(No incluye placas metálicas)',
                        buttonText: 'Contactar con un vendedor',
                        color: '#F7D7FA',
                        
                      },
                      {
                        title: 'Full',
                        price: '35 Uf+IVA',
                        features: [' ​1 Administrador.', ' 4 Sucursales.', 'Generación hasta 80 QR mensuales.', 'Logo de empresa.', 'Acceso a base de datos nuevos clientes.','Captación de nuevos clientes.','Creación de obituarios.','QR soporte para velatorio, misa y funeral.','Envío de mensajes de fidelización a usuarios.','Envío de encuesta de satisfacción.','Creación de memoriales empresa.'],
                        placa:'(No incluye placas metálicas)',
                        buttonText: 'Contactar con un vendedor',
                        color: '#DCD0FA',
                      
                      },
                      {
                        title: 'Premium',
                        price: '130 Uf+IVA',
                        features: [' ​1 Administrador.', ' Sucursales ilimitadas', 'Generación hasta 500 QR mensuales.', 'Logo de empresa.', 'Acceso a base de datos nuevos clientes.',' Captación de nuevos clientes.',
                          'Creación de obituarios.','QR soporte para velatorio, misa y funeral.','Envío de mensajes de fidelización a usuarios.','Envío de encuesta de satisfacción.'
                        ,'Estadísticas de ventas y encuestas.','Creación de memoriales empresa.'],
                        placa:'(No incluye placas metálicas)',
                        buttonText: 'Contactar con un vendedor',
                        color: '#DAD4FA', 
                        
                      },
                    ];
    
    return(
        <>
         {showScrollButton && (
        <Fab
        onClick={scrollToTop}
          color="primary"
          aria-label="scroll-to-bottom"
          sx={{
            position: "fixed",
            bottom: 20,
            right: 20,
            zIndex: 1000,
          }}
        >
          <ArrowUpwardIcon />
        </Fab>
      )}
        <ParallaxSection>
                      <ParallaxImage alt="Parallax Background" />
                      <FixedContent>
                        <Typography variant="h2" className="title">
                          Un memorial
                        </Typography>
                        <Typography variant="h2" className="title">
                        que hará crecer su empresa
                        </Typography>
                        <Typography variant="body1" className="description">
                        Le invitamos a unirse a Smart Memorial,
                         una oportunidad única para hacer crecer tu empresa.
                          Con nosotros, marcará la diferencia frente a la competencia,
                           estará a la vanguardia tecnológica e impulsará la innovación en su sector.
                            ¡Sea parte del cambio y lleve su negocio al siguiente nivel!
                        </Typography>
                        
                        <Button 
                            className="cta-button"
                            onClick={() => {
                            const contactSection = document.getElementById("contact");
                            if (contactSection) {
                            contactSection.scrollIntoView({ behavior: "smooth" });
                            }
                        }}>Escríbenos
                        </Button>
                      </FixedContent>
        </ParallaxSection>
        <section
 className={`sectioninfoportadasmart ${visible ? "visible" : ""}`}
  id="sec-e2ef"
>
  {/* Columna izquierda: Texto */}
  <Container
    maxWidth="md"
    sx={{
      flex: 1,
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: { xs: "center", md: "flex-start" },
      textAlign: { xs: "center", md: "left" },
      padding: "0px",
    }}
  >
    <StyledTitleportada
      variant="h4"
      sx={{
        marginBottom: "20px",
      }}
    >
      Un QR que preserva el legado de los seres fallecidos
    </StyledTitleportada>
    <StyledTitleTextportada
      variant="body1"
      sx={{
        marginBottom: "20px",
      }}
    >
      Smart-Memorial es una plataforma
        desarrollada para apoyar a las empresas
        funerarias y cementerios en las
        estrategias de marketing sobre
        posicionamiento en el mercado, relación
        con sus clientes y diferenciación con la
        competencia.
    </StyledTitleTextportada>
    <StyledButton
      href="Smart-Memorial.html#sec-ec39" 
    >
      Escríbenos
    </StyledButton>
  </Container>

  {/* Columna derecha: Imagen */}
  <Box
    sx={{
      flex: 1,
      position: "relative",
      height: "100%", // La altura de la sección
      backgroundImage: `url(${portada})`, // Reemplaza con tu imagen
      backgroundSize: "cover", // Escala la imagen para cubrir el espacio
      backgroundPosition: "center", // Centra la imagen
     
    }}
  />
</section>
        <section>
            <Container maxWidth="lg" sx={{ marginTop: '100px', marginBottom: '100px' }}>
                <Grid container sx={{ display: 'flex', alignItems: 'start', justifyContent: 'start' }}>
                <Grid item sm={12} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="contieneimagencelu animatable" id="imageContainer">
                    <img src={celu} alt="smart memorial" width="100%" />
                    </div>
                </Grid>
                <Grid item sm={12} md={6} className="animatable">
                    <div className="titulocelu animatable" id="textContainer">
                    <h3>Veamos un</h3>
                    <h3>memorial real</h3>
                    </div>
                    <div className="descricioncelu animatable" id="descriptionContainer">
                    <span>
                        Compartir un memorial, desempeña un papel significativo en el proceso de duelo y en mantener viva la memoria
                        de alguien especial.
                    </span>
                    </div>
                    <div>
                    <Link to="https://www.smartmemorial.cl/familiar/memorial/110"> 
                    <StyledButton >Revisar memorial</StyledButton>
                    </Link>
                    </div>
                </Grid>
                </Grid>
            </Container>
            </section>
            <section
            style={{
                background:'#F0EDF3',
                padding:'100px 0px'
            }}
     
      
    >
      <Container maxWidth="lg" >
        <StyledTitle variant="h4" gutterBottom>
        Atributos para su empresa
        </StyledTitle>
        <section className="tectogenerado" style={{ marginTop:'0px', marginbottom:'20px'}}>
                    <span >
                        Nuestros servicios ofrecen múltiples beneficios que harán crecer tu negocio tales como:
                    </span>
        </section>
        {/* Acordeón */}
        <div>
          <Accordion
            expanded={expanded === "panel1"} // Compara si el panel1 es el actual expandido
            onChange={handleChange("panel1")}
            sx={{
                background:'#F0EDF3',
              boxShadow: "none",
              border: "0px solid ",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
            sx={{
              background:'#F0EDF3',
              padding:'0px'
            }}
              expandIcon={<ExpandMoreIcon sx={{
                color: expanded === "panel1" ? "#694B81" : "#585857",}} />}
              aria-controls="panel1a-content"
              id="panel1-header"
            >
              <StyleTypographyAcordion variant="h6" sx={{
                color: expanded === "panel1" ? "#694B81" : "#585857",
                textAlign:'start',
                '@media (max-width: 750px)': {
                fontSize: "0.8rem",
                padding:'0px'
              }, // Cambia el color si es el panel expandido
              }}>
                Innovación de servicios
              </StyleTypographyAcordion>
            </AccordionSummary>
            <AccordionDetails>
              <StyleTypographycarddosacordeon>
              A través de la creación de memoriales con acceso de códigos QR. creación de obituarios y envío y recepción
              de mensajes y condolencias.
              </StyleTypographycarddosacordeon>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel2"} // Compara si el panel2 es el actual expandido
            onChange={handleChange("panel2")}
            sx={{
                background:'#F0EDF3',
              boxShadow: "none",
              border: "0px solid ",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
             sx={{
                background:'#F0EDF3',
            }}
              expandIcon={<ExpandMoreIcon   sx={{
                color: expanded === "panel2" ? "#694B81" : "#585857",}}/>}
              aria-controls="panel2a-content"
              id="panel2-header"
            >
              <StyleTypographyAcordion variant="h6" sx={{
                color: expanded === "panel2" ? "#694B81" : "#585857",
                textAlign:'start',
                '@media (max-width: 750px)': {
                  fontSize: "0.8rem",
                  padding:'0px'
                }, // Cambia el color si es el panel expandido
              }}>
                Diferenciación
              </StyleTypographyAcordion>
            </AccordionSummary>
            <AccordionDetails>
              <StyleTypographycarddosacordeon>
              A través de un servicio único en el mercado desarrollado de manera personalizada.
              </StyleTypographycarddosacordeon>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel3"} // Compara si el panel3 es el actual expandido
            onChange={handleChange("panel3")}
            sx={{
                background:'#F0EDF3',
              boxShadow: "none",
              border: "0px solid ",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
             sx={{
                background:'#F0EDF3',
            }}
              expandIcon={<ExpandMoreIcon  sx={{
                color: expanded === "panel3" ? "#694B81" : "#585857",}} />}
              aria-controls="panel3a-content"
              id="panel3-header"
            >
              <StyleTypographyAcordion variant="h6" sx={{
                color: expanded === "panel3" ? "#694B81" : "#585857", 
                textAlign:'start',
                '@media (max-width: 750px)': {
                  fontSize: "0.8rem",
                  padding:'0px'
                },
              }}>
                Fidelización
              </StyleTypographyAcordion>
            </AccordionSummary>
            <AccordionDetails>
              <StyleTypographycarddosacordeon>
              A través de envío de mensajes anuales de condolencias a familiares de los fallecidos.
              </StyleTypographycarddosacordeon>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={expanded === "panel4"} // Compara si el panel4 es el actual expandido
            onChange={handleChange("panel4")}
            sx={{
                background:'#F0EDF3',
              boxShadow: "none",
              border: "0px solid ",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
             sx={{
                background:'#F0EDF3',
            }}
              expandIcon={<ExpandMoreIcon  sx={{
                color: expanded === "panel4" ? "#694B81" : "#585857",}} />}
              aria-controls="panel4a-content"
              id="panel4-header"
            >
              <StyleTypographyAcordion variant="h6" sx={{
                color: expanded === "panel4" ? "#694B81" : "#585857", 
                textAlign:'start',
                '@media (max-width: 750px)': {
                  fontSize: "0.8rem",
                  padding:'0px'
                },
              }}>
                Captación nuevos clientes
              </StyleTypographyAcordion>
            </AccordionSummary>
            <AccordionDetails>
              <StyleTypographycarddosacordeon>
              A través de generación de bases de datos de visitantes de memoriales.
              </StyleTypographycarddosacordeon>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel5"} // Compara si el panel4 es el actual expandido
            onChange={handleChange("panel5")}
            sx={{
                background:'#F0EDF3',
              boxShadow: "none",
              border: "0px solid ",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
             sx={{
                background:'#F0EDF3',
            }}
              expandIcon={<ExpandMoreIcon  sx={{
                color: expanded === "panel5" ? "#694B81" : "#585857",}} />}
              aria-controls="panel5a-content"
              id="panel5-header"
            >
              <StyleTypographyAcordion variant="h6" sx={{
                color: expanded === "panel5" ? "#694B81" : "#585857", 
                textAlign:'start',
                '@media (max-width: 750px)': {
                  fontSize: "0.8rem",
                  padding:'0px'
                },
              }}>
               Calidad de servicio
              </StyleTypographyAcordion>
            </AccordionSummary>
            <AccordionDetails>
              <StyleTypographycarddosacordeon>
              A través del envío de encuesta de satisfacción a cada uno de sus clientes.
              </StyleTypographycarddosacordeon>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel6"} // Compara si el panel4 es el actual expandido
            onChange={handleChange("panel6")}
            sx={{
                background:'#F0EDF3',
              boxShadow: "none",
              border: "0px solid ",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
             sx={{
                background:'#F0EDF3',
            }}
              expandIcon={<ExpandMoreIcon  sx={{
                color: expanded === "panel6" ? "#694B81" : "#585857",}} />}
              aria-controls="panel6a-content"
              id="panel6-header"
            >
              <StyleTypographyAcordion variant="h6" sx={{
                color: expanded === "panel6" ? "#694B81" : "#585857", 
                textAlign:'start',
                '@media (max-width: 750px)': {
                  fontSize: "0.8rem",
                  padding:'0px'
                },
              }}>
               Posicionamiento y reforzamiento de la marca
              </StyleTypographyAcordion>
            </AccordionSummary>
            <AccordionDetails>
              <StyleTypographycarddosacordeon>
              A través de los memoriales que son diseñadas con la marca, colores corporativos e información de la empresa.
              </StyleTypographycarddosacordeon>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel7"} // Compara si el panel4 es el actual expandido
            onChange={handleChange("panel7")}
            sx={{
                background:'#F0EDF3',
              boxShadow: "none",
              border: "0px solid ",
              "&:before": {
                display: "none",
              },
            }}
          >
            <AccordionSummary
             sx={{
                background:'#F0EDF3',
            }}
              expandIcon={<ExpandMoreIcon  sx={{
                color: expanded === "panel7" ? "#694B81" : "#585857",}} />}
              aria-controls="panel7a-content"
              id="panel7-header"
            >
              <StyleTypographyAcordion variant="h6" sx={{
                color: expanded === "panel7" ? "#694B81" : "#585857", 
                textAlign:'start',
                '@media (max-width: 750px)': {
                  fontSize: "0.8rem",
                  padding:'0px'
                },
              }}>
               Exclusividad
              </StyleTypographyAcordion>
            </AccordionSummary>
            <AccordionDetails>
              <StyleTypographycarddosacordeon>
              A través de desarrollo de funciones personalizadas y a la medida de la empresa.
              </StyleTypographycarddosacordeon>
            </AccordionDetails>
          </Accordion>
        </div>
      </Container>
    </section>
    <section>
      <Container maxWidth='lg' sx={{marginTop:'100px', marginBottom:'100px'}}>
      <StyledTitle variant="h4" gutterBottom>
      Nuestros planes empresa
        </StyledTitle>
        <section className="tectogenerado" style={{ marginTop:'0px', marginbottom:'20px'}}>
                    <span >
                    Elija el servicio que se adapte a las necesidades que su empresa requiera
                    </span>
        </section>
        <Grid container spacing={4} sx={{marginTop:'50px'}}>
        {plans.map((plan, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card sx={{
                 padding:'30px',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                flexDirection:'column',
                backgroundColor: plan.color,
              }} >
                <CardContent sx={{
                  padding:'30px',
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                flexDirection:'column',
                '@media (max-width: 480px)': {
                  padding:'0px'
                },
              }}>
                  <Typography
                   variant="h5"
                    component="div" 
                    gutterBottom 
                    sx={{
                      fontFamily:'Poppins',  
                      fontWeight:'600', 
                      fontSize:'1.4rem',  
                      color:'#585857',
                      textAlign:'center',
                      '@media (max-width: 330px)': {
                        fontSize: "1.4rem",
                      },
                      }}>
                    {plan.title}
                  </Typography>
                  <Typography 
                  variant="h6"
                   color="primary" 
                   gutterBottom 
                   sx={{
                    textAlign:'center',
                    fontFamily:'Poppins', 
                    color:'#585857', 
                    fontWeight:'500',
                    fontSize:'2rem',
                    '@media (max-width: 330px)': {
                        fontSize: "1.5rem",
                      },
                    }}>
                    {plan.price}
                  </Typography>
                  <Typography
                   variant="h6" 
                  gutterBottom 
                  sx={{
                    textAlign:'center',
                    fontFamily:'Poppins', 
                    fontWeight:'500', 
                    fontSize:'0.9rem', 
                    color:'#585857',
                    '@media (max-width: 330px)': {
                        fontSize: "0.8rem",
                      },
                    }}>
                    Por Mes
                  </Typography>
                  <hr style={{
                    width:'80%'
                  }} />
                  <List  sx={{
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                flexDirection:'column'
              }}>
                    {plan.features.map((feature, idx) => (
                      <ListItem key={idx} disablePadding sx={{textAlign:'center', fontFamily:'Poppins'}}>
                        <ListItemText primary={feature} sx={{'& .MuiTypography-root':{fontFamily:'Poppins',  textAlign:'center', color:'#585857',fontWeight:'500',fontSize:'0.8rem','@media (max-width: 330px)': {
                        fontSize: "0.7rem",
                      },}}} />
                      </ListItem>
                    ))}
                  </List>
                  <Typography variant="h6"  gutterBottom sx={{fontFamily:'Poppins', textAlign:'center', fontWeight:'500', color:'#585857',fontSize:'0.8rem','@media (max-width: 330px)': {
                        fontSize: "0.7rem",
                      },}}>
                    {plan.placa}
                  </Typography>
                </CardContent>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={() => {
                    const contactSection = document.getElementById("contact");
                    if (contactSection) {
                    contactSection.scrollIntoView({ behavior: "smooth" });
                    }
                }}
                  sx={{
                    textAlign:'center',
                    fontFamily:'Poppins',
                    backgroundColor: "#694B81",
                    width:'90%',  
                  color: "#ffff",
                  fontWeight: "500",
                  textTransform: "capitalize",
                  borderRadius: "50px",
                  padding: "10px 20px",
                  transition: "all 0.3s ease",
                  "&:hover": {
                    backgroundColor: "#ffff",
                    color: "#694B81",
                    border:'1px solid #694B81',
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
                  },
                  '@media (max-width: 7000px)': {
                        fontSize: "0.7rem",
                        width:'100%',  
                      },
                  }}
                >
                  {plan.buttonText}
                </Button>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </section>
    <ParallaxSectiontres  id="contact">
        <ParallaxImagedos alt="Parallax Background" />
        <Container maxWidth='sm'>
        <Box
        
          component="form"
          onSubmit={handleSubmit}
          sx={{
            position:'relative',
            marginTop:'40px',
            display: "flex",
            flexDirection: "column",
            gap: 2,
            p: 3,
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          <Typography variant="h4" align="center" gutterBottom sx={{fontFamily:'Poppins', fontWeight:'600',fontSize:'1.5rem',color:'#585857', marginBottom:'0px',  '@media (max-width: 750px)': {
        fontSize: "1rem",
        
      }, }}>
          Solicite asesoría
          </Typography>
          <Typography variant="h6" align="center" gutterBottom sx={{fontFamily:'Poppins', fontWeight:'500',fontSize:'0.9rem',color:'#585857', '@media (max-width: 750px)': {
        fontSize: "0.7rem",
      
      },}}>
          Si está interesado en hacer crecer tu negocio o desea más información de nuestro servicio, contáctanos completando el siguiente formulario. Responderemos a la brevedad.
          </Typography>
    
          {success && (
            <Alert severity="success">¡Mensaje enviado correctamente!</Alert>
          )}
          {error && <Alert severity="error">{error}</Alert>}
    
          <TextField
          sx={{background:"#ffff", fontFamily:'Poppins', color:'#585857'}}
            label="Nombre"
            name="name"
            value={formData.name}
            onChange={handleChanges}
            fullWidth
            required
          />
    
          <TextField
          sx={{background:"#ffff", fontFamily:'Poppins', color:'#585857'}}
            label="Email"
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChanges}
            fullWidth
            required
          />
    
          <TextField
          sx={{background:"#ffff", fontFamily:'Poppins', color:'#585857'}}
            label="Mensaje"
            name="message"
            value={formData.message}
            onChange={handleChanges}
            multiline
            rows={4}
            fullWidth
            required
          />
    
          <Box display="flex" justifyContent="center" alignItems="center">
            {isSubmitting ? (
              <CircularProgress />
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                sx={{ px: 4, fontFamily:'Poppins', textTransform:'capitalize' }}
              >
                Enviar
              </Button>
            )}
          </Box>
        </Box>
        </Container>
        </ParallaxSectiontres>
        </>
    )
}

// Styled Components
const ParallaxSection = styled(Box)({
    position: "relative",
    height: "80vh",
    overflow: "hidden",
    '@media (max-width: 850px)': {
   overflow:'auto'// Para asegurar que la posición cambie
  },
  });
  
  const ParallaxImage = styled("div")({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${fondo})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed", // Imagen fija
    '@media (max-width: 850px)': {
    backgroundAttachment: "scroll", // Imagen estática
   
  },
  });
  
  const FixedContent = styled(Container)({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
    color: "#fff",
    zIndex: 2,
    "& .title": {
      fontFamily: "Poppins",
      fontWeight: 600,
      fontSize: "2.5rem",
      marginBottom: "0",
      '@media (max-width: 750px)': {
        fontSize: "1.5rem",
      },
    },
    "& .description": {
      fontFamily: "Poppins",
      fontWeight: 400,
      fontSize: "1.2rem",
      marginTop:'0.7rem',
      '@media (max-width: 750px)': {
        fontSize: "0.8rem",
        marginTop:'9px'
      },
    },
    "& .cta-button": {
      fontFamily:'Poppins',
      backgroundColor: "#fff",
      color: "#694B81",
      fontWeight: "500",
      textTransform: "capitalize",
      borderRadius: "50px",
      padding: "10px 20px",
      transition: "all 0.3s ease",
      margin:'20px',
      "&:hover": {
        backgroundColor: "#694B81",
        color: "#fff",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      },
      '@media (max-width: 750px)': {
        fontSize: "0.8rem",
       
      },
    },
  });

  const StyledButton = styled(Button)({
    fontFamily:'Poppins',
    backgroundColor: "#694B81",
    color: "#ffff",
    fontWeight: "500",
    textTransform: "capitalize",
    borderRadius: "50px",
    padding: "10px 20px",
    transition: "all 0.3s ease",
    "&:hover": {
      backgroundColor: "#ffff",
      color: "#694B81",
      border:'1px solid #694B81',
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
    },
    })
    const StyledBoton = styled(Button)({
      backgroundColor: "#694B81",
      color: "#ffff",
      fontWeight: "500",
      textTransform: "capitalize",
      borderRadius: "50px",
      padding: "10px 20px",
      transition: "all 0.3s ease",
      "&:hover": {
        backgroundColor: "#ffff",
        color: "#694B81",
        border:'1px solid #694B81',
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
      },
      })
    
    const StyledTitle = styled(Typography)({
        fontFamily: "Poppins",
        fontWeight: 600,
        color: "#585857",
        textAlign: "center",
        marginBottom:'10px',
        '@media (max-width: 750px)': {
            fontSize: "1.3rem",
          
           
          },
          '@media (max-width: 480px)': {
            fontSize: "1rem",
           
          },
      });

      /* portada */

  const StyledTitleportada = styled(Typography)({
    fontFamily: "Poppins",
    fontWeight: 600,
    color: "#585857",
    textAlign: "center",
    marginBottom:'40px',
    '@media (max-width: 750px)': {
        fontSize: "1.2rem",
      },
      '@media (max-width: 480px)': {
        fontSize: "1rem",
       
      },
  });
  
  const StyledTitleTextportada = styled(Typography)({
    fontFamily: "Poppins",
    fontWeight: 500,
    color: "#585857",
    textAlign: "justify",
    marginBottom:'40px',
    '@media (max-width: 750px)': {
      fontSize: "0.8rem",
      padding:'0px 10px'
    },
  });
  const ParallaxSectiontres = styled(Box)({
    position: "relative",
    height: "100vh",
    overflow: "hidden",
    '@media (max-width: 850px)': {
  overflow:'auto'
  },
  });
  const ParallaxImagedos = styled("div")({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundImage: `url(${fondo})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed", // Imagen fija
    '@media (max-width: 850px)': {
    backgroundAttachment: "scroll", // Imagen estática
    }
  });
  
  
/* acordeon */
const StyleTypographyAcordion = styled(Typography)({
    textAlign:'center',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    fontFamily:'Poppins',
    fontWeight:'600',
    color: "#585857",
    '@media (max-width: 750px)': {
      fontSize: "1rem",
    },
    
   });
  
   const StyleTypographycarddosacordeon = styled(Typography)({
    textAlign:'start',
    display:'flex',
    alignItems:'center',
    justifyContent:'flex-start',
    fontFamily:'Poppins',
    fontWeight:'500',
    color: "#585857",
    '@media (max-width: 750px)': {
      fontSize: "0.75rem",
    },
    
   });